import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import WasSindEigentlichCTeileAntwortSVG from "./_Components/WasSindEigentlichCTeileAntwortSVG";
import { BetterImage } from "playbook-pwa-ui";

export default function WasSindEigentlichCTeileAntwort() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} style={{ height: "90%" }}>
          <BetterImage
            src={
              <WasSindEigentlichCTeileAntwortSVG
                style={{ height: "100%", width: "100%" }}
              />
            }
            alt="Das Ziel: Prozesse reduzieren"
            height="100%"
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
