// Components
import { Helmet } from "react-helmet";
import { getChapterByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";

// Custom Components
import ChapterWrapper from "../../../components/ChapterWrapper";
import Beratungsdialog from "../../../components/Beratungsdialog";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading"

// Sektions
import Timeline from "./sections/Timeline";
import Mission from "./sections/Mission";
import Vision from "./sections/Vision";
export default function Ueber_SSV_Kroschke() {
  const chapterID = 1;
  const chapterData = useSelector((state) => getChapterByID(state, chapterID));

  if (!chapterData) {
    return <ChapterLoading />
  }

  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;

  return (
    <>
      <Helmet>
        <title>SSV-Kroschke Playbook | Über SSV-Kroschke</title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <Timeline anchor="usp" />
        <Mission anchor="mission" />
        <Vision anchor="vision" />
      </ChapterWrapper>
    </>
  );
}
