import ExpertenInVielenBereichen from "./slides/ExpertenInVielenBereichen";
import Facts from "./slides/Facts";
import Karte from "./slides/Karte";
import Video from "./slides/Video";

export default function KlausKroschkeGruppeSection() {
    return [
      <Facts key="facts" />,
      <Karte key="karte" />,
      <ExpertenInVielenBereichen key="experten" />,
      <Video key="video" />
    ];
  }
  