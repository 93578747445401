import { gsap } from "playbook-pwa-ui";

import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";

// SVGs
import QHeader from "../../../../../../components/QHeader";
import Calculator from "./_Components/Calculator";
import Files from "./_Components/Files";
import Star from "./_Components/Star";
import Magnifier from "./_Components/Magnifier";

export default function Vorteile2() {

  return (
    <Slide id="vorteile-2">
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Vorteile im Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Calculator className="gsap-facts" />
              <Typography align="center" variant="body1">
                Budgetierung: nach Kostenstellen, Personen und Zeiträumen
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Files className="gsap-facts" />
              <Typography align="center" variant="body1">
                Verwaltung von Kostenstellen, Kostenarten und Kostenträgern
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Star className="gsap-facts" />
              <Typography align="center" variant="body1">
                Individuelle Favoritenlisten
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Magnifier className="gsap-facts" />
              <Typography align="center" variant="body1">
                Transparenz in allen Vorgängen
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
