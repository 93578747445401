import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import ReactPlayer from "react-player";
import PlayIcon from "../../../../../../components/SvgIcons/PlayIcon";
import Standbild from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/azubi-und-studi-standbild.webp"

export default function Video() {
  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <Box width="100%" height="100%">
            <ReactPlayer
              width="100%"
              height="100%"
              style={{
                margin: "0px auto",
                display: "block",
                maxHeight: "calc(100dvh - 90px * 2)",
                userSelect: "none",
                "&:focus-visible": {
                  outline: "none",
                },
              }}
              controls
              playing
              playIcon={<PlayIcon style={{width: 100, height: 100}} />}
              url={[{src: '/assets/_Chapters/Karriere/AusbildungUndStudium/azubi-und-studi.mp4', type: 'video/mp4'}]}
              light={Standbild}
            />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
