import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/BeratungPlanungMontage/Schaumeinlage.webp";
import { Box, Grid, Typography } from "@material-ui/core";

export default function SieHabenDieWahl() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "left",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Sie haben die Wahl" />
          <Box width="80%">
            <QList
              items={[
                "Integrieren Sie die Schaumeinlagen in vorhandene Systeme",
              ]}
            />
            <Typography variant="body1">ODER</Typography>
            <QList
              items={[
                "Nutzen Sie unsere Expertise und wir entwerfen Ihnen auf Sie zugeschnittene Schubladensysteme",
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
