import { theme } from "../../../../../../../theme";

export default function BeispielGrafikSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg {...props} viewBox="0 0 3582 1137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8_298)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2158.63 501.041L1791.08 137L1423.37 503.499L1293 1001.06L1791.08 1137L2289 1003.48L2158.63 501.041ZM1791.08 166.45L2072.23 452.352L2034.37 479.472L1852.29 345.356L1791.08 221.326L1726.33 351.289L1552.76 479.43L1506.38 458.327L1791.04 166.366L1791.08 166.45ZM1319.98 984.705L1426.97 598.079L1469.36 617.359L1444.29 842.157L1367.59 957.204L1512.44 948.305L1710.19 1034.62L1715.06 1085.26L1320.02 984.705H1319.98ZM1598.75 711.897C1597.65 605.664 1682.91 518.711 1789.09 517.609C1895.28 516.508 1982.19 601.723 1983.29 707.956C1984.39 814.19 1899.21 901.227 1793.03 902.329C1686.85 903.431 1599.86 818.131 1598.75 711.897ZM1874.61 1034.62L2072.27 948.305L2217.17 957.204L2140.46 842.157L2115.39 617.359L2157.79 598.079L2264.77 984.705L1869.74 1085.26L1874.61 1034.62Z" fill="#E4032E"/>
        <path d="M0 235H1000" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M997.979 235L1330.54 411.35" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M0 661H1267.59" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M0 1105H1000" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M997 1105.5L1212.65 980.994" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M3582 1103H2582" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M2585 1103.5L2351.46 979" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M2294.46 661H3582" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M3582 235H2582" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <path d="M2584 234.5L2234.46 411.347" stroke="#E20030" stroke-width="10.3125" stroke-miterlimit="8"/>
        <g className="text-element">
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="2597" y="67.32">Warenwert:&#10;</tspan><tspan x="2597" y="171.32">250 &#x20ac;/Bestellung</tspan></text>
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="467.511" y="88.32">Volumen p.a.:&#10;</tspan><tspan x="512.2" y="192.32">1.000.000 &#x20ac;</tspan></text>
        </g>
        <g className="text-element">
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="2597" y="516.32">Prozesskosten:&#10;</tspan><tspan x="2597" y="620.32">je Bestellung 89,36 &#x20ac;</tspan></text>
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="293.851" y="516.32">Bestellungen p.a.:&#10;</tspan><tspan x="752.555" y="620.32">4.000</tspan></text>
        </g>
        <g className="text-element">
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="2597" y="944.32">Gesamt-Prozesskosten:&#10;</tspan><tspan x="2597" y="1048.32">357.860 &#x20ac; p.a.</tspan></text>
          <text fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="84" ><tspan x="345.422" y="944.32">20 Bestellungen&#10;</tspan><tspan x="705.741" y="1048.32">pro Tag</tspan></text>
          <path d="M281.232 937.24L277.644 933.376L281.968 929.052C280.312 927.089 279.055 924.973 278.196 922.704C277.399 920.435 277 917.981 277 915.344C277 911.419 277.951 907.861 279.852 904.672C281.815 901.421 284.421 898.845 287.672 896.944C290.923 894.981 294.48 894 298.344 894C300.859 894 303.251 894.429 305.52 895.288C307.851 896.085 309.997 897.312 311.96 898.968L316.652 894.276L320.148 898.232L315.548 902.832C316.959 904.733 318.001 906.727 318.676 908.812C319.351 910.836 319.688 913.013 319.688 915.344C319.688 919.208 318.707 922.765 316.744 926.016C314.843 929.267 312.267 931.873 309.016 933.836C305.827 935.737 302.269 936.688 298.344 936.688C296.013 936.688 293.805 936.351 291.72 935.676C289.635 935.001 287.672 933.989 285.832 932.64L281.232 937.24ZM286.016 925.004L307.912 903.108C306.44 902.004 304.907 901.176 303.312 900.624C301.779 900.072 300.123 899.796 298.344 899.796C295.461 899.796 292.824 900.501 290.432 901.912C288.101 903.261 286.231 905.132 284.82 907.524C283.471 909.855 282.796 912.461 282.796 915.344C282.796 917.123 283.041 918.84 283.532 920.496C284.084 922.091 284.912 923.593 286.016 925.004ZM298.344 930.892C301.165 930.892 303.741 930.217 306.072 928.868C308.464 927.457 310.365 925.587 311.776 923.256C313.187 920.864 313.892 918.227 313.892 915.344C313.892 913.811 313.677 912.369 313.248 911.02C312.88 909.609 312.297 908.26 311.5 906.972L289.972 928.5C291.199 929.297 292.517 929.911 293.928 930.34C295.339 930.708 296.811 930.892 298.344 930.892Z" fill="black"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8_298">
          <rect width="3582" height="1137" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}
