import WirAlsSystemintegrator from "./slides/WirAlsSystemintegrator";
import WirFindenDiePerfekteLoesung from "./slides/WirFindenDiePerfekteLoesung";
import AllesUnterEinemDach from "./slides/AllesUnterEinemDach";

export default function Anwendungstechnik() {
  return [
    <AllesUnterEinemDach key="wir-optimieren" />,
    <WirFindenDiePerfekteLoesung key="sie-haben-die-wahl" />,
    <WirAlsSystemintegrator key="wir-als-systemintegrator" />,
  ];
}
