import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import UndKostenEinsparenSVG from "./_Components/UndKostenEinsparenSVG";

export default function UndKostenEinsparen() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="Und Kosten einsparen" />
        </Grid>
        <Grid item xs={12} style={{ height: "60%" }}>
          <UndKostenEinsparenSVG style={{ height: "100%", width: "100%" }} />
        </Grid>
      </Grid>
    </Slide>
  );
}
