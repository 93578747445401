const SET_ACTIVE_MODULE = 'playbook/consulting/SET_ACTIVE_MODULE'
const SET_APPOINTMENT = 'playbook/consulting/SET_APPOINTMENT'
const SET_APPOINTMENT_PLACE = 'playbook/consulting/SET_APPOINTMENT_PLACE'
const SET_NEXT_APPOINTMENT = 'playbook/consulting/SET_NEXT_APPOINTMENT'
const SET_NEXT_APPOINTMENT_PLACE =
  'playbook/consulting/SET_NEXT_APPOINTMENT_PLACE'
const SET_CONSULTING_RUNNING = 'playbook/consulting/SET_CONSULTING_RUNNING'
const SET_GENERAL_NOTES = 'playbook/consulting/SET_GENERAL_NOTES'
const SET_TOPIC = 'playbook/consulting/SET_TOPIC'
const SET_INTERNAL_ID = 'playbook/consulting/SET_INTERNAL_ID'
const SET_CHAPTERS = 'playbook/consulting/SET_CHAPTERS'

const initialState = {
  activeModule: '',
  topic: '',
  appointment: null,
  appointmentPlace: '',
  nextAppointment: null,
  nextAppointmentPlace: '',
  chapters: [],
  running: false,
  notes: '',
  internalID: null,
}

const consulting = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODULE: {
      return {
        ...state,
        activeModule: action.key,
      }
    }
    case SET_APPOINTMENT: {
      return {
        ...state,
        appointment: action.appointment,
      }
    }
    case SET_APPOINTMENT_PLACE: {
      return {
        ...state,
        appointmentPlace: action.appointmentPlace,
      }
    }
    case SET_NEXT_APPOINTMENT: {
      return {
        ...state,
        nextAppointment: action.nextAppointment,
      }
    }
    case SET_NEXT_APPOINTMENT_PLACE: {
      return {
        ...state,
        nextAppointmentPlace: action.nextAppointmentPlace,
      }
    }
    case SET_CHAPTERS: {
      return {
        ...state,
        chapters: action.chapters,
      }
    }
    case SET_GENERAL_NOTES: {
      return {
        ...state,
        notes: action.notes,
      }
    }
    case SET_TOPIC: {
      return {
        ...state,
        topic: action.topic,
      }
    }
    case SET_CONSULTING_RUNNING: {
      return {
        ...state,
        running: action.status,
      }
    }
    case SET_INTERNAL_ID: {
      return {
        ...state,
        internalID: action.internalID,
      }
    }
    default:
      return state
  }
}

//actions
export const setActiveModule = key => ({
  type: SET_ACTIVE_MODULE,
  key,
})

export const setAppointment = appointment => ({
  type: SET_APPOINTMENT,
  appointment,
})

export const setAppointmentPlace = appointmentPlace => ({
  type: SET_APPOINTMENT_PLACE,
  appointmentPlace,
})

export const setNextAppointment = nextAppointment => ({
  type: SET_NEXT_APPOINTMENT,
  nextAppointment,
})

export const setNextAppointmentPlace = nextAppointmentPlace => ({
  type: SET_NEXT_APPOINTMENT_PLACE,
  nextAppointmentPlace,
})

export const setChapters = chapters => ({
  type: SET_CHAPTERS,
  chapters,
})

export const setGeneralNotes = notes => ({
  type: SET_GENERAL_NOTES,
  notes,
})

export const setConsultingRunning = status => ({
  type: SET_CONSULTING_RUNNING,
  status,
})

export const setConsultingTopic = topic => ({
  type: SET_TOPIC,
  topic,
})

export const setInternalID = internalID => ({
  type: SET_INTERNAL_ID,
  internalID,
})

//selector
export const getActiveModule = state => state.consulting.activeModule
export const getAppointment = state => state.consulting.appointment
export const getAppointmentPlace = state => state.consulting.appointmentPlace
export const getNextAppointment = state => state.consulting.nextAppointment
export const getNextAppointmentPlace = state =>
  state.consulting.nextAppointmentPlace
export const getChapters = state => state.consulting.chapters
export const getGeneralNotes = state => state.consulting.notes
export const getConsultingRunning = state => state.consulting.running
export const getConsultingTopic = state => state.consulting.topic
export const getConsultingInternalID = state => state.consulting.internalID
export const getConsultingData = state => state.consulting

export default consulting
