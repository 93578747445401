export default function Files({ className }) {
    return (
        <svg className={className} id="uuid-df91ca32-573f-42bd-99e7-ab579f355e1c" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
          <rect x="310.11" y="249.48" width="29.41" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="430.35 106.76 430.35 293.23 365.86 332.25 365.86 122.9 430.35 106.76" style="fill: #e4032e; stroke-width: 0px;"/>
          <path d="m291.68,116.12v215.66h66.1V116.12h-66.1Zm33.05,211.31c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm0-28.57c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm20.74-34.09h-41.48v-130.66h41.48v130.66Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="310.02" y="194.66" width="29.41" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="309.93" y="139.84" width="29.41" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <path d="m298.29,123.57h0s59.49,0,59.49,0h0s-59.49,0-59.49,0Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="149.15" y="249.56" width="30.34" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="123.47 123.65 123.47 331.86 58.98 292.84 58.98 107.5 123.47 123.65" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="197.46 116.12 131.36 116.12 66.87 99.98 123.17 99.98 197.46 116.12" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="291.68 116.12 357.78 116.12 422.27 99.98 365.97 99.98 291.68 116.12" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="149.24" y="194.74" width="30.34" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="149.33" y="139.92" width="30.34" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <path d="m131.36,116.12v215.74h66.1V116.12h-66.1Zm33.05,211.38c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm0-28.57c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm20.74-34.09h-41.48v-130.66h41.48v130.66Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <path d="m211.35,116.48v215.29h66.1V116.48h-66.1Zm33.05,210.94c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm0-28.57c-6.78,0-12.28-5.5-12.28-12.28s5.5-12.28,12.28-12.28,12.28,5.5,12.28,12.28-5.5,12.28-12.28,12.28Zm20.74-34.09h-41.48v-130.66h41.48v130.66Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="229.6" y="249.48" width="29.77" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="229.52" y="194.66" width="29.77" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="229.43" y="139.84" width="29.77" height="9.55" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="277.45 116.48 211.35 116.48 220.78 106.32 268.02 106.32 277.45 116.48" style="fill: #e4032e; stroke-width: 0px;"/>
        </svg>
    );
  }
  