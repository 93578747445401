import Slide from "../../../../../../components/Slide";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { theme } from "../../../../../../theme";

import ZitatImage from "../../../../../../assets/_Chapters/Karriere/Zitate/ausbildung-und-studium-2.webp";

const useStyles = makeStyles((theme) => ({
  fontRegular: {
    marginTop: "5px",
    fontWeight: 400,
  },
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
}));
export default function Zitat2() {
  const classes = useStyles();
  return (
    <Slide background={{ width: "100dvw", color: theme.palette.primary.main }}>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={12}>
          <Box className={classes.heightCorrection}>
            <svg
              style={{ width: "100%", height: "100%" }}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1932"
              height="990"
              viewBox="0 0 1932 990"
              fill="none"
            >
              <g clip-path="url(#clip0_897_83)">
                <text
                  fill="white"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Interstate"
                  font-size="30"
                  letter-spacing="0em"
                >
                  <tspan x="1231" y="946.8">
                    Aleksander, duales Studium für
                  </tspan>
                  <tspan x="1231" y="982.8">
                    Digitalisierungsmanagement
                  </tspan>
                </text>
                <mask
                  id="mask0_897_83"
                  style="mask-type:alpha"
                  maskUnits="userSpaceOnUse"
                  x="1231"
                  y="0"
                  width="701"
                  height="901"
                >
                  <rect x="1231" width="701" height="901" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_897_83)">
                  <rect
                    x="1231"
                    y="-39"
                    width="701"
                    height="1052"
                    fill="url(#pattern0_897_83)"
                  />
                </g>
                <text
                  fill="white"
                  xmlSpace="preserve"
                  style="white-space: pre"
                  font-family="Interstate"
                  font-size="120"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="0" y="361.2">
                    „SSV bietet hervor-
                  </tspan>
                  <tspan x="0" y="505.2">
                    ragende Übernahme-
                  </tspan>
                  <tspan x="0" y="649.2">
                    chancen entsprechend
                  </tspan>
                  <tspan x="0" y="793.2">
                    der individuellen Stärken.“
                  </tspan>
                </text>
              </g>
              <defs>
                <pattern
                  id="pattern0_897_83"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_897_83"
                    transform="matrix(0.000366385 0 0 0.000244141 -0.00029882 0)"
                  />
                </pattern>
                <clipPath id="clip0_897_83">
                  <rect width="1932" height="990" fill="white" />
                </clipPath>
                <image
                  id="image0_897_83"
                  width="2731"
                  height="4096"
                  xlinkHref={ZitatImage}
                />
              </defs>
            </svg>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
