import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import { Grid } from "@material-ui/core";
import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Elektr-Ausgabesystem/2.webp";

export default function Wiegzellenschrank1() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Alles im Blick mit dem Wiegezellenschrank" />
          <QList
            items={[
              "Keine internen Prozessaufwände",
              "Dauerhaft verfügbare Ware",
              "Warengruppenübergreifende Abwicklung",
              "Einfache & schnelle Artikelentnahme",
              "Abwicklung von Dienstleistungen möglich",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
