import { Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export default function BackgroundElement({ background, line }) {
  let zIndex = -1;
  let vColor = "";
  let vColorSecond = "";
  if (line !== undefined) {
    vColor = line.color === "secondary" ? grey["100"] : "rgba(228,3,46,1)";
    vColorSecond = background
      ? "transparent"
      : line.color === "secondary"
      ? "#fff"
      : grey["100"];
    zIndex = line.color === "secondary" ? "initial" : "-1";
  }
  return (
    <>
      {background && (
        <Box
          position="absolute"
          height="100dvh"
          width={background.width || "100dvw"}
          left={background.align === "left" ? "0px" : ""}
          right={background.align === "right" ? "0px" : ""}
          zIndex={zIndex}
          style={{
            userSelect: "none",
            pointerEvents: "none",
            backgroundRepeat: background.repeat || "no-repeat",
            backgroundSize: background.size || "cover",
            backgroundPosition: background.position || "center",
            backgroundImage: `url(${background.image})`,
            backgroundColor: background.color || "inherit",
          }}
        />
      )}
      {line && (
        <Box
          position="fixed"
          height="100dvh"
          width="100dvw"
          zIndex={zIndex}
          style={{
            userSelect: "none",
            pointerEvents: "none",
            backgroundImage: `linear-gradient(127deg, ${vColorSecond} 0%, ${vColorSecond} 18%, ${vColor} 18%, ${vColor} 46%, ${vColorSecond} 46%, ${vColorSecond} 100%)`,
            mixBlendMode: "multiply",
          }}
        />
      )}
    </>
  );
}
