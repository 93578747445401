import { Grid, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import AllgemeinSVG from "./_Components/AllgemeinSVG";
import QHeader from "../../../../../../components/QHeader";

export default function Allgemein() {
  return (
    <Slide>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <QHeader title="Nachhaltigkeit ist Teil unserer DNA" textAlign="center" />
        </Grid>
        <Grid item xs={12} style={{ height: "100%" }}>
          <AllgemeinSVG />
        </Grid>
      </Grid>
    </Slide>
  );
}
