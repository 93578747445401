import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoggedIn,
  fetchUserLogout,
  fetchUserDataIfNeeded,
  ssoRefresh,
} from "./redux/modules/userReducer";
import root from "window-or-global";
import { toast } from "playbook-pwa-ui";
import { route } from "preact-router";

import {
  getNewsData,
  setUnreadAmount,
  setNews,
} from "./redux/modules/newsReducer";
import {
  getBackendState,
  getStateVersion,
} from "./redux/modules/backendState";
import { getCompanies, setCompanies } from "./redux/modules/companiesReducer";
import { getCustomers, setCustomers } from "./redux/modules/customersReducer";
import APIService from "./api/index";

import "dayjs/locale/de";
import dayjs from "dayjs";

export default function AppWrap({ children }) {
  const dispatch = useDispatch();
  const isLoggedin = useSelector((state) => isLoggedIn(state));
  const newsData = useSelector((state) => getNewsData(state));
  const companies = useSelector((state) => getCompanies(state));
  const customers = useSelector((state) => getCustomers(state));
  const version = useSelector((state) => getStateVersion(state));

  dayjs.locale("de");

  useEffect(() => {
    dispatch(getBackendState());
  }, []); // eslint-disable-line

  const fetchCompanies = async () => {
    try {
      const response = await APIService.get(
        'api/companies/?size=10000',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      )
      dispatch(setCompanies(response.items));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await APIService.get(
        'api/customers/?size=10000',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      );
      dispatch(setCustomers(response.items));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    if (isLoggedin) {
      ssoRefresh();
      fetchCompanies();
      fetchCustomers();
    }
  }, [isLoggedin]);

  useEffect(async () => {
    dispatch(fetchUserDataIfNeeded());

    if (root) {
      dispatch(fetchUserDataIfNeeded());
      //route("/", true);
    }

    return () => {};
  }, []);

  return <Box>{children}</Box>;
}
