import { Grid, Box } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import DiagrammSVG from "./_Components/DiagrammSVG";
import { theme } from "../../../../../../theme";

export default function Intro() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Wir senken Ihre Kosten" />
          <QList
            items={[
              "Analyse Ihres Beschaffungsprozesses, Produktportfolios Ihre Lieferanten, der internen Logistik und Lagerkosten",
              "Erarbeitung eines Einsparpotentials zu einer wirksamen Weiterentwicklung ihres Unternehmens",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box
        height="100dvh"
        width="50dvw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
        bgcolor={theme.palette.primary.main}
      >
        <DiagrammSVG width="70%" style={{ maxHeight: "80dvh" }}/>
      </Box>
    </Slide>
  );
}
