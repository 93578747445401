export default function SmallKroschkeLogoRGB(props) {
  return (
    <svg
    {...props}
      height="32"
      viewBox="0 0 163 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_63_81)">
        <path
          d="M102.2 56.59H42.5C42.3675 56.59 42.26 56.6974 42.26 56.83V99.46C42.26 99.5925 42.3675 99.7 42.5 99.7H102.2C102.333 99.7 102.44 99.5925 102.44 99.46V56.83C102.44 56.6974 102.333 56.59 102.2 56.59Z"
          fill="#E20031"
        />
        <path
          d="M100.67 58.34V97.9699H44.01V58.34H100.67ZM102.2 54.8299H42.48C41.9495 54.8299 41.4408 55.0407 41.0658 55.4157C40.6907 55.7908 40.48 56.2995 40.48 56.8299V99.4699C40.48 100 40.6907 100.509 41.0658 100.884C41.4408 101.259 41.9495 101.47 42.48 101.47H102.19C102.72 101.47 103.229 101.259 103.604 100.884C103.979 100.509 104.19 100 104.19 99.4699V56.8199C104.19 56.2895 103.979 55.7808 103.604 55.4057C103.229 55.0307 102.72 54.8199 102.19 54.8199L102.2 54.8299Z"
          fill="#E20031"
        />
        <path
          d="M29.62 1.76001H2.08001C1.90328 1.76001 1.76001 1.90328 1.76001 2.08001V99.38C1.76001 99.5567 1.90328 99.7 2.08001 99.7H29.62C29.7967 99.7 29.94 99.5567 29.94 99.38V2.08001C29.94 1.90328 29.7967 1.76001 29.62 1.76001Z"
          fill="#4B4B4D"
        />
        <path
          d="M28.17 3.50997V97.97H3.48V3.50997H28.17ZM29.61 -0.0300293H2.07C1.521 -0.0300293 0.994489 0.188059 0.606289 0.57626C0.218089 0.96446 0 1.49097 0 2.03997V99.35C0 99.899 0.218089 100.425 0.606289 100.814C0.994489 101.202 1.521 101.42 2.07 101.42H29.61C30.1599 101.42 30.6875 101.202 31.0773 100.814C31.467 100.426 31.6874 99.8999 31.69 99.35V2.06997C31.6926 1.79514 31.6408 1.52252 31.5374 1.26785C31.4341 1.01317 31.2813 0.781512 31.0879 0.586243C30.8945 0.390973 30.6643 0.23597 30.4106 0.130192C30.157 0.0244136 29.8848 -0.030042 29.61 -0.0300293Z"
          fill="#E20031"
        />
        <path
          d="M160.6 1.76001H116.25C115.792 1.76001 115.42 2.13161 115.42 2.59001V98.87C115.42 99.3284 115.792 99.7 116.25 99.7H160.6C161.058 99.7 161.43 99.3284 161.43 98.87V2.59001C161.43 2.13161 161.058 1.76001 160.6 1.76001Z"
          fill="#E20031"
        />
        <path
          d="M159.67 3.50997V97.97H117.18V3.50997H159.67ZM160.6 -2.86102e-05H116.25C115.564 0.0026052 114.907 0.276325 114.421 0.761475C113.936 1.24662 113.663 1.90387 113.66 2.58997V98.86C113.663 99.5461 113.936 100.203 114.421 100.688C114.907 101.174 115.564 101.447 116.25 101.45H160.6C161.286 101.447 161.943 101.174 162.428 100.688C162.914 100.203 163.187 99.5461 163.19 98.86V2.58997C163.193 2.2477 163.128 1.90827 162.999 1.59116C162.87 1.27405 162.68 0.985513 162.439 0.74209C162.198 0.498668 161.912 0.305155 161.597 0.172655C161.281 0.0401554 160.942 -0.028723 160.6 -0.0300293V-2.86102e-05Z"
          fill="#E20031"
        />
      </g>
      <defs>
        <clipPath id="clip0_63_81">
          <rect width="163" height="107" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
