import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo1 from "../../../../../../assets/_Chapters/CTeileSortiment/Betriebeinrichtung/fetra.png";
import Logo2 from "../../../../../../assets/_Chapters/CTeileSortiment/Betriebeinrichtung/lisat.jpg";
import Logo3 from "../../../../../../assets/_Chapters/CTeileSortiment/Betriebeinrichtung/meta.png";
import Logo4 from "../../../../../../assets/_Chapters/CTeileSortiment/Betriebeinrichtung/variofit.jpg";
import Logo5 from "../../../../../../assets/_Chapters/CTeileSortiment/Betriebeinrichtung/zarges.jpg";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/betriebseinrichtung.webp";

const useStyles = makeStyles({
  container: {
    "& .MuiGrid-grid-xs-4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100px",
        width: "auto",
        maxWidth: "75%",
      },
    },
  },
});

export default function Betriebseinrichtung() {
  const classes = useStyles();
  return (
    <Slide
      background={{
        image: BackgroundImage,
        size: "55dvw",
        align: "left",
        position: "top left",
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <QHeader title="Betriebseinrichtung" textAlign="center" />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <img src={Logo1} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo2} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo3} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <img src={Logo4} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo5} alt="Logo" loading="lazy" />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
