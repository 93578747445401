import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";

// SVGs
import QHeader from "../../../../../../components/QHeader";
import PaperStats from "./_Components/PaperStats";
import Server from "./_Components/Server";
import Smartphone from "./_Components/Smartphone";
import Anchor from "./_Components/Anchor";

export default function Vorteile3() {

  return (
    <Slide id="vorteile-3">
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Vorteile im Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <PaperStats className="gsap-facts" />
              <Typography align="center" variant="body1">
                Reporting
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Server className="gsap-facts" />
              <Typography align="center" variant="body1">
                Anbindung an vorhandene elektronische Ausgabesysteme
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Smartphone className="gsap-facts" />
              <Typography align="center" variant="body1">
                App für mobiles & flexibles Arbeiten
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Anchor className="gsap-facts" />
              <Typography align="center" variant="body1">
                Anbindung an Ihr ERP-System
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
