const SET_CHAPTER_ID = "playbook/consulting/SET_CHAPTER_ID";
const SET_NEXT_APPOINTMENT = "playbook/consulting/SET_NEXT_APPOINTMENT";
const SET_ACTIVE_CHAPTERS = "playbook/consulting/SET_ACTIVE_CHAPTERS";
const SET_CONSULTING_HAS_STARTED =
  "playbook/consulting/SET_CONSULTING_HAS_STARTED";
const SET_CONSULTING_HAS_STARTED_DATE =
  "playbook/consulting/SET_CONSULTING_HAS_STARTED_DATE";
const SET_NEXT_APPOINTMENT_PLACE =
  "playbook/consulting/SET_NEXT_APPOINTMENT_PLACE";
const SET_GENERAL_NOTES = "playbook/consulting/SET_GENERAL_NOTES";
const SET_CONSULTING_HAS_ENDED = "playbook/consulting/SET_CONSULTUNG_HAS_ENDED";
const SET_CONSULTING_TIME_TRACKING_STATUS =
  "playbook/consulting/SET_CONSULTING_TIME_TRACKING_STATUS";
const SET_CONSULTING_TOPIC = "playbook/consulting/SET_CONSULTING_TOPIC";
const SET_INTERNALID = "playbook/consulting/SET_INTERNALID";

const initialState = {
  chapterId: 0,
  nextAppointment: null,
  nextAppointmentPlace: "",
  activeChapters: {
    missiontozero: false,
    freeathome: false,
    welcome: false,
    welcomeip: false,
    knx: false,
    comfortline: false,
    installationsgeraete: false,
    cwa: false,
    jahresgespraech_2021: false,
    branchenkonfiguratorIndustrie: false,
    jahresgespraech_2022: false,
  },
  consultingStarted: null,
  consultingDeltaTime: null,
  consultingEnd: null,
  timeTracking: null,
  notes: "",
  internalID: null,
  topic: "",
};

const consulting = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAPTER_ID: {
      return {
        ...state,
        chapterId: action.chapterId,
      };
    }
    case SET_NEXT_APPOINTMENT: {
      return {
        ...state,
        nextAppointment: action.nextAppointment,
      };
    }
    case SET_ACTIVE_CHAPTERS: {
      return {
        ...state,
        activeChapters: action.activeChapters,
      };
    }
    case SET_CONSULTING_HAS_STARTED: {
      return {
        ...state,
        consultingStarted: new Date().toISOString(),
      };
    }
    case SET_CONSULTING_HAS_STARTED_DATE: {
      return {
        ...state,
        consultingStarted: action.date,
      };
    }
    case SET_NEXT_APPOINTMENT_PLACE: {
      return {
        ...state,
        nextAppointmentPlace: action.nextAppointmentPlace,
      };
    }
    case SET_GENERAL_NOTES: {
      return {
        ...state,
        notes: action.notes,
      };
    }
    case SET_CONSULTING_HAS_ENDED: {
      return {
        ...state,
        consultingEnd: new Date().toISOString(),
      };
    }
    case SET_CONSULTING_TIME_TRACKING_STATUS: {
      return {
        ...state,
        timeTracking: action.status,
      };
    }
    case SET_CONSULTING_TOPIC: {
      return {
        ...state,
        topic: action.topic,
      };
    }
    case SET_INTERNALID: {
      return {
        ...state,
        internalID: action.internalID,
      };
    }
    default:
      return state;
  }
};

//actions
export const setActiveChapter = (chapterId) => ({
  type: SET_CHAPTER_ID,
  chapterId,
});

export const setNextAppointment = (nextAppointment) => ({
  type: SET_NEXT_APPOINTMENT,
  nextAppointment,
});

export const setActiveChapters = (activeChapters) => ({
  type: SET_ACTIVE_CHAPTERS,
  activeChapters,
});

export const setConsultingHasStarted = () => ({
  type: SET_CONSULTING_HAS_STARTED,
});

export const setConsultingHasStartedDate = (date) => ({
  type: SET_CONSULTING_HAS_STARTED_DATE,
  date: date.toISOString(),
});

export const setConsultingHasEnded = () => ({
  type: SET_CONSULTING_HAS_ENDED,
});

export const setNextAppointmentPlace = (nextAppointmentPlace) => ({
  type: SET_NEXT_APPOINTMENT_PLACE,
  nextAppointmentPlace,
});

export const setGeneralNotes = (notes) => ({
  type: SET_GENERAL_NOTES,
  notes,
});

export const setConsultingTimeTrackingStatus = (status) => ({
  type: SET_CONSULTING_TIME_TRACKING_STATUS,
  status,
});

export const setConsultingTopic = (topic) => ({
  type: SET_CONSULTING_TOPIC,
  topic,
});

export const setInternalID = (internalID) => ({
  type: SET_INTERNALID,
  internalID,
});

//selector
export const getActiveChapter = (state) => state.consulting.chapterId;
export const getConsultingStarted = (state) =>
  state.consulting.consultingStarted;
export const getConsultingEnd = (state) => state.consulting.consultingEnd;
export const getNextAppointmentPlace = (state) =>
  state.consulting.nextAppointmentPlace;
export const getConsultingTimeTrackingStatus = (state) =>
  state.consulting.timeTracking;
export const getGeneralNotes = (state) => state.consulting.notes;
export const getConsultingTopic = (state) => state.consulting.topic;
export const getConsultingInternalID = (state) => state.consulting.internalID;
export const getConsultingData = (state) => state.consulting;

export default consulting;
