import { Grid, makeStyles, Box } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import DieBenoetigteZeitFuerCTeileSVG from "./_Components/DieBenoetigteZeitFuerCTeileSVG";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.secondary.main,
  },
}));

export default function DieBenoetigteZeitFuerCTeile() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="Die benötigte Zeit für C-Teile" />
        </Grid>
        <Grid item xs={12} style={{ height: "60%" }}>
          <DieBenoetigteZeitFuerCTeileSVG
            style={{ height: "100%", width: "100%" }}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
