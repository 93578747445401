import { Circ } from "gsap";

const gsapBarData = {
  stagger: 1.2,
  duration: 1,
};

const gsapBarSlowData = {
  stagger: 1.2,
  duration: 1.6,
};

const gsapArrowlineData = {
  delay: 1,
  stagger: 0.2,
  duration: 0.3,
};

const gsappSlideFromLeft = {
  delay: 0.8,
  duration: 1.2,
};

const gsapMultipleHover = {
  duration: 1.6,
};

const gsapChart = {
  delay: 0.8,
  duration: 1.6,
};

export const gsapDB = [
  {
    element: ".text-element",
    set: { opacity: 0, y: -100 },
    to: { delay: 0.6, stagger: 0.5, opacity: 1, y: 0 },
  },
  {
    element: ".gsap-arrow-marathon",
    set: { opacity: 0, y: 300 },
    to: { delay: 0.6, stagger: 0.3, opacity: 1, y: 0 },
  },
  {
    element: ".gsap-bar:not(.slow)",
    set: {
      opacity: 1,
      scaleY: 0,
      transformOrigin: "center bottom",
    },
    to: {
      delay: 0.4,
      stagger: gsapBarData.stagger,
      duration: gsapBarData.duration,
      ease: "power2.out",
      scaleY: 1,
    },
  },
  {
    element: ".gsap-bar.slow",
    set: { scaleY: 0, transformOrigin: "center bottom" },
    to: {
      delay: 0.4,
      stagger: gsapBarSlowData.stagger * 1.2,
      duration: gsapBarSlowData.duration * 1.2,
      ease: "power2.out",
      scaleY: 1,
    },
  },
  {
    element: ".gsap-bar-label",
    set: { delay: 0.4, opacity: 0 },
    to: {
      stagger: gsapBarData.stagger,
      duration: gsapBarData.duration + gsapBarData.stagger,
      opacity: 1,
      duration: 1,
      ease: "power2.out",
    },
  },
  {
    element: ".gsap-facts",
    set: { opacity: 0, y: -80 },
    to: {
      opacity: 1,
      delay: 0.5,
      stagger: 0.3,
      y: 0,
      repeat: 0,
      ease: "sine.out",
    },
  },
  {
    element: ".gsap-stack",
    set: { scaleY: 1, transformOrigin: "center top" },
    to: {
      delay: 1,
      stagger: 0.8,
      duration: 0.3,
      ease: "linear",
      scaleY: 0,
    },
  },
  {
    element: ".gsap-chart",
    set: { scaleX: 1, transformOrigin: "center right" },
    to: {
      delay: gsapChart.delay,
      duration: gsapChart.duration,
      ease: "linear",
      scaleX: 0,
    },
  },
  {
    element: ".gsap-chart-circle",
    set: { scale: 0, transformOrigin: "center" },
    to: {
      delay: gsapChart.delay + gsapChart.duration + 0.4,
      duration: 0.5,
      ease: "sine.inOut",
      scale: 1,
    },
  },
  {
    element: ".gsap-stack-text",
    set: { x: 50, opacity: 0 },
    to: {
      opacity: 1,
      delay: 1,
      stagger: 0.8,
      duration: 0.3,
      ease: "linear",
      x: 0,
    },
  },
  {
    element: ".gsap-stack-line",
    set: { scaleX: 0, transformOrigin: "center right" },
    to: {
      delay: 1,
      stagger: 0.8,
      duration: 0.3,
      ease: "linear",
      scaleX: 1,
    },
  },
  {
    element: ".gsap-arrowline-arrow:not(.slow)",
    set: { opacity: 0 },
    to: {
      delay: gsapArrowlineData.delay,
      stagger: gsapArrowlineData.stagger,
      duration: gsapArrowlineData.duration,
      ease: "linear",
      opacity: 1,
    },
  },
  {
    element: ".gsap-arrowline-arrow.slow",
    set: { opacity: 0 },
    to: {
      delay: gsapArrowlineData.delay,
      stagger: gsapArrowlineData.stagger * 2.6,
      ease: "linear",
      opacity: 1,
    },
  },
  {
    element: ".gsap-arrowline-label",
    set: { opacity: 0 },
    to: {
      delay: gsapArrowlineData.delay + gsapArrowlineData.stagger,
      stagger: gsapArrowlineData.stagger,
      duration: gsapArrowlineData.duration,
      ease: "linear",
      opacity: 1,
    },
  },
  {
    element: ".gsap-slide-from-bottom",
    set: { y: 100, opacity: 0 },
    to: {
      y: 0,
      opacity: 1,
      delay: 0.8,
      duration: 1.2,
      ease: "power1.out",
    },
  },
  {
    element: ".gsap-medium-slide-from-left",
    set: { x: -200, opacity: 0 },
    to: {
      x: 0,
      opacity: 1,
      delay: 0.8,
      duration: 1.2,
      ease: "power1.out",
    },
  },
  {
    element: ".gsap-slide-from-left",
    set: { x: -549 },
    to: {
      x: 0,
      delay: 0.8,
      duration: 1.2,
      ease: "power1.out",
    },
  },
  {
    element: ".gsap-slide-from-left-label",
    set: { opacity: 0 },
    to: {
      opacity: 1,
      delay: gsappSlideFromLeft.delay + gsappSlideFromLeft.duration,
      duration: gsappSlideFromLeft.duration,
      ease: "power1.out",
    },
  },
  {
    element: ".gsap-multiple-hover",
    set: { y: -70 },
    to: {
      y: 10,
      delay: 0,
      stagger: {
        delay: 0,
        repeat: -1,
        each: gsapMultipleHover.duration * 0.7,
        yoyo: true,
      },
      duration: gsapMultipleHover.duration,
      ease: "sine.inOut",
    },
  },
  {
    element: ".gsap-steckdose-1",
    set: { y: 0 },
    to: {
      delay: 1,
      duration: 1.5,
      ease: Circ.easeIn,
      y: 36,
    },
  },
  {
    element: ".gsap-steckdose-2",
    set: { y: 0 },
    to: {
      delay: 1,
      duration: 1.5,
      ease: Circ.easeIn,
      y: -36,
    },
  },
  {
    element: ".gsap-rotate",
    set: { rotation: 0 },
    to: {
      rotation: 360,
      duration: 12,
      repeat: -1,
      transformOrigin: "center",
      ease: "none",
    },
  },
  {
    element: ".gsap-rotate-fast",
    set: { rotation: 0 },
    to: {
      rotation: 360,
      duration: 3,
      repeat: -1,
      transformOrigin: "center",
      ease: "none",
    },
  },
  {
    element: ".gsap-fade-out",
    set: { opacity: 1 },
    to: {
      opacity: 0,
      delay: 1,
      stagger: {
        each: 0.3,
      },
      ease: "linear",
    },
  },
  {
    element: ".gsap-fade-in",
    set: { opacity: 0 },
    to: {
      opacity: 1,
      delay: 1,
      stagger: {
        each: 0.3,
      },
      ease: "linear",
    },
  },
  {
    element: ".pulse-party",
    set: { scale: 0.8, opacity: 0.8, transformOrigin: "center" },
    to: {
      scale: 1,
      opacity: 1,
      stagger: {
        delay: 0,
        repeat: -1,
        each: gsapMultipleHover.duration * 1.2,
        yoyo: true,
      },
      ease: "ease-in-out",
    },
  },
  {
    element: ".gsap-multiple-rotate",
    set: { rotation: 0, transformOrigin: "center" },
    to: {
      rotation: function (index) {
        // Rotiere jedes zweite Element um 360 Grad, jedes andere um -360 Grad
        return index % 2 === 0 ? 360 : -360;
      },
      stagger: {
        delay: 0,
        repeat: -1,
      },
      duration: 12,
      ease: "linear",
    },
  },
  {
    element: ".gsap-zipfel",
    set: { rotation: 15, transformOrigin: "top" },
    to: {
      rotation: 0,
      transformOrigin: "top",
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
    },
  },
  {
    element: ".gsap-waage",
    set: { rotation: -10, transformOrigin: "center bottom" },
    to: {
      delay: 1,
      rotation: 0,
      duration: 2,
      repeat: 0,
      yoyo: true,
      ease: "power1.inOut",
    },
  },
  {
    element: ".gsap-herzfall",
    set: {
      opacity: 0,
      y: -30,
      transformOrigin: "center bottom",
    },
    to: {
      delay: 1,
      opacity: 1,
      y: 0,
      duration: 2,
      repeat: 0,
      yoyo: true,
      ease: "power1.inOut",
    },
  },
];
