import { createSlice } from "@reduxjs/toolkit";

const fieldValues = createSlice({
  name: "fieldValues",
  initialState: {
    fieldValues: [],
  },
  reducers: {
    setFieldValue: (state, { payload }) => {
      const curFieldIndex = state.fieldValues.findIndex(
        (el) => el.id === payload.id
      );
      if (curFieldIndex != -1) {
        state.fieldValues[curFieldIndex].value = payload.value;
      } else {
        state.fieldValues.push(payload);
      }
    },
  },
});

export const { setFieldValue } = fieldValues.actions;

export default fieldValues.reducer;
