import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import { Box, Grid } from "@material-ui/core";
import Filter from "../_Components/Filter";

export default function WirAlsSystemintegrator() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Wir als Systemintegrator" />
          <QList
            items={[
              <>
                <b>Übernahme bereits bestehender Lieferantenverhältnisse:</b>
                <br />
                Ihre Konditionen bleiben bestehen
              </>,
              <>
                <b>Direkter Kontakt zu Hersteller bleibt erhalten:</b>
                <br />
                Anwendungsfälle, Probleme und Neuheiten können weiterhin direkt
                mit Hersteller besprochen werden
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={6} style={{ height: "100%" }}>
          <Box display="flex" justifyContent="center" height="100%">
            <Filter height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
