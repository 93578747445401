import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MenuIcon(props) {
  return (
    <SvgIcon {...props}>
    <path d="M2 4.85715H22" stroke="#33363F" stroke-width="1.71429" stroke-linecap="round"/>
    <path d="M2 12H22" stroke="#33363F" stroke-width="1.71429" stroke-linecap="round"/>
    <path d="M2 19.1429H22" stroke="#33363F" stroke-width="1.71429" stroke-linecap="round"/>
    </SvgIcon>
  );
}
