export default function Wrench({ className }) {
  return (
    <svg
      className={className}
      id="uuid-22914abd-7cdc-4661-9790-bd31a1102255"
      data-name="Schrift"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.14 439.28"
    >
      <path
        d="m246.23,176.1c-18.18-56.74,29.57-97.3,73.08-86.96.51,2.39-1.63,3.23-2.83,4.45-9.87,9.99-19.83,19.88-29.75,29.81-1.61,1.61-3.18,3.26-4.84,4.97,4.05,13.94,8.01,27.58,12.14,41.79,13.72,4,27.53,8.03,41.88,12.22,12.74-12.71,25.44-25.38,38.87-38.77,2.41,8,2.18,15.23,1.39,22.37-1.33,12.15-5.7,23.24-13.69,32.55-19.93,23.24-44.99,27.93-74.18,19.53-1.46,1.38-3.29,3.02-5.03,4.76-38.98,38.96-77.94,77.94-116.92,116.9-1.63,1.63-3.22,3.31-4.96,4.82-11.75,10.2-29.57,9.5-40.49-1.56-10.77-10.9-11.36-28.31-1.31-40,1.5-1.74,3.17-3.34,4.8-4.97,38.82-38.83,77.64-77.65,116.46-116.47,1.77-1.77,3.51-3.56,5.37-5.45Zm-102.77,161.75c9.71.09,17.72-7.81,17.63-17.38-.09-9.4-7.79-17.07-17.24-17.15-9.71-.09-17.73,7.82-17.63,17.38.1,9.37,7.84,17.06,17.24,17.15Z"
        style="fill: #e4032e; stroke-width: 0px;"
      />
      <path
        d="m152.32,320.38c.11,4.87-3.68,8.71-8.62,8.74-4.92.03-8.79-3.81-8.72-8.65.07-4.55,3.73-8.28,8.32-8.47,4.86-.19,8.91,3.56,9.02,8.37Z"
        style="fill: #e4032e; stroke-width: 0px;"
      />
    </svg>
  );
}
