import { Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import AusbildungStudium from "../../../../../../components/SvgIcons/KroschkeIcons/AusbildungStudium";

const useStyles = makeStyles((theme) => ({
  textWrap: {
    hyphens: "auto",
    textWrap: "balance",
  },
}));
export default function Info() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={8} className={classes.textWrap}>
          <QHeader
            title="Ausbildung"
          />
          <QList
            items={[
              "Kaufmann/Kauffrau für Groß- und Außenhandelsmanagement",
              "Fachkraft für Lagerlogistik",
            ]}
          />
          <QHeader
            title="Duales Studium"
          />
          <QList
            items={[
                "Groß- und Einzelhandelsmanagement",
                "Dienstleistungsmanagement",
                "Digitalisierungsmanagement",
                "Technischer Vertrieb/Wirtschaftsingenieurwesen",
                "Wirtschaftsinformatik",
            ]}
          />
        </Grid>
        <Grid item xs={4}><AusbildungStudium style={{ height: "60dvh", width: "85%" }} /></Grid>
      </Grid>
    </Slide>
  );
}