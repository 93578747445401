import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import { preTheme } from "../../../../../../theme";

import Image2009 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2009.webp";
import Image2012 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2012.webp";
import Image2017 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2017.webp";
import Image2018 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2018.webp";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
  fitContentHeight: {
    height: "fit-content",
    "& .MuiTypography-body2": {
      height: "20dvh",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      fontSize: "13px",
      [preTheme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
      // 1200px
      [preTheme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
      // 1200px
      [preTheme.breakpoints.up("xxl")]: {
        fontSize: "18px",
      },
      // 1400px
      [preTheme.breakpoints.up("xxxl")]: {
        fontSize: "19px",
      },
    },
  },
  topPosition: {
    justifyContent: "flex-start",
  },
  bottomPosition: {
    justifyContent: "flex-end",
  },
  hyphens: {
    hyphens: "auto",
  },
}));

export default function Third() {
  const classes = useStyles();

  return (
    <Slide padding={{ top: true, bottom: true, right: true }}>
      <Box display="flex" height="100%" alignItems="center">
        <Grid
          container
          alignItems="center"
          className={classes.fitContentHeight}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.bottomPosition}>
              <span>
                <strong>
                  Durch die Erweiterung der elektronischen Ausgabesysteme
                </strong>{" "}
                können nun auch Ausleihartikel über die Systeme abgewickelt
                werden, inkl. der Kalibrierung von Messmitteln und der Prüfung
                und Reparatur von Elektrowerkzeugen, vollautomatisch mit
                Ersatzgerät im System.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.bottomPosition}>
              <span>
                <strong>
                  SSV-Vollversorgung wird LEANdustry<sup>®</sup> by SSV
                </strong>{" "}
                und ist eine Mischung aus Lean Management und Industrie 4.0.
                Deshalb werden die Ausgabesysteme, das Vollversorgungskonzept
                und alle Dienstleistungen unter diesem Begriff zusammengefasst.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={Image2009} alt="2009" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2012} alt="2012" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2017} alt="2017" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2018} alt="2018" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              className={clsx(classes.topPosition, classes.hyphens)}
            >
              <span>
                <strong>
                  Einführung des Dienstleistungspaketes „Arbeitsschutz“.
                </strong>{" "}
                Auch im PSA-Bereich werden herstellerübergreifende
                Anwendungstests durchgeführt. Nach und nach werden die
                Versorgung mit Arbeitsschutzschuhen, Otoplastiken und
                Korrektions schutzbrillen sowie die Befüllung und Kontrolle der
                Erste-Hilfe-Kästen und Erstellung von Hand- und Hautschutzplänen
                ergänzt.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.topPosition}>
              <span>
                <strong>
                  Der Umzug in das „Zentrum der Beschaffungsrevolution“
                </strong>{" "}
                war unumgänglich. Das alte Firmengebäude drohte aus allen Nähten
                zu platzen. Ab September 2017 bieten 2.300m<sup>2</sup>{" "}
                Bürofläche und 2.700m<sup>2</sup> Lagerfläche ausreichend Platz,
                die Kompetenzen und Möglichkeiten des SSV-Versorgungskonzeptes
                weiter auszubauen.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </Slide>
  );
}
