import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Elektr-Ausgabesystem/1.webp";
import { Grid } from "@material-ui/core";

export default function ZurRichtigenZeit1() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "left",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title={<>Das können unsere<br/>E-PLS-Systeme</>} />
          <QList
            items={[
              "Systemversorgung durch unsere Mitarbeiter",
              "Volle Lagerkosteneinsparung",
              "Logistische Abwicklung aus einer Hand",
              "Verfügbarkeit aller Produkte 24/7",
              "Prozesskosteneinsparung",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
