import {theme} from "../../../../../../../theme"

export default function DerAufwandHinterCTeilenSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  const primaryColor = theme.palette.primary.main;
  return (
    <svg {...props} viewBox="0 0 2666 1444" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="der-aufwand" clip-path="url(#clip0_114_265)">

        <text className="gsap-stack-text" id="Personal" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2137.14" y="1422.12">Personal</tspan></text>
        <text className="gsap-stack-text" id="Logistik" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2137.14" y="994.12">Logistik</tspan></text>
        <text className="gsap-stack-text" id="Lagerkosten" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2137.14" y="641.12">Lagerkosten</tspan></text>
        <text className="gsap-stack-text" id="Kaufpreis" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2137.14" y="394.12">Kaufpreis</tspan></text>
        
        <path  id="Vector_5" d="M1239.14 1303.81C1235.46 1296.69 1221.79 1227.63 1218.48 1213.99L1214.21 1193.92C1206.88 1156.49 1200.07 1130.07 1196.09 1090.6C1194.99 1079.85 1198.29 1076.33 1198.88 1065.96C1199.26 1058.55 1196.85 1049.99 1198.71 1043.51C1201.25 1034.58 1203.33 1027.89 1205.32 1017.98C1206.63 1011.8 1211.62 1007.18 1217.47 1001C1224.62 993.378 1232.45 984.528 1240.54 975H761.667C750.194 1010.27 747.188 1075.4 737.874 1095.89C734.487 1103.31 731.862 1109.11 728.729 1117.58C718.652 1145.06 692.996 1231.91 684.656 1265.19C681.396 1278.02 671.277 1304.32 676.442 1314.18C680.126 1321.25 687.916 1321.72 696.934 1324.35C704.47 1326.46 713.699 1328.79 721.066 1331.42C737.027 1337.09 755.57 1340.61 771.658 1345.9C804.427 1356.61 839.144 1364.07 871.32 1374.4C892.954 1381.38 950.363 1398.11 971.362 1401.46C1035.59 1411.45 1103.71 1419.03 1164.72 1417.89C1187.49 1417.5 1220.26 1414.71 1231.99 1391.42C1234.15 1387.14 1235.16 1382.36 1236.18 1377.7C1238.97 1364.79 1239.23 1351.87 1241.26 1338.91C1243.12 1327.35 1244.73 1314.65 1239.14 1303.81ZM1197.4 1361.95C1194.99 1372.66 1184.32 1379.44 1173.52 1376.98C1162.77 1374.57 1155.99 1363.85 1158.49 1353.1C1160.91 1342.38 1171.57 1335.61 1182.33 1338.02C1193.08 1340.48 1199.86 1351.19 1197.4 1361.95Z" fill={primaryColor}/>
        <g >
          <path id="Vector_6" opacity="0.7" d="M789.395 630.173C791.339 637.023 793.115 643.197 795.186 650.132L820.044 725.313C829.134 747.512 827.95 747.596 821.27 768.696L790.79 865.611C788.845 871.954 786.097 874.829 782.631 888.698C778.065 906.796 768.341 959.059 765.044 966.755C763.945 969.249 762.93 972.04 762 975H1240.18C1268.55 941.426 1299.75 898.635 1313.61 864.469C1316.74 856.647 1320.97 851.953 1324.31 845.611L1376.14 746.328C1395.88 708.272 1399.98 699.604 1407.51 653.091C1409.07 643.408 1409.54 633.725 1410 624H787.873C788.253 625.86 788.718 627.848 789.395 630.131V630.173Z" fill={primaryColor}/>
          <path id="Vector_7" opacity="0.7" d="M633.298 745.728L375.882 811.316L379.056 778.268L507.849 722.001L602.274 727.806L552.289 652.769L549.073 624H435.94C415.328 663.955 373.935 691.495 325.854 692.003C276.885 692.512 234.222 664.802 213.187 624H101.832L98.6155 652.769L48.6306 727.806L143.014 722.001L271.849 778.268L275.023 811.316L17.6069 745.728L51.2547 624H34.6636L0 756.363L324.542 845L649 757.93L614.252 624H599.565L633.213 745.728H633.298Z" fill={primaryColor}/>
          </g>
        <path id="Vector_8" opacity="0.4" d="M1395.96 379H1389.31C1389.19 379.645 1389.08 380.29 1389 381C1391.28 380.968 1393.6 380.194 1396 379H1395.96Z" fill={primaryColor}/>
        <g >
          <path id="Vector_9" opacity="0.4" d="M1388.89 380.672C1389.02 379.781 1389.1 378.933 1389.23 378.042H926.857C922.623 392.336 911.996 405.527 903.698 452.182C902.047 461.216 901.496 473.473 901.666 481.786C901.793 489.378 904.926 505.369 897.135 507.15C891.462 505.284 823.383 420.414 806.532 405.102C801.875 400.861 795.016 395.305 791.248 391.148C786.76 386.186 782.103 381.563 777.361 378H748.021C745.735 380.418 743.533 383.556 741.374 387.585C733.626 402.133 737.987 412.525 740.696 431.611C743.406 450.697 752.001 495.613 759.664 511.773C766.607 526.491 771.391 554.314 776.472 569.541C778.42 575.309 779.986 584.216 781.087 589.518C782.484 596.813 784.135 602.751 785.151 609.706C785.998 615.772 786.464 619.589 787.395 624H1410.44C1410.86 615.136 1411.29 606.313 1412.52 597.491C1412.77 595.583 1413.07 593.674 1413.74 591.893C1414.55 589.899 1416.28 586.676 1417.3 584.81C1422.72 574.842 1424.84 570.983 1428.9 563.645C1432.63 556.901 1436.61 550.242 1439.99 543.329C1446.13 530.859 1454.81 513.979 1459 500.788C1461.46 492.941 1459.21 484.671 1460.15 476.867C1464.21 443.148 1472 410.828 1457.99 386.525C1456.34 383.726 1453.92 380.757 1451.21 378H1396.47C1393.84 379.527 1391.3 380.587 1388.81 380.63L1388.89 380.672Z" fill={primaryColor}/>
          <path id="Vector_10" opacity="0.4" d="M511.171 379H487.412L507.444 399.318L482.753 416.933L431.085 379H220.261L168.719 416.933L138.48 403.204L162.155 379H138.184L84.3133 432.52L34 624H50.6017L86.6849 494.023L114.34 506.527L101.211 624H212.637C203.955 607.188 198.916 588.179 198.704 567.988C197.984 498.923 253.549 442.404 322.793 441.686C392.037 440.968 448.703 496.347 449.423 565.411C449.635 586.532 444.595 606.47 435.489 624H548.694L535.565 506.527L563.221 494.023L599.304 624H614L563.771 430.957L511.171 379Z" fill={primaryColor}/>
        </g>
        <g >
          <g id="Group">
            <path id="Vector_11" opacity="0.2" d="M748.291 378.515H777.616C767.714 371.109 757.558 368.655 748.291 378.515Z" fill={primaryColor}/>
            <path id="Vector_12" opacity="0.2" d="M1424.17 363.745C1413.21 364.379 1404.45 373.817 1396.49 378.515H1451.21C1449.13 376.356 1446.89 374.325 1444.69 372.674C1438.22 367.807 1436.14 363.067 1424.17 363.745Z" fill={primaryColor}/>
            <path id="Vector_13" opacity="0.2" d="M1405.97 270.554C1405.89 257.604 1403.94 217.779 1399.33 206.649C1396.49 199.793 1385.91 189.255 1374.91 187.096C1368.27 185.827 1362.09 186.673 1356.71 186.038C1348.84 185.192 1345.92 184.176 1338.77 185.023C1322.78 186.8 1316.68 194.757 1310.25 195.264C1309.49 185.488 1321.85 130.936 1321.68 125.138C1321.42 113.5 1308.05 86.2027 1299.97 76.2149C1293.03 67.7506 1288.16 63.3915 1273.82 64.4496C1265.27 65.0844 1253.89 69.0626 1247.28 72.4483C1235.27 78.5849 1209.96 92.043 1204.97 104.909C1197.1 125.519 1190.2 148.965 1183.43 170.168C1171.71 206.776 1161.42 231.83 1149.32 265.56C1142.55 284.351 1118.98 335.686 1103.75 343.473C1097.02 338.606 1102.18 324.344 1105.06 316.176C1108.23 307.162 1112.04 296.708 1115.3 287.398C1135.48 229.756 1147.5 159.334 1145.77 101.523C1145.18 81.0818 1144.58 68.851 1137.35 50.0604C1132.44 37.2794 1126.56 28.7728 1114.71 21.9168C1109.88 19.1236 1105.74 19.3352 1100.87 15.8649C1094.82 11.5058 1089.4 1.22175 1081.4 0.0790758C1071.12 -1.35984 1017.76 17.2614 1008.66 21.5359C1004.73 23.3557 1002.44 25.6411 998.634 27.9687C993.344 31.1851 994.275 34.8671 994.064 40.8767C993.598 55.3082 984.796 113.923 982.596 133.518C974.006 209.738 945.908 299.586 927.627 376.018C927.415 376.822 927.204 377.669 926.95 378.473H1389.09C1394.42 339.834 1406.39 345.632 1405.89 270.512L1405.97 270.554Z" fill={primaryColor}/>
          </g>
          <g id="Group_2">
            <path id="Vector_14" opacity="0.2" d="M324.239 212.617L487.41 378.516H511.15L324.239 193.445L138.513 378.516H162.464L324.239 212.617Z" fill={primaryColor}/>
            <path id="Vector_15" opacity="0.2" d="M324.239 248.418L282.092 333.06L220.521 378.513H431.13L364.143 329.167L324.239 248.418Z" fill={primaryColor}/>
          </g>
        </g>
      </g>
      <g>
          <rect className="gsap-stack" y="974" width="1528" height="446" fill="#fff"/>
          <rect className="gsap-stack" y="624" width="1528" height="352" fill="#fff"/>
          <rect className="gsap-stack" y="378" width="1528" height="248" fill="#fff"/>
          <rect className="gsap-stack" width="1528" height="380" fill="#fff"/>
      </g>
        <path className="gsap-stack-line" id="Vector_4" d="M1331 1401H2061.97" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
        <path className="gsap-stack-line" id="Vector_3" d="M1331 973H2061.97" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
        <path className="gsap-stack-line" id="Vector_2" d="M1479 620H2062.22" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
        <path className="gsap-stack-line" id="Vector" d="M1523 380H2061.86" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
      <defs>
        <clipPath id="clip0_114_265">
          <rect width="2666" height="1444" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
