import { Box, Grid, CircularProgress, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spinner: {
    color: "rgb(228, 3, 46)",
    height: "60px !important",
    width: "60px !important",
  },
  align: {
    textAlign: "center",
  },
}));

export default function ChapterLoading() {
  const classes = useStyles();

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.align}>
            <CircularProgress className={classes.spinner} />
          </Grid>
          <Grid item xs={12} className={classes.align}>
            <Typography>Kapitel wird geladen...</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
