import { h } from "preact";
import { useState, useRef } from "preact/hooks";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableCell from "@material-ui/core/TableCell";
import root from "window-or-global";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from "../../SvgIcons/MoreIcon"

const useStyles = makeStyles((theme) => ({
  eDialogButton: {
    textTransform: "inherit !important",
    maxHeight: "45px",
  },
  dropdownButton: {
    "&:before": {
      content: "none !important",
      left: "0px !important",
    },
    maxHeight: "45px",
  },
}));

export default function OptionsForConsultation({
  eDialog,
  pdf,
  disabled,
  deleteRemoteConsultation,
  id,
}) {
  const classes = useStyles();
  const options = ["eReport", "Löschen"];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClick = () => {
    root.open(pdf, "_blank");
  };

  const handleMenuItemClick = (event, index) => {
    switch (options[index]) {
      case "BERICHT":
        root.open(pdf, "_blank");
        break;
      case "eReport":
        root.open(eDialog, "_blank");
        break;
      case "Löschen":
        handleDeleteClickOpen();
        break;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteTrueClose = () => {
    deleteRemoteConsultation(id);
    setOpenDelete(false);
  };

  return (
    <>  
      <TableCell></TableCell>
      <TableCell>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleClick}
          disabled={disabled}
        >
          Bericht
        </Button>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={handleToggle}
          disabled={disabled}
          ref={anchorRef}
        >
          <MoreIcon />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 99 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={disabled}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Löschen
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Möchten Sie die Beratung entfernen? (Unwiderruflich)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleDeleteClose}
              color="primary"
              className="plaintext"
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleDeleteTrueClose}
              color="primary"
              className="plaintext"
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </>
  )
}
