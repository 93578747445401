import { gsap } from "playbook-pwa-ui";
import { useRef } from "preact/hooks";
import {theme} from "../../../../../../../theme"

export default function BeratungSVG() {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
      <g id="uuid-9f689622-b86b-4acc-af21-025f8fab1c99" data-name="Ebene 2">
        <path d="m42.56,240.85c1.38,9,3.85,16.78,7.4,24.29,3.89-6.08,8.09-12.24,14.12-16.21,4.2-2.76,9.07-4.31,13.45-6.76,4.39-2.45,10.72-8.18,11.6-13.12,1.34,3.24.4,6.78-1.4,9.7,10.09-4.13,22.48-16.97,26.11-33.33,3.71-16.7-4.82-35.41-17.13-43.59-4.81-3.53-11.02-3.03-11.02-3.03-9.95.28-20.27,5.16-28.93,12.4-8.24,6.89-12.94,14.33-16.44,24.48-2.71,7.87-3.5,18.27-1.96,26.44,1.09-3.82,2.76-7.06,6.41-10.27-3.15,8.54-3.6,20-2.22,29Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path d="m407.16,242c18.46,38.04,21.86,86.1,11.15,127.01-35.61-32.37-65.4-22.9-121.81-36.91,4.21,8.07,11.8,14.74,21.68,17.9-60.19,8.26-125.05-12.38-161.05-51.41-33.81-36.62-36.62-71.42-36.47-83.31.03-2.79.22-4.31.22-4.31,0,0-2.39-13.25,8.24-36.8,1.01-1.41,2.05-2.82,3.13-4.21,1.19.44,2.4.81,3.66,1.11,18.05,4.37,36.2-6.73,40.55-24.76,1.47-6.11,1.19-12.22-.56-17.81,7.49-5.23,15.33-9.97,23.39-14.06,14.78-7.52,30.27-12.9,45.68-15.34,38-6.01,84.37,2.59,125.28,38.79-8.09-1.29-17.09-.97-22.89,4.7,56.52,22.63,75.29,83.04,80.94,105.79-4.77-5.18-14.37-8.02-21.14-6.37Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path className="gsap-rotate" d="m202.58,152.62c.31-1.27.56-2.54.78-3.81l20.9-9.65-1.06-13.81-22.13-6.34c-.52-1.55-1.11-3.08-1.76-4.57-.37-.89-.77-1.76-1.2-2.63l11.22-20.08-8.97-10.54-21.62,7.87c-2.11-1.48-4.32-2.86-6.64-4.09l-2.72-22.84-13.45-3.25-12.86,19.07c-2.63.02-5.21.25-7.77.61l-15.63-16.88-12.8,5.27.81,23c-2.08,1.55-4.06,3.24-5.93,5.04l-22.58-4.46-7.25,11.79,14.18,18.14c-.38,1.23-.74,2.48-1.05,3.75-.31,1.27-.56,2.54-.78,3.8l-20.9,9.66,1.06,13.81,22.13,6.34c.83,2.46,1.82,4.87,2.96,7.2l-11.22,20.08,8.97,10.54,21.62-7.87c2.1,1.49,4.31,2.87,6.64,4.1l2.72,22.83,2.4.58,11.05,2.67,12.86-19.06c2.62-.03,5.21-.25,7.77-.61l15.63,16.88,12.8-5.29-.81-22.99c2.08-1.55,4.06-3.25,5.93-5.05l22.58,4.46,7.25-11.79-14.18-18.14c.38-1.23.74-2.48,1.05-3.75Zm-26.12-6.31c-4.36,18.03-22.51,29.13-40.55,24.76-1.26-.3-2.48-.67-3.66-1.11-15.83-5.78-25.15-22.67-21.09-39.44,4.36-18.03,22.51-29.12,40.54-24.76,11.94,2.89,20.83,11.81,24.21,22.74,1.74,5.6,2.03,11.7.56,17.81Z" style="fill: #4a4a49; stroke-width: 0px;"/>
      </g>
      <g id="uuid-24e7534b-2143-466b-9ce7-39dd4b538c11" data-name="Schrift">
        <text fontFamily={fontFamily} transform="translate(210.9 268.95)" style="fill: #fff; font-size: 26px; font-weight: 700;"><tspan x="0" y="0">BERATUNG</tspan></text>
        <text fontFamily={fontFamily} transform="translate(211.9 288.95)" style="fill: #fff; font-size: 16px; font-weight: 300;"><tspan x="0" y="0" >zu nachhaltigen PSA</tspan><tspan x="0" y="19.2">Produkten</tspan></text>
        <g>
          <path d="m259.34,201.31c0-11.35,16.59-20.76,38.3-22.49-4.58-8.32-19.81-14.44-37.9-14.44-21.72,0-39.32,8.81-39.32,19.67,0,8.58,10.98,15.87,26.29,18.56-.82,3.02-3.6,7.37-12.3,10.28-.62.21-.49,1.13.17,1.12,4.37-.08,12.7-1.59,21.64-10.37,1.11.05,2.24.08,3.38.08-.17-.79-.25-1.59-.25-2.4Z" style="fill: #fff; stroke-width: 0px;"/>
          <path d="m329.48,233.71c-4.41,0-13.13-1.36-22.49-10.35-1.01.04-2.03.06-3.07.06-22.71,0-40.49-9.15-40.49-20.84s17.79-20.84,40.49-20.84,40.49,9.15,40.49,20.84c0,8.68-10.35,16.4-25.9,19.46,1.31,3.03,4.84,6.7,13.73,9.05l.93.25v1.04s-.05.95-.05.95l-.99.2c-.47.07-1.38.19-2.65.19Zm-21.58-12.74l.36.36c6.24,6.12,12.12,8.59,16.47,9.53-5.89-2.88-8.13-6.53-8.91-9.41l-.34-1.24,1.27-.22c15.14-2.66,25.32-9.65,25.32-17.4,0-10.2-17.11-18.49-38.15-18.49s-38.15,8.3-38.15,18.49,17.11,18.5,38.15,18.5c1.17,0,2.33-.03,3.47-.08l.51-.02Z" style="fill: #fff; stroke-width: 0px;"/>
        </g>
      </g>
    </svg>
  );
}
