import EPLSSystem from "./slides/EPLSSystem";
import Individuell from "./slides/Individuell";
import Wiegzellenschraenke from "./slides/Wiegezellenschraenke";
import Wiegzellenschrank1 from "./slides/Wiegezellenschrank1";
import Wiegzellenschrank2 from "./slides/Wiegezellenschrank2";
import ZurRichtigenZeit1 from "./slides/ZurRichtigenZeit1";
import ZurRichtigenZeit2 from "./slides/ZurRichtigenZeit2";

export default function AuswahlAusgabesysteme() {
  return [
    <ZurRichtigenZeit1 key="zur-richtigen-zeit-1" />,
    <ZurRichtigenZeit2 key="zur-richtigen-zeit-2" />,
    <EPLSSystem key="e-pls-system" />,
    <Individuell key="individuell" />,
    <Wiegzellenschrank1 key="wiegzellenschrank-1" />,
    <Wiegzellenschrank2 key="wiegzellenschrank-2" />,
    <Wiegzellenschraenke key="wiegzellenschraenke"  />,
  ];
}
