import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import SchrankFrontSVG from "./_Components/SchrankFrontSVG"

import { Box, Grid } from "@material-ui/core";

export default function Wiegzellenschrank2() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Alles im Blick mit dem Wiegezellenschrank" />
          <QList
            items={[
              "Dauerhafter Überblick über alle Bestände",
              "Geringes Risiko für Fehl- oder Nullbestände",
              "Ausführliches Artikel- und Entnahmereporting",
              "Verbrauchsgenaue & kostenstellenbezogene Abrechnung",
              "Steigerung der Mitarbeiterzufriedenheit",
            ]}
          />
        </Grid>
      </Grid>
      <Box
        height="100dvh"
        width="50dvw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
      >
        <SchrankFrontSVG height="60%" />
      </Box>
    </Slide>
  );
}
