import BeschaffungsprozesseHinterCTeilen from "./slides/BeschaffungsprozesseHinterCTeile";
import DasZielProzesseReduzieren from "./slides/DasZielProzesseReduzieren";
import DerAufwandHinterCTeilen from "./slides/DerAufwandHinterCTeilen";
import DieBenoetigteZeitFuerCTeile from "./slides/DieBenoetigteZeitFuerCTeile";
import Intro from "./slides/Intro";
import MengenanteileImVerhaeltnis from "./slides/MengenanteileImVerhaeltnis";
import UndKostenEinsparen from "./slides/UndKostenEinsparen";
import WasSindEigentlichCTeile from "./slides/WasSindEigentlichCTeile";
import WasSindEigentlichCTeileAntwort from "./slides/WasSindEigentlichCTeileAntwort";
import WertanteileImVerhaeltnis from "./slides/WertanteileImVerhaeltnis";

export default function Mission30() {
  return [
    <Intro key="intro" />,
    <WasSindEigentlichCTeile key="was-sind-eigentlich-c-teile" />,
    <WasSindEigentlichCTeileAntwort key="was-sind-eigentlich-c-teile-antwort" />,
    <WertanteileImVerhaeltnis key="wertanteile-im-verhaeltnis" />,
    <MengenanteileImVerhaeltnis key="mengenanteile-im-verhaeltnis" />,
    <DerAufwandHinterCTeilen key="der-aufwand-hinter-c-teilen" />,
    <DieBenoetigteZeitFuerCTeile key="die-benoetigte-zeit-fuer-c-t-eile" />,
    <BeschaffungsprozesseHinterCTeilen key="beschaffungsprozesse-hinter-c-teile" />,
    <DasZielProzesseReduzieren key="das-ziel-prozesse-reduzieren" />,
    <UndKostenEinsparen key="und-kosten-einsparen" />,
  ];
}
