import { Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import KarteSVG from "./_Components/KarteSVG";
import { BetterImage } from "playbook-pwa-ui";

export default function Karte() {
  return (
    <Slide>
      <BetterImage
        src={<KarteSVG style={{ height: "100%", width: "100%" }} />}
        alt="Übersichtskarte aller Kroschke-Unternehmen"
        height="100%"
      />
    </Slide>
  );
}
