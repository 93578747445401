import { Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../components/Slide";
import QHeader from "../../../../components/QHeader";
import QList from "../../../../components/QList";

import BackgroundImage from "../../../../assets/_Chapters/_Images/background/E-Procurement/2.jpg";

const useStyles = makeStyles((theme) => ({
  textWrap: {
    hyphens: "auto",
    textWrap: "balance",
  },
}));
export default function SSVTradeCore() {
  const classes = useStyles();
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6} className={classes.textWrap}>
          <QHeader
            title={
              <>
                SSV-Beschaffungsplattform —<br />
                die perfekte Wahl
              </>
            }
          />
          <QList
            items={[
              "Kontrolle über bedarfsspezifische Sortimente, Materialgruppen und Rechnungsprüfung",
              "Detaillierte Produktinformationen",
              "Preistransparenz",
              "Abwicklung des C-Teile Bedarfs über einen Lieferanten",
              "Drastische Reduzierung Ihrer Einkaufskosten",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
