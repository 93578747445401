import {
  v5 as uuidv5,
  v4 as uuidv4,
  version as uuidVersion,
  validate as uuidValidate,
} from "uuid";

const uuidValidateV4 = (uuid) => uuidValidate(uuid) && uuidVersion(uuid) === 4;

const MAX_CONSULTATIONS_OFFLINE = 20;
const SET_CONSULTATION = "playbook/consultationStore/SET_CONSULTATION";
const DELETE_CONSULTATION = "playbook/consultationStore/DELETE_CONSULTATION";

export const initialState = {
  consultations: [],
};

const consultationStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULTATION: {
      if (state.consultations.length <= MAX_CONSULTATIONS_OFFLINE) {
        return {
          ...state,
          consultations: [...state.consultations, action.data],
        };
      }
      return state;
    }
    case DELETE_CONSULTATION: {
      const consultations = state.consultations.filter(
        (c) => c.consulting.internalID !== action.id
      );
      return {
        ...state,
        consultations,
      };
    }
    default:
      return state;
  }
};

//actions
export const setOfflineConsultation = () => (dispatch, getState) => {
  const {
    consulting,
    customer,
    consultant,
    setup,
  } = getState();

  let c = consulting;

  if (!uuidValidateV4(c.internalID)) {
    c.internalID = uuidv5("offline-consultations", uuidv4());
  }

  dispatch({
    type: SET_CONSULTATION,
    data: {
      consulting: c,
      customer,
      consultant,
      setup
    },
  });
};

export const deletePausedConsultation = (id) => ({
  type: DELETE_CONSULTATION,
  id,
});

export const getConsultationStore = (state) => state.consultationStore;
export const getConsultationStoreAmount = (state) =>
  state.consultationStore.consultations.length;

export default consultationStore;
