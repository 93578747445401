import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import { Box, Grid } from "@material-ui/core";
import SchrankSVG from "./_Components/SchrankSVG";

export default function ZurRichtigenZeit2() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title={<>Das können unsere<br/>E-PLS-Systeme</>} />
          <QList
            items={[
              "Herstellerübergreifende Sortimentsabwicklung",
              "Detaillierte Verbrauchsstatistiken",
              "Monatliche Abrechnung nach Ihren Vorgaben",
              "Verwaltung von Ausleih- und Nachschliffartikeln",
              "Sortiment als Konsignationsware",
            ]}
          />
        </Grid>
      </Grid>
      <Box
        height="100dvh"
        width="50dvw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
      >
        <SchrankSVG width="70%" />
      </Box>
    </Slide>
  );
}
