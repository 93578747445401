import Allgemein from "./slides/Allgemein";
import Optimierung from "./slides/Optimierung";
import Beratung from "./slides/Beratung";
import Reduzierung from "./slides/Reduzierung";
import EinZweitesLeben from "../Recycling/slides/EinZweitesLeben";
import DieOptimaleNutzdauer from "../Recycling/slides/DieOptimaleNutzdauer";

export default function USP() {
  return [
    <Allgemein key="allgemein" />,
    <Optimierung key="optimierung" />,
    <Beratung key="beratung" />,
    <Reduzierung key="reduzierung" />,
    <EinZweitesLeben key="ein-zweites-leben" />,
    <DieOptimaleNutzdauer key="die-optimale-nutzdauer" />,
  ];
}
