import { useEffect, useMemo, useState } from "preact/hooks";
import { route } from "preact-router";
import { useSelector } from "react-redux";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import { Grid, Paper, makeStyles, Box, Typography } from "@material-ui/core";

import { getActiveChapter } from "../../redux/modules/backendState";
import { getActiveModule } from "../../redux/modules/consulting";
import { getCustomCategories } from "../../redux/modules/settingsReducer";

import { Section } from "../Section";
import { Slide } from "../Slide";
import { ChapterCard } from "playbook-pwa-ui";
import { grey } from "@material-ui/core/colors";

import { reBuild } from "playbook-pwa-ui";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  chapterPaper: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    backgroundColor: "#fff",
    transform: "translateY(0)",
    transition: "all 0.3s ease",
    padding: "1.4rem .5rem",
    boxShadow: "0px 15px 30px 0px rgba(7,6,5,0.05)",
    marginTop: "3px",
    "&:hover": {
      transition: "all 0.3s ease",
      boxShadow: "0px 15px 30px 0px rgba(7,6,5,0.15)",
      transform: "translateY(-3px)",
    },
  },
  chapterActive: {
    border: `3px solid ${theme.palette.primary.main} !important`,
  },
  sectionStyle: {
    borderTop: `2px solid ${grey[100]}`,
  },
  typoHeadline: {
    zIndex: 1,
    fontWeight: 800,
    color: "black",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorBlack: {
    color: "#000",
  },
}));

export function NextChapter({ section, activeChapterID }) {
  const classes = useStyles();

  const activeChapter = useSelector((state) => getActiveChapter(state));
  const activeChapterKey = useSelector((state) => getActiveModule(state));
  const customCategories = useSelector((state) => getCustomCategories(state));

  const [activeTab, setActiveTab] = useState("favs");
  const [chapterCounter, setChapterCounter] = useState(0);

  useEffect(() => {
    if (customCategories.length === 0) {
      setActiveTab("chaps");
    }
    setChapterCounter(activeChapter.length);
  }, [customCategories, activeChapter]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setTimeout(() => {
      reBuild();
    }, 200);
  };

  const routeToChapter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const link = e.currentTarget.getAttribute("data-link");
    route(link, true);
  };

  const favData = useMemo(() => {
    return customCategories.map((key) => {
      const module = activeChapter.find((el) => el.chapter.key === key);
      return (
        <ChapterCard
          key={key}
          onCardClick={routeToChapter}
          chapter={module.chapter}
          active={module.chapter.key === activeChapterKey}
          disableFavorite
        />
      );
    });
  }, [customCategories, activeChapter, activeChapterKey]);

  const chapter = useMemo(() => {
    const chunks = [];
    const cards = [];

    activeChapter.forEach((module) => {
      cards.push(
        <Grid
          style={{ cursor: "pointer" }}
          item
          xs={4}
          onClick={routeToChapter}
          data-link={module.chapter.path}
        >
          <Paper
            className={clsx(
              classes.chapterPaper,
              module.chapter.id === activeChapterID
                ? classes.chapterActive
                : null
            )}
            square
            elevation={0}
          >
            <Typography variant="h6" color="primary" align="center">
              {module.chapter.name}
            </Typography>
          </Paper>
        </Grid>
      );
    });

    const chunkSize = 15;
    for (let i = 0; i < cards.length; i += chunkSize) {
      const chunk = cards.slice(i, i + chunkSize);
      chunks.push(
        <SwiperSlide className="nextChapterSliderSlide">
          <Grid container spacing={3}>
            {chunk}
          </Grid>
        </SwiperSlide>
      );
    }

    return chunks;
  }, [activeChapter, activeChapterKey, classes]);

  return (
    <Section
      selector={`${section} fp-auto-height`}
      className={classes.sectionStyle}
    >
      <Slide
        background={{
          width: 1,
          height: 1,
          backgroundColor: grey[100],
        }}
        disablePadding
      >
        <Grid container m={0} justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                gap: 20,
                padding: "30px 30px 0px 30px",
              }}
            >
              {customCategories.length > 0 ? (
                <Typography
                  className={classes.typoHeadline}
                  variant="h4"
                  display="inline"
                  onClick={() => handleTabChange("favs")}
                >
                  Favoriten
                </Typography>
              ) : null}
              <Typography
                className={classes.typoHeadline}
                variant="h4"
                display="inline"
                onClick={() => handleTabChange("chaps")}
              >
                Kapitel{" "}
                <span className={classes.colorPrimary}>({chapterCounter})</span>
              </Typography>
            </Box>
            {activeTab === "favs" && (
              <Box
                component="ul"
                sx={{
                  display: "flex",
                  gap: 20,
                  p: 2,
                  m: 0,
                  overflow: "auto",
                  maxWidth: "100%",
                }}
              >
                {favData}
              </Box>
            )}
            {activeTab === "chaps" && (
              <Swiper
                modules={[Pagination, Navigation]}
                pagination={true}
                navigation={true}
                className="nextChapterSlider"
              >
                {chapter}
              </Swiper>
            )}
          </Grid>
        </Grid>
      </Slide>
    </Section>
  );
}
