import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SpeakBubbles(props) {
  return (
    <SvgIcon {...props}>
      <g id="Frame">
        <path
          id="Vector"
          d="M19.619 21.671C14.581 22.898 10.908 19.81 10.908 16.504C10.908 13.329 14.018 11.037 17.454 11.037C20.911 11.037 24 13.346 24 16.504C24 17.624 23.597 18.724 22.883 19.577C22.854 20.577 23.441 22.012 23.971 23.056C22.552 22.799 20.533 22.232 19.619 21.671ZM8.908 16.504C8.908 12.387 12.742 9.03703 17.454 9.03703C18.34 9.03703 19.194 9.15603 19.998 9.37503C19.977 4.54103 15.237 1.05603 10 1.05603C4.719 1.05603 0 4.58303 0 9.40803C0 11.118 0.615 12.799 1.705 14.103C1.752 15.63 0.854 17.821 0.044 19.416C2.212 19.025 5.296 18.158 6.693 17.301C7.496 17.497 8.269 17.605 9.021 17.664C8.954 17.285 8.908 16.899 8.908 16.504Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}
