import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Schrank from "../../../../../../assets/_Chapters/EPLSystem/wiegezellenschraenke.webp";

import { Grid } from "@material-ui/core";
import { ImageComponent } from "playbook-pwa-ui";

export default function Wiegzellenschraenke() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader title="Wiegezellenschränke im Detail" textAlign="center" />
          <ImageComponent
            col={12}
            wrapperHeight="60%"
            previewImgMaxHeight="70dvh"
            images={[
              {
                src: Schrank,
                alt: "Wiegezellenschränke",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
