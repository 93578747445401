import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import EinZweitesLebenSVG from "../../../../Nachhaltigkeit/sections/Recycling/slides/_Components/EinZweitesLebenSVG";

const useStyles = makeStyles((theme) => ({
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
}));
export default function Nachschliffservice() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <QHeader title="Nachschliffservice" />
          <Typography variant="body1">
            Nachschliff aus einer Hand mit dem Dienstleister Ihrer Wahl.
          </Typography>
          <QList
            items={[
              "Abholung und Lieferung durch eigene Mitarbeiter",
              "Erste Prüfung bereits durch SSV",
              "Nachschliff und Beschichtung durch qualifizierte Schleifdienste",
            ]}
          />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <EinZweitesLebenSVG height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
