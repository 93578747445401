import { Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import { useModal } from "mui-modal-provider";

import Image1 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/kroschke-team.webp"
import Image2 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-aussenansicht.webp"
import Image3 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-besprechungsraum.webp"
import Image4 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-buero.webp"
import Image5 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-foyer.webp"
import Image6 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-innenausstattung.webp"
import Image7 from "../../../../../../assets/_Chapters/Karriere/SSV-Kroschke/ssv-innengarten.webp"
import { QDialog, QGalery } from "playbook-pwa-ui";

export default function Galerie() {
  const { showModal } = useModal();
  const dialog = (image, alt) => {
    const d = showModal(QDialog, {
      children: (
        <QGalery
          images={[
            {
              src: image,
              alt: alt,
            },
          ]}
          closeHandler={() => d.hide()}
        />
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  
  return (
    <Slide background>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} style={{ height: "100%" }}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: "100%", height: "100%"}} viewBox="0 0 768 379" fill="none">
              <g clip-path="url(#clip0_893_264)">
              <mask id="mask0_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="0" width="193" height="238">
              <rect x="200" width="193" height="238" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_893_264)">
              <rect x="162" width="358" height="238" fill="url(#pattern0_893_264)"/>
              </g>
              <mask id="mask1_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="400" y="65" width="167" height="173">
              <rect x="400" y="65" width="167" height="173" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask1_893_264)">
              <rect x="377" y="65" width="260" height="173" fill="url(#pattern1_893_264)"/>
              </g>
              <mask id="mask2_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="574" y="22" width="194" height="273">
              <rect x="574" y="22" width="194" height="273" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask2_893_264)">
              <rect x="570" y="-2" width="202" height="303" fill="url(#pattern2_893_264)"/>
              </g>
              <mask id="mask3_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="22" width="193" height="94">
              <rect y="22" width="193" height="94" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask3_893_264)">
              <rect x="-3" y="22" width="196" height="94" fill="url(#pattern3_893_264)"/>
              </g>
              <mask id="mask4_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="123" width="193" height="205">
              <rect y="123" width="193" height="205" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask4_893_264)">
              <rect x="-76" y="123" width="306" height="205" fill="url(#pattern4_893_264)"/>
              </g>
              <mask id="mask5_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="245" width="160" height="116">
              <rect x="200" y="245" width="160" height="116" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask5_893_264)">
              <rect x="181" y="245" width="176" height="116" fill="url(#pattern5_893_264)"/>
              </g>
              <mask id="mask6_893_264" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="367" y="245" width="200" height="134">
              <rect x="367" y="245" width="200" height="134" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask6_893_264)">
              <rect x="367" y="245" width="200" height="134" fill="url(#pattern6_893_264)"/>
              </g>
                  <rect y="22" width="193" height="94" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image2, "Außenansicht Firmengebäude")}
                    />
                  <rect y="123" width="193" height="205" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image6, "Aufenthalsbereich")}
                    />
                  <rect x="200" width="193" height="238" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image3, "Besprechungsräume")}
                    />
                  <rect x="400" y="65" width="167" height="173" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image7, "Innenhof")}
                    />
                  <rect x="574" y="22" width="194" height="273" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image1, "Aufenthalsbereich")}
                    />
                  <rect x="200" y="245" width="160" height="116" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image4, "Büroräume")}
                    />
                  <rect x="367" y="245" width="200" height="134" fill="black" fill-opacity="0"
                          style={{ cursor: "pointer" }}
                          onClick={() => dialog(Image5, "Foyer Wartebereich")}
                    />
              </g>
              <defs>
                  <pattern id="pattern0_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image0_893_264" transform="matrix(0.000244141 0 0 0.000367237 0 -0.00164538)"/>
                  </pattern>
                  <pattern id="pattern1_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image1_893_264" transform="matrix(0.000244141 0 0 0.000366917 0 -0.000841086)"/>
                  </pattern>
                  <pattern id="pattern2_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image2_893_264" transform="scale(0.000366211 0.000244141)"/>
                  </pattern>
                  <pattern id="pattern3_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image3_893_264" transform="matrix(0.000244141 0 0 0.000509059 0 -0.000914229)"/>
                  </pattern>
                  <pattern id="pattern4_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image4_893_264" transform="matrix(0.000245307 0 0 0.000366166 -0.00238965 0)"/>
                  </pattern>
                  <pattern id="pattern5_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image5_893_264" transform="matrix(0.000244141 0 0 0.00037042 0 -0.00599407)"/>
                  </pattern>
                  <pattern id="pattern6_893_264" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image6_893_264" transform="matrix(0.000245242 0 0 0.000366032 -0.00225476 0)"/>
                  </pattern>
                  <clipPath id="clip0_893_264">
                  <rect width="768" height="379" fill="white"/>
                  </clipPath>
                  <image id="image0_893_264" width="4096" height="2732" xlinkHref={Image3}/>
                  <image id="image1_893_264" width="4096" height="2730" xlinkHref={Image7}/>
                  <image id="image2_893_264" width="2731" height="4096" xlinkHref={Image1}/>
                  <image id="image3_893_264" width="4096" height="1968" xlinkHref={Image2}/>
                  <image id="image4_893_264" width="4096" height="2731" xlinkHref={Image6}/>
                  <image id="image5_893_264" width="4096" height="2732" xlinkHref={Image4}/>
                  <image id="image6_893_264" width="4096" height="2732" xlinkHref={Image5}/>
              </defs>
          </svg>
        </Grid>
      </Grid>
    </Slide>
  );
}
