import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import { Box, Grid } from "@material-ui/core";
import GebrauchtSVG from "../_Components/GebrauchtSVG";

export default function Gebraucht() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Gebraucht-vor-Neu-Entnahme" />
          <QList
            items={[
              "Individuelle Einstellmöglichkeiten bei der Ausgabe von Gebraucht- und Neuartikeln",
              "Kosteneinsparung durch Reduzieren der Kosten für Neuwerkzeuge",
            ]}
          />
        </Grid>
        <Grid item xs={6} style={{ height: "100%" }}>
          <Box display="flex" justifyContent="center" height="100%">
            <GebrauchtSVG height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
