import { moveTo } from "playbook-pwa-ui";
import Slide from "../Slide";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { useRef } from "preact/hooks";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  h1: {
    fontWeight: "700",
    fontSize: "250px",
    color: theme.palette.primary.contrastText,
  },
  h2: {
    fontWeight: "700",
    fontSize: "72px",
    color: theme.palette.primary.contrastText,
    textTransform: "uppercase",
    marginLeft: "8px",
    transform: "translateY(-50px)",
    hyphens: "auto",
    lineHeight: "1em",
    marginBottom: "0.3em",
  },
  button: {
    marginLeft: "12px",
    color: "white",
    borderColor: "white",
  },
}));

export default function ChapterIntro({ background, id, name, width }) {
  // Declaration
  const classes = useStyles();
  const ref = useRef();
  const backgroundPath = `../../../../../assets/_Chapters/_Images/background/${background}`;

  // Functions
  const moveToFirstSlide = () => {
    moveTo(2, 0);
  };

  return (
    <Slide background={{ image: backgroundPath, line: "primary" }} container>
      <Box
        width={width || "700px"}
        ref={ref}
        paddingTop="12dvh"
        maxWidth="100%"
      >
        <Typography variant="h2" className={clsx(classes.h2, "text-element")}>
          {name.replace("®", "")}
          {name.includes("®") && <sup>®</sup>}
        </Typography>
        <Button
          variant="outlined"
          size="medium"
          onClick={moveToFirstSlide}
          className={clsx(classes.button, "text-element")}
        >
          Starten
        </Button>
      </Box>
    </Slide>
  );
}
