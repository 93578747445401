import { Divider, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { preTheme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: "10px",
  },
  defaultList: {
    marginBottom: ".6em",
    [preTheme.breakpoints.up("xl")]: {
      marginBottom: ".8em",
    },
    [preTheme.breakpoints.up("xxl")]: {
      marginBottom: "1em",
    },
  },
  list: {
    listStyle: "square",
    "&::marker": {
      color: (props) => props.color || theme.palette.primary.main,
      [preTheme.breakpoints.up("lg")]: {
        fontSize: "22px",
      },
      [preTheme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
      [preTheme.breakpoints.up("xxl")]: {
        fontSize: "30px",
      },
      [preTheme.breakpoints.up("xxxl")]: {
        fontSize: "32px",
      },
    },
  },
  blattList: {
    display: "flex",
    "&:before": {
      content: "url('../../assets/icons/blatt.svg')",
      aspectRatio: 1,
      width: "32px",
      [preTheme.breakpoints.up("lg")]: {
        width: "42px",
      },
      [preTheme.breakpoints.up("xxl")]: {
        width: "44px",
      },
      [preTheme.breakpoints.up("xxxl")]: {
        width: "48px",
      },
    },
  },
  noPadding: {
    paddingInlineStart: "15px",
  },
  fontWeightRegular: {
    width: "fit-content",
    fontSize: "20px",
    fontWeight: "400",
    [preTheme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
    // 1200px
    [preTheme.breakpoints.up("xl")]: {
      fontSize: "26px",
    },
    // 1400px
    [preTheme.breakpoints.up("xxl")]: {
      fontSize: "28px",
    },
  },
  headline: {
    fontWeight: "700",
    [preTheme.breakpoints.up("lg")]: {
      fontSize: "22px",
    },
    [preTheme.breakpoints.up("xl")]: {
      fontSize: "28px",
    },
    [preTheme.breakpoints.up("xxl")]: {
      fontSize: "30px",
    },
    [preTheme.breakpoints.up("xxxl")]: {
      fontSize: "32px",
    },
  },
}));

export default function QList({ items, divider, headline, listStyle, color }) {
  const classes = useStyles({
    color,
  });

  function GenerateListItem(item, i) {
    if (Array.isArray(item)) {
      return <QList items={item} subItem={true} />;
    }
    return (
      <li
        className={clsx(
          classes.defaultList,
          listStyle === "blatt" ? classes.blattList : classes.list
        )}
      >
        <Typography
          variant="body1"
          className={clsx(
            classes.fontWeightRegular,
            listStyle === "blatt" ? classes.marginLeft : null
          )}
        >
          {item}
        </Typography>
      </li>
    );
  }

  const listItems = items.map((item, i) => [
    GenerateListItem(item, i),
    divider && i !== items.length - 1 && <Divider key={`divider-${i}`} />,
  ]);

  return (
    <>
      {headline && (
        <Typography variant="body1" className={classes.headline}>
          {headline}
        </Typography>
      )}
      <ul className={listStyle === "blatt" ? classes.noPadding : null}>
        {listItems}
      </ul>
    </>
  );
}
