import VR from "./slides/VR";
import AR from "./slides/AR";
import Vorteile from "./slides/Vorteile";

export default function VRAR() {
  return [
    <VR key="wir-optimieren" />,
    <AR key="sie-haben-die-wahl" />,
    <Vorteile key="vorteile" />,
  ];
}
