import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import ATeileSVG from "./_Components/Teile/ATeileSVG";
import Ausweis from "../../../../../../components/SvgIcons/KroschkeIcons/Ausweis";
import Weg from "../../../../../../components/SvgIcons/KroschkeIcons/Weg";
import Vogel from "../../../../../../components/SvgIcons/KroschkeIcons/Vogel";
import Waage from "../../../../../../components/SvgIcons/KroschkeIcons/Waage";

const useStyles = makeStyles((theme) => ({
  fontWeightBold: {
    fontWeight: 700,
    marginBottom: ".4em",
  },
}));

export default function AufEinenBlick1() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="SSV-Kroschke auf einen Blick" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Ausweis height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Nur ‘ne Nummer
              </Typography>
              <Typography variant="body1" align="center">
                Wir bevorzugen das ,,Du‘‘, eine Nummer hat nur dein Transponder.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Weg height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Das war schon immer so
              </Typography>
              <Typography variant="body1" align="center">
                Mag sein, wir gehen mit unseren Mitarbeitern trotzdem lieber
                neue Wege.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Vogel height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Alles Grünschnäbel
              </Typography>
              <Typography variant="body1" align="center">
                Unser Durchschnittsalter von 34,4 Jahren vereint Wissen und
                frische Ideen.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Waage height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Entweder, oder …
              </Typography>
              <Typography variant="body1" align="center">
                Falsch! Bei uns kommt weder Karriere noch Privatleben zu kurz.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
