import { useEffect, useState } from "preact/hooks";
import { Children } from "preact/compat";
import { route } from "preact-router";
import { useDispatch, useSelector } from "react-redux";

import ReactFullpage from "@fullpage/react-fullpage";

import { Box, Grid, makeStyles } from "@material-ui/core";
import { gsap } from "playbook-pwa-ui";

import {
  addChapter,
  getConsultingData,
  setActiveModule,
} from "../../redux/modules/consulting";

import { NextChapter, sectionStart } from "playbook-pwa-ui";
import { Section } from "playbook-pwa-ui";
import { getModuleByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import Menu from "../Menu";
import { grey } from "@material-ui/core/colors";
import { gsapDB } from "../../static/data";

const useStyles = makeStyles(() => ({
  actions: {
    position: "absolute",
    bottom: "25px",
    "& img": {
      height: "25px",
      widht: "auto",
    },
  },
  logo: {
    position: "absolute",
    bottom: "12px",
    right: "12px",
    zIndex: 200,
  },
}));

export default function ChapterWrapper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [iconToShow, setIconToShow] = useState("logo");
  const [iconBackground, setIconbackground] = useState("white");
  const [currentSlideState, setCurrentSlideState] = useState({});

  const consulting = useSelector((state) => getConsultingData(state));
  const module = useSelector((state) => getModuleByID(state, props.chapterID));

  const section = module?.chapter.path;
  const slides = Children.toArray(props.children);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Mount chapter: ", section);
    }
    // eslint-disable-next-line no-undef
    fullpage_api.reBuild();
    dispatch(setActiveModule(module.chapter.key));
    return () => {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log("Unmount chapter: ", section);
      }
      // eslint-disable-next-line no-undef
      fullpage_api.destroy("all");
    };
  }, [dispatch, module?.chapter.key, section]);

  if (!module) {
    route("/", true);
    return;
  }

  let anchors = [];

  const sections = slides.map((slide) => {
    const { anchor } = slide.props;
    anchors.push(anchor);

    const actions =
      slide.props.actions !== undefined ? slide.props.actions : [];

    const actionItems = actions.map((action, i) => (
      <Grid item key={`action-${i}`}>
        <a href={action.link}>
          <img src={action.imgSrc} />
        </a>
      </Grid>
    ));

    return (
      <Section key={`${section}-${anchor}`} selector={section} {...slide.props}>
        {slide}
      </Section>
    );
  });

  anchors.push("themenwahl");

  //! ON LEAVE
  const onLeave = (origin, destination, direction) => {
    if (consulting?.running && destination.isLast) {
      dispatch(addChapter(props.chapterData.chapter.key));
    }
    setIconToShow(
      destination.index === 0
        ? "logo"
        : currentSlideState[destination.anchor] > 0
        ? "back-arrow"
        : "small-logo"
    );
    setIconbackground(destination.index === 0 ? "white" : grey[100]);
  };

  //! ON SLIDE LEAVE
  const onSlideLeave = (section, origin, destination, direction) => {
    if (destination.index == 0) setIconToShow("");
    else if (destination.index > 0) setIconToShow("back-arrow");

    const toCompair = { [section.anchor]: destination.index };
    const temp = { ...currentSlideState, ...toCompair };

    setCurrentSlideState(temp);
  };

  //! AFTER LOAD
  const afterLoad = (origin, destination, direction) => {
    // Content
  };

  //! AFTER SLIDE LOAD
  const afterSlideLoad = (section, origin, destination, direction) => {
    // Content
  };

  gsapDB.forEach((item) => {
    if (
      document.querySelectorAll(
        `.fp-section.active .fp-slide.active ${item.element}`
      ).length > 0
    )
      gsap
        .timeline()
        .set(`.fp-section.active .fp-slide.active ${item.element}`, item.set)
        .to(`.fp-section.active .fp-slide.active ${item.element}`, item.to);
  });

  return (
    <>
      <Menu
        absolute
        icon={{
          image: iconToShow,
          action: sectionStart,
          background: iconBackground,
          text: props.chapterData.chapter.name,
        }}
      />
      <ReactFullpage
        licenseKey="82375DBB-66484CC6-A59E8B65-42EC72CD"
        navigation
        navigationPosition={"right"}
        anchors={anchors}
        onLeave={onLeave}
        onSlideLeave={onSlideLeave}
        afterLoad={afterLoad}
        afterSlideLoad={afterSlideLoad}
        showActiveTooltip={false}
        slidesNavigation
        slidesNavPosition={"bottom"}
        continuousVertical={false}
        touchSensitivity={5}
        scrollOverflowOptions={{
          click: true,
        }}
        autoScrolling
        verticalCentered={false}
        scrollingSpeed={600}
        css3
        easingcss3={"ease"}
        normalScrollElements=".MuiDialog-root, .MuiDialog-scrollPaper, .MuiAutocomplete-popper, #consultationDialog, .autoScroll, #QSrollBody"
        sectionSelector={`.${section}`}
        render={() => (
          <ReactFullpage.Wrapper>
            {sections}
            {!props.disableNextChapter && (
              <NextChapter
                section={section}
                activeChapterID={props.chapterData.chapter.id}
              />
            )}
          </ReactFullpage.Wrapper>
        )}
      />
      {props.logo !== null && <Box className={classes.logo}>{props.logo}</Box>}
    </>
  );
}

ChapterWrapper.defaultProps = {
  anchors: [],
  section: "",
  actions: null,
  normalScrollElements: [],
  logo: null,
};
