import { preTheme } from "../../../../../theme";
import Video from "./Video";
import First from "./slides/first";
import Second from "./slides/second";
import Third from "./slides/third";

export default function Timeline() {
  return [
    <First key="timeline-1" />,
    <Second key="timeline-2" />,
    <Third key="timeline-3" />,
    <Video key="video" />,
  ];
}
