import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import { Grid, Typography } from "@material-ui/core";

// SVGs
import Books from "./_Components/Books";
import HomeworkBook from "./_Components/HomeworkBook";
import Money from "./_Components/Money";
import Share from "./_Components/Share";

export default function Vorteile1() {

  return (
    <Slide id="vorteile-1">
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Vorteile im Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Books className="gsap-facts" />
              <Typography align="center" variant="body1">
                Alle Lieferantenkataloge mit einem Griff
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <HomeworkBook className="gsap-facts" />
              <Typography align="center" variant="body1">
                Zusammenfassung in individuellen Katalogen
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Money className="gsap-facts" />
              <Typography align="center" variant="body1">
                Individuelle Nettopreise sofort sichtbar
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Share className="gsap-facts" />
              <Typography align="center" variant="body1">
                Genehmigungs&shy;workflow: Detailliert und mehrstufig, für
                Abteilungen und einzelne Personen
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
