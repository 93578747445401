import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function HandUndHerz(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
    <mask id="mask0_896_390" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="47" y="112" width="657" height="496">
    <path d="M704 112.148H47V607.849H704V112.148Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_390)">
    <mask id="mask1_896_390" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="47" y="112" width="657" height="496">
    <path d="M704 112.146H47V607.847H704V112.146Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_390)">
    <mask id="mask2_896_390" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="47" y="112" width="657" height="496">
    <path d="M704 112.145H47V607.845H704V112.145Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_390)">
    <path d="M579.775 128.993C602.199 151.271 602.199 187.377 579.775 209.503L485.622 302.743L391.774 209.503C369.522 187.226 369.522 151.271 391.774 128.993C402.832 118.177 417.076 112.285 432.444 112.285C447.814 112.285 462.227 118.177 473.113 128.993L480.32 136.154C481.769 137.745 483.678 138.541 485.777 138.541C487.871 138.541 489.78 137.594 491.229 136.154L498.436 128.993C509.496 118.177 523.735 112.285 539.107 112.285C554.646 112.285 569.059 118.328 579.775 128.993Z" fill={primaryColor}/>
    <path d="M701.003 372.741C704.682 380.848 705.006 389.771 701.803 398.03C698.599 406.308 692.192 412.977 684.035 416.481L500.359 495.551C472.501 507.486 441.27 509.229 412.288 500.174L371.77 487.614C340.71 478.067 306.923 479.506 276.968 491.27L240.779 505.269L185.217 387.062C292.832 300.187 429.105 338.055 483.066 358.742C494.276 363.042 501.159 374.484 499.559 386.418C499.404 387.062 499.234 387.687 499.234 388.331C497.785 395.018 493.952 400.739 488.174 404.717C482.092 408.695 474.885 410.135 467.829 408.695L385.842 391.04C381.685 390.093 377.509 392.783 376.556 397.083C375.603 401.212 378.31 405.361 382.638 406.308L464.625 423.964C467.678 424.608 470.712 424.911 473.59 424.911C481.752 424.911 489.929 422.373 496.965 417.902C505.126 412.484 510.904 404.376 513.612 395.151L661.896 354.896C677.743 350.274 694.081 357.927 700.963 372.721L701.003 372.741Z" fill={primaryColor}/>
    <path d="M234.201 527.696C235.955 531.351 236.127 535.501 234.677 539.306C233.229 543.114 230.521 545.994 226.67 547.736L195.285 562.379L112.171 386.263L143.727 371.468C147.407 369.725 151.583 369.555 155.415 370.994C159.267 372.434 162.146 375.124 163.9 378.95L168.381 388.346L229.873 518.64L234.201 527.712V527.696Z" fill={primaryColor}/>
    <path d="M185.676 578.124L190.328 587.991C190.328 587.991 190.328 588.314 190.175 588.314L148.533 607.732C148.209 607.732 148.056 607.732 148.056 607.579L47 393.727C47 393.727 47 393.405 47.1525 393.405L88.948 373.836C89.1004 373.685 89.1004 373.836 89.2719 373.988L94.401 384.805C94.5535 385.127 94.5535 385.6 94.7254 385.922C94.8968 386.244 95.0493 386.566 95.3736 386.87L183.769 573.805C183.922 574.599 184.093 575.394 184.417 576.189C184.741 576.834 185.066 577.459 185.695 578.105L185.676 578.124Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
