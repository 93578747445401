import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo1 from "../../../../../../assets/_Chapters/Referenzen/bmw.png";
import Logo2 from "../../../../../../assets/_Chapters/Referenzen/boehringer-ingelheim.jpg";
import Logo3 from "../../../../../../assets/_Chapters/Referenzen/bosch.png";
import Logo4 from "../../../../../../assets/_Chapters/Referenzen/dmg-mori.png";
import Logo5 from "../../../../../../assets/_Chapters/Referenzen/engine-overhaul.png";
import Logo6 from "../../../../../../assets/_Chapters/Referenzen/hirschvogel.png";
import Logo7 from "../../../../../../assets/_Chapters/Referenzen/maier.png";
import Logo8 from "../../../../../../assets/_Chapters/Referenzen/max-boegel.png";
import Logo9 from "../../../../../../assets/_Chapters/Referenzen/schuler.png";
import Logo10 from "../../../../../../assets/_Chapters/Referenzen/siemens-energy.png";
import Logo11 from "../../../../../../assets/_Chapters/Referenzen/siemens.png";

const useStyles = makeStyles({
  container: {
    "& .MuiGrid-grid-xs-4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "150px",
        width: "auto",
        maxWidth: "65%",
      },
    },
  },
});

export default function Logos2() {
  const classes = useStyles();

  return (
    <Slide>
      <Grid container spacing={5} className={classes.container}>
        <Grid item xs={4}>
          <img src={Logo7} alt="Logo" loading="lazy" />
        </Grid>
        <Grid item xs={4}>
          <img src={Logo8} alt="Logo" loading="lazy" />
        </Grid>
        <Grid item xs={4}>
          <img src={Logo9} alt="Logo" loading="lazy" />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <img src={Logo10} alt="Logo" loading="lazy" />
        </Grid>
        <Grid item xs={4}>
          <img src={Logo11} alt="Logo" loading="lazy" />
        </Grid>
      </Grid>
    </Slide>
  );
}
