import useMediaQuery from "@material-ui/core/useMediaQuery";

export function CenteredText() {
  const matches = useMediaQuery("(min-width:600px)");
  const absoluteContainer = {
    display: "flex",
    height: "83vh",
    width: "100vw",
    marginTop: this.props.marginTextTop,
    top: this.props.top,
    left: this.props.left,
    left: this.props.left !== undefined ? this.props.left : "13vw",
    justifyContent:
      this.props.justifyContent !== undefined
        ? this.props.justifyContent
        : "flex-start",
    alignItems: this.props.align !== undefined ? this.props.align : "center",
    position: "absolute",
    zIndex: 2,
  };
  const titleBox = {
    color: this.props.color,
    fontSize: matches ? "6.5rem" : "2rem",
    fontFamily: "Averta-Bold",
    zIndex: 2,
    lineHeight: "1em",
    width: this.props.colWidth !== undefined ? this.props.colWidth : "auto",
  };

  return (
    <div style={absoluteContainer}>
      <h1 style={titleBox}>{this.props.title}</h1>
    </div>
  );
}
