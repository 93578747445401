import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Ueber-SSV-Kroschke/1.webp";
import { Grid } from "@material-ui/core";

export default function UnsereMissionAntwort() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "left",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Wie wir das machen:" />
          <QList
            items={[
              "Wir verschlanken und digitalisieren Ihre Beschaffungsprozesse im Bereich der C-Teile",
              "Wir führen für unsere Kunden LEANmanagement und Industrie 4.0 sinnvoll zusammen",
              "Wir garantieren mit unseren Dienstleistungen und Ausgabesystemen für jeden Produktbereich die optimale Versorgung",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
