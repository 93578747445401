import {theme} from "../../../../../../../theme"

export default function BeschaffungsprozesseHinterCTeilenSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  
  return (
    <svg {...props} viewBox="0 0 1920 556" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
      <g>
        <g className="gsap-arrowline-arrow" id="Group_9">
        <path id="Vector_26" d="M0 85.5017H212.5C212.5 85.5017 309.18 72.7817 357.15 160.212" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_27" d="M340.97 157.153L355.03 156.432L364.27 145.812L366.07 179.102L340.97 157.153Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_8">
        <path id="Vector_24" d="M373.5 201.5C380.35 224.89 383.5 249.57 383.5 283.5C383.5 377.66 419.6 425.5 460.98 449.41" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_25" d="M446.48 456.261L457.25 447.191L458.2 433.141L479.75 458.591L446.48 456.261Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_7">
        <path id="Vector_22" d="M498.53 465C542.15 477.16 581.5 470.5 581.5 470.5C581.5 470.5 698.27 491.81 753.36 402.16" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_23" d="M759.83 416.85L751.06 405.83L737.04 404.49L763.08 383.66L759.83 416.85Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_6">
        <path id="Vector_20" d="M769.71 366.44C777.14 343.82 781.5 316.5 781.5 283.5C781.5 193.27 810.68 143.62 845.47 116.5" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_21" d="M844.88 133L842.08 119.2L830.21 111.64L862.87 104.93L844.88 133Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_5">
        <path id="Vector_18" d="M878.28 97.4218C920.48 79.8018 960.5 85.5019 960.5 85.5019C960.5 85.5019 1066.53 61.6319 1111.61 166.952" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_19" d="M1095.84 162.382L1109.91 162.972L1120.11 153.262L1118.79 186.582L1095.84 162.382Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_4">
        <path id="Vector_16" d="M1123 202.012C1128.39 224.682 1131.5 251.592 1131.5 283.502C1131.5 386.192 1168.83 434.962 1211.08 457.272" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_17" d="M1196.9 464.834L1207.24 455.264L1207.53 441.184L1230.24 465.604L1196.9 464.834Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_3">
        <path id="Vector_14" d="M1247.48 470.501C1290.72 480.191 1329.5 470.501 1329.5 470.501C1329.5 470.501 1446.74 490.141 1501.7 400.371" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_15" d="M1508.19 415.049L1499.4 404.049L1485.38 402.729L1511.39 381.859L1508.19 415.049Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group_2">
        <path id="Vector_12" d="M1518.04 364.472C1525.27 342.262 1529.5 315.572 1529.5 283.502C1529.5 196.492 1557.37 147.312 1591.12 119.672" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_13" d="M1590.95 136.18L1587.8 122.45L1575.74 115.19L1608.22 107.66L1590.95 136.18Z" fill="#E4032E"/>
        </g>
        <g className="gsap-arrowline-arrow" id="Group">
        <path id="Vector_10" d="M1629.44 97.1673C1670.45 80.7173 1708.5 85.4973 1708.5 85.4973H1888.61" stroke="#E4032E" stroke-width="7" stroke-miterlimit="10"/>
        <path id="Vector_11" d="M1878.78 98.4591L1884.28 85.4991L1878.78 72.5391L1909.5 85.4991L1878.78 98.4591Z" fill="#E4032E"/>
        </g>
      </g>
      
      {/*<path className="gsap-arrowline-line" d="M0 88.5004C0 88.5004 248.5 88.5004 281 88.5004C424 88.5004 294 486.5 581 486.5C903.5 486.5 665 87.3995 954.75 88.5004C1266.5 82.5 1019 488.5 1327.5 486.5C1636 484.501 1473.5 88.5004 1649 88.5004C1789.5 88.5004 1909.5 88.5004 1909.5 88.5004" stroke="#9A9A9A" stroke-width="39"/> */}

      <g className="gsap-arrowline-label" >
      <path id="Vector" d="M212.5 171C259.72 171 298 132.72 298 85.5C298 38.2797 259.72 0 212.5 0C165.28 0 127 38.2797 127 85.5C127 132.72 165.28 171 212.5 171Z" fill="#E4032E"/>

        <path id="Vector_28" d="M167.65 80.8883H164.59V69.7583H161.95V67.4883C163.67 67.3983 164.79 66.7583 165.1 65.4883H167.65V80.8883Z" fill="white"/>
        <path id="Vector_29" d="M183.6 73.14C183.6 77.83 181.31 81.13 177.11 81.13C172.91 81.13 170.66 77.87 170.66 73.19C170.66 68.51 172.93 65.25 177.13 65.25C181.33 65.25 183.6 68.46 183.6 73.15V73.14ZM180.48 73.18C180.48 70.43 179.47 68.25 177.11 68.25C174.75 68.25 173.79 70.38 173.79 73.13C173.79 75.88 174.78 78.1 177.13 78.1C179.48 78.1 180.47 75.92 180.47 73.17L180.48 73.18Z" fill="white"/>
        <path id="Vector_30" d="M206.7 80.8883H203.31L202.1 77.4583H196.47L195.26 80.8883H191.96L197.86 65.4883H200.85L206.7 80.8883ZM201.05 74.5283L200.06 71.7383C199.75 70.8383 199.44 69.9083 199.29 69.3383C199.14 69.9083 198.83 70.8383 198.52 71.7383L197.53 74.5283H201.05Z" fill="white"/>
        <path id="Vector_31" d="M216.6 69.9116L215.81 72.8816C215.33 72.4416 214.73 72.1316 214.05 72.1316C212.86 72.1316 212.09 72.6416 212.09 74.6416V80.8916H209.05V69.3416H212.09V70.1115C212.62 69.5615 213.39 69.1016 214.47 69.1016C215.46 69.1016 216.21 69.5016 216.6 69.9116Z" fill="white"/>
        <path id="Vector_32" d="M225.75 77.3499L225.31 80.4298C224.8 80.8298 223.9 81.1299 222.96 81.1299C221.09 81.1299 219.88 79.6798 219.88 77.6498V72.1898H217.94V69.3298H219.88V66.3598L222.92 64.8398V69.3298H225.69V72.1898H222.92V76.8298C222.92 77.9298 223.32 78.2198 224.04 78.2198C224.59 78.2198 225.01 77.9798 225.76 77.3398L225.75 77.3499Z" fill="white"/>
        <path id="Vector_33" d="M231.95 66.6501C231.95 67.6001 231.16 68.3902 230.21 68.3902C229.26 68.3902 228.47 67.6001 228.47 66.6501C228.47 65.7001 229.26 64.9102 230.21 64.9102C231.16 64.9102 231.95 65.7001 231.95 66.6501ZM231.73 80.8902H228.69V69.3401H231.73V80.8902Z" fill="white"/>
        <path id="Vector_34" d="M244.83 80.8894H241.51L239.31 75.5694L237.84 77.3994V80.8994H234.8V66.3794L237.84 64.8594V70.9994C237.84 71.6994 237.82 72.8894 237.8 73.4594C238.11 72.9994 238.64 72.2694 239.03 71.7694L240.9 69.3494H244.49L241.52 73.0194L244.84 80.8994L244.83 80.8894Z" fill="white"/>
        <path id="Vector_35" d="M255.76 75.1198C255.76 75.5598 255.74 75.8698 255.72 76.0898H248.81C248.92 77.6698 250.09 78.2698 251.19 78.2698C252.03 78.2698 252.73 77.9598 253.17 77.4598L255.33 79.2599C254.41 80.4499 252.73 81.1299 251.19 81.1299C247.87 81.1299 245.75 78.7798 245.75 75.1198C245.75 71.4598 247.97 69.0898 250.9 69.0898C253.98 69.0898 255.76 71.8198 255.76 75.1198ZM252.81 73.7998C252.72 72.7698 252.11 71.7299 250.74 71.7299C249.64 71.7299 248.96 72.6298 248.85 73.7998H252.81Z" fill="white"/>
        <path id="Vector_36" d="M261.32 80.8916H258.28V66.3716L261.32 64.8516V80.8916Z" fill="white"/>
        <path id="Vector_37" d="M179.81 101.662C179.81 105.112 178.18 107.542 175.32 107.542C174.31 107.542 173.43 107.142 172.88 106.642V107.302H169.84V92.7817L172.88 91.2617V96.4717C173.45 95.9617 174.22 95.5017 175.32 95.5017C177.78 95.5017 179.81 97.3517 179.81 101.662ZM176.73 101.732C176.73 99.3517 175.94 98.3617 174.75 98.3617C173.8 98.3617 173.32 98.7617 172.88 99.3317V103.692C173.34 104.332 174.05 104.682 174.93 104.682C176.1 104.682 176.73 103.672 176.73 101.732Z" fill="white"/>
        <path id="Vector_38" d="M191.62 101.518C191.62 101.958 191.6 102.268 191.58 102.488H184.67C184.78 104.068 185.95 104.668 187.05 104.668C187.89 104.668 188.59 104.358 189.03 103.858L191.19 105.658C190.27 106.848 188.59 107.528 187.05 107.528C183.73 107.528 181.61 105.178 181.61 101.518C181.61 97.8583 183.83 95.4883 186.76 95.4883C189.84 95.4883 191.62 98.2183 191.62 101.518ZM188.67 100.198C188.58 99.1683 187.97 98.1283 186.6 98.1283C185.5 98.1283 184.82 99.0283 184.71 100.198H188.67Z" fill="white"/>
        <path id="Vector_39" d="M204.05 107.29H201.01V101.04C201.01 98.97 200.31 98.36 199.05 98.36C197.79 98.36 197.07 98.98 197.07 100.98V107.29H194.03V95.74H197.07V96.51C197.6 95.96 198.57 95.5 199.78 95.5C202.73 95.5 204.05 97.52 204.05 100.78V107.29Z" fill="white"/>
        <path id="Vector_40" d="M216.74 101.52C216.74 105.06 214.52 107.53 211.5 107.53C208.48 107.53 206.29 105.07 206.29 101.52C206.29 97.97 208.49 95.49 211.5 95.49C214.51 95.49 216.74 97.98 216.74 101.52ZM210.84 92.72C210.84 93.62 210.05 94.41 209.12 94.41C208.19 94.41 207.4 93.62 207.4 92.72C207.4 91.77 208.19 91 209.12 91C210.05 91 210.84 91.77 210.84 92.72ZM213.66 101.52C213.66 99.76 212.96 98.42 211.5 98.42C210.04 98.42 209.37 99.74 209.37 101.52C209.37 103.3 210.05 104.6 211.5 104.6C212.95 104.6 213.66 103.13 213.66 101.52ZM215.6 92.72C215.6 93.62 214.81 94.41 213.91 94.41C213.01 94.41 212.17 93.62 212.17 92.72C212.17 91.77 212.96 91 213.91 91C214.86 91 215.6 91.77 215.6 92.72Z" fill="white"/>
        <path id="Vector_41" d="M225.98 103.748L225.54 106.828C225.03 107.228 224.13 107.528 223.19 107.528C221.32 107.528 220.11 106.078 220.11 104.048V98.5883H218.17V95.7283H220.11V92.7583L223.15 91.2383V95.7283H225.92V98.5883H223.15V103.228C223.15 104.328 223.55 104.618 224.27 104.618C224.82 104.618 225.24 104.378 225.99 103.738L225.98 103.748Z" fill="white"/>
        <path id="Vector_42" d="M232.19 93.0486C232.19 93.9986 231.4 94.7886 230.45 94.7886C229.5 94.7886 228.71 93.9986 228.71 93.0486C228.71 92.0986 229.5 91.3086 230.45 91.3086C231.4 91.3086 232.19 92.0986 232.19 93.0486ZM231.97 107.289H228.93V95.7386H231.97V107.289Z" fill="white"/>
        <path id="Vector_43" d="M244.401 105.33C244.401 109.14 243.481 111.1 239.431 111.54L238.351 109.25C240.731 108.92 241.361 108.35 241.361 106.57C240.791 107.08 240.021 107.54 238.921 107.54C236.461 107.54 234.431 105.69 234.431 101.38C234.431 97.93 236.061 95.5 238.921 95.5C239.931 95.5 240.811 95.9 241.361 96.4V95.74H244.401V105.33ZM241.361 103.7V99.34C240.901 98.7 240.191 98.35 239.311 98.35C238.141 98.35 237.511 99.36 237.511 101.3C237.511 103.68 238.301 104.67 239.491 104.67C240.441 104.67 240.921 104.27 241.361 103.7Z" fill="white"/>
        <path id="Vector_44" d="M254.27 103.748L253.83 106.828C253.32 107.228 252.42 107.528 251.48 107.528C249.61 107.528 248.4 106.078 248.4 104.048V98.5883H246.46V95.7283H248.4V92.7583L251.44 91.2383V95.7283H254.21V98.5883H251.44V103.228C251.44 104.328 251.84 104.618 252.56 104.618C253.11 104.618 253.53 104.378 254.28 103.738L254.27 103.748Z" fill="white"/>
      </g>
      <g className="gsap-arrowline-label" >
        <path id="Vector_3" d="M383.5 369C430.72 369 469 330.72 469 283.5C469 236.28 430.72 198 383.5 198C336.28 198 298 236.28 298 283.5C298 330.72 336.28 369 383.5 369Z" fill="#E4032E"/>
        <text id="10 Freigaben" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="317.46" y="292.94">10 Freigaben</tspan></text>
      </g>
      <g className="gsap-arrowline-label" > 
        <path id="Vector_5" d="M581.5 556C628.72 556 667 517.72 667 470.5C667 423.28 628.72 385 581.5 385C534.28 385 496 423.28 496 470.5C496 517.72 534.28 556 581.5 556Z" fill="#E4032E"/>
        <text id="10 Anfragen" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="518.36" y="473.94">10 Anfragen</tspan></text>
      </g>
      <g className="gsap-arrowline-label" > 
        <path id="Vector_4" d="M781.5 369C828.72 369 867 330.72 867 283.5C867 236.28 828.72 198 781.5 198C734.28 198 696 236.28 696 283.5C696 330.72 734.28 369 781.5 369Z" fill="#E4032E"/>
        <text id="10 Angebote" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="716.45" y="292.94">10 Angebote</tspan></text>
      </g>
      <g className="gsap-arrowline-label" > 
      <path id="Vector_2" d="M960.5 171C1007.72 171 1046 132.72 1046 85.5C1046 38.2797 1007.72 0 960.5 0C913.28 0 875 38.2797 875 85.5C875 132.72 913.28 171 960.5 171Z" fill="#E4032E"/>

      <g id="Group_11">
      <path id="Vector_45" d="M954.75 77.0211H951.69V65.8911H949.05V63.6211C950.77 63.5311 951.89 62.8911 952.2 61.6211H954.75V77.0211Z" fill="white"/>
      <path id="Vector_46" d="M970.71 69.2806C970.71 73.9706 968.419 77.2706 964.219 77.2706C960.019 77.2706 957.77 74.0106 957.77 69.3306C957.77 64.6506 960.04 61.3906 964.24 61.3906C968.44 61.3906 970.71 64.6006 970.71 69.2906V69.2806ZM967.59 69.3206C967.59 66.5706 966.579 64.3906 964.219 64.3906C961.859 64.3906 960.9 66.5206 960.9 69.2706C960.9 72.0206 961.89 74.2406 964.24 74.2406C966.59 74.2406 967.58 72.0606 967.58 69.3106L967.59 69.3206Z" fill="white"/>
      <path id="Vector_47" d="M907.03 98.4495C907.03 101.68 904.85 103.419 901.55 103.419H894.55V88.0195H901.61C904.6 88.0195 906.49 89.4495 906.49 92.0895C906.49 93.4095 905.87 94.4695 904.86 95.0795C906.09 95.6295 907.02 96.7495 907.02 98.4495H907.03ZM903.38 92.3295C903.38 91.3595 902.83 90.8595 901.49 90.8595H897.57V93.7895H901.64C902.92 93.7895 903.38 93.1995 903.38 92.3395V92.3295ZM903.86 98.4895C903.86 97.1695 903.16 96.5095 901.66 96.5095H897.57V100.49H901.66C903.16 100.49 903.86 99.6995 903.86 98.4895Z" fill="white"/>
      <path id="Vector_48" d="M918.859 97.6589C918.859 98.0989 918.839 98.4089 918.819 98.6289H911.909C912.019 100.209 913.189 100.809 914.289 100.809C915.129 100.809 915.829 100.499 916.269 99.9989L918.429 101.799C917.509 102.989 915.829 103.669 914.289 103.669C910.969 103.669 908.859 101.319 908.859 97.6589C908.859 93.9989 911.079 91.6289 914.009 91.6289C917.089 91.6289 918.869 94.3589 918.869 97.6589H918.859ZM915.909 96.3389C915.819 95.3089 915.209 94.2689 913.839 94.2689C912.739 94.2689 912.059 95.1689 911.949 96.3389H915.909Z" fill="white"/>
      <path id="Vector_49" d="M930.19 100.049C930.19 102.709 928.08 103.659 925.48 103.659C923.65 103.659 921.87 103.129 920.64 102.319L921.65 100.009C922.88 100.689 924.18 101.069 925.65 101.069C926.84 101.069 927.19 100.779 927.19 100.189C927.19 99.6589 926.77 99.3989 925.5 99.0189C922.73 98.1789 920.95 97.4989 920.95 95.0589C920.95 92.6189 922.86 91.6289 925.57 91.6289C927.02 91.6289 928.61 92.0689 929.84 92.7489L928.87 95.1489C927.73 94.5289 926.47 94.2289 925.5 94.2289C924.27 94.2289 923.94 94.4689 923.94 94.9789C923.94 95.4889 924.29 95.6589 926.34 96.3189C928.41 96.9989 930.19 97.7489 930.19 100.059V100.049Z" fill="white"/>
      <path id="Vector_50" d="M939.539 99.8811L939.099 102.961C938.589 103.361 937.69 103.661 936.75 103.661C934.88 103.661 933.669 102.211 933.669 100.181V94.7211H931.729V91.8611H933.669V88.8911L936.71 87.3711V91.8611H939.479V94.7211H936.71V99.3611C936.71 100.461 937.11 100.751 937.83 100.751C938.38 100.751 938.799 100.511 939.549 99.8711L939.539 99.8811Z" fill="white"/>
      <path id="Vector_51" d="M951.33 97.6589C951.33 98.0989 951.31 98.4089 951.29 98.6289H944.38C944.49 100.209 945.66 100.809 946.76 100.809C947.6 100.809 948.3 100.499 948.74 99.9989L950.9 101.799C949.98 102.989 948.3 103.669 946.76 103.669C943.44 103.669 941.33 101.319 941.33 97.6589C941.33 93.9989 943.55 91.6289 946.48 91.6289C949.56 91.6289 951.34 94.3589 951.34 97.6589H951.33ZM948.38 96.3389C948.29 95.3089 947.68 94.2689 946.31 94.2689C945.21 94.2689 944.53 95.1689 944.42 96.3389H948.38Z" fill="white"/>
      <path id="Vector_52" d="M956.899 103.419H953.859V88.8989L956.899 87.3789V103.419Z" fill="white"/>
      <path id="Vector_53" d="M963.1 103.419H960.06V88.8989L963.1 87.3789V103.419Z" fill="white"/>
      <path id="Vector_54" d="M976.06 103.421H973.02V102.651C972.49 103.201 971.52 103.661 970.31 103.661C967.36 103.661 966.04 101.641 966.04 98.3811V91.8711H969.08V98.1211C969.08 100.191 969.78 100.801 971.04 100.801C972.3 100.801 973.02 100.181 973.02 98.1811V91.8711H976.06V103.421Z" fill="white"/>
      <path id="Vector_55" d="M989.02 103.419H985.98V97.1689C985.98 95.0989 985.28 94.4889 984.02 94.4889C982.76 94.4889 982.04 95.1089 982.04 97.1089V103.419H979V91.8689H982.04V92.6389C982.57 92.0889 983.54 91.6289 984.75 91.6289C987.7 91.6289 989.02 93.6489 989.02 96.9089V103.419Z" fill="white"/>
      <path id="Vector_56" d="M1001.23 101.459C1001.23 105.269 1000.31 107.229 996.26 107.669L995.18 105.379C997.56 105.049 998.19 104.479 998.19 102.699C997.62 103.209 996.85 103.669 995.75 103.669C993.29 103.669 991.26 101.819 991.26 97.5089C991.26 94.0589 992.89 91.6289 995.75 91.6289C996.76 91.6289 997.64 92.0289 998.19 92.5289V91.8689H1001.23V101.459ZM998.19 99.8289V95.4689C997.73 94.8289 997.02 94.4789 996.14 94.4789C994.97 94.4789 994.34 95.4889 994.34 97.4289C994.34 99.8089 995.13 100.799 996.32 100.799C997.27 100.799 997.75 100.399 998.19 99.8289Z" fill="white"/>
      <path id="Vector_57" d="M1013.64 97.6589C1013.64 98.0989 1013.62 98.4089 1013.6 98.6289H1006.69C1006.8 100.209 1007.97 100.809 1009.07 100.809C1009.91 100.809 1010.61 100.499 1011.05 99.9989L1013.21 101.799C1012.29 102.989 1010.61 103.669 1009.07 103.669C1005.75 103.669 1003.64 101.319 1003.64 97.6589C1003.64 93.9989 1005.86 91.6289 1008.79 91.6289C1011.87 91.6289 1013.65 94.3589 1013.65 97.6589H1013.64ZM1010.69 96.3389C1010.6 95.3089 1009.99 94.2689 1008.62 94.2689C1007.52 94.2689 1006.84 95.1689 1006.73 96.3389H1010.69Z" fill="white"/>
      <path id="Vector_58" d="M1026.07 103.419H1023.03V97.1689C1023.03 95.0989 1022.33 94.4889 1021.07 94.4889C1019.81 94.4889 1019.09 95.1089 1019.09 97.1089V103.419H1016.05V91.8689H1019.09V92.6389C1019.62 92.0889 1020.59 91.6289 1021.8 91.6289C1024.75 91.6289 1026.07 93.6489 1026.07 96.9089V103.419Z" fill="white"/>
      </g>
      </g>
      <g className="gsap-arrowline-label" > 
        <path id="Vector_7" d="M1131.5 369C1178.72 369 1217 330.72 1217 283.5C1217 236.28 1178.72 198 1131.5 198C1084.28 198 1046 236.28 1046 283.5C1046 330.72 1084.28 369 1131.5 369Z" fill="#E4032E"/>
        <g id="Group_12">
          <path id="Vector_59" d="M1074.35 276.017H1071.29V264.887H1068.65V262.617C1070.37 262.527 1071.49 261.887 1071.8 260.617H1074.35V276.017Z" fill="white"/>
          <path id="Vector_60" d="M1090.3 268.281C1090.3 272.971 1088.01 276.271 1083.81 276.271C1079.61 276.271 1077.36 273.011 1077.36 268.331C1077.36 263.651 1079.63 260.391 1083.83 260.391C1088.03 260.391 1090.3 263.601 1090.3 268.291V268.281ZM1087.18 268.321C1087.18 265.571 1086.17 263.391 1083.81 263.391C1081.45 263.391 1080.49 265.521 1080.49 268.271C1080.49 271.021 1081.48 273.241 1083.83 273.241C1086.18 273.241 1087.17 271.061 1087.17 268.311L1087.18 268.321Z" fill="white"/>
          <path id="Vector_61" d="M1113.4 276.017H1110.01L1108.8 272.587H1103.17L1101.96 276.017H1098.66L1104.56 260.617H1107.55L1113.4 276.017ZM1107.74 269.657L1106.75 266.867C1106.44 265.967 1106.13 265.037 1105.98 264.467C1105.83 265.037 1105.52 265.967 1105.21 266.867L1104.22 269.657H1107.74Z" fill="white"/>
          <path id="Vector_62" d="M1125.1 276.019H1122.06V275.249C1121.53 275.799 1120.56 276.259 1119.35 276.259C1116.4 276.259 1115.08 274.239 1115.08 270.979V264.469H1118.12V270.719C1118.12 272.789 1118.82 273.399 1120.08 273.399C1121.34 273.399 1122.06 272.779 1122.06 270.779V264.469H1125.1V276.019Z" fill="white"/>
          <path id="Vector_63" d="M1134.01 262.819C1133.7 262.669 1133.39 262.579 1132.95 262.579C1132.05 262.579 1131.83 262.869 1131.83 263.499V264.469H1133.79V267.329H1131.83V276.019H1128.79V267.329H1127.4V264.469H1128.79V263.079C1128.79 260.919 1129.98 260.039 1132.09 260.039C1132.84 260.039 1133.48 260.149 1134 260.369V262.809L1134.01 262.819Z" fill="white"/>
          <path id="Vector_64" d="M1143.08 272.479L1142.64 275.559C1142.13 275.959 1141.23 276.259 1140.29 276.259C1138.42 276.259 1137.21 274.809 1137.21 272.779V267.319H1135.27V264.459H1137.21V261.489L1140.25 259.969V264.459H1143.02V267.319H1140.25V271.959C1140.25 273.059 1140.65 273.349 1141.37 273.349C1141.92 273.349 1142.34 273.109 1143.09 272.469L1143.08 272.479Z" fill="white"/>
          <path id="Vector_65" d="M1153.46 265.04L1152.67 268.01C1152.19 267.57 1151.59 267.26 1150.91 267.26C1149.72 267.26 1148.95 267.771 1148.95 269.771V276.021H1145.91V264.47H1148.95V265.24C1149.48 264.69 1150.25 264.23 1151.33 264.23C1152.32 264.23 1153.07 264.63 1153.46 265.04Z" fill="white"/>
          <path id="Vector_66" d="M1164.18 276.021H1161.14V275.25C1160.59 275.82 1159.62 276.26 1158.54 276.26C1156.38 276.26 1154.23 274.941 1154.23 272.151C1154.23 269.601 1156.3 268.32 1158.92 268.32C1159.98 268.32 1160.66 268.541 1161.14 268.781V268.211C1161.14 267.401 1160.59 266.74 1159.49 266.74C1158.1 266.74 1157.38 266.94 1156.32 267.51L1155.15 265.401C1156.49 264.631 1157.61 264.23 1159.48 264.23C1162.27 264.23 1164.17 265.681 1164.17 268.281V276.031L1164.18 276.021ZM1161.14 272.63V271.18C1160.61 270.85 1160.11 270.701 1159.03 270.701C1157.89 270.701 1157.27 271.231 1157.27 272.151C1157.27 272.961 1157.73 273.69 1159.03 273.69C1160.02 273.69 1160.81 273.18 1161.14 272.63Z" fill="white"/>
          <path id="Vector_67" d="M1176.38 274.06C1176.38 277.87 1175.46 279.821 1171.41 280.271L1170.33 277.98C1172.71 277.65 1173.34 277.08 1173.34 275.3C1172.77 275.81 1172 276.271 1170.9 276.271C1168.44 276.271 1166.41 274.42 1166.41 270.11C1166.41 266.65 1168.04 264.23 1170.9 264.23C1171.91 264.23 1172.79 264.63 1173.34 265.13V264.47H1176.38V274.06ZM1173.34 272.43V268.07C1172.88 267.43 1172.17 267.081 1171.29 267.081C1170.12 267.081 1169.49 268.091 1169.49 270.031C1169.49 272.411 1170.28 273.401 1171.47 273.401C1172.42 273.401 1172.9 273 1173.34 272.43Z" fill="white"/>
          <path id="Vector_68" d="M1188.51 272.651C1188.51 275.311 1186.4 276.26 1183.8 276.26C1181.97 276.26 1180.19 275.73 1178.96 274.92L1179.97 272.61C1181.2 273.29 1182.5 273.67 1183.97 273.67C1185.16 273.67 1185.51 273.38 1185.51 272.79C1185.51 272.26 1185.09 272 1183.82 271.62C1181.05 270.78 1179.27 270.1 1179.27 267.66C1179.27 265.22 1181.18 264.23 1183.89 264.23C1185.34 264.23 1186.93 264.67 1188.16 265.35L1187.19 267.75C1186.05 267.13 1184.79 266.831 1183.82 266.831C1182.59 266.831 1182.26 267.071 1182.26 267.581C1182.26 268.091 1182.61 268.26 1184.66 268.92C1186.73 269.6 1188.51 270.35 1188.51 272.66V272.651Z" fill="white"/>
          <path id="Vector_69" d="M1196.89 269.548H1190.57V266.508H1196.89V269.548Z" fill="white"/>
          <path id="Vector_70" d="M1070.16 296.791C1070.16 300.241 1068.53 302.671 1065.67 302.671C1064.66 302.671 1063.78 302.271 1063.23 301.771V302.431H1060.19V287.911L1063.23 286.391V291.601C1063.8 291.091 1064.57 290.631 1065.67 290.631C1068.13 290.631 1070.16 292.481 1070.16 296.791ZM1067.08 296.861C1067.08 294.481 1066.29 293.491 1065.1 293.491C1064.15 293.491 1063.67 293.891 1063.23 294.461V298.821C1063.69 299.461 1064.4 299.811 1065.28 299.811C1066.45 299.811 1067.08 298.801 1067.08 296.861Z" fill="white"/>
          <path id="Vector_71" d="M1081.97 296.659C1081.97 297.099 1081.95 297.409 1081.93 297.629H1075.02C1075.13 299.209 1076.3 299.809 1077.4 299.809C1078.24 299.809 1078.94 299.499 1079.38 298.999L1081.54 300.799C1080.62 301.989 1078.94 302.669 1077.4 302.669C1074.08 302.669 1071.97 300.319 1071.97 296.659C1071.97 292.999 1074.19 290.629 1077.12 290.629C1080.2 290.629 1081.98 293.359 1081.98 296.659H1081.97ZM1079.02 295.339C1078.93 294.309 1078.31 293.269 1076.95 293.269C1075.85 293.269 1075.17 294.169 1075.06 295.339H1079.02Z" fill="white"/>
          <path id="Vector_72" d="M1093.3 299.049C1093.3 301.709 1091.19 302.659 1088.59 302.659C1086.76 302.659 1084.98 302.129 1083.75 301.319L1084.76 299.009C1085.99 299.689 1087.29 300.069 1088.76 300.069C1089.95 300.069 1090.3 299.779 1090.3 299.189C1090.3 298.659 1089.88 298.399 1088.61 298.019C1085.84 297.179 1084.06 296.499 1084.06 294.059C1084.06 291.619 1085.97 290.629 1088.68 290.629C1090.13 290.629 1091.72 291.069 1092.95 291.749L1091.98 294.149C1090.84 293.529 1089.58 293.229 1088.61 293.229C1087.38 293.229 1087.05 293.469 1087.05 293.979C1087.05 294.489 1087.4 294.659 1089.45 295.319C1091.52 295.999 1093.3 296.749 1093.3 299.059V299.049Z" fill="white"/>
          <path id="Vector_73" d="M1102.65 298.877L1102.21 301.957C1101.7 302.357 1100.8 302.657 1099.86 302.657C1097.99 302.657 1096.78 301.207 1096.78 299.177V293.717H1094.84V290.857H1096.78V287.887L1099.82 286.367V290.857H1102.59V293.717H1099.82V298.357C1099.82 299.457 1100.22 299.747 1100.94 299.747C1101.49 299.747 1101.91 299.507 1102.66 298.867L1102.65 298.877Z" fill="white"/>
          <path id="Vector_74" d="M1114.88 302.419H1111.84V301.65C1111.29 302.22 1110.32 302.659 1109.24 302.659C1107.08 302.659 1104.93 301.339 1104.93 298.549C1104.93 295.999 1107 294.719 1109.62 294.719C1110.68 294.719 1111.36 294.94 1111.84 295.18V294.609C1111.84 293.799 1111.29 293.14 1110.19 293.14C1108.8 293.14 1108.08 293.339 1107.02 293.909L1105.85 291.799C1107.19 291.029 1108.31 290.63 1110.18 290.63C1112.97 290.63 1114.87 292.08 1114.87 294.68V302.43L1114.88 302.419ZM1109.47 287.74C1109.47 288.64 1108.68 289.44 1107.75 289.44C1106.82 289.44 1106.03 288.65 1106.03 287.74C1106.03 286.83 1106.82 286.02 1107.75 286.02C1108.68 286.02 1109.47 286.79 1109.47 287.74ZM1111.85 299.03V297.58C1111.32 297.25 1110.82 297.099 1109.74 297.099C1108.6 297.099 1107.98 297.629 1107.98 298.549C1107.98 299.359 1108.44 300.09 1109.74 300.09C1110.73 300.09 1111.52 299.58 1111.85 299.03ZM1114.23 287.74C1114.23 288.64 1113.44 289.44 1112.54 289.44C1111.64 289.44 1110.8 288.65 1110.8 287.74C1110.8 286.83 1111.59 286.02 1112.54 286.02C1113.49 286.02 1114.23 286.79 1114.23 287.74Z" fill="white"/>
          <path id="Vector_75" d="M1124.61 298.877L1124.17 301.957C1123.66 302.357 1122.76 302.657 1121.82 302.657C1119.95 302.657 1118.74 301.207 1118.74 299.177V293.717H1116.8V290.857H1118.74V287.887L1121.78 286.367V290.857H1124.55V293.717H1121.78V298.357C1121.78 299.457 1122.18 299.747 1122.9 299.747C1123.45 299.747 1123.87 299.507 1124.62 298.867L1124.61 298.877Z" fill="white"/>
          <path id="Vector_76" d="M1130.81 288.189C1130.81 289.139 1130.02 289.929 1129.07 289.929C1128.12 289.929 1127.33 289.139 1127.33 288.189C1127.33 287.239 1128.12 286.449 1129.07 286.449C1130.02 286.449 1130.81 287.239 1130.81 288.189ZM1130.59 302.429H1127.55V290.879H1130.59V302.429Z" fill="white"/>
          <path id="Vector_77" d="M1143.02 300.459C1143.02 304.269 1142.1 306.219 1138.05 306.669L1136.97 304.379C1139.35 304.049 1139.98 303.479 1139.98 301.699C1139.41 302.209 1138.64 302.669 1137.54 302.669C1135.08 302.669 1133.05 300.819 1133.05 296.509C1133.05 293.049 1134.68 290.629 1137.54 290.629C1138.55 290.629 1139.43 291.029 1139.98 291.529V290.869H1143.02V300.459ZM1139.98 298.829V294.469C1139.52 293.829 1138.81 293.479 1137.93 293.479C1136.76 293.479 1136.13 294.489 1136.13 296.429C1136.13 298.809 1136.92 299.799 1138.11 299.799C1139.06 299.799 1139.54 299.399 1139.98 298.829Z" fill="white"/>
          <path id="Vector_78" d="M1155.91 302.417H1152.87V301.647C1152.34 302.197 1151.37 302.657 1150.16 302.657C1147.21 302.657 1145.89 300.637 1145.89 297.377V290.867H1148.93V297.117C1148.93 299.187 1149.63 299.797 1150.89 299.797C1152.15 299.797 1152.87 299.177 1152.87 297.177V290.867H1155.91V302.417Z" fill="white"/>
          <path id="Vector_79" d="M1168.87 302.421H1165.83V296.171C1165.83 294.101 1165.13 293.491 1163.87 293.491C1162.61 293.491 1161.89 294.111 1161.89 296.111V302.431H1158.85V290.881H1161.89V291.651C1162.42 291.101 1163.39 290.641 1164.6 290.641C1167.55 290.641 1168.87 292.661 1168.87 295.921V302.431V302.421Z" fill="white"/>
          <path id="Vector_80" d="M1181.08 300.459C1181.08 304.269 1180.16 306.219 1176.11 306.669L1175.03 304.379C1177.41 304.049 1178.04 303.479 1178.04 301.699C1177.47 302.209 1176.7 302.669 1175.6 302.669C1173.14 302.669 1171.11 300.819 1171.11 296.509C1171.11 293.049 1172.74 290.629 1175.6 290.629C1176.61 290.629 1177.49 291.029 1178.04 291.529V290.869H1181.08V300.459ZM1178.04 298.829V294.469C1177.58 293.829 1176.87 293.479 1175.99 293.479C1174.82 293.479 1174.19 294.489 1174.19 296.429C1174.19 298.809 1174.98 299.799 1176.17 299.799C1177.12 299.799 1177.6 299.399 1178.04 298.829Z" fill="white"/>
          <path id="Vector_81" d="M1193.49 296.659C1193.49 297.099 1193.47 297.409 1193.45 297.629H1186.54C1186.65 299.209 1187.82 299.809 1188.92 299.809C1189.76 299.809 1190.46 299.499 1190.9 298.999L1193.06 300.799C1192.14 301.989 1190.46 302.669 1188.92 302.669C1185.6 302.669 1183.48 300.319 1183.48 296.659C1183.48 292.999 1185.7 290.629 1188.63 290.629C1191.71 290.629 1193.49 293.359 1193.49 296.659ZM1190.54 295.339C1190.45 294.309 1189.84 293.269 1188.47 293.269C1187.37 293.269 1186.69 294.169 1186.58 295.339H1190.54Z" fill="white"/>
          <path id="Vector_82" d="M1205.92 302.421H1202.88V296.171C1202.88 294.101 1202.18 293.491 1200.92 293.491C1199.66 293.491 1198.94 294.111 1198.94 296.111V302.431H1195.9V290.881H1198.94V291.651C1199.47 291.101 1200.44 290.641 1201.65 290.641C1204.6 290.641 1205.92 292.661 1205.92 295.921V302.431V302.421Z" fill="white"/>
        </g>
      </g>
      <g className="gsap-arrowline-label" >
        <path id="Vector_9" d="M1329.5 556C1376.72 556 1415 517.72 1415 470.5C1415 423.28 1376.72 385 1329.5 385C1282.28 385 1244 423.28 1244 470.5C1244 517.72 1282.28 556 1329.5 556Z" fill="#E4032E"/>
        <g id="Group_13">
            <path id="Vector_83" d="M1283.95 463.021H1280.89V451.891H1278.25V449.621C1279.97 449.531 1281.09 448.891 1281.4 447.621H1283.95V463.021Z" fill="white"/>
            <path id="Vector_84" d="M1299.9 455.281C1299.9 459.971 1297.61 463.271 1293.41 463.271C1289.21 463.271 1286.96 460.011 1286.96 455.331C1286.96 450.651 1289.23 447.391 1293.43 447.391C1297.63 447.391 1299.9 450.601 1299.9 455.291V455.281ZM1296.78 455.321C1296.78 452.571 1295.77 450.391 1293.41 450.391C1291.05 450.391 1290.09 452.521 1290.09 455.271C1290.09 458.021 1291.08 460.241 1293.43 460.241C1295.78 460.241 1296.77 458.061 1296.77 455.311L1296.78 455.321Z" fill="white"/>
            <path id="Vector_85" d="M1325.66 447.621L1322.45 463.021H1319.44L1317.55 455.101C1317.33 454.181 1317.11 453.031 1317.02 452.461C1316.95 453.031 1316.73 454.201 1316.51 455.101L1314.57 463.021H1311.69L1308.48 447.621H1311.69L1313.03 455.121C1313.16 455.871 1313.34 457.011 1313.4 457.651C1313.51 457.011 1313.75 455.871 1313.93 455.121L1315.73 447.621H1318.41L1320.24 455.121C1320.42 455.871 1320.66 457.011 1320.77 457.651C1320.84 457.011 1321.01 455.851 1321.14 455.121L1322.48 447.621H1325.65H1325.66Z" fill="white"/>
            <path id="Vector_86" d="M1336.53 463.021H1333.49V462.25C1332.94 462.82 1331.97 463.26 1330.89 463.26C1328.73 463.26 1326.58 461.941 1326.58 459.151C1326.58 456.601 1328.65 455.32 1331.27 455.32C1332.33 455.32 1333.01 455.541 1333.49 455.781V455.211C1333.49 454.401 1332.94 453.74 1331.84 453.74C1330.45 453.74 1329.73 453.94 1328.67 454.51L1327.5 452.401C1328.84 451.631 1329.96 451.23 1331.83 451.23C1334.62 451.23 1336.52 452.681 1336.52 455.281V463.031L1336.53 463.021ZM1333.49 459.63V458.18C1332.96 457.85 1332.46 457.701 1331.38 457.701C1330.24 457.701 1329.62 458.231 1329.62 459.151C1329.62 459.961 1330.08 460.69 1331.38 460.69C1332.37 460.69 1333.16 460.18 1333.49 459.63Z" fill="white"/>
            <path id="Vector_87" d="M1346.91 452.04L1346.12 455.01C1345.64 454.57 1345.04 454.26 1344.36 454.26C1343.17 454.26 1342.4 454.771 1342.4 456.771V463.021H1339.36V451.47H1342.4V452.24C1342.93 451.69 1343.7 451.23 1344.78 451.23C1345.77 451.23 1346.52 451.63 1346.91 452.04Z" fill="white"/>
            <path id="Vector_88" d="M1357.87 457.26C1357.87 457.7 1357.85 458.01 1357.83 458.23H1350.92C1351.03 459.81 1352.2 460.41 1353.3 460.41C1354.14 460.41 1354.84 460.1 1355.28 459.6L1357.44 461.401C1356.52 462.591 1354.84 463.271 1353.3 463.271C1349.98 463.271 1347.86 460.92 1347.86 457.26C1347.86 453.6 1350.08 451.23 1353.01 451.23C1356.09 451.23 1357.87 453.96 1357.87 457.26ZM1354.92 455.94C1354.83 454.91 1354.22 453.87 1352.85 453.87C1351.75 453.87 1351.07 454.77 1350.96 455.94H1354.92Z" fill="white"/>
            <path id="Vector_89" d="M1370.29 463.022H1367.25V456.772C1367.25 454.702 1366.55 454.092 1365.29 454.092C1364.03 454.092 1363.31 454.712 1363.31 456.712V463.032H1360.27V451.482H1363.31V452.252C1363.84 451.702 1364.81 451.242 1366.02 451.242C1368.97 451.242 1370.29 453.262 1370.29 456.522V463.032V463.022Z" fill="white"/>
            <path id="Vector_90" d="M1379.29 456.552H1372.98V453.512H1379.29V456.552Z" fill="white"/>
            <path id="Vector_91" d="M1293.63 483.659C1293.63 484.099 1293.61 484.409 1293.59 484.629H1286.68C1286.79 486.209 1287.96 486.809 1289.06 486.809C1289.9 486.809 1290.6 486.499 1291.04 485.999L1293.2 487.799C1292.28 488.989 1290.6 489.669 1289.06 489.669C1285.74 489.669 1283.63 487.319 1283.63 483.659C1283.63 479.999 1285.85 477.629 1288.78 477.629C1291.86 477.629 1293.64 480.359 1293.64 483.659H1293.63ZM1290.68 482.339C1290.59 481.309 1289.98 480.269 1288.61 480.269C1287.51 480.269 1286.83 481.169 1286.72 482.339H1290.68Z" fill="white"/>
            <path id="Vector_92" d="M1299.41 475.189C1299.41 476.139 1298.62 476.929 1297.67 476.929C1296.72 476.929 1295.93 476.139 1295.93 475.189C1295.93 474.239 1296.72 473.449 1297.67 473.449C1298.62 473.449 1299.41 474.239 1299.41 475.189ZM1299.19 489.429H1296.15V477.879H1299.19V489.429Z" fill="white"/>
            <path id="Vector_93" d="M1312.26 489.421H1309.22V483.171C1309.22 481.101 1308.52 480.491 1307.26 480.491C1306 480.491 1305.28 481.111 1305.28 483.111V489.431H1302.24V477.881H1305.28V478.651C1305.81 478.101 1306.78 477.641 1307.99 477.641C1310.94 477.641 1312.26 479.661 1312.26 482.921V489.431V489.421Z" fill="white"/>
            <path id="Vector_94" d="M1324.47 487.459C1324.47 491.269 1323.55 493.219 1319.5 493.669L1318.42 491.379C1320.8 491.049 1321.43 490.479 1321.43 488.699C1320.86 489.209 1320.09 489.669 1318.99 489.669C1316.53 489.669 1314.5 487.819 1314.5 483.509C1314.5 480.049 1316.13 477.629 1318.99 477.629C1320 477.629 1320.88 478.029 1321.43 478.529V477.869H1324.47V487.459ZM1321.43 485.829V481.469C1320.97 480.829 1320.26 480.479 1319.38 480.479C1318.21 480.479 1317.58 481.489 1317.58 483.429C1317.58 485.809 1318.37 486.799 1319.56 486.799C1320.51 486.799 1320.99 486.399 1321.43 485.829Z" fill="white"/>
            <path id="Vector_95" d="M1336.86 489.419H1333.82V488.65C1333.27 489.22 1332.3 489.659 1331.22 489.659C1329.06 489.659 1326.91 488.339 1326.91 485.549C1326.91 482.999 1328.98 481.719 1331.6 481.719C1332.66 481.719 1333.34 481.94 1333.82 482.18V481.609C1333.82 480.799 1333.27 480.14 1332.17 480.14C1330.78 480.14 1330.06 480.339 1329 480.909L1327.83 478.799C1329.17 478.029 1330.29 477.63 1332.17 477.63C1334.96 477.63 1336.86 479.08 1336.86 481.68V489.43V489.419ZM1331.45 474.74C1331.45 475.64 1330.66 476.44 1329.73 476.44C1328.8 476.44 1328.01 475.65 1328.01 474.74C1328.01 473.83 1328.8 473.02 1329.73 473.02C1330.66 473.02 1331.45 473.79 1331.45 474.74ZM1333.83 486.03V484.58C1333.3 484.25 1332.8 484.099 1331.72 484.099C1330.58 484.099 1329.96 484.629 1329.96 485.549C1329.96 486.359 1330.42 487.09 1331.72 487.09C1332.71 487.09 1333.5 486.58 1333.83 486.03ZM1336.21 474.74C1336.21 475.64 1335.42 476.44 1334.52 476.44C1333.62 476.44 1332.78 475.65 1332.78 474.74C1332.78 473.83 1333.57 473.02 1334.52 473.02C1335.47 473.02 1336.21 473.79 1336.21 474.74Z" fill="white"/>
            <path id="Vector_96" d="M1349.7 489.421H1346.66V483.171C1346.66 481.101 1345.95 480.491 1344.7 480.491C1343.45 480.491 1342.72 481.111 1342.72 483.111V489.431H1339.68V477.881H1342.72V478.651C1343.25 478.101 1344.22 477.641 1345.43 477.641C1348.38 477.641 1349.7 479.661 1349.7 482.921V489.431V489.421Z" fill="white"/>
            <path id="Vector_97" d="M1361.91 487.459C1361.91 491.269 1360.99 493.219 1356.94 493.669L1355.86 491.379C1358.24 491.049 1358.87 490.479 1358.87 488.699C1358.3 489.209 1357.53 489.669 1356.43 489.669C1353.97 489.669 1351.94 487.819 1351.94 483.509C1351.94 480.049 1353.57 477.629 1356.43 477.629C1357.44 477.629 1358.32 478.029 1358.87 478.529V477.869H1361.91V487.459ZM1358.87 485.829V481.469C1358.41 480.829 1357.7 480.479 1356.82 480.479C1355.65 480.479 1355.02 481.489 1355.02 483.429C1355.02 485.809 1355.81 486.799 1357 486.799C1357.95 486.799 1358.43 486.399 1358.87 485.829Z" fill="white"/>
            <path id="Vector_98" d="M1374.32 483.659C1374.32 484.099 1374.3 484.409 1374.28 484.629H1367.37C1367.48 486.209 1368.65 486.809 1369.75 486.809C1370.59 486.809 1371.29 486.499 1371.73 485.999L1373.89 487.799C1372.97 488.989 1371.29 489.669 1369.75 489.669C1366.43 489.669 1364.32 487.319 1364.32 483.659C1364.32 479.999 1366.54 477.629 1369.47 477.629C1372.55 477.629 1374.33 480.359 1374.33 483.659H1374.32ZM1371.37 482.339C1371.28 481.309 1370.67 480.269 1369.3 480.269C1368.2 480.269 1367.52 481.169 1367.41 482.339H1371.37Z" fill="white"/>
          </g>
        </g>
      <g className="gsap-arrowline-label" >
        <path id="Vector_8" d="M1529.5 369C1576.72 369 1615 330.72 1615 283.5C1615 236.28 1576.72 198 1529.5 198C1482.28 198 1444 236.28 1444 283.5C1444 330.72 1482.28 369 1529.5 369Z" fill="#E4032E"/>

        <g id="Group_14">
          <path id="Vector_99" d="M1474.68 276.017H1471.62V264.887H1468.98V262.617C1470.7 262.527 1471.82 261.887 1472.13 260.617H1474.68V276.017Z" fill="white"/>
          <path id="Vector_100" d="M1490.63 268.281C1490.63 272.971 1488.34 276.271 1484.14 276.271C1479.94 276.271 1477.69 273.011 1477.69 268.331C1477.69 263.651 1479.96 260.391 1484.16 260.391C1488.36 260.391 1490.63 263.601 1490.63 268.291V268.281ZM1487.51 268.321C1487.51 265.571 1486.5 263.391 1484.14 263.391C1481.78 263.391 1480.82 265.521 1480.82 268.271C1480.82 271.021 1481.81 273.241 1484.16 273.241C1486.51 273.241 1487.5 271.061 1487.5 268.311L1487.51 268.321Z" fill="white"/>
          <path id="Vector_101" d="M1510.9 276.017H1500.1V260.617H1503.18V272.987H1510.9V276.027V276.017Z" fill="white"/>
          <path id="Vector_102" d="M1522.75 270.26C1522.75 273.8 1520.53 276.271 1517.51 276.271C1514.49 276.271 1512.3 273.81 1512.3 270.26C1512.3 266.71 1514.5 264.23 1517.51 264.23C1520.52 264.23 1522.75 266.72 1522.75 270.26ZM1519.67 270.26C1519.67 268.5 1518.97 267.16 1517.51 267.16C1516.05 267.16 1515.38 268.48 1515.38 270.26C1515.38 272.04 1516.06 273.341 1517.51 273.341C1518.96 273.341 1519.67 271.87 1519.67 270.26Z" fill="white"/>
          <path id="Vector_103" d="M1534.48 274.06C1534.48 277.87 1533.56 279.821 1529.51 280.271L1528.43 277.98C1530.81 277.65 1531.44 277.08 1531.44 275.3C1530.87 275.81 1530.1 276.271 1529 276.271C1526.54 276.271 1524.51 274.42 1524.51 270.11C1524.51 266.65 1526.14 264.23 1529 264.23C1530.01 264.23 1530.89 264.63 1531.44 265.13V264.47H1534.48V274.06ZM1531.44 272.43V268.07C1530.98 267.43 1530.27 267.081 1529.39 267.081C1528.22 267.081 1527.59 268.091 1527.59 270.031C1527.59 272.411 1528.38 273.401 1529.57 273.401C1530.52 273.401 1531 273 1531.44 272.43Z" fill="white"/>
          <path id="Vector_104" d="M1540.84 261.787C1540.84 262.737 1540.05 263.527 1539.1 263.527C1538.15 263.527 1537.36 262.737 1537.36 261.787C1537.36 260.837 1538.15 260.047 1539.1 260.047C1540.05 260.047 1540.84 260.837 1540.84 261.787ZM1540.62 276.027H1537.58V264.477H1540.62V276.027Z" fill="white"/>
          <path id="Vector_105" d="M1552.8 272.651C1552.8 275.311 1550.69 276.26 1548.09 276.26C1546.26 276.26 1544.48 275.73 1543.25 274.92L1544.26 272.61C1545.49 273.29 1546.79 273.67 1548.26 273.67C1549.45 273.67 1549.8 273.38 1549.8 272.79C1549.8 272.26 1549.38 272 1548.11 271.62C1545.34 270.78 1543.56 270.1 1543.56 267.66C1543.56 265.22 1545.47 264.23 1548.18 264.23C1549.63 264.23 1551.22 264.67 1552.45 265.35L1551.48 267.75C1550.34 267.13 1549.08 266.831 1548.11 266.831C1546.88 266.831 1546.55 267.071 1546.55 267.581C1546.55 268.091 1546.9 268.26 1548.95 268.92C1551.02 269.6 1552.8 270.35 1552.8 272.66V272.651Z" fill="white"/>
          <path id="Vector_106" d="M1562.16 272.479L1561.72 275.559C1561.21 275.959 1560.31 276.259 1559.37 276.259C1557.5 276.259 1556.29 274.809 1556.29 272.779V267.319H1554.35V264.459H1556.29V261.489L1559.33 259.969V264.459H1562.1V267.319H1559.33V271.959C1559.33 273.059 1559.73 273.349 1560.45 273.349C1561 273.349 1561.42 273.109 1562.17 272.469L1562.16 272.479Z" fill="white"/>
          <path id="Vector_107" d="M1568.36 261.787C1568.36 262.737 1567.57 263.527 1566.62 263.527C1565.67 263.527 1564.88 262.737 1564.88 261.787C1564.88 260.837 1565.67 260.047 1566.62 260.047C1567.57 260.047 1568.36 260.837 1568.36 261.787ZM1568.14 276.027H1565.1V264.477H1568.14V276.027Z" fill="white"/>
          <path id="Vector_108" d="M1581.23 276.021H1577.91L1575.71 270.69L1574.24 272.521V276.021H1571.2V261.5L1574.24 259.98V266.12C1574.24 266.82 1574.22 268.011 1574.2 268.581C1574.51 268.121 1575.04 267.39 1575.43 266.88L1577.3 264.461H1580.89L1577.92 268.13L1581.24 276.01L1581.23 276.021Z" fill="white"/>
          <path id="Vector_109" d="M1588.55 269.548H1582.24V266.508H1588.55V269.548Z" fill="white"/>
          <path id="Vector_110" d="M1494.65 296.791C1494.65 300.241 1493.02 302.671 1490.16 302.671C1489.15 302.671 1488.27 302.271 1487.72 301.771V305.161L1484.68 306.681V290.881H1487.72V291.611C1488.29 291.101 1489.06 290.641 1490.16 290.641C1492.62 290.641 1494.65 292.491 1494.65 296.801V296.791ZM1491.57 296.861C1491.57 294.481 1490.78 293.491 1489.59 293.491C1488.64 293.491 1488.16 293.891 1487.72 294.461V298.821C1488.18 299.461 1488.89 299.811 1489.77 299.811C1490.94 299.811 1491.57 298.801 1491.57 296.861Z" fill="white"/>
          <path id="Vector_111" d="M1504.59 291.439L1503.8 294.409C1503.32 293.969 1502.72 293.659 1502.04 293.659C1500.85 293.659 1500.08 294.169 1500.08 296.169V302.419H1497.04V290.869H1500.08V291.639C1500.61 291.089 1501.38 290.629 1502.46 290.629C1503.45 290.629 1504.2 291.029 1504.59 291.439Z" fill="white"/>
          <path id="Vector_112" d="M1515.99 296.659C1515.99 300.199 1513.77 302.669 1510.75 302.669C1507.73 302.669 1505.54 300.209 1505.54 296.659C1505.54 293.109 1507.74 290.629 1510.75 290.629C1513.76 290.629 1515.99 293.119 1515.99 296.659ZM1512.91 296.659C1512.91 294.899 1512.21 293.559 1510.75 293.559C1509.29 293.559 1508.62 294.879 1508.62 296.659C1508.62 298.439 1509.3 299.739 1510.75 299.739C1512.2 299.739 1512.91 298.269 1512.91 296.659Z" fill="white"/>
          <path id="Vector_113" d="M1527.23 302.417H1517.68V300.217L1521.88 295.227C1522.34 294.677 1523.02 293.927 1523.33 293.577C1522.91 293.597 1522.01 293.617 1521.44 293.617H1518.05V290.867H1527.22V293.067L1523.15 298.067C1522.69 298.637 1522.03 299.367 1521.72 299.717C1522.14 299.697 1523.04 299.677 1523.61 299.677H1527.22V302.427L1527.23 302.417Z" fill="white"/>
          <path id="Vector_114" d="M1539.09 296.659C1539.09 297.099 1539.07 297.409 1539.05 297.629H1532.14C1532.25 299.209 1533.42 299.809 1534.52 299.809C1535.36 299.809 1536.06 299.499 1536.5 298.999L1538.66 300.799C1537.74 301.989 1536.06 302.669 1534.52 302.669C1531.2 302.669 1529.09 300.319 1529.09 296.659C1529.09 292.999 1531.31 290.629 1534.24 290.629C1537.32 290.629 1539.1 293.359 1539.1 296.659H1539.09ZM1536.14 295.339C1536.05 294.309 1535.44 293.269 1534.07 293.269C1532.97 293.269 1532.29 294.169 1532.18 295.339H1536.14Z" fill="white"/>
          <path id="Vector_115" d="M1550.42 299.049C1550.42 301.709 1548.31 302.659 1545.71 302.659C1543.88 302.659 1542.1 302.129 1540.87 301.319L1541.88 299.009C1543.11 299.689 1544.41 300.069 1545.88 300.069C1547.07 300.069 1547.42 299.779 1547.42 299.189C1547.42 298.659 1547 298.399 1545.73 298.019C1542.96 297.179 1541.18 296.499 1541.18 294.059C1541.18 291.619 1543.09 290.629 1545.8 290.629C1547.25 290.629 1548.84 291.069 1550.07 291.749L1549.1 294.149C1547.96 293.529 1546.7 293.229 1545.73 293.229C1544.5 293.229 1544.17 293.469 1544.17 293.979C1544.17 294.489 1544.52 294.659 1546.57 295.319C1548.64 295.999 1550.42 296.749 1550.42 299.059V299.049Z" fill="white"/>
          <path id="Vector_116" d="M1561.97 299.049C1561.97 301.709 1559.86 302.659 1557.26 302.659C1555.43 302.659 1553.65 302.129 1552.42 301.319L1553.43 299.009C1554.66 299.689 1555.96 300.069 1557.43 300.069C1558.62 300.069 1558.97 299.779 1558.97 299.189C1558.97 298.659 1558.55 298.399 1557.27 298.019C1554.5 297.179 1552.72 296.499 1552.72 294.059C1552.72 291.619 1554.63 290.629 1557.34 290.629C1558.79 290.629 1560.38 291.069 1561.61 291.749L1560.64 294.149C1559.5 293.529 1558.24 293.229 1557.27 293.229C1556.04 293.229 1555.71 293.469 1555.71 293.979C1555.71 294.489 1556.06 294.659 1558.11 295.319C1560.18 295.999 1561.96 296.749 1561.96 299.059L1561.97 299.049Z" fill="white"/>
          <path id="Vector_117" d="M1573.85 296.659C1573.85 297.099 1573.83 297.409 1573.81 297.629H1566.9C1567.01 299.209 1568.18 299.809 1569.28 299.809C1570.12 299.809 1570.82 299.499 1571.26 298.999L1573.42 300.799C1572.5 301.989 1570.82 302.669 1569.28 302.669C1565.96 302.669 1563.85 300.319 1563.85 296.659C1563.85 292.999 1566.07 290.629 1569 290.629C1572.08 290.629 1573.86 293.359 1573.86 296.659H1573.85ZM1570.9 295.339C1570.81 294.309 1570.19 293.269 1568.83 293.269C1567.73 293.269 1567.05 294.169 1566.94 295.339H1570.9Z" fill="white"/>
        </g>
      </g>
      
      <g className="gsap-arrowline-label" >
        <path id="Vector_6" d="M1708.5 171C1755.72 171 1794 132.72 1794 85.5C1794 38.2797 1755.72 0 1708.5 0C1661.28 0 1623 38.2797 1623 85.5C1623 132.72 1661.28 171 1708.5 171Z" fill="#E4032E"/>
        <text fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="21.6675" font-weight="bold" letter-spacing="0em"><tspan x="1631.75" y="76.9395">10 Rechnungen</tspan></text>
        <text id="10x Fracht" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="1652.85" y="103.342">10x Fracht</tspan></text>
      </g>
      </g>
    </svg>
  );
}
