import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { deDE } from "@material-ui/core/locale";

export const colors = {
  primary: "#E4032E",
  secondary: "#4A4949",
};

export const preTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    },
  },
});

export const theme = createMuiTheme(
  {
    //breakpoints: preTheme.breakpoints,
    palette: {
      type: "light",
      primary: {
        main: colors.primary,
        contrastText: "#fff",
      },
      secondary: {
        main: colors.secondary,
        contrastText: "#fff",
      },
      companys: {
        ssvKroschke: colors.primary,
        kroschkeInternational: "#05afc0",
      },
      common: {
        blue: "#007bff",
        indigo: "#6610f2",
        purple: "#6f42c1",
        pink: "#e83e8c",
        red: "#dc3545",
        orange: "#fd7e14",
        yellow: "#ffc107",
        green: "#28a745",
        teal: "#20c997",
        cyan: "#17a2b8",
        white: "#fff",
        gray: "#6c757d",
        grayDark: "#343a40",
        primary: "#009FE3",
        secondary: "#F2F2F2",
        success: "#28a745",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        dark: "#343a40",
      },
    },
    customShadows: {
      regular: "",
      strong: "0px 10px 60px 0px rgba(0, 0, 0, 0.20);;",
    },
    typography: {
      fontFamily: "local-interstate, Arial",
      h1: {
        fontSize: "66px",
        fontWeight: "800",
      },
      h2: {
        fontSize: "56px",
        fontWeight: "800",
      },
      h3: {
        fontSize: "32px",
        fontWeight: "700",
        [preTheme.breakpoints.up("lg")]: {
          fontSize: "44px",
        },
        // 1200px
        [preTheme.breakpoints.up("xl")]: {
          fontSize: "48px",
        },
        // 1400px
        [preTheme.breakpoints.up("xxl")]: {
          fontSize: "58px",
        },
      },
      h5: {
        fontSize: "20px",
        fontWeight: "500",
        [preTheme.breakpoints.up("lg")]: {
          fontSize: "26px",
        },
        // 1200px
        [preTheme.breakpoints.up("xl")]: {
          fontSize: "28px",
        },
        // 1400px
        [preTheme.breakpoints.up("xxl")]: {
          fontSize: "30px",
        },
      },
      h6: {
        fontSize: "21px",
        fontWeight: "700",
        // 1200px
        [preTheme.breakpoints.up("xl")]: {
          fontSize: "22px",
        },
        // 1400px
        [preTheme.breakpoints.up("xxl")]: {
          fontSize: "23px",
        },
      },
      subtitle1: {
        fontSize: "20px",
        fontWeight: "800",
      },
      body1: {
        fontSize: "18px",
        fontWeight: "500",
        // iPad mini / iPad Air
        [preTheme.breakpoints.up("lg")]: {
          fontSize: "21px",
        },
        // 1200px
        [preTheme.breakpoints.up("xl")]: {
          fontSize: "24px",
        },
        // 1400px
        [preTheme.breakpoints.up("xxl")]: {
          fontSize: "28px",
        },
      },
      body2: {
        fontSize: "18px",
        fontWeight: "500",
      },
    },
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
    props: {
      MuiTooltip: {
        arrow: true,
      },
      MuiAccordion: {
        square: true,
      },
      MuiButtonBase: {
        disableTouchRipple: true,
      },
    },
    overrides: {
      MuiContainer: {
        
      },
      MuiButton: {
        root: {
          textTransform: "initial",
          fontSize: "18px",
          fontWeight: 700,
          borderRadius: 0,
          padding: "14px 18px",
          minWidth: "150px",
        },
        outlined: {
          borderRadius: "0px",
          padding: "14px 18px",
        },
        outlinedSizeSmall: {
          fontSize: "16px",
          padding: "2px 28px",
        },
        sizeSmall: {
          fontSize: "16px",
          padding: "2px 28px",
        },
        sizeLarge: {
          fontSize: "20px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            color: "white",
          },
        },
        outlinedPrimary: {
          backgroundColor: "#fff",
          "&:hover": {
            color: colors.primary,
          },
        },
      },
      MuiContainer: {
        root: {
          paddingLeft: "40px !important",
          paddingRight: "40px !important",
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: "none",
          zIndex: 2000,
        },
        positionFixed: {
          top: "-1px !important",
        },
      },
      MuiToolbar: {
        root: {
          justifyContent: "space-between",
        },
        regular: {
          minHeight: "90px !important",
        },
      },
      MuiIconButton: {
        colorInherit: { backgroundColor: "#fff" },
      },
      MuiInputBase: {
        root: {
          fontSize: "18px",
          fontWeight: "500",
          // iPad mini / iPad Air
          [preTheme.breakpoints.up("lg")]: {
            fontSize: "18px",
          },
          // 1200px
          [preTheme.breakpoints.up("xl")]: {
            fontSize: "20px",
          },
          // 1400px
          [preTheme.breakpoints.up("xxl")]: {
            fontSize: "22px",
          },
          // 1600px
          [preTheme.breakpoints.up("xxxl")]: {
            fontSize: "24px",
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: "18px",
          fontWeight: "500",
          // iPad mini / iPad Air
          [preTheme.breakpoints.up("lg")]: {
            fontSize: "18px",
          },
          // 1200px
          [preTheme.breakpoints.up("xl")]: {
            fontSize: "20px",
          },
          // 1400px
          [preTheme.breakpoints.up("xxl")]: {
            fontSize: "22px",
          },
          // 1600px
          [preTheme.breakpoints.up("xxxl")]: {
            fontSize: "24px",
          },
        },
      },
      MuiCard: {
        MuiPaper: {
          root: {
            backgroundColor: "pink",
          },
        },
      },
      MuiCardContent: {
        root: {
          padding: "8px !important",
          paddingBottom: "8px",
          minHeight: "52px",
        },
      },
      MuiPaper: {
        outlined: {
          borderColor: colors.primary,
          boxShadow: "0px 5px 8px 5px rgba(0, 0, 0, 0.05)",
        },
      },
      MuiFab: {
        root: {
          height: "60px",
          width: "60px",
        },
        primary: {
          border: "8px solid white",
        },
      },
      MuiTab: {
        root: {
          fontSize: "20px",
          fontWeight: 700,
          textTransform: "initial",
          paddingLeft: "0px",
          paddingRight: "0px",
          width: "fit-content !important",
          minWidth: "initial !important",
          marginRight: "50px",
        },
        textColorPrimary: {
          color: colors.secondary,
        },
      },
      MuiOutlinedInput: {
        root: {
          "& fieldset": {
            borderColor: colors.secondary,
          },
        },
      },
      MuiFilledInput: {
        root: {
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
      },
      MuiTablePagination: {
        toolbar: {
          minHeight: "60px !important",
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: colors.secondary,
        },
      },
      MuiTableCell: {
        head: {
          color: "#fff",
        },
      },
      MuiTableSortLabel: {
        icon: {
          color: `#fff !important`,
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: colors.secondary,
        },
      },
      MuiStepLabel: {
        label: {
          color: "#9F9F9F",
          "&.MuiStepLabel-active": {
            color: "#fff",
          },
        },
        completed: {
          color: "#fff !important",
        },
      },
      MuiGrid: {
        container: {
          height: "100%",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          "& .MuiTypography-body1": {
            fontSize: "18px",
          },
        },
      },
      MuiAutocomplete: {
        option: {
          fontSize: "18px",
          // iPad mini / iPad Air
          [preTheme.breakpoints.up("lg")]: {
            fontSize: "18px",
          },
          // 1200px
          [preTheme.breakpoints.up("xl")]: {
            fontSize: "20px",
          },
          // 1400px
          [preTheme.breakpoints.up("xxl")]: {
            fontSize: "22px",
          },
          // 1600px
          [preTheme.breakpoints.up("xxxl")]: {
            fontSize: "24px",
          },
        },
        /*
      popper: {
        "& .MuiTypography-body2": {
          fontSize: "18px"
        }
      }
      */
      },
      MuiMenuItem: {
        root: {
          fontSize: "18px !important",
          // 1200px
          [preTheme.breakpoints.up("xl")]: {
            fontSize: "20px !important",
          },
        },
      },
    },
  },
  deDE
);
