import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";

// Images
import ImageAussenanlage from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/aussenanlage.webp";
import ImageBesprechungsraum from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/besprechungsraum.webp";
import ImageEinsortierung from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/einsortierung.webp";
import ImageSitze from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/sitze.webp";
import ImageSport from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/sport.webp";
import ImageTeamBesprechung from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/team-besprechung.webp";
import ImageTeam from "../../../../../../assets/_Chapters/Karriere/Berufserfahrene/team.webp";
import { useModal } from "mui-modal-provider";
import { QDialog, QGalery } from "playbook-pwa-ui";


const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.secondary.main,
  },
}));

export default function Galerie() {
  const classes = useStyles();
  const { showModal } = useModal();
  const dialog = (image, alt) => {
    const d = showModal(QDialog, {
      children: (
        <QGalery
          images={[
            {
              src: image,
              alt: alt,
            },
          ]}
          closeHandler={() => d.hide()}
        />
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} style={{ height: "100%" }}>
        <svg style={{width: "100%", height: "100%"}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="768" height="379" viewBox="0 0 768 379" fill="none">
          <g clip-path="url(#clip0_899_109)">
          <mask id="mask0_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="0" width="193" height="238">
          <rect x="200" width="193" height="238" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_899_109)">
          <rect x="97" width="426" height="238" fill="url(#pattern0_899_109)"/>
          </g>
          <mask id="mask1_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="400" y="65" width="167" height="173">
          <rect x="400" y="65" width="167" height="173" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask1_899_109)">
          <rect x="371" y="65" width="259" height="173" fill="url(#pattern1_899_109)"/>
          </g>
          <mask id="mask2_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="574" y="22" width="194" height="273">
          <rect x="574" y="22" width="194" height="273" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask2_899_109)">
          <rect x="410" y="22" width="522" height="273" fill="url(#pattern2_899_109)"/>
          </g>
          <mask id="mask3_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="22" width="193" height="94">
          <rect y="22" width="193" height="94" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask3_899_109)">
          <rect x="-3" y="13" width="200" height="112" fill="url(#pattern3_899_109)"/>
          </g>
          <mask id="mask4_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="123" width="193" height="205">
          <rect y="123" width="193" height="205" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask4_899_109)">
          <rect x="-84" y="123" width="308" height="205" fill="url(#pattern4_899_109)"/>
          </g>
          <mask id="mask5_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="245" width="160" height="116">
          <rect x="200" y="245" width="160" height="116" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask5_899_109)">
          <rect x="169" y="245" width="210" height="116" fill="url(#pattern5_899_109)"/>
          </g>
          <mask id="mask6_899_109" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="367" y="245" width="200" height="134">
          <rect x="367" y="245" width="200" height="134" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask6_899_109)">
          <rect x="367" y="232" width="200" height="151" fill="url(#pattern6_899_109)"/>
          </g>
          <rect y="22" width="193" height="94" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageBesprechungsraum, "Karriere bei SSV-Kroschke")}
          />
          <rect y="123" width="193" height="205" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageSport, "Karriere bei SSV-Kroschke")}
          />
          <rect x="200" width="193" height="238" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageAussenanlage, "Karriere bei SSV-Kroschke")}
          />
          <rect x="400" y="65" width="167" height="173" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageTeamBesprechung, "Karriere bei SSV-Kroschke")}
          />
          <rect x="574" y="22" width="194" height="273" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageEinsortierung, "Karriere bei SSV-Kroschke")}
          />
          <rect x="200" y="245" width="160" height="116" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageSitze, "Karriere bei SSV-Kroschke")}
          />
          <rect x="367" y="245" width="200" height="134" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(ImageTeam, "Karriere bei SSV-Kroschke")}
          />
          </g>
          <defs>
            <pattern id="pattern0_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_899_109" transform="scale(0.000439564 0.000786782)"/>
            </pattern>
            <pattern id="pattern1_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image1_899_109" transform="matrix(0.000244672 0 0 0.0003663 -0.00108759 0)"/>
            </pattern>
            <pattern id="pattern2_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image2_899_109" transform="matrix(0.000438596 0 0 0.000838635 0 -0.000245807)"/>
            </pattern>
            <pattern id="pattern3_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image3_899_109" transform="matrix(0.00078125 0 0 0.00139509 0 -0.00223214)"/>
            </pattern>
            <pattern id="pattern4_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image4_899_109" transform="matrix(0.000244141 0 0 0.000366806 0 -0.000690739)"/>
            </pattern>
            <pattern id="pattern5_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image5_899_109" transform="matrix(0.000441112 0 0 0.000798564 0 -0.00309539)"/>
            </pattern>
            <pattern id="pattern6_899_109" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image6_899_109" transform="matrix(0.000245768 0 0 0.000325521 -0.00136719 0)"/>
            </pattern>
            <clipPath id="clip0_899_109">
            <rect width="768" height="379" fill="white"/>
            </clipPath>
            <image id="image0_899_109" width="2275" height="1271" xlinkHref={ImageAussenanlage}/>
            <image id="image1_899_109" width="4096" height="2730" xlinkHref={ImageTeamBesprechung}/>
            <image id="image2_899_109" width="2280" height="1193" xlinkHref={ImageEinsortierung}/>
            <image id="image3_899_109" width="1280" height="720" xlinkHref={ImageBesprechungsraum}/>
            <image id="image4_899_109" width="4096" height="2730" xlinkHref={ImageSport}/>
            <image id="image5_899_109" width="2267" height="1260" xlinkHref={ImageSitze}/>
            <image id="image6_899_109" width="4080" height="3072" xlinkHref={ImageTeam}/>
          </defs>
        </svg>
        </Grid>
      </Grid>
    </Slide>
  );
}
