import { h } from "preact";
import { useState } from "preact/hooks";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { toast } from "playbook-pwa-ui";
import APIService from '../../../api/index'

export default function ResetPassword({ setViewResetPassword }) {
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setViewResetPassword(false);
  };

  const handleResetPassword = () => {
    const data = {email}
    APIService.post(
      '/api/password/sendreset',
      data,
      "text/plain",
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
      
    ).then(
      (data) => {
        toast.info("E-Mail zum Zurücksetzen des Kennworts wird verschickt.")
        handleClose()
        return data
      },
      (error) => {
        toast.info("Kennwort zurücksetzen aktuell nicht möglich.")
      }
    );
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Box marginY="20px" display="grid" gridRowGap="10px">
        <TextField
          fullWidth
          placeholder="E-Mail"
          variant="outlined"
          value={email}
          onChange={handleChangeEmail}
        />
      </Box>
      <Box
        size="small"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
        >
          E-Mail versenden
        </Button>
      </Box>
    </>
  );
}
