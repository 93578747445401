import { Helmet } from "react-helmet";
import { getChapterByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";
import ChapterWrapper from "../../../components/ChapterWrapper";
import Beratungsdialog from "../../../components/Beratungsdialog";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading"
import OneSection from "./sections/OneSection";

export default function LEANdustry() {
  const chapterID = 3;
  const chapterData = useSelector((state) => getChapterByID(state, chapterID));

  if (!chapterData) {
    return <ChapterLoading />
  }

  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;

  return (
    <>
      <Helmet>
        <title>SSV-Kroschke Playbook | LEANdustry®</title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <OneSection anchor="leandustry" />
      </ChapterWrapper>
    </>
  );
}
