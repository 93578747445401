import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/7.webp";
import { Grid } from "@material-ui/core";

export default function Fallschutz() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Fallschutz" />
          <QList
            items={[
              "Unterweisungen und Sachkundigenprüfung für PSA gegen Absturz",
              "Begehungen zur Feststellung benötigter Absturzsicherungen",
              "Montage von Systemen gemeinsam mit unseren Service-Partnern",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
