import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Button,
  Typography,
  Box,
  makeStyles,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "preact/hooks";
import { Offline, Online } from "react-detect-offline";
import OptionsForConsultation from "../OptionsForConsultation";
import OptionsForPausedConsultation from "../OptionsForPausedConsultation";
import { loadConsultation as loadConsultationDispatch } from "../../../redux/modules/reducers";
import {
  getConsultationStore,
  deletePausedConsultation as deletePausedConsultationDispatch,
} from "../../../redux/modules/consultationStore";
import { getConsultingRunning } from "../../../redux/modules/consulting";
import { getDisplayDate } from "@material-ui/pickers/_helpers/text-field-helper";
import dayjs from "dayjs";
import APIService from "../../../api/index";
import { isLoggedIn } from "../../../redux/modules/userReducer";
import { route } from "preact-router";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: theme.palette.secondary.main,
      transform: "translateY(-1px)",
    },
    "& .MuiTableSortLabel-root": {
      color: theme.palette.primary.contrastText,
      fontWeight: "700",
      "&.MuiTableSortLabel-active": {
        color: theme.palette.primary.contrastText,
        fontWeight: "700",
        "& .MuiTableSortLabel-icon": {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  root2: {
    "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:not(:first-child)": {
      fontWeight: "400",
    },
    "& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(4)": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableHead-root": {
      backgroundColor: theme.palette.secondary.main,
      transform: "translateY(-1px)",
    },
    "& .MuiTableCell-stickyHeader": {
      fontWeight: "700",
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiTableSortLabel-root": {
      color: theme.palette.primary.contrastText,
      fontWeight: "700",
      "&.MuiTableSortLabel-active": {
        color: theme.palette.primary.contrastText,
        fontWeight: "700",
        "& .MuiTableSortLabel-icon": {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  paperShadow: {
    boxShadow: theme.customShadows.strong,
  },
}));

export default function Beratung({ setTab, setConsultingTab, searchString, handleDialogClose }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const storedConsultations = useSelector(getConsultationStore);
  const consultingRunning = useSelector(getConsultingRunning);
  const isLoggedin = useSelector(isLoggedIn);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState("appointment");
  const [order, setOrder] = useState("desc");

  const handleSort = (property) => {
    const isAsc = orderBy == property && order == "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadConsultation = (consultation) => {
    dispatch(loadConsultationDispatch(consultation));
    setConsultingTab(1);
    handleDialogClose();
    route("/", true);
  };

  const deleteRemoteConsultation = (id) => {
    if (navigator.onLine && isLoggedin) {
      APIService.delete(`/api/consultation/${id}`).then(() => {
        setItems((cur) => {
          return cur.filter((el) => el.id !== id);
        });
        toast.success("Beratung gelöscht.");
      });
    }
  };

  const deletePausedConsultation = (id) => {
    dispatch(deletePausedConsultationDispatch(id));
    setItems((cur) => {
      return cur.filter((el) => el.id !== id);
    });
  };

  const resultLink = (id) => process.env.PREACT_APP_PLAYBOOKMICROSITE + id;
  const pdfLink = (id) =>
    `${process.env.PREACT_APP_PLAYBOOKAPI}/api/consultation/${id}/pdf`;

  const adjustData = (data) => {
    let res = data.reverse().map((d) => {
      const dateAppointment = dayjs(new Date(d.consulting.appointment));
      return {
        id: d.id, // for fixing master data
        customerEmail: d.customer?.email,
        customer: d.customer, // for fixing master data
        appointment: dateAppointment,
        topic: d.consulting?.topic,
        progress: "",
        status: d.consulting?.status,
        action: [
          <Online
            key="online"
            polling={{
              url: `${process.env.PREACT_APP_PLAYBOOKAPI}/ping`,
              interval: 60000,
            }}
          >
            {(d.consulting.prepared === 1 && (
              <OptionsForPausedConsultation
                loadConsultation={loadConsultation}
                deletePausedConsultation={deleteRemoteConsultation}
                data={{
                  ...d,
                  consulting: { ...d.consulting, running: true },
                }}
                disabled={consultingRunning}
              />
            )) || (
              <OptionsForConsultation
                eDialog={resultLink(d.id)}
                pdf={pdfLink(d.id)}
                id={d.id}
                deleteRemoteConsultation={deleteRemoteConsultation}
              />
            )}
          </Online>,
          <Offline
            key="offline"
            polling={{
              url: `${process.env.PREACT_APP_PLAYBOOKAPI}/ping`,
              interval: 60000,
            }}
          >
            <OptionsForConsultation disabled />
          </Offline>,
        ],
      };
    });
    return res;
  };

  const adjustDataForStoredConsultations = (data) => {
    let res = data.reverse().map((d) => {
      const dateAppointment = dayjs(new Date(d.consulting.appointment));
      return {
        customerEmail: d.customer?.email,
        appointment: dateAppointment,
        topic: d.consulting?.topic,
        progress: (
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Pausiert
          </Typography>
        ),
        action: (
          <OptionsForPausedConsultation
            loadConsultation={loadConsultation}
            deletePausedConsultation={deletePausedConsultation}
            data={d}
            disabled={consultingRunning}
          />
        ),
        id: d.consulting.internalID,
      };
    });
    return res;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const goToNextPage = () => {
    setConsultingTab(1);
  };

  const pausedConsultations = adjustDataForStoredConsultations(
    storedConsultations.consultations
  );

  useEffect(() => {
    APIService.get(
      `/api/consultations/consultant?size=${rowsPerPage}&page=${page}`,
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    ).then(
      (data) => {
        const d = adjustData(data.items);
        setItems([...pausedConsultations, ...d]);
        setPage(data.page);
        setTotalCount(data.total);
      },
      (error) => {
        //reject(error)
      }
    );
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (searchString && searchString !== "") {
      setFilteredItems(
        items.filter((el) => {
          return el.customerEmail.toLowerCase().includes(searchString.toLowerCase())
            || el.topic.toLowerCase().includes(searchString.toLowerCase());
        })
      );
    } else {
      setFilteredItems(items);
    }
  }, [items, searchString]);

  return (
    <>
      <Box
        className={classes.paperShadow}
        sx={{
          height: "calc(100dvh - (90px + 40px + 100px))",
          overflowY: "scroll",
        }}
      >
        <TableContainer
          component={Paper}
          square
          elevation={0}
          style={{ height: "calc(100dvh - (90px + 90px + 40px + 70px))" }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={classes.root}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy == "appointment"}
                    direction={orderBy == "appointment" ? order : "asc"}
                    onClick={() => handleSort("appointment")}
                  >
                    Datum
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy == "customerEmail"}
                    direction={orderBy == "customerEmail" ? order : "asc"}
                    onClick={() => handleSort("customerEmail")}
                  >
                    Kunde
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy == "topic"}
                    direction={orderBy == "topic" ? order : "asc"}
                    onClick={() => handleSort("topic")}
                  >
                    Thema
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems
                .slice()
                .sort((a, b) => {
                  if (orderBy == "appointment") {
                    const dateA = new Date(a.appointment);
                    const dateB = new Date(b.appointment);

                    if (order === "asc") {
                      return dateA - dateB;
                    } else {
                      return dateB - dateA;
                    }
                  } else {
                    if (order === "asc") {
                      return a[orderBy] > b[orderBy] ? 1 : -1;
                    } else {
                      return b[orderBy] > a[orderBy] ? 1 : -1;
                    }
                  }
                })
                .map((row, index) => (
                  <TableRow>
                    <TableCell>
                      {row.appointment.format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell>{row.customerEmail}</TableCell>
                    <TableCell>{row.topic}</TableCell>
                    {row.action}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <Box
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button variant="contained" color="primary" onClick={goToNextPage}>
          Neues Gespräch
        </Button>
      </Box>
    </>
  );
}
