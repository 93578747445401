import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo7 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/honeywell.png";
import Logo8 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/maskot.png";
import Logo9 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/seiz.png";
import Logo10 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/uvex.png";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/arbeitsschutz.webp";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridAutoRows: "auto 100%",
    "& .MuiGrid-grid-xs-5": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100px",
        width: "auto",
        maxWidth: "75%",
      },
    },
  },
});

export default function Arbeitsschutz2() {
  const classes = useStyles();

  return (
    <Slide
      background={{
        image: BackgroundImage,
        size: "55dvw",
        align: "left",
        position: "top left",
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <QHeader title="Arbeitsschutz" textAlign="center" />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <img src={Logo7} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={5}>
            <img
              src={Logo8}
              alt="Logo"
              loading="lazy"
              style={{ maxHeight: "180px" }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <img src={Logo9} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={5}>
            <img src={Logo10} alt="Logo" loading="lazy" />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
