import Galerie from "./slides/Galerie";
import Info from "./slides/Info";
import NavSlide from "./slides/NavSlide";
import Video from "./slides/Video";
import VideoOuttakes from "./slides/VideoOuttakes";
import Zitat1 from "./slides/Zitat1";
import Zitat2 from "./slides/Zitat2";

export default function Azubis() {
  return [
    <Video key="video" />,
    <Zitat1 key="zitat-1" />,
    <Galerie key="galerie" />,
    <Info key="info" />,
    <Zitat2 key="zitat-2" />,
    <VideoOuttakes key="video-outtakes" />,
    <NavSlide key="nav-slide" />,
  ];
}
