export default function SchrankFrontSVG(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 144 275"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_95_186)">
        <path d="M6.61 1.77051H0V262.951H6.61V1.77051Z" fill="#E4032E" />
        <path
          d="M50.5303 8.38051V1.77051L5.28027 1.77051V8.38051L50.5303 8.38051Z"
          fill="#E4032E"
        />
        <path
          d="M138.82 8.38049V1.77051L93.5703 1.77051V8.38049L138.82 8.38049Z"
          fill="#E4032E"
        />
        <path d="M144.44 265.771H0V275.241H144.44V265.771Z" fill="#E4032E" />
        <path
          d="M54.1699 0V264.39H89.8399V0H54.1699ZM62.8099 56.15H81.6399V87.2H62.8099V56.15ZM78.3699 108.87V113.58C78.3699 115.42 76.8799 116.92 75.0299 116.92H69.4099C67.5699 116.92 66.0699 115.42 66.0699 113.58V108.87C66.0699 107.02 67.5699 105.53 69.4099 105.53H75.0299C76.8799 105.53 78.3699 107.02 78.3699 108.87ZM84.6899 132.57C84.6899 133.5 83.9399 134.26 82.9999 134.26H61.4499C60.5099 134.26 59.7599 133.5 59.7599 132.57V131.41C59.7599 130.48 60.5099 129.72 61.4499 129.72H82.9999C83.9399 129.72 84.6899 130.48 84.6899 131.41V132.57Z"
          fill="#E4032E"
        />
        <path
          d="M50.5304 246.891H5.94043V262.951H50.5304V246.891Z"
          fill="#E4032E"
        />
        <path
          d="M137.83 262.95H144.44L144.44 1.7702H137.83L137.83 262.95Z"
          fill="#E4032E"
        />
        <path
          d="M93.9002 262.95H138.49V246.89H93.9002V262.95Z"
          fill="#E4032E"
        />
        <path
          d="M10.9103 16.9605H50.5303V10.5605H9.03027L10.9103 16.9605Z"
          fill="#E4032E"
        />
        <path
          d="M133.2 16.9605H93.5801V10.5605H135.08L133.2 16.9605Z"
          fill="#E4032E"
        />
        <path
          d="M11.9103 51.5905H50.5303V46.0605H9.03027L11.9103 51.5905Z"
          fill="#E4032E"
        />
        <path
          d="M132.2 51.5905H93.5801V46.0605H135.08L132.2 51.5905Z"
          fill="#E4032E"
        />
        <path
          d="M12.9103 80.5403H50.5303V76.6104H9.03027L12.9103 80.5403Z"
          fill="#E4032E"
        />
        <path
          d="M131.2 80.5403H93.5801V76.6104H135.08L131.2 80.5403Z"
          fill="#E4032E"
        />
        <path
          d="M11.9103 102.17H50.5303V99.7305H9.03027L11.9103 102.17Z"
          fill="#E4032E"
        />
        <path
          d="M132.2 102.17H93.5801V99.7305H135.08L132.2 102.17Z"
          fill="#E4032E"
        />
        <path
          d="M12.9103 237.811H50.5303V244.031H9.03027L12.9103 237.811Z"
          fill="#E4032E"
        />
        <path
          d="M132.2 237.811H93.5801V244.031H135.08L132.2 237.811Z"
          fill="#E4032E"
        />
        <path
          d="M11.8605 177.7H50.4905V181.98H8.98047L11.8605 177.7Z"
          fill="#E4032E"
        />
        <path
          d="M132.16 177.7H93.5303V181.98H135.04L132.16 177.7Z"
          fill="#E4032E"
        />
        <path
          d="M11.8605 145.58H50.4905V148.31H8.98047L11.8605 145.58Z"
          fill="#E4032E"
        />
        <path
          d="M132.16 145.58H93.5303V148.31H135.04L132.16 145.58Z"
          fill="#E4032E"
        />
        <path d="M50.5303 41.29H5.28027V44.92H50.5303V41.29Z" fill="#E4032E" />
        <path d="M138.83 41.29H93.5801V44.92H138.83V41.29Z" fill="#E4032E" />
        <path
          d="M50.5303 71.6807H5.28027V75.3107H50.5303V71.6807Z"
          fill="#E4032E"
        />
        <path
          d="M138.83 71.6807H93.5801V75.3107H138.83V71.6807Z"
          fill="#E4032E"
        />
        <path
          d="M50.5303 95.1309H5.28027V98.7609H50.5303V95.1309Z"
          fill="#E4032E"
        />
        <path
          d="M138.83 95.1309H93.5801V98.7609H138.83V95.1309Z"
          fill="#E4032E"
        />
        <path
          d="M50.5303 119.24H5.28027V122.87H50.5303V119.24Z"
          fill="#E4032E"
        />
        <path d="M138.83 119.24H93.5801V122.87H138.83V119.24Z" fill="#E4032E" />
        <path
          d="M50.5303 149.62H5.28027V153.25H50.5303V149.62Z"
          fill="#E4032E"
        />
        <path d="M138.83 149.62H93.5801V153.25H138.83V149.62Z" fill="#E4032E" />
        <path
          d="M50.5303 183.061H5.28027V186.691H50.5303V183.061Z"
          fill="#E4032E"
        />
        <path
          d="M138.83 183.061H93.5801V186.691H138.83V183.061Z"
          fill="#E4032E"
        />
      </g>
      <defs>
        <clipPath id="clip0_95_186">
          <rect width="144" height="275" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
