import { Box, Grid, Link, SvgIcon, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import ArrowUp from "../../../../../../components/SvgIcons/ArrowUp";
import ArrowDown from "../../../../../../components/SvgIcons/ArrowDown";

export default function NavSlide() {
  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <Box
            width="100%"
            height="50%"
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            marginBottom="50px"
          >
            <Link href="#ausbildung-und-studium" style={{ textDecoration: "none" }}>
              <Typography variant="h5" align="center" color="primary">
                <ArrowUp height="1em" /> Ausbildung und Studium
              </Typography>
            </Link>
          </Box>
          <Box width="100%" height="50%" marginTop="50px">
            <Link href="#themenwahl" style={{ textDecoration: "none" }}>
              <Typography variant="h5" align="center" color="primary">
                <ArrowDown height="20px" /> Kapitelwahl
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
