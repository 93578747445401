import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PeopleClap(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
    <mask id="mask0_896_327" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="73" width="659" height="575">
    <path d="M704.001 73.1914H46.7334V647.808H704.001V73.1914Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_327)">
    <mask id="mask1_896_327" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="73" width="662" height="575">
    <path d="M707.937 73.1914H46.7344V647.808H707.937V73.1914Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_327)">
    <mask id="mask2_896_327" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="73" width="662" height="575">
    <path d="M707.938 73.1914H46.7354V647.808H707.938V73.1914Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_327)">
    <path d="M394.529 93.4347C394.529 82.8472 385.942 74.2598 375.356 74.2598C364.769 74.2598 356.183 82.8472 356.183 93.4347V175.613C356.183 186.2 364.769 194.788 375.356 194.788C385.942 194.788 394.529 186.2 394.529 175.613V93.4347Z" fill={primaryColor}/>
    <path d="M375.356 195.85C364.204 195.85 355.117 186.762 355.117 175.61V93.4316C355.117 82.2788 364.204 73.1914 375.356 73.1914C386.507 73.1914 395.594 82.2788 395.594 93.4316V175.61C395.594 186.762 386.507 195.85 375.356 195.85ZM375.356 75.322C365.356 75.322 357.248 83.4528 357.248 93.4316V175.61C357.248 185.61 365.378 193.719 375.356 193.719C385.333 193.719 393.463 185.588 393.463 175.61V93.4316C393.463 83.4311 385.333 75.322 375.356 75.322Z" fill={primaryColor}/>
    <path d="M279.642 209.717C283.381 213.456 288.294 215.326 293.207 215.326C298.119 215.326 303.01 213.456 306.771 209.717C314.249 202.217 314.249 190.086 306.771 182.585L265.686 141.497C258.208 133.996 246.056 133.996 238.557 141.497C231.057 148.997 231.057 161.128 238.557 168.628L279.642 209.717Z" fill={primaryColor}/>
    <path d="M293.206 216.394C287.793 216.394 282.728 214.285 278.902 210.459L237.817 169.37C229.926 161.478 229.926 148.629 237.817 140.738C241.643 136.911 246.729 134.803 252.12 134.803C257.512 134.803 262.598 136.911 266.424 140.738L307.51 181.827C315.401 189.719 315.401 202.567 307.51 210.459C303.684 214.285 298.597 216.394 293.206 216.394ZM252.12 136.955C247.273 136.955 242.729 138.846 239.317 142.26C232.252 149.325 232.252 160.826 239.317 167.891L280.402 208.98C283.815 212.394 288.38 214.285 293.206 214.285C298.032 214.285 302.597 212.394 306.01 208.98C313.075 201.915 313.075 190.414 306.01 183.348L264.924 142.26C261.511 138.846 256.946 136.955 252.12 136.955Z" fill={primaryColor}/>
    <path d="M457.526 215.326C462.438 215.326 467.35 213.456 471.089 209.717L512.174 168.628C519.652 161.149 519.652 148.997 512.174 141.497C504.677 133.996 492.547 133.996 485.045 141.497L443.96 182.585C436.461 190.086 436.461 202.217 443.96 209.717C447.699 213.456 452.615 215.326 457.526 215.326Z" fill={primaryColor}/>
    <path d="M457.53 216.394C452.118 216.394 447.053 214.285 443.224 210.458C439.4 206.632 437.291 201.545 437.291 196.154C437.291 190.762 439.4 185.675 443.224 181.848L484.313 140.759C488.138 136.933 493.223 134.824 498.615 134.824C504.007 134.824 509.116 136.933 512.918 140.759C520.809 148.651 520.809 161.499 512.918 169.391L471.833 210.48C468.007 214.306 462.942 216.415 457.53 216.415V216.394ZM498.615 136.955C493.766 136.955 489.225 138.846 485.812 142.259L444.727 183.348C441.311 186.762 439.421 191.327 439.421 196.154C439.421 200.98 441.311 205.545 444.727 208.958C448.139 212.372 452.705 214.263 457.53 214.263C462.355 214.263 466.921 212.372 470.333 208.958L511.418 167.869C518.483 160.804 518.483 149.303 511.418 142.259C508.006 138.846 503.44 136.955 498.615 136.955Z" fill={primaryColor}/>
    <path d="M319.88 259.547C319.684 259.72 319.489 259.873 319.315 260.025L236.405 331.115C228.731 337.703 218.906 341.333 208.776 341.333H149.387C93.4323 341.312 48.0645 386.596 48.0645 442.643V627.567C48.0645 638.151 56.6511 646.74 67.2376 646.74H231.558C242.166 646.74 250.73 638.151 250.73 627.567V451.491L348.314 367.835C353.292 363.573 356.161 357.334 356.161 350.769V277.134C356.161 258.416 334.401 247.742 319.858 259.547H319.88Z" fill={primaryColor}/>
    <path d="M231.58 647.806H67.2384C56.0866 647.806 47 638.717 47 627.566V442.642C47 386.183 92.9331 340.246 149.387 340.246H208.776C218.646 340.246 228.211 336.702 235.71 330.288L318.859 259.002L319.207 258.72C326.185 253.067 335.597 251.958 343.771 255.85C352.075 259.806 357.227 267.959 357.227 277.134V350.768C357.227 357.66 354.227 364.16 349.01 368.638L251.797 451.969V627.543C251.797 638.694 242.71 647.782 231.558 647.782L231.58 647.806ZM149.409 342.376C94.1068 342.376 49.1304 387.357 49.1304 442.642V627.566C49.1304 637.564 57.2605 645.672 67.2384 645.672H231.558C241.558 645.672 249.666 637.544 249.666 627.566V451.012L347.619 367.029C352.379 362.964 355.097 357.029 355.097 350.768V277.134C355.097 268.785 350.401 261.372 342.836 257.763C335.293 254.176 326.967 255.132 320.533 260.35L319.968 260.828L237.058 331.897C229.189 338.658 219.124 342.376 208.733 342.376H149.366H149.409Z" fill={primaryColor}/>
    <path d="M601.348 341.316H541.958C531.831 341.316 522.003 337.685 514.329 331.098L431.42 260.029C431.246 259.876 431.05 259.724 430.855 259.551C416.312 247.745 394.552 258.398 394.552 277.138V350.772C394.552 357.338 397.421 363.577 402.399 367.839L499.983 451.495V627.571C499.983 638.155 508.571 646.744 519.154 646.744H683.475C694.062 646.744 702.65 638.155 702.65 627.571V442.647C702.65 386.6 657.282 341.316 601.328 341.316H601.348Z" fill={primaryColor}/>
    <path d="M683.476 647.806H519.155C508.005 647.806 498.917 638.717 498.917 627.567V451.991L401.704 368.66C396.487 364.182 393.487 357.66 393.487 350.79V277.155C393.487 267.981 398.64 259.829 406.944 255.872C415.117 251.98 424.53 253.067 431.508 258.742L432.094 259.22L515.026 330.31C522.528 336.746 532.092 340.267 541.958 340.267H601.349C657.803 340.267 703.737 386.205 703.737 442.664V627.586C703.737 638.741 694.649 647.826 683.499 647.826L683.476 647.806ZM417.095 255.633C414.008 255.633 410.856 256.35 407.856 257.763C400.291 261.372 395.596 268.786 395.596 277.134V350.768C395.596 357.029 398.313 362.964 403.074 367.03L501.027 451.012V627.567C501.027 637.564 509.158 645.673 519.135 645.673H683.456C693.453 645.673 701.564 637.545 701.564 627.567V442.642C701.564 387.357 656.587 342.376 601.305 342.376H541.915C531.525 342.376 521.481 338.659 513.59 331.898L430.464 260.633L430.138 260.35C426.269 257.22 421.725 255.611 417.073 255.611L417.095 255.633Z" fill={primaryColor}/>
    <path d="M230.211 260.504C230.211 215.936 193.973 179.695 149.409 179.695C104.846 179.695 68.6084 215.936 68.6084 260.504C68.6084 305.071 104.846 341.313 149.409 341.313C193.973 341.313 230.211 305.071 230.211 260.504Z" fill={primaryColor}/>
    <path d="M149.41 342.38C104.26 342.38 67.5439 305.661 67.5439 260.507C67.5439 215.352 104.26 178.633 149.41 178.633C194.561 178.633 231.277 215.352 231.277 260.507C231.277 305.661 194.561 342.38 149.41 342.38ZM149.41 180.763C105.434 180.763 69.6743 216.548 69.6743 260.507C69.6743 304.465 105.456 340.25 149.41 340.25C193.365 340.25 229.147 304.465 229.147 260.507C229.147 216.548 193.365 180.763 149.41 180.763Z" fill={primaryColor}/>
    <path d="M682.13 260.504C682.13 215.936 645.89 179.695 601.33 179.695C556.766 179.695 520.525 215.936 520.525 260.504C520.525 305.071 556.766 341.313 601.33 341.313C645.89 341.313 682.13 305.071 682.13 260.504Z" fill={primaryColor}/>
    <path d="M601.328 342.38C556.177 342.38 519.461 305.661 519.461 260.507C519.461 215.352 556.177 178.633 601.328 178.633C646.475 178.633 683.191 215.352 683.191 260.507C683.191 305.661 646.475 342.38 601.328 342.38ZM601.328 180.763C557.35 180.763 521.59 216.548 521.59 260.507C521.59 304.465 557.37 340.25 601.328 340.25C645.282 340.25 681.062 304.465 681.062 260.507C681.062 216.548 645.282 180.763 601.328 180.763Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
