import { Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import GesundheitSVG from "../../../../../../components/SvgIcons/KroschkeIcons/GesundheitSVG";

const useStyles = makeStyles((theme) => ({
  textWrap: {
    hyphens: "auto",
    textWrap: "balance",
  },
}));
export default function Gesundheit() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={7} className={classes.textWrap}>
          <QHeader
            title={
              <>
                Deine Gesundheit
                <br />
                ist uns wichtig
              </>
            }
          />
          <QList
            items={[
              "Ergonomische und moderne Arbeitsplätze",
              "Fitnessstudio zur kostenfreien Nutzung",
              "Fahrradleasing",
              "Eigene Kantine mit Zuschuss zum Essensangebot",
              "Betriebsarzt",
            ]}
          />
        </Grid>
        <Grid item xs={4}><GesundheitSVG className="gsap-medium-slide-from-left" style={{ height: "80dvh", width: "85%" }} /></Grid>
      </Grid>
    </Slide>
  );
}
