import LeanManagement from "../LeanManagement";
import USP from "../USP";
import Fakten1 from "../DasStecktAllesDrin/slides/Fakten1";
import Fakten2 from "../DasStecktAllesDrin/slides/Fakten2";

export default function OneSection() {
    return [
        <LeanManagement key="lean-management" />,
        <USP key="usp" />,
        <Fakten1 key="das-steckt-alles-drin-1" />,
        <Fakten2 key="das-steckt-alles-drin-2" />,
    ];
  }
  