import NachhaltigArbeiten_1 from "./Slides/NachhaltigArbeiten_1";
import NachhaltigArbeiten_2 from "./Slides/NachhaltigArbeiten_2";
import EcoVadis from "./Slides/EcoVadis";

export default function NachhaltigArbeiten() {
  return [
    <NachhaltigArbeiten_1 key="wie-wir-nachhaltig-arbeiten-1" />,
    <NachhaltigArbeiten_2 key="wie-wir-nachhaltig-arbeiten-2" />,
    <EcoVadis key="ecovadis" />,
  ];
}
