// Components
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import { Box, Grid } from "@material-ui/core";

// Logos
import PfeileSVG from "./_Components/PfeileSVG";

export default function WasWirGemeinsamSchaffen() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={8}>
          <QHeader title="Was wir gemeinsam schaffen" />
          <QList
            items={[
              "Innovativere Beschaffungslösungen",
              "Umfassende und  kompetente Beratung in allen Bereichen",
              "Alle Dienstleistungen und Produkte aus einer Hand",
            ]}
          />
        </Grid>
      </Grid>
      <Box
        position="absolute"
        bottom="0"
        right="10dvw"
        height="80dvh"
        display="flex"
        alignItems="flex-end"
      >
        <PfeileSVG style={{height: "100%", width: "100%"}} />
      </Box>
    </Slide>
  );
}
