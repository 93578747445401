import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Nachhaltigkeit/1.jpg";

export default function NachhaltigArbeiten_1() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Nachhaltig arbeiten - wir machen es vor" />
          <QList
            listStyle="blatt"
            noPadding
            items={[
              "In e-Fuhrpark mit Ladepark investieren",
              "In Photovoltaik investieren",
              "Mit nachhaltiger Bauweise bauen",
              "Verpackungsmaterial erneut verwenden",
              "Aktuellste Technik einsetzen",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
