import Beschaffung1 from "./slides/Beschaffung/Beschaffung1";
import Beschaffung2 from "./slides/Beschaffung/Beschaffung2";
import BetrieblicheSicherheit from "./slides/BetrieblicheSicherheit";
import Fallschutz from "./slides/Fallschutz";
import HandUndHautschutzplaene from "./slides/HandUndHautschutzplaene";
import Korrektionsschutzbrillen from "./slides/Korrektionsschutzbrillen";
import Otoplastiken from "./slides/Otoplastiken";
import PersonalisierteBerufsbekleidung from "./slides/PersonalisierteBerufsbekleidung";
import Schuhabwicklung from "./slides/Schuhabwicklung";

export default function Leistungen() {
  return [
    <Beschaffung1 key="beschaffung-1" />,
    <Beschaffung2 key="beschaffung-2" />,
    <Schuhabwicklung key="schuhabwicklung" />,
    <Otoplastiken key="otoplastiken" />,
    <Korrektionsschutzbrillen key="korrektionsschutzbrillen" />,
    <HandUndHautschutzplaene key="hand-und-hautschutzplaene" />,
    <PersonalisierteBerufsbekleidung key="personalisierte-berufsbekleidung" />,
    <BetrieblicheSicherheit key="betriebliche-sicherheit" />,
    <Fallschutz key="fallschutz" />,
  ];
}
