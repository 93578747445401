import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import {useDispatch, useSelector} from 'react-redux'
import {useState} from 'preact/hooks'
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import {resetApp} from '../../../redux/modules/reducers'
import {getConsultingStarted} from '../../../redux/modules/consultingReducer'
import {getBackendState} from '../../../redux/modules/backendState'
import {
  isLoggedIn,
  fetchUserLogout,
  getUser
} from '../../../redux/modules/userReducer'
import ChangeLogModal from "../ChangelogModal";
import ChangePasswordModal from "../ChangePasswordModal";
import APIService from '../../../api/index'

const useStyles = makeStyles((theme) => ({
  colorWhite: {
    color: theme.palette.secondary.contrastText,
  },
  fontRegular: {
    fontWeight: "400",
  },
  fontBold: {
    fontWeight: "700",
  },
  paperShadow: {
    boxShadow: theme.customShadows.strong,
  },
}));

export default function Profil() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [openDelete, setOpenDelete] = useState(false)
  const {
    email,
    firstname,
    lastname,
    salutation,
    telefon,
    fax,
    region,
    company,
  } = useSelector(getUser)
  const isLoggedin = useSelector(isLoggedIn)
  const consultingStarted = useSelector(getConsultingStarted)

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      salutation: salutation,
      firstname: firstname,
      lastname: lastname,
      email: email,
      telefon: telefon,
      fax: fax,
    },
  })

  const currentValues = watch();

  const refreshCacheAndReload = () => {
    setOpenDelete(true)
  }

  const handleDeleteClose = () => {
    setOpenDelete(false)
  }

  const handleDeleteTrueClose = () => {
    setOpenDelete(false)
    dispatch(resetApp())
    dispatch(fetchUserLogout())
    dispatch(getBackendState())
  }

  
  return (
    <>
      <Box
        className={classes.paperShadow}
        sx={{
          height: "calc(100dvh - (90px + 40px + 100px))",
          overflowY: "scroll",
        }}
      >
        <Paper elevation={0} square style={{ height: "100%" }}>
          <Box bgcolor="secondary.main" p={2}>
            <Typography variant="body2" className={classes.colorWhite}>
              Profilinformationen
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className={classes.fontBold}>
                      Ihre Kontaktdaten
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="salutation"
                      value={currentValues?.salutation}
                      onChange={(e) => {
                        setValue("salutation", e.target.value);
                      }}
                      label="Anrede"
                      fullWidth
                      variant="outlined"
                      select
                      disabled={true}
                      invalid={!!errors?.salutation}
                      required
                    >
                      <MenuItem value="Herr">Herr</MenuItem>
                      <MenuItem value="Frau">Frau</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="firstname"
                      value={currentValues?.firstname}
                      onChange={(e) => {
                        setValue("firstname", e.target.value);
                      }}
                      label="Vorname"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      invalid={!!errors?.firstname}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="lastname"
                      value={currentValues?.lastname}
                      onChange={(e) => {
                        setValue("lastname", e.target.value);
                      }}
                      label="Nachname"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      invalid={!!errors?.lastname}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="email"
                      value={currentValues?.email}
                      onChange={(e) => {
                        setValue("email", e.target.value);
                      }}
                      label="E-Mail"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      invalid={!!errors?.email}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="telefon"
                      value={currentValues?.telefon}
                      onChange={(e) => {
                        setValue("telefon", e.target.value);
                      }}
                      label="E-Telefon"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      invalid={!!errors?.telefon}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="fax"
                      value={currentValues?.fax}
                      onChange={(e) => {
                        setValue("fax", e.target.value);
                      }}
                      label="Fax"
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      invalid={!!errors?.fax}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              {company && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h4" className={classes.fontBold}>
                        Firma & Anschrift
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.fontBold}>
                        {company.name}
                      </Typography>
                      <Typography variant="body1" className={classes.fontRegular}>
                        {company.street}<br />
                        {company.postcode} {company.city}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Box pt={3}  display="flex" justifyContent="flex-end">
        <ChangePasswordModal />
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{marginRight: "1em"}}
          onClick={refreshCacheAndReload}
        >
          Anwendungsspeicher leeren
        </Button>
        <ChangeLogModal />
      </Box>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
          Anwendungsspeicher leeren
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Durch das Leeren des Anwendungsspeichers werden auch alle
            pausierten Beratungen gelöscht.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDeleteClose}
            color="primary"
            className="plaintext"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleDeleteTrueClose}
            color="primary"
            className="plaintext"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
