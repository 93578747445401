import { gsap } from "playbook-pwa-ui";
import { useRef } from "preact/hooks";

export default function TochtergesellschaftenSVG({className}) {
  return (
    <svg className={className} id="uuid-1c17065d-355f-46e8-bbaa-b1cfa790abb0" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
      <path d="m262,125.01l-.4,218.79,101.33-14.24.02-9,.39-209.78-101.34,14.24Zm44.58,189.72l-23.44,3.29.05-25.3,23.44-3.29-.05,25.3Zm.07-39.32l-23.44,3.3.04-25.32,23.45-3.29-.05,25.3Zm.07-39.32l-23.44,3.3.04-25.3,23.45-3.29-.05,25.29Zm34.73,73.73l-23.45,3.3.05-25.32,23.44-3.29-.04,25.3Zm.07-39.31l-23.45,3.29.05-25.32,23.44-3.29-.04,25.32Zm.06-39.32l-23.44,3.29.05-25.29,23.44-3.3-.05,25.3Zm.07-35.47l-58.32,8.19.02-10.59,58.32-8.19-.02,10.59Zm.03-18.38l-58.32,8.19.02-10.6,58.32-8.19-.02,10.6Zm.03-18.38l-58.31,8.19v-10.59s58.33-8.19,58.33-8.19l-.02,10.59Zm.03-18.38l-58.31,8.19.02-10.59,58.31-8.19-.02,10.59Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <polygon points="125.81 331.33 168.36 354.48 168.69 177.89 126.13 154.74 125.82 324.15 125.81 331.33" style="fill: #e4032e; stroke-width: 0px;"/>
      <polygon points="357.73 105.2 317.67 83.41 219.81 97.17 259.87 118.96 357.73 105.2" style="fill: #e4032e; stroke-width: 0px;"/>
      <polygon points="240.61 163.95 200.55 142.16 131.75 151.83 171.8 173.62 240.61 163.95" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m173.93,178.62l-.33,177.25,69.66-10.16.33-177.25-69.66,10.16Zm31.88,146.03l-15.03,2.11.03-16.21,15.03-2.12-.03,16.22Zm.04-24.01l-15.03,2.11.03-16.21,15.03-2.11-.03,16.21Zm.05-24.01l-15.04,2.11.03-16.22,15.03-2.11-.02,16.22Zm.04-24l-15.03,2.11.02-16.23,15.04-2.11-.03,16.23Zm.04-24.01l-15.03,2.11.03-16.23,15.03-2.11-.03,16.23Zm.04-24.01l-15.03,2.11.03-16.23,15.03-2.11-.03,16.23Zm22.78,116.8l-15.03,2.11.03-16.21,15.03-2.11-.03,16.21Zm.04-24l-15.03,2.11.03-16.22,15.03-2.11-.03,16.22Zm.04-24.01l-15.03,2.11.03-16.23,15.03-2.11-.03,16.23Zm.05-24.01l-15.04,2.11.03-16.22,15.03-2.11-.02,16.22Zm.04-24.01l-15.03,2.11.02-16.22,15.04-2.11-.03,16.22Zm.04-24.01l-15.03,2.11.03-16.22,15.03-2.11-.03,16.22Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <polygon points="248.51 335.37 254.23 342.41 254.63 124.29 212.08 101.14 211.99 141.47 248.83 161.51 248.51 335.37" style="fill: #e4032e; stroke-width: 0px;"/>
    </svg>
  );
}
