export default function HomeworkBook({ className }) {
  return (
    <svg
      className={className}
      id="uuid-4a445c14-bd00-4a48-897e-9510c50f9e37"
      data-name="Schrift"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.14 439.28"
    >
      <rect
        x="149.96"
        y="85.38"
        width="20.23"
        height="269.67"
        style="fill: #e20432; stroke-width: 0px;"
      />
      <path
        d="m308.71,186.06h-94.49v-20.23h94.73v20.23h-.24Zm-20.23,33.8h-52.36v-13.57h52.36v13.57Zm37.13-135.19l-141.86.71v269.67h141.86c7.38,0,13.57-6.19,13.57-13.57V98.24c0-7.38-5.95-13.57-13.57-13.57Z"
        style="fill: #e20432; stroke-width: 0px;"
      />
    </svg>
  );
}
