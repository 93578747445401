import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import ReduzierungSVG from "./_Components/ReduzierungSVG";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fontRegular: {
    marginTop: "5px",
    fontWeight: 400,
  },
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
}));

export default function Optimierung() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={5}>
        <QList
          listStyle="blatt"
          items={[
            "Einsparpotential-Analysen",
            "Elektronische Ausgabesysteme",
            "Ausleihverwaltung",
          ]}
        />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <ReduzierungSVG  height="100%"/>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
