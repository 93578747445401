import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Button,
  IconButton,
  Typography,
  Box,
  makeStyles,
  Grid,
  TextField,
  MenuItem,
  TablePagination,
  Tooltip,
  Chip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, useCallback } from "preact/hooks";
import { route } from "preact-router";
import {
  getConsultingRunning,
  setConsultingRunning,
  setConsultingTopic,
  getConsultingTopic,
  setAppointment,
  getAppointment,
} from "../../../redux/modules/consulting";
import customer, {
  getCustomerCompany,
  getCustomerEmail,
  getCustomerLastname,
  getCustomerFirstname,
  getCustomerSalutation,
  setCustomerCompany,
  setCustomerEmail,
  setCustomerFirstname,
  setCustomerLastname,
  setCustomerSalutation,
  getCustomerData,
} from "../../../redux/modules/customer";
import {
  getConsultantEmail,
  setConsultantCompany,
} from "../../../redux/modules/consultant";
import HookForm, { useForm } from "react-hook-form";
import { toast } from "playbook-pwa-ui";
import * as yup from "yup"
import isEmail from 'is-email';
import MoreIcon from "../../../components/SvgIcons/MoreIcon";
import PlayIcon from "../../../components/SvgIcons/PlayIcon";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getCompanies } from "../../../redux/modules/companiesReducer";
import {
  getCustomers,
  setCustomers,
} from "../../../redux/modules/customersReducer";

const useStyles = makeStyles((theme) => ({
  colorWhite: {
    color: theme.palette.secondary.contrastText,
  },
  fontRegular: {
    fontWeight: "400",
  },
  fontBold: {
    fontWeight: "700",
  },
  widthButton: {
    width: "180px",
  },
  paperShadow: {
    boxShadow: theme.customShadows.strong,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: "10px",
  },
  actionText: {
    color: "#E4032E",
    cursor: "pointer",
  },
}));

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

export default function BeratungsForm({
  /*setNewConsulting,
  startConsulting,
  consultingData,
  setConsultingData,*/
  updateCustomer,
  setTab,
  handleDialogClose,
  setConsultingTab,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedDate = useSelector(getAppointment);
  const customer = useSelector(getCustomerData);
  const company = useSelector(getCustomerCompany);
  const consultingRunning = useSelector(getConsultingRunning);
  const topic = useSelector(getConsultingTopic);
  const companies = useSelector(getCompanies);
  const customers = useSelector(getCustomers);
  const [isNewCompany, setIsNewCompany] = useState(true);
  const [isNewCustomer, setIsNewCustomer] = useState(true);
  const [showCopmanyInfo, setShowCompanyInfo] = useState(null);
  const [showCustomerInfo, setShowCustomerInfo] = useState(null);

  const beratungSchema = yup
  .object()
  .shape({
    email: yup
      .string()      
      .required("Mail is required")
      .test("is-valid", (message) => `${message.path} is invalid`, (value) => {
        return value ? isEmail(value) : new yup.ValidationError("Invalid value")
      }),
  })
  .required()

  

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: useYupValidationResolver(beratungSchema),
    defaultValues: {
      date: selectedDate || new Date(),
      company: company,
      topic: topic,
      salutation: customer?.salutation,
      firstname: customer?.firstname,
      lastname: customer?.lastname,
      email: customer?.email,
    },
  });

  const currentValues = watch();

  useEffect(() => {
    register("date", { required: true });
    register("company", { required: true });
    register("topic", { required: true });
    register("salutation", { required: true });
    register("firstname", { required: true });
    register("lastname", { required: true });
    register("email", { required: true });

    if (updateCustomer) {
      setIsNewCompany(!companies.find((el) => el.id == customer.company.id));
      setIsNewCustomer(!customers.find((el) => el.email == customer.email));
    }
  }, []);

  const setCustomerState = (data) => {
    dispatch(setAppointment(data.date));
    dispatch(setConsultingTopic(data.topic));
    dispatch(setCustomerCompany(data.company));
    dispatch(setCustomerSalutation(data.salutation));
    dispatch(setCustomerFirstname(data.firstname));
    dispatch(setCustomerLastname(data.lastname));
    dispatch(setCustomerEmail(data.email));
  };

  const setConsultantCompanyId = (company) => {
    dispatch(setCustomerCompany(company));
  };

  const handleDateChange = (v) => {
    setSetupField({
      id: "selectedDate",
      value: v,
    });
  };

  const handleCompanyChange = (event, value, reason) => {
    
    if (reason == "select-option") {
      setIsNewCompany(false);
      setValue("company", value);
    }

    if (reason == "blur") {
      if (!isNewCompany && value !== currentValues.company) {  
        setIsNewCompany(true);
        setValue("company", {
          id: null,
          name: value,
          street: "",
          postcode: "",
          city: "",
          logo: "",
        });
      } else if (isNewCompany) {
        setValue("company", { ...currentValues.company, name: value });
      }
    }

    if (reason == "clear") {
      if (!isNewCompany) {
        clearCompany();
        setIsNewCompany(true);
      } else {
        setValue("company", { ...currentValues.company, name: "" });
      }

      if (!isNewCustomer) {
        clearCustomer();
        setIsNewCustomer(true);
      }
    }
  };

  const filteredCustomers = useMemo(() => {
    if (!isNewCompany) {
      return customers.filter((el) => el.companyID == currentValues.company?.id);
    }
    return customers;
  }, [isNewCompany, currentValues.company]);

  const handleEmailChange = (event, value, reason) => {
    if (reason == "select-option") {
      setCustomer(value);
      setIsNewCustomer(false);
    }

    if (reason == "blur") {
      if (!isNewCustomer && (value !== currentValues.email)) {
        setIsNewCustomer(true);
        clearCustomer();
      }

      setValue("email", value);
    }

    if (reason == "clear") {
      if (!isNewCustomer) {
        clearCustomer();
        setIsNewCustomer(true);
      }
    }
  };

  const onError = (err) => {
    toast.error('Fehler bei der Eingabe')
  }

  const onSubmit = (data) => {
    const foundCustomer = customers.find(customer => {
      return customer?.email == data?.email
    })
    
    if (isNewCompany) {
      const foundCompany = companies.find(company => {
        return company.name === data?.company?.name
            && company.postcode === data?.company?.postcode 
      })

      if (foundCompany) {
        setShowCompanyInfo(foundCompany)
      } else if (foundCustomer) {
        setShowCustomerInfo(foundCustomer)
      } else {
        handleStart(data)
      }
    } else {
      if (foundCustomer) {
        setShowCustomerInfo(foundCustomer)
      } else {
        handleStart(data)
      }
    }
  };

  const handleStart = (data) => {
    setCustomerState(data);
    if (!updateCustomer) {
      startConsulting();
    } else {
      handleDialogClose();
    }
  }

  const startConsulting = () => {
    setConsultingTab(2);
    dispatch(setConsultingRunning(true));
    handleDialogClose();
    toast.info("Beratung gestartet.");
    route("/", true);
  };

  const abortConsulting = (e) => {
    e.preventDefault();
    if (updateCustomer) {
      handleDialogClose();
    } else {
      setConsultingTab(0);
    }
  };

  const clearCustomer = () => {
    setValue("salutation", "Herr");
    setValue("firstname", "");
    setValue("lastname", "");
    setValue("email", "");
  };

  const setCustomer = (el) => {
    setValue("salutation", el?.salutation);
    setValue("firstname", el?.firstname);
    setValue("lastname", el?.lastname);
    setValue("email", el?.email);
  };

  const clearCompany = () => {
    setValue("company", {
      id: null,
      name: "",
      street: "",
      postcode: "",
      city: "",
      logo: "",
    });
  };

  const abortShowCompanInfo = () => {
    setShowCompanyInfo(null)
  }

  const handleUseSavedCompany = () => {
    setValue("company", showCopmanyInfo)
    setShowCompanyInfo(null)

    const foundCustomer = customers.find(customer => {
      return customer?.email == currentValues?.email
    })

    if (foundCustomer) {
      setShowCustomerInfo({
        salutation: currentValues?.salutation,
        firstname: currentValues?.firstname,
        lastname: currentValues?.lastname,
        email: currentValues?.email
      })
    } else {
      handleStart({
        ...currentValues,
        company: showCopmanyInfo
      })
    }
  }

  const handleUseCompanyInput = () => {
    setShowCompanyInfo(null)
    
    const foundCustomer = customers.find(customer => {
      return customer?.email == currentValues?.email
    })

    if (foundCustomer) {
      setShowCustomerInfo({
        salutation: currentValues?.salutation,
        firstname: currentValues?.firstname,
        lastname: currentValues?.lastname,
        email: currentValues?.email
      })
    } else {
      handleStart(currentValues)
    }
  }

  const abortShowCustomerInfo = () => {
    setShowCustomerInfo(null)
  }

  const handleUseSavedCustomer = () => {
    setValue("company", showCopmanyInfo)
    setShowCustomerInfo(null)

    handleStart({
      ...currentValues,
      salutation: showCustomerInfo?.salutation,
      firstname: showCustomerInfo?.firstname,
      lastname: showCustomerInfo?.lastname,
      email: showCustomerInfo?.email,
    })
  }

  if (!!showCopmanyInfo) {
    return (
      <>
        <Box
          className={classes.paperShadow}
          sx={{
            height: "calc(100dvh - 90px - 2rem - 100px)",
            overflowY: "scroll",
          }}
        >
          <Paper elevation={0} square style={{ height: "100%" }}>
            <Box bgcolor="secondary.main" p={2}>
              <Typography variant="body2" className={classes.colorWhite}>
                Firma gefunden
              </Typography>
            </Box>
            <Box p={2}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Es wurde eine passende Firma gefunden.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Eingabe:<br />
                    {currentValues?.company?.name}<br />
                    {currentValues?.company?.street}<br />
                    {currentValues?.company?.postcode} {currentValues?.company?.city}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Gefundene Firma:<br />
                    {showCopmanyInfo?.name}<br />
                    {showCopmanyInfo?.street}<br />
                    {showCopmanyInfo?.postcode} {showCopmanyInfo?.city}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Wie möchten Sie vorgehen?
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={abortShowCompanInfo}
            style={{ marginRight: "10px" }}
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUseSavedCompany}
            style={{ marginRight: "10px" }}
          >
            Firma übernehmen
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUseCompanyInput}
          >
            Eingabe verwenden
          </Button>
        </Box>
      </>
    )
  }

  if (!!showCustomerInfo) {
    return (
      <>
        <Box
          className={classes.paperShadow}
          sx={{
            height: "calc(100dvh - 90px - 2rem - 100px)",
            overflowY: "scroll",
          }}
        >
          <Paper elevation={0} square style={{ height: "100%" }}>
            <Box bgcolor="secondary.main" p={2}>
              <Typography variant="body2" className={classes.colorWhite}>
                Kunde gefunden
              </Typography>
            </Box>
            <Box p={2}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Es können nicht mehrere Kunden mit der selber E-Mailadresse verwendet werden.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Eingabe:<br />
                    {currentValues?.salutation} {currentValues?.firstname} {currentValues?.lastname}<br />
                    {currentValues?.email}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Gefundener Kunde:<br />
                    {showCustomerInfo?.salutation} {showCustomerInfo?.firstname} {showCustomerInfo?.lastname}<br />
                    {showCustomerInfo?.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Wie möchten Sie vorgehen?
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={abortShowCustomerInfo}
            style={{ marginRight: "10px" }}
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUseSavedCustomer}
            style={{ marginRight: "10px" }}
          >
            Kunde übernehmen
          </Button>
        </Box>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box
        className={classes.paperShadow}
        sx={{
          height: "calc(100dvh - 90px - 2rem - 100px)",
          overflowY: "scroll",
        }}
      >
        <Paper elevation={0} square style={{ height: "100%" }}>
          <Box bgcolor="secondary.main" p={2}>
            <Typography variant="body2" className={classes.colorWhite}>
              { !updateCustomer ? "Neue Beratung anlegen" : "Beratung editieren"}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}>
                <Box pb={2} className={classes.flexBox}>
                  <Typography variant="h4" className={classes.fontBold}>
                    Termin
                  </Typography>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Wann und bei welchem Unternehmen ist der Termin? Aus welchem
                    Anlass?
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <DatePicker
                      id="date"
                      value={currentValues?.date}
                      onChange={(newDate) => {
                        setValue("date", newDate);
                      }}
                      cancelLabel="Abbrechen"
                      format="dd.MM.yyyy"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        variant: "outlined",
                      }}
                      InputLabelProps={{ variant: "outlined" }}
                      inputVariant="outlined"
                      invalid={!!errors?.date}
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      id="company"
                      value={currentValues?.company.name}
                      freeSolo
                      autoSelect
                      getOptionLabel={(option) => {
                        return option.name ? option.name : option;
                      }}
                      options={companies || []}
                      onChange={(event, value, reason) =>
                        handleCompanyChange(event, value, reason)
                      }
                      noOptionsText="Keine Firmen gefunden"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Firma"
                          id="company"
                          variant="outlined"
                          fullWidth
                          invalid={!!errors?.company}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="thema"
                      value={currentValues?.topic}
                      onChange={(e) => {
                        setValue("topic", e.target.value);
                      }}
                      label="Thema"
                      fullWidth
                      variant="outlined"
                      invalid={!!errors?.topic}
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="street"
                      value={currentValues?.company?.street}
                      onChange={(e) => {
                        setValue("company", {
                          ...currentValues.company,
                          street: e.target.value,
                        });
                      }}
                      label="Straße"
                      variant="outlined"
                      fullWidth
                      disabled={!isNewCompany}
                      invalid={!!errors?.company}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="postcode"
                      value={currentValues?.company?.postcode}
                      onChange={(e) => {
                        setValue("company", {
                          ...currentValues.company,
                          postcode: e.target.value,
                        });
                      }}
                      label="PLZ"
                      variant="outlined"
                      fullWidth
                      disabled={!isNewCompany}
                      invalid={!!errors?.company}
                      inputProps={{ pattern: "[0-9]{5}", maxLength: 5, minLength: 5 }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="city"
                      value={currentValues?.company?.city}
                      onChange={(e) => {
                        setValue("company", {
                          ...currentValues.company,
                          city: e.target.value,
                        });
                      }}
                      label="Ort"
                      variant="outlined"
                      fullWidth
                      disabled={!isNewCompany}
                      invalid={!!errors?.city}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box pb={2} className={classes.flexBox}>
                  <Typography variant="h4" className={classes.fontBold}>
                    Kunde
                  </Typography>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Mit wem sprechen Sie?
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      id="salutation"
                      value={currentValues?.salutation}
                      onChange={(e) => {
                        setValue("salutation", e.target.value);
                      }}
                      label="Anrede"
                      fullWidth
                      variant="outlined"
                      select
                      disabled={!isNewCustomer}
                      invalid={!!errors?.salutation}
                      required
                    >
                      <MenuItem value="Herr">Herr</MenuItem>
                      <MenuItem value="Frau">Frau</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="firstname"
                      value={currentValues?.firstname}
                      onChange={(e) => {
                        setValue("firstname", e.target.value);
                      }}
                      label="Vorname"
                      fullWidth
                      variant="outlined"
                      disabled={!isNewCustomer}
                      invalid={!!errors?.firstname}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="lastname"
                      value={currentValues?.lastname}
                      onChange={(e) => {
                        setValue("lastname", e.target.value);
                      }}
                      label="Nachname"
                      fullWidth
                      variant="outlined"
                      disabled={!isNewCustomer}
                      invalid={!!errors?.lastname}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {!isNewCompany && !isNewCustomer && (
                      <Typography
                        variant="body2"
                        className={classes.actionText}
                        onClick={() => {
                          clearCustomer();
                          setIsNewCustomer(true);
                        }}
                      >
                        Neuer Kontakt
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      id="email"
                      value={currentValues?.email}
                      freeSolo
                      autoSelect
                      getOptionLabel={(option) => {
                        return option.email ? option.email : option;
                      }}
                      options={
                        !isNewCompany
                          ? filteredCustomers
                          : []
                      }
                      onChange={(event, value, reason) =>
                        handleEmailChange(event, value, reason)
                      }
                      noOptionsText="Keine Personen gefunden"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="email"
                          label="E-Mail"
                          fullWidth
                          variant="outlined"
                          error={!!errors?.email}
                          invalid={!!errors?.email}
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Box
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={abortConsulting}
          style={{ marginRight: "10px" }}
        >
          Abbrechen
        </Button>
        {!updateCustomer && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.widthButton}
          >
            Starten
          </Button>
        )}
        {updateCustomer && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.widthButton}
          >
            Aktualisieren
          </Button>
        )}
      </Box>
    </form>
  );
}
