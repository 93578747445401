import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/4.webp";
import { Box, Grid } from "@material-ui/core";

export default function HandUndHautschutzplaene() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Hand- und Hautschutzpläne" />
          <QList
            items={[
              "Erstellung individueller auf die Risiken im Betrieb angepasster Hand- und Hautschutzpläne",
              "Herstellerübergreifende Analyse der Permeationszeiten im eigenen Labor",
              "Kostensenkung durch bedarfsgerechte Planung (Handschuhwäsche)",
              "Nachhaltiger Handschuh-Waschservice",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box position="absolute" bottom="15px" right="15px" fontSize="12px">
        Foto: Seiz Technical Gloves GmbH
      </Box>
    </Slide>
  );
}
