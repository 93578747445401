import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/ServicesZerspanung/1.webp";
import { Grid } from "@material-ui/core";

export default function AllesUnterEinemDach() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Alles unter einem Dach" />
          <QList
            items={[
              <>
                <b>Gesamtumfängliche Belieferung mit:</b>
                <br />
                Werkzeugen, Spanntechnik und Messmitteln/Messtechnik
              </>,
              <>
                <b>Netzwerk an starken Partnern:</b>
                <br />
                Zusammenarbeit mit allen namenhaften und Zugriff auf
                umfangreiche Handelssortimente
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
