import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Elektr-Ausgabesystem/3.webp";
import { Grid } from "@material-ui/core";

export default function Intro() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Mission 30 %" />
          <QList
            items={[
              "Unsere Ausgabesysteme reduzieren Ihre Beschaffungskosten um mindestens 30 %",
              "Nachweisliche Senkung durch die Übernahme des C-Teile-Managements durch SSV-Kroschke",
              "Reduzierung der Lagerkosten um 100 %",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
