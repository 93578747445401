import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Weg(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
    <mask id="mask0_896_500" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="189" y="82" width="372" height="557">
    <path d="M560.333 82H189V639H560.333V82Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_500)">
    <mask id="mask1_896_500" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="192" y="82" width="369" height="557">
    <path d="M560.332 82H192.569V639H560.332V82Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_500)">
    <mask id="mask2_896_500" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="192" y="82" width="369" height="557">
    <path d="M560.331 82H192.568V639H560.331V82Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_500)">
    <mask id="mask3_896_500" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="192" y="82" width="369" height="557">
    <path d="M560.33 82H192.567V639H560.33V82Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_896_500)">
    <path d="M329.846 318.003V221.484H378.685C383.49 221.484 385.186 218.247 382.429 214.28L293.17 86.0065C290.413 82.0395 285.906 82.0395 283.131 86.0065L193.891 214.299C191.134 218.266 192.81 221.503 197.635 221.503H243.382V403.893C260.443 374.103 272.607 360.499 286.614 348.242C299.857 336.66 314.051 326.087 329.846 318.022V318.003Z" fill={primaryColor}/>
    <path d="M557.37 354.114L429.665 264.444C425.716 261.675 422.494 263.359 422.494 268.206V315.848H416.31C320.96 315.848 243.381 393.785 243.381 489.575V637.965H329.845V489.575C329.845 441.67 368.626 402.711 416.31 402.711H422.494V450.09C422.494 454.936 425.716 456.622 429.665 453.852L557.37 364.182C561.316 361.413 561.316 356.884 557.37 354.114Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
