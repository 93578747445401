import Beratungsdialog from "../../../components/Beratungsdialog";
import ChapterWrapper from "../../../components/ChapterWrapper";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading";
import { Helmet } from "react-helmet";
import { getChapterByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";

// Sections
import AuswahlAusgabesysteme from "./sections/AuswahlAusgabesysteme";
import Mission30 from "./sections/Mission30";

export default function ElektronischeAusgabesysteme() {
  const chapterID = 4;
  const chapterData = useSelector((state) => getChapterByID(state, chapterID));

  if (!chapterData) {
    return <ChapterLoading />;
  }

  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;

  return (
    <>
      <Helmet>
        <title>SSV-Kroschke Playbook | Elektronische Ausgabesysteme</title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <Mission30 anchor="mission-30-prozent" />
        <AuswahlAusgabesysteme anchor="wiegzellenschraenke" />
      </ChapterWrapper>
    </>
  );
}
