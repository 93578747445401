import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PauseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path id="Vector" d="M11 22H7V2H11V22ZM17 2H13V22H17V2Z" fill="#E4032E" />
    </SvgIcon>
  );
}
