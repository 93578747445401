import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo1 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/alunox.png";
import Logo2 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/bosch.png";
import Logo3 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/lecite.jpg";
import Logo4 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/lukas.jpg";
import Logo5 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/nordviles.jpg";
import Logo6 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/oerlikon.jpg";
import Logo7 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/pferd.jpg";
import Logo8 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/rehm.png";
import Logo9 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/sia.jpg";
import Logo10 from "../../../../../../assets/_Chapters/CTeileSortiment/WeiterePartner/tesa.jpg";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/weitere-partner.webp";

const useStyles = makeStyles({
  container: {
    "& .MuiGrid-grid-xs-6": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100px",
        width: "auto",
        maxWidth: "75%",
      },
    },
  },
});

export default function WeiterePartner2() {
  const classes = useStyles();

  return (
    <Slide
      background={{
        image: BackgroundImage,
        size: "80dvw",
        align: "left",
        position: "top left",
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <QHeader title="weitere Partner" textAlign="center" />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <img src={Logo7} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={6}>
            <img src={Logo8} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={6}>
            <img src={Logo9} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={6}>
            <img src={Logo10} alt="Logo" loading="lazy" />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
