import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import { Grid } from "@material-ui/core";
import VRARVorteile from "../_Components/VR-AR-Vorteile";

export default function Vorteile() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Vorteile für die Planung" />
          <QList
            items={[
              "Sie sehen das „fertige“ Projekt schon vor dem Aufbau",
              "Sie vermeiden kostspielige Fehler in der Planung",
              "Optimierte Abläufe und Prozesse können greifbar dargestellt werden",
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <VRARVorteile />
        </Grid>
      </Grid>
    </Slide>
  );
}
