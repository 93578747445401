import { Grid } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import br_er_er_ls from "../../../../../../assets/_Chapters/EPLSystem/br_er_er_ls.webp";
import br_ls_ms from "../../../../../../assets/_Chapters/EPLSystem/br_ls_ms.webp";
import lid_br_er from "../../../../../../assets/_Chapters/EPLSystem/lid_br_er.webp";

import { BetterImage, ImageComponent } from "playbook-pwa-ui";

export default function Individuell() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader title="Individuell für Ihren Bedarf" textAlign="center" />
        </Grid>
        <ImageComponent
          col={4}
          wrapperWidth="100%"
          align="left"
          images={[
            {
              src: br_er_er_ls,
              alt: "E-PLS-System",
            },
            {
              src: br_ls_ms,
              alt: "E-PLS-System",
            },
            {
              src: lid_br_er,
              alt: "E-PLS-System",
            },
          ]}
        />
      </Grid>
    </Slide>
  );
}
