import { Grid } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/1.webp";

export default function UnsereMarke() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Work – unsere Marke für PSA" />
          <QList
            items={[
              "Optimales Preis-Leistungsverhältnis",
              "20 Jahre Erfahrung",
              "Hauseigenes Labor",
              "Individuelle Lösungen",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
