import { makeStyles } from "@material-ui/core/styles";
import { gsap } from "playbook-pwa-ui";

import { Grid, Link, Typography } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";

import SchuhabwicklungSVG from "./_Components/SchuhabwicklungSVG"
import AnpassenVonOtoplastikenSVG from "./_Components/AnpassenVonOtoplastikenSVG"
import KorrektionsschutzbrillenSVG from "./_Components/KorrektionsschutzbrillenSVG"
import HandUndHautschutzplaeneSVG from "./_Components/HandUndHautschutzplaeneSVG"

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function Beschaffung1() {

  return (
    <Slide>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <QHeader
            title="Beschaffung bis an den Arbeitsplatz"
            textAlign="center"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Link href="#leistungen/2" color="initial">
                <SchuhabwicklungSVG className="gsap-facts" />
                <Typography align="center" variant="body1" >
                  Schuhabwicklung
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#leistungen/3" color="initial">
                <AnpassenVonOtoplastikenSVG className="gsap-facts" />
                <Typography align="center" variant="body1" >
                  Anpassen von Otoplastiken
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#leistungen/4" color="initial">
                <KorrektionsschutzbrillenSVG className="gsap-facts" />
                <Typography align="center" variant="body1" >
                  Korrektionsschutzbrillen
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#leistungen/5" color="initial">
                <HandUndHautschutzplaeneSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Hand- und Hautschutzpläne
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
