import { Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import WieMachenWirDasSVG1 from "./_Components/WieMachenWirDasSVG1";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
  },
}));

export default function WieMachenWirDas1() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader
            textAlign="center"
            title="Wie machen wir das?"
            subTitle={
              <>
                <span className={classes.primary}>C-Teile-Management</span> =
                Einsparpotential
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <WieMachenWirDasSVG1 width="100%" />
        </Grid>
      </Grid>
    </Slide>
  );
}
