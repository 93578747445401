const SET_COMPANIES = "playbook/companies/SET_COMPANIES";
const ADD_COMPANY = "playbook/companies/ADD_COMPANY";

const initialState = {
  companies: [],
  fetchDate: null,
};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANIES: {
      return {
        ...state,
        companies: action.companies,
        fetchDate: new Date(),
      };
    }
    case ADD_COMPANY: {
      return {
        ...state,
        companies: [...state.companies, action.company],
      };
    }
    default:
      return state;
  }
};

//actions
export const setCompanies = (companies) => ({
  type: SET_COMPANIES,
  companies,
});

export const addCompany = (company) => ({
  type: ADD_COMPANY,
  company,
});

//selector
export const getCompanies = (state) => state.companies.companies;

export default companies;
