import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PaketSVG(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} viewBox="0 0 387 387">
    <mask id="mask0_896_661" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="387" height="387">
    <path d="M387 0H0V387H387V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_661)">
    <mask id="mask1_896_661" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="385" height="386">
    <path d="M386 1H1V387H386V1Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_661)">
    <mask id="mask2_896_661" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="385" height="386">
    <path d="M386 1H1V387H386V1Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_661)">
    <mask id="mask3_896_661" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="385" height="386">
    <path d="M386 1H1V387H386V1Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_896_661)">
    <path className="pulse-party" d="M162.793 100.618L193.499 83.6013L224.205 100.618L218.336 64.5715L243.172 39.0468L208.852 33.7859L193.499 1L178.146 33.7859L143.826 39.0468L168.662 64.5715L162.793 100.618Z" fill={primaryColor}/>
    <path className="pulse-party" d="M354.957 81.9395L345.357 102.437L323.902 105.723L339.436 121.675L335.77 144.199L354.957 133.561L374.145 144.199L370.479 121.675L386 105.723L364.545 102.437L354.957 81.9395Z" fill={primaryColor}/>
    <path className="pulse-party" d="M12.8547 144.199L32.0425 133.561L51.2304 144.199L47.5768 121.675L63.098 105.723L41.6429 102.437L32.0425 81.9395L22.4551 102.437L1 105.723L16.5213 121.675L12.8547 144.199Z" fill={primaryColor}/>
    <path d="M193.475 106.84H193.527C196.947 106.84 199.707 110.36 199.707 114.712V193.845C199.707 198.197 196.934 201.717 193.527 201.717H193.475C190.055 201.717 187.295 198.197 187.295 193.845V114.712C187.295 110.36 190.068 106.84 193.475 106.84Z" fill={primaryColor}/>
    <path d="M225.01 184.894C228.158 184.933 230.801 182.634 231.216 179.516C232.563 169.281 237.888 147.185 258.877 134.157C272.649 126.038 288.935 123.57 304.378 127.129C307.384 127.817 310.39 125.934 311.375 122.999C312.554 119.479 310.39 115.724 306.775 114.919C288.274 110.801 268.801 113.841 252.334 123.57C227.2 139.197 220.657 165.462 218.947 177.918C218.441 181.595 221.305 184.855 225.01 184.907V184.894Z" fill={primaryColor}/>
    <path d="M122.812 122.895C143.283 131.416 156.355 153.797 156.433 179.621C156.433 182.803 159.011 185.388 162.185 185.609H162.224C165.852 185.856 168.884 182.921 168.858 179.27C168.637 148.589 152.598 121.817 127.58 111.399C114.3 106.255 99.7769 105.502 86.1343 109.177C82.5843 110.139 80.5502 113.893 81.807 117.362V117.388C82.8823 120.336 86.0177 122.116 89.0364 121.284C100.14 118.232 111.968 118.764 122.812 122.895Z" fill={primaryColor}/>
    <path d="M188.11 252.572L165.45 279.084C164.427 280.279 162.95 280.954 161.382 280.954C160.993 280.954 160.618 280.916 160.242 280.838L65.0156 260.08V356.1L187.942 386.911L188.11 252.572Z" fill={primaryColor}/>
    <path d="M321.985 260.079L226.759 280.837C226.383 280.915 226.008 280.966 225.619 280.953C224.051 280.953 222.574 280.266 221.551 279.083L198.813 252.48L198.645 387.001L321.973 356.086V260.066L321.985 260.079Z" fill={primaryColor}/>
    <path d="M38.2617 243.387L161.382 270.223L193.5 232.644L100.256 216.394L139.966 208.924V198L69.3949 211.275L69.5245 212.029L38.2617 243.387Z" fill={primaryColor}/>
    <path d="M348.739 243.387L317.476 212.029L317.605 211.275L247.034 198V208.924L286.744 216.394L193.5 232.644L225.618 270.223L348.739 243.387Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
