import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import BeispielGrafikSVG from "./_Components/BeispielGrafikSVG";

export default function BeispielGrafik() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <BeispielGrafikSVG height="70%" style={{ maxHeight: "80dvh" }}/>
        </Grid>
      </Grid>
    </Slide>
  );
}
