
import WalterLogo from "../../../../../../assets/_Chapters/ServicesZerspanung/Filter/walter.png";
import SandvikLogo from "../../../../../../assets/_Chapters/ServicesZerspanung/Filter/sandvik.png";
import KennametalLogo from "../../../../../../assets/_Chapters/ServicesZerspanung/Filter/kennmetal.png";
import Trichter from "../../../../../../assets/_Chapters/ServicesZerspanung/Filter/ssv-filter.png";
import { theme } from "../../../../../../theme";

export default function Filter(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1353 2218"
      fill="none"
    >
      <g id="Unbenannt 7">
        <rect
          id="Rectangle"
          y="483"
          width="1353"
          height="1522"
          fill="url(#pattern0)"
        />
        <rect
          id="Rectangle_2"
          x="370"
          y="606"
          width="611.001"
          height="135"
          fill="url(#pattern1)"
        />
        <text
          id="Ihre Firma"
          fill="#E20031"
          xmlSpace="preserve"
          style="white-space: pre"
          fontFamily={fontFamily}
          font-size="83"
          font-weight="bold"
          letter-spacing="0em"
        >
          <tspan x="486.37" y="2141.88">
            Ihre Firma
          </tspan>
        </text>
        <rect
          id="Rectangle_3"
          x="371"
          width="610.998"
          height="235.999"
          fill="url(#pattern2)"
        />
        <rect
          id="Rectangle_4"
          x="370"
          y="291"
          width="600"
          height="223"
          fill="url(#pattern3)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_126_217"
            transform="matrix(0.000813079 0 0 0.000723057 -0.317812 -0.191853)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1_126_217"
            transform="scale(0.000650195 0.00295858)"
          />
        </pattern>
        <pattern
          id="pattern2"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2_126_217"
            transform="scale(0.00124378 0.00321543)"
          />
        </pattern>
        <pattern
          id="pattern3"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3_126_217"
            transform="scale(0.000564334 0.00151976)"
          />
        </pattern>
        <image
          id="image0_126_217"
          data-name="img2.png"
          width="2038"
          height="1831"
          xlinkHref={Trichter}
        />
        <image
          className="text-element"
          id="image2_126_217"
          data-name="img11.png"
          width="804"
          height="311"
          xlinkHref={SandvikLogo}
        />
        <image
          className="text-element"
          id="image3_126_217"
          data-name="img8.jpg"
          width="1772"
          height="658"
          xlinkHref={WalterLogo}
        />
        <image
          className="text-element"
          id="image1_126_217"
          data-name="img5.png"
          width="1538"
          height="338"
          xlinkHref={KennametalLogo}
        />
      </defs>
    </svg>
  );
}
