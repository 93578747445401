import { theme } from "../../../../../../theme";

export default function WirFindenDiePerfekteLoesungSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
      {...props}
      viewBox="0 0 2604 1137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="grafik 2" clip-path="url(#clip0_125_169)">
        <g id="Group 13">
          <path
            id="Vector"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M865.634 501.041L498.085 137L130.366 503.499L0 1001.06L498.085 1137L996 1003.48L865.634 501.041ZM498.085 166.45L779.232 452.352L741.367 479.472L559.286 345.356L498.085 221.326L433.325 351.289L259.758 479.43L213.38 458.327L498.042 166.366L498.085 166.45ZM26.9796 984.705L133.966 598.079L176.363 617.359L151.289 842.157L74.5857 957.204L219.437 948.305L417.188 1034.62L422.059 1085.26L27.0219 984.705H26.9796ZM305.755 711.897C304.653 605.664 389.912 518.711 496.094 517.609C602.276 516.508 689.187 601.723 690.288 707.956C691.389 814.19 606.215 901.227 500.033 902.329C393.851 903.431 306.856 818.131 305.755 711.897ZM581.607 1034.62L779.274 948.305L924.167 957.204L847.464 842.157L822.39 617.359L864.787 598.079L971.773 984.705L576.736 1085.26L581.607 1034.62Z"
            fill="#E4032E"
          />
          <text
            className="text-element"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            fontFamily={fontFamily}
            fontSize="82"
            letter-spacing="0em"
          >
            <tspan x="1330.55" y="88.12">
              Durchführung von Vergleichs-&#10;
            </tspan>
            <tspan x="1330.55" y="198.12">
              tests&#x200b; in Ihrem Unternehmen
            </tspan>
          </text>
          <text
            className="text-element"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            fontFamily={fontFamily}
            fontSize="82"
            letter-spacing="0em"
          >
            <tspan x="1329.15" y="520.12">
              Beratung durch unsere&#10;
            </tspan>
            <tspan x="1329.15" y="630.12">
              Anwendungstechniker
            </tspan>
          </text>
          <text
            className="text-element"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            fontFamily={fontFamily}
            fontSize="82"
            letter-spacing="0em"
          >
            <tspan x="1329.15" y="951.12">
              Erarbeitung eines optimierten&#10;
            </tspan>
            <tspan x="1329.15" y="1061.12">
              Bearbeitungskonzeptes
            </tspan>
          </text>
          <path
            id="Vector_2"
            d="M2601.15 1103L1289 1103"
            stroke="#E20030"
            stroke-width="10.3125"
            stroke-miterlimit="8"
          />
          <path
            id="Vector_3"
            d="M1292 1103.5L1058.46 979"
            stroke="#E20030"
            stroke-width="10.3125"
            stroke-miterlimit="8"
          />
          <path
            id="Vector_4"
            d="M1001.46 661H2601.15"
            stroke="#E20030"
            stroke-width="10.3125"
            stroke-miterlimit="8"
          />
          <path
            id="Vector_5"
            d="M2601.15 235H1289"
            stroke="#E20030"
            stroke-width="10.3125"
            stroke-miterlimit="8"
          />
          <path
            id="Vector_6"
            d="M1291 234.5L941.46 411.347"
            stroke="#E20030"
            stroke-width="10.3125"
            stroke-miterlimit="8"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_125_169">
          <rect width="2604" height="1137" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
