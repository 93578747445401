import { Grid, Typography } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import MessmittelSVG from "../_Components/MessmittelSVG";
import SoftwareSVG from "../_Components/SoftwareSVG";
import UeberblickSVG from "../_Components/UeberblickSVG";
import UmsatzSVG from "../_Components/UmsatzSVG";

export default function Vorteile() {
  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Vorteile im Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <UeberblickSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Überblick wo sich welches Material befindet
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <MessmittelSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Messmittel sind rund um die Uhr verfügbar
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <SoftwareSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Keine zusätzliche Prüfmittelverwaltungssoftware nötig
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <UmsatzSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Senkung der Investitionskosten
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
