// Components
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import { Box, Grid } from "@material-ui/core";
import { theme } from "../../../../../../theme";

// Logos
import KroschkeLogoInternational from "../../../../../../assets/_defaultAssets/Logos/JS/KroschkeLogoInternational";
import SSVKroschkeLogo from "../../../../../../assets/_defaultAssets/Logos/JS/SSVKroschkeLogo";
import PuzzleSVG from "./_Components/PuzzleSVG";

export default function DieKompetenzen() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Die Kompetenzen" />
          <Box marginTop="30px">
            <QList
              color={theme.palette.companys.kroschkeInternational}
              items={[
                "Enge Kundenbindung durch intensive Betreuung und Konzeptarbeit",
                "Besondere Expertise als PSA-Fachhändler",
                "Angebotsbearbeitung und Realisierung",
              ]}
            />
            <QList
              items={[
                "Beschaffungsdienstleistungen für die Industrie",
                "C-Teile-Management",
                "Einzigartiges Vollversorgungskonzept über alle C-Teile-Sortimente hinweg",
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
          >
            <PuzzleSVG style={{ height: "80dvh", width: "100%" }} />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
