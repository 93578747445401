import SSVDeutschlandkarteSVG from "../_Components/SSVDeutschlandkarteSVG";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import Menu from "../../../../../../components/Menu";
import { Box, Button, Dialog, Grid } from "@material-ui/core";
import { useState } from "preact/hooks";
import { PDFViewer } from "playbook-pwa-ui";
import { setAllowScrolling } from "playbook-pwa-ui";

export default function Profitieren() {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setAllowScrolling(true);
    setOpen(false);
  };
  const openDialog = () => {
    setAllowScrolling(false);
    setOpen(true);
  };

  return (
    <>
      <Dialog fullScreen onClose={closeDialog} open={open}>
        <Menu absolute closeEvent={closeDialog} icon={{ image: "none" }} />
        <PDFViewer path="../../../../../../assets/_Chapters/PSA-Vollversorgungskonzept/PDF/PSA-Konzept_Flyer_210x105mm_RZ.pdf" />
      </Dialog>
      <Slide>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={6}>
            <QHeader title="Profitieren Sie von unserem Netzwerk" />
            <QList
              items={[
                "Unschlagbares Netz an Experten",
                "Professioneller Rundum-Service",
                "Über 2.300 Servicepartner (DACH)",
              ]}
            />
            <Button variant="contained" color="primary" onClick={openDialog}>
              Info-Flyer als PDF
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          width="50dvw"
          height="100dvh"
          top="0px"
          right="2rem"
        >
          <SSVDeutschlandkarteSVG
            style={{ height: "110%", maxWidth: "100%" }}
          />
        </Box>
      </Slide>
    </>
  );
}
