import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";

// SVGs
import ElektronischeAusgabesystemeSVG from "./_Components/ElektronischeAusgabesystemeSVG";
import CTeileSortimentSVG from "./_Components/CTeileSortimentSVG";
import EProcurementSVG from "./_Components/EProcurementSVG";
import EinsparpotentialAnalyseSVG from "./_Components/EinsparpotentialAnalyseSVG";

export default function Fakten1() {

  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Das steckt alles drin" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ElektronischeAusgabesystemeSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Elektronische Ausgabesysteme
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CTeileSortimentSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                C-Teile Sortiment
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <EProcurementSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                E-Procurement
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <EinsparpotentialAnalyseSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Einsparpotential-Analyse
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
