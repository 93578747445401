export default function EinkaufSVG (props) {
    return (
      <svg {...props} viewBox="0 0 749 721" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M467.97 345.981C458.837 375.283 442.819 396.39 423.829 409.3C408.963 419.371 392.383 424.407 375.803 424.407C359.224 424.407 342.671 419.371 327.805 409.3C308.814 396.39 292.797 375.283 283.637 345.981C273.78 334.945 237.968 290.215 265.584 248.859C357.402 251.296 414.829 192.477 414.829 192.477C414.829 192.477 436.525 231.824 486.05 248.859C513.665 290.215 477.854 334.945 467.97 345.981Z" fill="#E4032E"/>
        <path d="M599.993 582.999L599.859 592.294L591.181 595.561C521.701 621.623 434.141 627.087 395.196 628.212L420.561 463.485C420.561 463.485 402.588 474.092 374.625 474.092C348.911 474.092 328.689 463.485 328.689 463.485L354.054 628.185C314.734 627.034 227.87 621.436 158.846 595.561L150.141 592.294L150.007 582.999C150.007 582.758 148.239 496.538 201.889 468.44L297.672 415.567L304.1 412.031C323.68 430.459 348.001 443.932 375.509 444.039C403.526 444.173 427.096 430.995 446.917 412.594L453.453 416.183L548.137 468.413C601.787 496.538 599.993 582.758 599.993 582.999Z" fill="#E4032E"/>
        <path d="M486.051 231.449C436.526 214.414 420.562 164.46 420.562 164.46C420.562 164.46 357.403 233.886 265.585 231.449C230.309 234.02 234.782 189.236 246.3 162.076C257.79 134.943 296.709 92.8101 375.805 91.0423C454.9 89.2478 493.096 144.987 501.078 164.46C509.033 183.906 524.943 244.064 486.051 231.449Z" fill="#E4032E"/>
        <path d="M275.281 124.845C275.281 124.845 232.56 158.567 232.56 202.199C232.56 238.466 238.318 429.71 238.318 429.71L291.192 399.631C291.192 399.631 281.254 381.658 275.576 370.596C272.389 364.409 266.282 345.204 266.282 345.204C266.282 345.204 244.452 331.035 239.149 290C234.435 253.519 257.443 225.208 257.443 225.208L275.281 124.871V124.845Z" fill="#E4032E"/>
        <path d="M472.497 126.077C472.497 126.077 517.388 165.933 517.388 202.2C517.388 238.467 511.629 429.711 511.629 429.711L458.756 399.631C458.756 399.631 468.693 381.659 474.371 370.596C477.559 364.409 483.666 345.204 483.666 345.204C483.666 345.204 505.495 331.035 510.799 290.001C515.513 253.52 492.505 225.208 492.505 225.208L472.47 126.104L472.497 126.077Z" fill="#E4032E"/>
      </svg>
    )
  }
  