import { theme } from "../../../../../../../../theme";

export default function CTeileBarSVG2(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
      {...props}
      viewBox="0 0 778 907"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="temp 3">
        <text
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          fontFamily={fontFamily}
          fontSize="73"
        >
          <tspan x="125.242" y="801.28">
            5 %
          </tspan>
        </text>
        <rect id="rectangle" y="857" width="389" height="50" fill="#E20031" />
        <rect
          className="gsap-bar"
          x="389"
          y="126"
          width="389"
          height="781"
          fill="#4A4949"
        />
      </g>
    </svg>
  );
}
