import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/1.webp";
import { Box, Grid, Typography } from "@material-ui/core";

export default function Schuhabwicklung() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Schuhabwicklung" />
          <QList
            items={[
              "Individuelle Fußvermessung",
              "Schuhanproben sowie -ausgaben",
              "Orthopädische Einlagen Hersteller- und Schuhunabhängig gemäß DGUV 112-191",
              "Kein Aufwand mit Retouren und keine unnötigen Kosten",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box position="absolute" bottom="15px" right="15px" fontSize="12px">
        Foto: Elten GmbH
      </Box>
    </Slide>
  );
}
