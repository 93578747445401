import {
  Button,
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { getActiveChapter } from "../../../redux/modules/backendState";
import {
  getChapters,
  setGeneralNotes,
  getGeneralNotes,
} from "playbook-pwa-ui/src/redux/modules/consulting";
import { useEffect, useMemo, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import PauseIcon from "../../SvgIcons/PauseIcon";
import StopIcon from "../../SvgIcons/StopIcon";
import PlayIcon from "../../SvgIcons/PlayIcon";
import clsx from "clsx";
import { theme } from "../../../theme";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BeratungsForm from "../BeratungsForm";
import { getCustomerData } from "../../../redux/modules/customer";
import { useModal } from "mui-modal-provider";
import { QDialog } from "playbook-pwa-ui";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function createData(customer, date, thema, status) {
  return { customer, date, thema, status };
}

const useStyles = makeStyles((theme) => ({
  notes: {
    color: theme.palette.secondary.contrastText,
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.primary.contrastText,
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
    "& .MuiFilledInput-multiline": {
      padding: "16px",
    },
  },
  colorWhite: {
    color: theme.palette.secondary.contrastText,
  },
  fontRegular: {
    fontWeight: "400",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  hideLabel: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },
  },
  centeredText: {
    userSelect: "none",
    pointerEvents: "none",
  },
  paperShadow: {
    boxShadow: theme.customShadows.strong,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fontSizeRegular: {
    fontSize: "16px",
  },
  actionText: {
    color: "#E4032E",
    cursor: "pointer",
  },
}));

export default function ConsultingDialog({
  closeConsultingDialog,
  pauseConsulting,
  consultingData,
  setConsultingData,
  endConsulting,
}) {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const classes = useStyles();
  const storeNotes = useSelector(getGeneralNotes);
  const [note, setNote] = useState(storeNotes);
  const chapters = useSelector((state) => getActiveChapter(state));
  const readChapterIDs = useSelector((state) => getChapters(state));
  const customer = useSelector((state) => getCustomerData(state));

  const handleNoteChange = (e) => {
    setNote(e);
  };

  const handleUpdateNote = () => {
    dispatch(setGeneralNotes(note));
  };

  const openSetupPage = (e, customer) => {
    if (customer) {
      const d = showModal(QDialog, {
        children: (
          <Box width="100dvw" height="calc(100dvh - 79px)" marginTop="79px">
            <Box p={2} pt={0} >
              <BeratungsForm
                handleDialogClose={() => d.destroy()}
                updateCustomer
              />
            </Box>
          </Box>
        ),
        onConfirm: () => {
          d.hide();
        },
        onCancel: () => {
          d.hide();
        },
      });
    }
  };

  const handlePauseConsulting = (e) => {
    handleUpdateNote()
    pauseConsulting(e)
  }

  const handleCloseConsultingDialog = (e) => {
    handleUpdateNote()
    closeConsultingDialog(e)
  }

  const handleEndConsulting = (e) => {
    handleUpdateNote()
    endConsulting(e)
  }

  const renderData = chapters.map((v, i) => {
    const isRead = readChapterIDs.some((key) => key == v.chapter.key);
    return (
      <Box marginBottom="8px" marginTop={i === 0 ? "8px" : "initial"}>
        <Typography
          className={clsx(
            isRead ? classes.colorPrimary : classes.fontRegular,
            classes.fontSizeRegular
          )}
          variant="body2"
        >
          <span className={classes.colorPrimary}>{v.chapter.id}. </span>
          {v.chapter.name}
        </Typography>
      </Box>
    );
  });

  return (
    <>
      <Box
        sx={{
          height: "calc(100dvh - (90px + 40px))",
          overflowY: "scroll",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={4}
            style={{
              paddingLeft: "40px",
            }}
          >
            <Typography variant="body2">Besprochen:</Typography>
            {renderData}
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              paddingRight: "40px",
            }}
          >
            <Paper square elevation={0} className={classes.paperShadow}>
              <Box bgcolor="secondary.main" p={2} style={{flexGrow: 0}}>
                <Typography variant="body2" className={classes.colorWhite}>
                  Notizen{" "}
                  {/*chapters.filter(function (obj) {
                    return obj.chapter.id === activeChapter;
                  })[0]?.chapter.name || ""*/}
                </Typography>
              </Box>
              <ReactQuill
                formats={['bold', 'italic', 'underline']}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    ['clean']
                  ],
                }}
                name="note"
                onChange={handleNoteChange}
                onBlur={handleUpdateNote}
                value={note}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              paddingTop: "50px",
              paddingLeft: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
              }}
            >
              <NavigateNextIcon className={classes.actionText} />
              <Typography
                variant="body2"
                className={classes.actionText}
                onClick={(e) => {
                  openSetupPage(e, customer);
                }}
              >
                Beratung editieren
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              paddingTop: "50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePauseConsulting}
                startIcon={<PauseIcon />}
                style={{ marginRight: "10px" }}
              >
                Pause
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseConsultingDialog}
                startIcon={<PlayIcon stroke="red" />}
                style={{ marginRight: "10px" }}
              >
                Fortsetzen
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEndConsulting}
                startIcon={<StopIcon />}
              >
                Beenden
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
