import Abwicklung from "./slides/Abwicklung";
import Gebraucht from "./slides/Gebraucht";
import Nachschliffservice from "./slides/Nachschliffservice";

export default function NachschliffAbwicklung() {
  return [
    <Nachschliffservice key="nachshcliffservice" />,
    <Abwicklung key="abwicklung" />,
    <Gebraucht key="gebraucht" />,
  ];
}
