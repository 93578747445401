import Beispiel from "./slides/Beispiel";
import BeispielGrafik from "./slides/BeispielGrafik";
import Grafik from "./slides/Grafik";
import Intro from "./slides/Intro";
import WieMachenWirDas1 from "./slides/WieMachenWirDas1";
import WieMachenWirDas2 from "./slides/WieMachenWirDas2";

export default function Einsparpotential() {
  return [
    <Intro key="intro" />,
    <WieMachenWirDas1 key="wie-machen-wir-das-1" />,
    <WieMachenWirDas2 key="wie-machen-wir-das-2" />,
    <Beispiel key="beispiel" />,
    <BeispielGrafik key="beispiel-grafik" />,
    <Grafik key="grafik" />,
  ];
}
