import { Grid } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/2.webp";

export default function UnserSortiment() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "left",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Unser Sortiment" />
          <QList
            items={["Handschutz", "Augenschutz", "Atemschutz", "Gehörschutz"]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
