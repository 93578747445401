import SvgIcon from "@material-ui/core/SvgIcon";

export default function ArrowIconLeft(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.474 5.20915C9.474 5.20915 4.973 9.71415 3.22 11.4682C3.073 11.6142 3 11.8062 3 11.9982C3 12.1902 3.073 12.3822 3.22 12.5282C4.972 14.2822 9.472 18.7852 9.472 18.7852C9.617 18.9302 9.808 19.0022 9.999 19.0022C10.19 19.0012 10.382 18.9282 10.529 18.7812C10.822 18.4882 10.823 18.0152 10.533 17.7242L5.557 12.7482H20.249C20.663 12.7482 20.999 12.4122 20.999 11.9982C20.999 11.5842 20.663 11.2482 20.249 11.2482H5.557L10.535 6.26915C10.824 5.98015 10.822 5.50815 10.529 5.21515C10.382 5.06815 10.19 4.99415 9.999 4.99415C9.808 4.99315 9.619 5.06515 9.474 5.20915Z"
        fill="#33363F"
      />
    </SvgIcon>
  );
}
