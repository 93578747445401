import { theme } from "../../../../../../../../theme";

export default function ATeileBarSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
      {...props}
      viewBox="0 0 389 907"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ATeileBarSVG Kopie 1" clip-path="url(#clip0_0_3)">
        <text
          className="gsap-bar-label"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          fontFamily={fontFamily}
          fontSize="73"
        >
          <tspan x="100" y="70.28">
            80 %
          </tspan>
        </text>
        <rect
          className="gsap-bar"
          y="126"
          width="389"
          height="781"
          fill="#E20031"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_3">
          <rect width="389" height="907" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
