import { h, Component } from "preact";
import { Router } from "preact-router";

import {theme} from "./theme";

// Code-splitting is automated for routes
import Chapters from "./routes/Chapters";

//Redux
import configureStore from "./redux/store/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

//Material UI
import { ThemeProvider } from "@material-ui/core";
import ModalProvider from "mui-modal-provider";

//Material UI Picker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

//StylesProvider for overriding MaterialUI
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

import CustomerView from "./routes/CustomerView";
import ResetPassword from "./routes/ResetPassword";

//Toastify
import { ToastContainer, toast } from "playbook-pwa-ui";

//tippy.js
import "tippy.js/dist/tippy.css";

//Sentry https://docs.sentry.io/platforms/javascript/react/
// import * as Sentry from '@sentry/react';

//Fullpage ScrollOverflow
//import "fullpage.js/vendors/scrolloverflow";

import "aos/dist/aos.css";

import root from "window-or-global";

import APIService from "./api/index";
import AppWrap from "./appWrap";

// Kapitel
import Ueber_SSV_Kroschke from "./routes/Chapters/Ueber-SSV-Kroschke";
import Nachhaltigkeit from "./routes/Chapters/Nachhaltigkeit";
import KlausKroschkeGruppe from "./routes/Chapters/KlausKroschkeGruppe";
import EProcurement from "./routes/Chapters/EProcurement";
import LEANdustry from "./routes/Chapters/LEANdustry";
import ElektronischeAusgabesysteme from "./routes/Chapters/ElektronischeAusgabesysteme";
import CTeileSortiment from "./routes/Chapters/CTeileSortiment";
import Einsparpotentialanalyse from "./routes/Chapters/Einsparpotentialanalyse";
import PSAVollversorgungskonzept from "./routes/Chapters/PSAVollversorgung";
import DienstleistungspaketeFuerBetriebseinrichtung from "./routes/Chapters/DienstleistungspaketeFuerBetriebseinrichtung";
import DienstleistungspaketeFuerZerspanung from "./routes/Chapters/DienstleistungspaketeFuerZerspanung";
import VerwaltungMessmittelUndElektrowerkzeuge from "./routes/Chapters/VerwaltungMessmittelUndElektrowerkzeuge";
import Referenzen from "./routes/Chapters/Referenzen";
import Karriere from "./routes/Chapters/Karriere";

const generateClassName = createGenerateClassName({
  productionPrefix: "qmarketing",
});

const broadcast = new BroadcastChannel('ssv-kroscke-pwa');

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  handleConnection(event) {
    if (event.type === "offline") {
      toast.error("Keine Internetverbindung.");
    }
    if (event.type === "online") {
      toast.info("Sie sind wieder online.");
    }
  }

  async componentDidMount() {
    if (process.env.NODE_ENV !== "development") {
      APIService.get("api/pb/hit");
    }

    broadcast.onmessage = (event) => {
      if (event.data.payload === 'install') {
        toast.info("Die PWA wird gecached.");
      }
      
      if (event.data.payload === 'activate') {
        toast.info("Die PWA wurde erfolgreich gecached.");
      }
    };

    root.addEventListener("online", this.handleConnection);
    root.addEventListener("offline", this.handleConnection);
  }

  render() {
    const { store, persistor } = configureStore({});
    return (
      <div id="app">
        <ToastContainer
          position="bottom-center"
          toastClassName="toast-container"
          autoClose={3000}
          hideProgressBar
          icon={true}
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider
              injectFirst={false}
              generateClassName={generateClassName}
            >
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <ModalProvider>
                    <AppWrap>
                      <Router onChange={this.handleRoute}>
                        <CustomerView path="/c" />
                        <Chapters path="/" />
                        <Ueber_SSV_Kroschke path="/ueber-ssv-kroschke" />
                        <KlausKroschkeGruppe path="/klaus-kroschke-gruppe" />
                        <LEANdustry path="/leandustry" />
                        <ElektronischeAusgabesysteme path="/elektronische-ausgabesysteme" />
                        <CTeileSortiment path="/c-teile-sortiment" />
                        <Nachhaltigkeit path="/nachhaltigkeit" />
                        <EProcurement path="/e-procurement" />
                        <Einsparpotentialanalyse path="/einsparpotential-analyse" />
                        <PSAVollversorgungskonzept path="/psa-vollversorgungskonzept" />
                        <DienstleistungspaketeFuerBetriebseinrichtung path="/dienstleistungspakete-fuer-betriebseinrichtung" />
                        <DienstleistungspaketeFuerZerspanung path="/dienstleistungspakete-fuer-zerspanung" />
                        <VerwaltungMessmittelUndElektrowerkzeuge path="/verwaltung-messmittel-und-elektrowerkzeuge" />
                        <Referenzen path="/referenzen" />
                        <Karriere path="/karriere" />
                        <ResetPassword path="/reset-password/:hash" />
                      </Router>
                    </AppWrap>
                  </ModalProvider>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}
