import { Box, Grid, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import ATeileSVG from "./_Components/Teile/ATeileSVG";
import BTeileSVG from "./_Components/Teile/BTeileSVG";
import CTeileSVG from "./_Components/Teile/CTeileSVG";

export default function WasSindEigentlichCTeile() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="Was sind eigentlich C-Teile?" />
        </Grid>
        <Grid item xs={4}>
          <Box width="50%" display="block" margin="15px auto">
            <ATeileSVG className="gsap-facts" />
          </Box>
          <Typography variant="body1" align="center">
            A-Teile
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box width="50%" display="block" margin="15px auto">
            <BTeileSVG className="gsap-facts" />
          </Box>
          <Typography variant="body1" align="center">
            B-Teile
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box width="50%" display="block" margin="15px auto">
            <CTeileSVG className="gsap-facts" />
          </Box>
          <Typography variant="body1" align="center">
            C-Teile
          </Typography>
        </Grid>
      </Grid>
    </Slide>
  );
}
