import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";
import ReactPlayer from 'react-player'

export default function Video() {
  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <Box width="100%" height="100%">
            <ReactPlayer
              width="100%"
              height="100%"
              style={{
                margin: "0px auto",
                display: "block",
                maxHeight: "calc(100dvh - 90px * 2)"
              }}
              controls
              url={[{src: '/assets/_Chapters/UeberSSVKroschke/Video/Imagefilm.mp4', type: 'video/mp4'}]}
            />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
