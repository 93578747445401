import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Nachhaltigkeit/2.jpg";

export default function NachhaltigArbeiten_2() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50%",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Nachhaltig arbeiten – wir machen es vor" />
          <QList
            listStyle="blatt"
            noPadding
            items={[
              "Gebündelt versenden",
              "Den papierlosen Arbeitsalltag organisieren",
              "Bis 2030 klimaneutral werden",
              "Soziale Verantwortung übernehmen",
              "Nachhaltige Projekte in der Region unterstützen",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
