import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/BeratungPlanungMontage/8.webp";
import { Grid, Typography } from "@material-ui/core";

export default function AR() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Ihre Planung mit AR" />
          <Typography variant="body1">
            Augmented Reality bedeutet „erweiterte Realität“. Hierbei wird die
            reale Welt mit Elementen aus dem virtuellen Raum überlagert.
          </Typography>
          <QList
            items={[
              "Brille scannt die Umwelt in Echtzeit",
              "Platzierung von digitalen Objekten perspektivisch korrekt in den realen Raum",
              "Betrachtung der Planung von oben und Verschieben einzelner Elemente möglich",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
