import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Klaus-Kroschke-Gruppe/2.jpg";
import { Grid } from "@material-ui/core";

export default function DerZusammenschluss() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "left"
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Der Zusammenschluss" />
          <QList
            items={[
              <>
                <b>September 2020:</b> Eintritt in die Klaus Kroschke Gruppe
              </>,
              <>
                <b>Unser Ziel:</b> Deutschlands innovativste C-Teile-Plattform
                werden
              </>,
              <>
                <b>Durch:</b> Bündelung der Kompetenzen in einem Unternehmen
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
