import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import ATeileSVG from "./_Components/Teile/ATeileSVG";
import PeopleClap from "../../../../../../components/SvgIcons/KroschkeIcons/PeopleClap";
import Revolution from "../../../../../../components/SvgIcons/KroschkeIcons/Revolution";
import HandUndHerz from "../../../../../../components/SvgIcons/KroschkeIcons/HandUndHerz";
import Woooow from "../../../../../../components/SvgIcons/KroschkeIcons/Woooow";

const useStyles = makeStyles((theme) => ({
  fontWeightBold: {
    fontWeight: 700,
    marginBottom: ".4em",
  },
}));

export default function AufEinenBlick2() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="SSV-Kroschke auf einen Blick" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <PeopleClap height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Wissen, nicht schätzen
              </Typography>
              <Typography variant="body1" align="center">
                Stimmt. Wir wertschätzen unsere Mitarbeiter jedoch trotzdem sehr
                gerne.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Revolution height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                REVOLUTION
              </Typography>
              <Typography variant="body1" align="center">
                Wird bei unserer Arbeit immer sehr groß geschrieben.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <HandUndHerz height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Arbeiten mit Verstand
              </Typography>
              <Typography variant="body1" align="center">
                Und mit ganzem Herzen Sport, Kultur und Soziales in der Region
                unterstützen.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box width="50%" display="block" margin="15px auto">
                <Woooow height="35vh" className="gsap-facts" />
              </Box>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                className={classes.fontWeightBold}
              >
                Woooow
              </Typography>
              <Typography variant="body1" align="center">
                Ist das, was du zum Firmengebäude und den Benefits sagen wirst.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
