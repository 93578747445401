import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function AusbildungStudium(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} viewBox="0 0 363 397">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M351.003 187.927L192.813 164.127L44.1126 251.827C30.8526 267.037 34.0426 284.557 43.9826 300.197L43.1226 300.707C28.3626 317.647 33.9826 337.447 46.6226 354.327L213.033 391.207L364.913 296.677C370.733 293.057 363.333 281.987 357.883 285.377L212.053 376.137L55.0026 341.327C43.8026 328.707 46.3026 311.327 58.1826 307.787L214.023 342.327L365.513 248.037C371.333 244.417 363.933 233.347 358.473 236.737L213.033 327.257L55.9826 292.447C44.7826 279.827 47.2826 262.447 59.1626 258.907L209.503 288.567L354.213 203.217C365.783 196.397 361.313 189.477 351.003 187.927Z" fill="#E4032E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M146.653 347.247C146.653 347.247 133.113 362.217 133.113 398.097L142.853 390.017L152.353 403.917C152.353 403.917 153.893 363.047 166.963 351.877L146.643 347.247H146.653Z" fill="#4A4A49"/>
        <path d="M179.812 136.927C180.842 136.927 181.832 136.847 182.762 136.677C183.592 136.527 184.382 136.297 185.112 136.017C186.192 135.727 187.062 135.257 187.592 134.927L323.952 62.5474C326.472 61.3274 328.512 59.3574 329.702 56.9974C331.362 54.0474 331.502 50.6574 330.082 47.6674C328.452 44.2374 325.012 41.8774 320.682 41.2074L169.082 17.2274C165.372 16.6074 161.502 17.2774 158.622 18.9674L21.982 91.4574C16.962 94.1574 14.342 98.7974 15.142 103.577C15.942 108.297 19.872 111.797 25.422 112.707L177.322 136.847C178.162 136.957 179.332 137.107 179.812 136.947V136.927Z" fill="white"/>
        <path d="M142.822 241.078C133.472 241.078 115.932 240.708 103.032 238.248C96.1417 237.238 89.8217 234.828 84.2517 231.098C81.0717 229.008 78.4317 226.728 75.4217 223.488L75.3017 223.348C70.3417 217.798 66.7917 211.058 65.0417 203.848C64.8317 203.168 64.6917 202.538 64.5817 202.028C64.4017 201.428 64.2417 200.758 64.1017 199.998L62.2717 188.408L51.0117 122.218L177.412 142.348C178.132 142.428 178.602 142.468 178.982 142.468C179.162 142.468 179.332 142.468 179.482 142.438L179.952 142.378H180.422C181.122 142.348 181.942 142.258 182.612 142.138C183.412 141.988 184.122 141.808 184.842 141.558L185.172 141.448C186.002 141.188 186.732 140.848 187.022 140.678L187.202 140.578L300.362 80.5176L313.532 158.938C314.852 168.248 313.992 176.608 310.992 183.798C310.532 185.098 309.652 186.748 307.642 190.108L307.552 190.258L307.462 190.398C302.342 198.318 295.942 204.008 287.922 207.798C272.152 216.298 249.272 224.648 225.072 230.748C223.902 231.058 223.282 231.188 222.702 231.298C216.532 232.888 209.842 234.338 202.262 235.718C197.912 236.518 193.432 237.228 188.972 237.848C182.012 238.888 176.522 239.548 171.202 239.988C163.572 240.648 155.602 240.988 146.842 241.038C145.562 241.048 144.192 241.058 142.802 241.058L142.822 241.078Z" fill="white"/>
        <path d="M188.142 151.017C186.952 151.427 185.752 151.747 184.442 151.987C183.312 152.197 181.982 152.337 180.732 152.377C179.122 152.577 177.642 152.457 176.052 152.267L63.2324 134.297L72.1624 186.797L73.9624 198.207C73.9924 198.397 74.1324 199.147 74.2824 199.407C74.3724 199.877 74.4924 200.537 74.7024 201.177C76.0324 206.847 78.7524 212.177 82.7824 216.677C85.3224 219.417 87.3924 221.167 89.8124 222.767C94.2424 225.737 99.3324 227.627 104.712 228.387C116.182 230.617 133.272 231.217 146.822 231.057C154.482 231.017 162.382 230.727 170.402 230.037C176.232 229.557 181.822 228.817 187.582 227.957C191.932 227.357 196.272 226.657 200.492 225.887C207.442 224.617 214.162 223.187 220.552 221.537C221.022 221.447 221.402 221.387 222.602 221.067C243.842 215.727 266.842 207.877 283.442 198.877C290.682 195.517 295.462 190.597 299.092 184.977C300.082 183.327 301.522 180.857 301.622 180.357C304.282 174.247 304.642 167.347 303.652 160.347L292.822 95.8672L192.102 149.327C191.142 149.887 189.702 150.537 188.142 151.017Z" fill="white"/>
        <path d="M338.941 161.007C323.981 161.007 311.161 150.317 308.461 135.587C306.321 123.877 311.101 112.257 320.221 105.337L313.891 73.1372L323.601 68.1472C324.941 67.4772 326.111 66.6872 326.991 65.8472L340.191 53.3672L349.481 100.967C359.651 104.657 367.431 113.517 369.431 124.427C372.511 141.237 361.331 157.417 344.521 160.497C342.681 160.837 340.801 161.007 338.931 161.007H338.941Z" fill="white"/>
        <path d="M340.911 109.207L333.861 73.1172C332.211 74.6772 330.221 76.0172 328.091 77.0872L325.161 78.5972L331.421 110.457C322.181 114.007 316.452 123.727 318.302 133.797C320.382 145.187 331.341 152.757 342.731 150.667C354.121 148.587 361.681 137.627 359.601 126.237C357.871 116.797 350.021 110.077 340.911 109.207Z" fill="white"/>
        <path d="M188.142 151.017C186.952 151.427 185.752 151.747 184.442 151.987C183.312 152.197 181.982 152.337 180.732 152.377C179.122 152.577 177.642 152.457 176.052 152.267L63.2324 134.297L72.1624 186.797L73.9624 198.207C73.9924 198.397 74.1324 199.147 74.2824 199.407C74.3724 199.877 74.4924 200.537 74.7024 201.177C76.0324 206.847 78.7524 212.177 82.7824 216.677C85.3224 219.417 87.3924 221.167 89.8124 222.767C94.2424 225.737 99.3324 227.627 104.712 228.387C116.182 230.617 133.272 231.217 146.822 231.057C154.482 231.017 162.382 230.727 170.402 230.037C176.232 229.557 181.822 228.817 187.582 227.957C191.932 227.357 196.272 226.657 200.492 225.887C207.442 224.617 214.162 223.187 220.552 221.537C221.022 221.447 221.402 221.387 222.602 221.067C243.842 215.727 266.842 207.877 283.442 198.877C290.682 195.517 295.462 190.597 299.092 184.977C300.082 183.327 301.522 180.857 301.622 180.357C304.282 174.247 304.642 167.347 303.652 160.347L292.822 95.8672L192.102 149.327C191.142 149.887 189.702 150.537 188.142 151.017Z" fill="#E4032E"/>
        <path className="gsap-zipfel" d="M325.134 109.127C315.9 114.139 310.46 124.666 312.461 135.563C314.939 149.125 327.981 158.134 341.544 155.647C355.111 153.169 364.106 140.122 361.63 126.564L361.63 126.561C359.764 116.38 351.95 108.889 342.5 106.767L334.456 56.357L333.779 52.1162L329.597 53.0919C329.167 53.1922 328.787 53.2817 328.445 53.3622C325.618 54.0281 325.415 54.0758 321.518 54.4619L317.36 54.8738L317.953 59.0098L325.134 109.127Z" fill="#4A4A49" stroke="white" stroke-width="8"/>
        <path d="M183.395 140.666C184.417 140.481 185.383 140.206 186.275 139.876C187.673 139.474 188.786 138.886 189.497 138.454L325.684 66.1656C328.886 64.5973 331.548 62.0633 333.15 58.9325C335.387 54.9003 335.594 50.1744 333.613 46.0041L333.613 46.0032C331.319 41.177 326.617 38.1453 321.218 37.3082C321.216 37.3078 321.214 37.3075 321.212 37.3072L169.659 13.3348C169.653 13.3338 169.647 13.3328 169.641 13.3318C165.134 12.5829 160.343 13.3529 156.595 15.524L20.0254 87.9766L20.0153 87.9819L20.0053 87.9873C13.8258 91.311 9.97176 97.4603 11.1149 104.29L11.1162 104.299C12.2608 111.052 17.8333 115.583 24.6928 116.707L24.7025 116.709L24.7122 116.711L176.612 140.851L176.666 140.859L176.721 140.866C177.683 140.992 178.537 141.061 179.627 141C180.833 140.992 182.236 140.877 183.395 140.666ZM183.395 140.666C183.394 140.666 183.393 140.666 183.391 140.666L182.68 136.73L183.399 140.665C183.398 140.665 183.397 140.665 183.395 140.666Z" fill="#E4032E" stroke="white" stroke-width="8"/>
    </SvgIcon>
  );
}
