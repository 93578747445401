import DerZusammenschluss from "./slides/DerZusammenschluss";
import DieKompetenzen from "./slides/DieKompetenzen";
import WasWirGemeinsamSchaffen from "./slides/WasWirGemeinsamSchaffen";

export default function Zusammenschluss() {
    return [
      <DerZusammenschluss key="der-zusammenschluss" />,
      <DieKompetenzen key="die-kompetenzen" />,
      <WasWirGemeinsamSchaffen key="was-wir-gemeinsam-schaffen" />,
    ];
  }
  