import * as React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Button,
  Container,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import BackgroundElement from "../BackgroundElement";

import ImageUp from "../../assets/_defaultAssets/controls/move-up.png";
import ImageDown from "../../assets/_defaultAssets/controls/move-down.png";

const useStyles = makeStyles((theme) => ({
  slide: {
    position: "relative",
    height: "100dvh",
    overflow: "hidden",
  },
  slideHeight: {
    height: "calc(100dvh - 90px * 2)",
  },
  slidePaddingContainer: {
    padding: "90px 30px",
  },
  slidePadding: {
    padding: "90px 30px",
  },
  paddingTop: {
    paddingTop: "90px",
  },
  paddingRight: {
    paddingRight: "30px",
  },
  paddingBottom: {
    paddingBottom: "90px",
  },
  paddingLeft: {
    paddingLeft: "30px",
  },
  textUpPosition: {
    position: "absolute",
    top: "40px",
  },
  textDownPosition: {
    position: "absolute",
    bottom: "40px",
  },
  textStyle: {
    fontWeight: 500,
  },
}));

export default function Slide({
  children,
  background,
  container,
  id,
  padding,
}) {
  const classes = useStyles();

  const p = clsx(
    padding?.top ? classes.paddingTop : null,
    padding?.right ? classes.paddingRight : null,
    padding?.bottom ? classes.paddingBottom : null,
    padding?.left ? classes.paddingLeft : null
  );

  return (
    <Box className={clsx("slide", classes.slide)} id={id}>
      {background && (
        <BackgroundElement background={background} line={background.line} />
      )}
      {container ? (
        <Container>
          <Box
            className={clsx(classes.slideHeight, classes.slidePaddingContainer)}
          >
            {children}
          </Box>
        </Container>
      ) : (
        <Box
          className={clsx(
            classes.slideHeight,
            padding ? p : classes.slidePadding
          )}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
