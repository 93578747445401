import { Grid, Box, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import EinZweitesLebenSVG from "./_Components/EinZweitesLebenSVG";

const useStyles = makeStyles((theme) => ({
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
  hyphens: {
    hyphens: "auto",
    textWrap: "balance"
  }
}));

export default function EinZweitesLeben() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5} className={classes.hyphens}>
          <QHeader
            title="Ein zweites Leben für Zerspanungswerkzeuge"
            subTitle="Lieferung und Nachschliffservice aus einer Hand, mit dem Dienstleister Ihrer Wahl."
          />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <EinZweitesLebenSVG  height="100%"/>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
