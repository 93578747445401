import ChapterWrapper from "../../../components/ChapterWrapper";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading"
import Beratungsdialog from "../../../components/Beratungsdialog";

import { Helmet } from "react-helmet";
import { getChapterByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";

import Elektrowerkzeuge from "./sections/Elektrowerkzeuge";
import Messmittel from "./sections/Messmittel";

export default function VerwaltungMessmittelUndElektrowerkzeuge() {
  const chapterID = 12;
  const chapterData = useSelector((state) => getChapterByID(state, chapterID));

  if (!chapterData) {
    return <ChapterLoading />
  }

  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;

  return (
    <>
      <Helmet>
        <title>
          SSV-Kroschke Playbook | Verwaltung Messmittel Und Elektrowerkzeuge
        </title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <Messmittel anchor="messmittel" />
        <Elektrowerkzeuge anchor="elektrowerkzeuge" />
      </ChapterWrapper>
    </>
  );
}
