import { gsap } from "playbook-pwa-ui";

import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";
import TochtergesellschaftenSVG from "./_Components/TochtergesellschaftenSVG";
import MitarbeiterSVG from "./_Components/MitarbeiterSVG";
import FamilienunternehmenSVG from "./_Components/FamilienunternehmenSVG";
import UmsatzSVG from "./_Components/UmsatzSVG";
import QHeader from "../../../../../../components/QHeader";

export default function Facts() {
  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Klaus Kroschke Gruppe" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TochtergesellschaftenSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                11 Tochtergesellschaften
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <MitarbeiterSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Über 750 Mitarbeiter
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FamilienunternehmenSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Inhabergeführtes Familienunternehmen
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <UmsatzSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                über 220 Mio. € Umsatz im Jahr 2022
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
