import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import ATeileSVG from "./_Components/Teile/ATeileSVG";
import BTeileSVG from "./_Components/Teile/BTeileSVG";
import CTeileSVG from "./_Components/Teile/CTeileSVG";
import { theme } from "../../../../../../theme";
import ATeileBarSVG from "./_Components/Bar/ATeileBarSVG";
import BTeileBarSVG from "./_Components/Bar/BTeileBarSVG";
import CTeileBarSVG from "./_Components/Bar/CTeileBarSVG";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
  },
}));

export default function WertanteileImVerhaeltnis() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader
            textAlign="center"
            title={
              <>
                <span className={classes.primary}>Wertanteile</span> im
                Verhältnis
              </>
            }
          />
        </Grid>
        <Grid container style={{ height: "70dvh" }} spacing={5}>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <ATeileBarSVG height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <ATeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                A-Teile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <BTeileBarSVG height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <BTeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                B-Teile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <CTeileBarSVG height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <CTeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                C-Teile
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
