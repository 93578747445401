import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Klaus-Kroschke-Gruppe/1.jpg";


export default function ExpertenInVielenBereichen() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Experten in vielen Bereichen" />
          <QList
            items={[
              "Arbeitssicherheit für Mensch und Betrieb",
              "Reinraum-Produkte",
              "Kennzeichnung im Unternehmen",
              "Industrielle Produktkennzeichnung",
              "Versandkennzeichnung und Etikettendrucker",
              "Logistik für Hard- und Softwaresysteme",
              "Beschaffungs-Dienstleistungen",
              "Elektronische Ausgabesysteme",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
