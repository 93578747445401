import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import BeratungSVG from "./_Components/BeratungSVG";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fontRegular: {
    marginTop: "5px",
    fontWeight: 400,
  },
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
}));
export default function Optimierung() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={5}>
          <QList
            listStyle="blatt"
            items={[
              <>
                <b>Für das gesamte C-Teile Sortiment,</b>{" "}
                <span className={classes.fontRegular}>
                  denn 25 % aller eingekauften Produkte in der Industrie werden
                  statistisch nie verwendet.
                </span>
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <BeratungSVG height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
