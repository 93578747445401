// Components
import Slide from "../../../../../components/Slide";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  transform: {
    transform: "scale(1.5) rotate(30deg)",
  },
}));

export default function USP() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <Box marginBottom="40px">
            <QHeader title="Lean Management" />
            <QList
              items={[
                "Konzept zur Ertragsoptimierung",
                "Umgestaltung von Prozessen und Ressourcen",
                "Beseitigung von Kosten und Zeitverlust",
              ]}
            />
          </Box>
          <QHeader title="Industrie 4.0" />
          <QList
            items={[
              "Umfangreiche Digitalisierung durch Informations- und Kommunikationstechnologie",
              "Maschinen und Prozesse werden intelligent miteinander vernetzt",
            ]}
          />
        </Grid>
      </Grid>
      <Box
        width="50dvw"
        height="100dvh"
        position="absolute"
        right="0px"
        top="0px"
      >
        <svg
          className={classes.transform}
          width="100%"
          height="100%"
          id="uuid-c768c9af-792e-4a5a-a102-f23c7d661949"
          data-name="Schrift"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 578.64 917.52"
        >
          <g className="gsap-steckdose-1">
            <g>
              <path
                d="m317.16,453.27c-3.19,0-5.76-2.58-5.76-5.76v-35.55c0-3.18,2.58-5.76,5.76-5.76h0c3.19,0,5.76,2.58,5.76,5.76v35.55c0,3.18-2.58,5.76-5.76,5.76h0Z"
                style="fill: #4a4a49; stroke-width: 0px;"
              />
              <path
                d="m317.26,406.21v47.04c3.14-.05,5.67-2.6,5.67-5.75v-35.55c0-3.15-2.53-5.69-5.67-5.75Z"
                style="fill: #4a4a49; stroke-width: 0px;"
              />
              <path
                d="m270.09,453.27c-3.19,0-5.77-2.58-5.77-5.76v-35.55c0-3.18,2.58-5.76,5.77-5.76h0c3.18,0,5.76,2.58,5.76,5.76v35.55c0,3.18-2.58,5.76-5.76,5.76h0Z"
                style="fill: #4a4a49; stroke-width: 0px;"
              />
              <path
                d="m270.18,406.21v47.04c3.15-.05,5.67-2.6,5.67-5.75v-35.55c0-3.15-2.53-5.69-5.67-5.75Z"
                style="fill: #4a4a49; stroke-width: 0px;"
              />
            </g>
            <path
              d="m275.93,332.18h12.6v3.03h-12.66v.57s-14.47,30.35-14.47,30.35v26.09l-11.91,5.39v16.45l14.85.03h5.86s5.67.03,5.67.03l17.58.04h.28l14.09-.03h5.86s5.67-.02,5.67-.02l18.34-.04v-16.45l-11.91-5.39v-26.09l-14.46-30.35-.17-8.89h-12.5v-3.02h12.44l-.2-10.4h-12.24v-3.02h12.18l-.2-10.4h-11.98v-3.02h11.92l-.1-5.49h-7.94V5h-17.87v286.53h-7.94l-.26,13.8h12.09v3.03h-12.14l-.2,10.4h12.35v3.03h-12.4m-.36,50.01v-2.46h35.63v2.46h-35.63Zm0,7.56v-2.46h35.63v2.46h-35.63Zm0,7.56v-2.46h35.63v2.46h-35.63Z"
              style="fill: #e4032e; stroke-width: 0px;"
            />
          </g>
          <g className="gsap-steckdose-2">
            <path
              d="m276.13,580.67h12.4v3.02h-12.35l.2,10.4h12.14v3.03h-12.09l.26,13.8h7.94v302.6h17.87v-302.6h7.94l.1-5.49h-11.92v-3.02h11.98l.2-10.4h-12.18v-3.03h12.24l.2-10.4h-12.44v-3.02h12.5l.17-8.89,14.46-30.34v-26.09l11.91-5.39v-16.45l-43.96-.09h-.28l-43.96.09v16.45l11.91,5.39v26.09l14.47,30.34v.57h12.67v3.03h-12.6m-.16-52.27v-2.46h35.63v2.46h-35.63Zm0,7.56v-2.45h35.63v2.45h-35.63Zm0,7.56v-2.46h35.63v2.46h-35.63Z"
              style="fill: #e4032e; stroke-width: 0px;"
            />
          </g>
        </svg>
      </Box>
    </Slide>
  );
}
