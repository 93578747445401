import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function WorkLifeWaage(props) {
  const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} viewBox="0 0 489 293">
      <path className="gsap-herzfall" fill-rule="evenodd" clip-rule="evenodd" d="M355.88 44.9503C336.56 44.8903 319.46 64.5203 327.36 87.8303C336.44 110.88 361.6 130.93 383.71 147.27C405.82 130.93 430.98 110.87 440.06 87.8303C447.95 64.5203 430.86 44.8903 411.54 44.9503C401.77 44.9803 391.68 50.2403 383.71 62.4303C375.75 50.2403 365.66 44.9803 355.88 44.9503Z" fill={primaryColor}/>
      <g className="gsap-waage">
        <g className="aktentasche">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M90.5199 54.3C90.5199 53.32 90.9099 52.39 91.6099 51.69C92.3199 50.99 93.2499 50.6 94.2199 50.6H117.27C118.26 50.6 119.19 50.98 119.88 51.68C120.58 52.39 120.97 53.32 120.97 54.3V56.01C120.97 56.68 121.52 57.23 122.19 57.23H130.36C131.03 57.23 131.58 56.68 131.58 56.01V54.3C131.58 50.53 130.11 46.97 127.44 44.27C127.42 44.24 127.39 44.22 127.37 44.19C124.66 41.49 121.07 40 117.28 40H94.2299C90.4199 40 86.8299 41.49 84.1199 44.19C81.4099 46.9 79.9199 50.49 79.9199 54.3V56.01C79.9199 56.68 80.4699 57.23 81.1399 57.23H89.3099C89.9799 57.23 90.5299 56.68 90.5299 56.01V54.3H90.5199Z" fill={primaryColor}/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M151.93 61.75H59.2001C55.5401 61.75 52.5801 64.71 52.5801 68.37V83.96C52.5801 85.9 53.4501 87.52 55.1501 88.77C56.1001 89.47 57.3401 90.06 58.8301 90.52L93.6001 101.41C93.9901 101.53 94.3901 101.24 94.3901 100.83V96.22C94.3901 92.81 97.1501 90.04 100.57 90.04H110.56C113.97 90.04 116.74 92.8 116.74 96.22V100.83C116.74 101.24 117.14 101.53 117.53 101.41L152.3 90.52C153.82 90.05 155.03 89.47 155.98 88.76C157.67 87.53 158.56 85.87 158.56 83.96V68.37C158.56 64.71 155.6 61.75 151.94 61.75H151.93Z" fill={primaryColor}/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M116.74 109.88V112.46C116.74 115.87 113.98 118.64 110.56 118.64H100.57C97.1602 118.64 94.3902 115.88 94.3902 112.46V109.88C94.3902 109.35 94.0402 108.88 93.5402 108.72L57.7402 97.5099C57.2502 97.3599 56.7802 97.1899 56.3402 97.0099C55.5302 96.6899 54.6602 97.2699 54.6602 98.1299V135.16C54.6602 138.81 57.6202 141.77 61.2702 141.77H149.86C153.51 141.77 156.47 138.81 156.47 135.16V98.1299C156.47 97.2599 155.59 96.6799 154.79 97.0099C154.34 97.1899 153.87 97.3499 153.38 97.5099L117.59 108.72C117.08 108.88 116.74 109.35 116.74 109.88Z" fill={primaryColor}/>
          <path d="M110.561 95.4404H100.571C99.179 95.4404 98.0508 96.5687 98.0508 97.9604V110.7C98.0508 112.092 99.179 113.22 100.571 113.22H110.561C111.953 113.22 113.081 112.092 113.081 110.7V97.9604C113.081 96.5687 111.953 95.4404 110.561 95.4404Z" fill={primaryColor}/>
        </g>
        <path d="M163.7 158.92C163.7 180.59 137.57 198.15 105.35 198.15C73.13 198.15 47 180.59 47 158.92H163.69H163.7Z" fill={primaryColor}/>
        <path d="M111.86 190.21H98.8301V208.09H111.86V190.21Z" fill={primaryColor}/>
        <path d="M440.841 158.92C440.841 180.59 414.711 198.15 382.491 198.15C350.271 198.15 324.141 180.59 324.141 158.92H440.831H440.841Z" fill={primaryColor}/>
        <path d="M389.001 190.21H375.971V208.09H389.001V190.21Z" fill={primaryColor}/>
        <path d="M406.54 214.47H81.3004C79.4204 214.47 77.9004 212.95 77.9004 211.07C77.9004 209.19 79.4204 207.67 81.3004 207.67H406.54C408.42 207.67 409.94 209.19 409.94 211.07C409.94 212.95 408.42 214.47 406.54 214.47Z" fill={primaryColor}/>
      
      </g>
      <path d="M240.92 193.22H246.93C256.37 193.22 264.04 200.88 264.04 210.33V246.61H223.82V210.33C223.82 200.89 231.48 193.22 240.93 193.22H240.92Z" fill={primaryColor}/>
      <path d="M221.021 233.7H266.831C276.391 233.7 284.151 241.46 284.151 251.02V254.06H203.711V251.02C203.711 241.46 211.471 233.7 221.031 233.7H221.021Z" fill={primaryColor}/>
    </SvgIcon>
  );
}
