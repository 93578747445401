import {theme} from "../../../../../../../theme"

export default function UndKostenEinsparenSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  const primaryColor = theme.palette.primary.main;
  return (
    <svg {...props} viewBox="0 0 2338 1420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="die-benoetigte-zeit" clip-path="url(#clip0_917_384)">
        <g id="Group 20">
          <path id="Vector" opacity="0.3" d="M764.428 585.678C743.352 653.258 706.409 701.922 662.612 731.702C628.342 754.938 590.096 766.555 551.851 766.555C513.606 766.555 475.429 754.938 441.159 731.702C397.362 701.922 360.418 653.258 339.308 585.678C316.587 560.215 233.996 457.062 297.67 361.688C297.361 358.467 297.053 355.211 296.882 352.059H806.923C806.752 355.211 806.443 358.467 806.135 361.688C869.809 457.062 787.218 560.215 764.428 585.678Z" fill={primaryColor}/>
          <path id="Vector_2" d="M949.315 868.094V868.026L730.946 747.566L715.868 739.273C670.151 781.699 615.799 812.097 551.234 811.788C487.732 811.549 431.632 780.466 386.498 737.971L371.659 746.127L150.754 867.991V868.06C27.0391 932.865 0 1282.49 0 1283.04L0.376973 1304.46L51.5079 1329.13C105.312 1349.28 163.742 1364.08 221.145 1375.02V1146.5H519.123V1404.8C537.286 1405.11 548.287 1404.97 550.069 1404.97C551.851 1404.97 562.783 1405.11 580.946 1404.8V1146.5H878.925V1375.02C936.327 1364.08 994.757 1349.25 1048.56 1329.13L1100.07 1307.88L1100.45 1286.46C1100.45 1285.91 1073.03 932.83 949.315 868.06V868.094ZM519.123 1092.15H236.464L286.566 816.518L383.791 759.869L519.123 958.978V1092.15ZM580.946 1092.15V958.978L716.279 759.869L813.503 816.518L863.606 1092.15H580.946Z" fill="url(#paint0_linear_917_384)"/>
          <g id="Group" opacity="0.3">
            <path id="Vector_3" d="M810.762 243.009C810.077 172.721 789.892 149.657 754.353 107.163C725.258 72.3784 686.396 48.1152 639.96 35.5038L621.249 210.761H589L608.671 26.3879C610.179 12.3029 599.144 0 584.957 0H515.114C500.926 0 489.891 12.2687 491.399 26.3879L511.07 210.761H478.788L460.453 38.2454C418.027 51.4737 382.386 74.7088 355.244 107.197C319.74 149.692 299.521 172.755 298.836 243.043V245.819H810.728V243.043L810.762 243.009Z" fill={primaryColor}/>
            <path id="Vector_4" d="M836.739 245.82H269.089C262.711 245.82 257.54 250.991 257.54 257.369V296.848C257.54 303.227 262.711 308.397 269.089 308.397H836.739C843.117 308.397 848.288 303.227 848.288 296.848V257.369C848.288 250.991 843.117 245.82 836.739 245.82Z" fill={primaryColor}/>
          </g>
        </g>
      </g>
      <g>
        <text className="gsap-stack-text" id="C-Teile" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2060.88" y="1405.12">C-Teile</tspan></text>
        <text className="gsap-stack-text" id="B-Teile" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2054.5" y="1264.12">B-Teile</tspan></text>
        <text className="gsap-stack-text" id="A-Teile" fill="black" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="92" letter-spacing="0em"><tspan x="2057.56" y="750.12">A-Teile</tspan></text>
      </g>
      <g>
        <rect className="gsap-stack" id="Rectangle 44" y="1231" width="1123" height="189" fill="#fff" />
        <path className="gsap-stack" id="Rectangle 45" d="M0 738H426.5L561.5 795L683.5 738H1123V1231H0V738Z" fill="#fff" />
        <path className="gsap-stack" id="Rectangle 46" d="M0 0H1123V738H680.5L561.5 795L429 738H0V0Z" fill="#fff" />
      </g>
      <g>
        <path className="gsap-stack-line" id="Vector_7" d="M1025.67 1377H1974.86" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
        <path className="gsap-stack-line" id="Vector_5" d="M1153 1231H1975.53" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
        <path className="gsap-stack-line" id="Vector_6" d="M842.668 729H1975.2" stroke={primaryColor} stroke-width="10.3125" stroke-miterlimit="8"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_917_384" x1="550.223" y1="737.971" x2="550.223" y2="1405" gradientUnits="userSpaceOnUse">
          <stop offset="0.7399" stopColor={primaryColor} stop-opacity="0.6"/>
          <stop offset="0.74" stopColor={primaryColor}/>
          <stop offset="1" stopColor={primaryColor}/>
        </linearGradient>
        <clipPath id="clip0_917_384">
          <rect width="2338" height="1420" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
