import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Image1993 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/1993.webp";
import Image1994 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/1994.webp";
import clsx from "clsx";
import { preTheme } from "../../../../../../theme";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
  fitContentHeight: {
    height: "fit-content",
    "& .MuiTypography-body2": {
      height: "20dvh",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      fontSize: "13px",
      [preTheme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
      // 1200px
      [preTheme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
      // 1200px
      [preTheme.breakpoints.up("xxl")]: {
        fontSize: "18px",
      },
      // 1400px
      [preTheme.breakpoints.up("xxxl")]: {
        fontSize: "19px",
      },
    },
  },
  topPosition: {
    justifyContent: "flex-start",
  },
  bottomPosition: {
    justifyContent: "flex-end",
  },
  mbAuto: {
    marginBottom: "auto",
  },
}));

export default function First() {
  const classes = useStyles();

  return (
    <Slide padding={{ top: true, bottom: true, left: true }}>
      <Box display="flex" height="100%" alignItems="center">
        <Grid
          container
          alignItems="center"
          className={classes.fitContentHeight}
        >
          <Grid item xs={6}>
            <Box height="20dvh" display="flex" alignItems="flex-end">
              <QHeader title="Wir sind SSV-Kroschke" />
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.bottomPosition}>
              <span>
                <strong>Umzug in den Neubau</strong> am Ortseingang Schönau.
                Unsere Kunden waren damals Hand- und Heimwerker, aber auch erste
                Industriekunden in der Umgebung.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.mbAuto}>
            <Typography variant="body1">
              und Beschaffungsmanagement ist unsere Welt! Seit über 30 Jahren
              revolutionieren wir das C-Teile-Management bei unseren Kunden und
              schaffen so garantierte Einsparpotentiale. Wir schaffen gemeinsame
              Erfolge und steuern Sie in die digitale Zukunft.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={Image1993} alt="1993" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image1994} alt="1994" className={classes.image} />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.topPosition}>
              <span>
                <strong>Der offizielle Beginn</strong> der Firma SSV-Technik
                erfolgte 1993 durch Ulrich Mönch mit dem Einzug in die ehemalige
                Kantine des Heizwerkes in Schönau. "SSV " steht noch aus
                damaligen Zeiten für "Spezial-, Schweiß- und Verbindungstechnik"
                und wurde seitdem als Name beibehalten.
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}
