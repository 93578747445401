import { h } from "preact";
import { forwardRef } from "preact/compat";
import { useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import zIndex from "@material-ui/core/styles/zIndex";
import { QAppBar } from "../QAppBar";
import { QGalery } from "../QGalery";
import { Grid } from "@material-ui/core";
import "../../style/index.css";//Warum?
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
  },
  imgContainer: {
    height: "90vh",
    width: "90vw",
  },
  imgMax: {
    display: "flex",
    alignItems: "center",
    maxHeight: "80% !important",
    maxWidth: "80% !important",
  },
  imgPreview: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "100%",
  },
  imgSlide: {
    height: "inherit",
    width: "inherit",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export function ImageComponent() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [moving, setMoving] = useState(false);
  const [startAt, setStartAt] = useState(0);
  let align = "center";

  switch (this.props.align) {
    case "left":
      align = "flex-start";
      break;
    case "right":
      align = "flex-end";
      break;
  }

  function handleClickOpen(e) {
    if (!moving) {
      e.stopPropagation();
      e.preventDefault();
      setOpen(true);
    }
  }

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  function handleMoving(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  const cssMovingNone = (e) => {
    e.target.style.pointerEvents = "none";
  };
  const cssMovingAll = (e) => {
    e.target.style.pointerEvents = "all";
  };

  const params = {
    speed: 800,
    loop: false,
    zoom: {
      maxRatio: 5,
    },
  };

  const imageArray = this.props.src
    ? [{ src: this.props.src, alt: this.props.alt ? this.props.alt : "null" }]
    : this.props.images;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align,
        alignItems: align,
        height: this.props.wrapperHeight,
      }}
    >
      <Grid
        container
        spacing={2}
        className={classes.container}
        style={{
          width: this.props.wrapperWidth,
          height: this.props.wrapperHeight,
          position: this.props.wrapperPosition,
          display: this.props.display,
          alignItems: this.props.align,
          justifyContent: this.props.justifyImageContent,
          marginTop: this.props.marginTop,
          zIndex: this.props.zIndex,
        }}
      >
        {imageArray.map((e, i) => {
          return (
            <Grid
              item
              xs={
                this.props.col
                  ? this.props.col
                  : imageArray.length <= 3
                  ? 12
                  : imageArray.length >= 4
                  ? 4
                  : 3
              }
              style={{ height: this.props.wrapperHeight }}
            >
              <img
                onClick={(e) => {
                  setStartAt(i);
                  handleClickOpen(e);
                  setMoving(false);
                }}
                onTouchStart={(e) => {
                  setMoving(false);
                  setStartAt(i);
                }}
                onTouchEnd={(e) => {
                  !moving ? handleClickOpen(e) : setMoving(true);
                }}
                onTouchMove={(e) => {
                  setMoving(true);
                }}
                {...e.props}
                alt={e.alt}
                src={e.src}
                className={clsx(
                  typeof this.props.hotspot === "undefined" ? null : "hotspot",
                  this.props.thumbnailClass
                    ? this.props.thumbnailClass
                    : this.props.className
                    ? this.props.className
                    : classes.imgPreview
                )}
                style={{
                  maxHeight: this.props.previewImgMaxHeight,
                  maxWidth: this.props.previewImgMaxWidth,
                  position: this.props.wrapperPosition,
                  width: "auto",
                  filter:
                    this.props.shadow !== undefined
                      ? "drop-shadow(-5px 5px 10px #0000004D)"
                      : "none",
                }}
              />
            </Grid>
          );
        })}
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <QGalery
            images={imageArray}
            closeHandler={handleClose}
            startAt={startAt}
          />
        </Dialog>
      </Grid>
    </div>
  );
}

ImageComponent.defaultProps = {
  wrapperHeight: "auto",
  wrapperWidth: "auto",
  wrapperPosition: "relative",
};
