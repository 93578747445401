import { useState } from "preact/hooks";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  isLoggedIn,
  writeUserData,
  fetchUserLogout,
  getUser,
} from "../../../redux/modules/userReducer";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import ResetPassword from "../ResetPassword";
import ChangePassword from "../ChangePassword"
import Menu from "..";
import { Box, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { toast } from "playbook-pwa-ui";
import APIService from '../../../api/index'

const useStyles = makeStyles((theme) => ({
  fontWeightBold: {
    fontWeight: 700,
  },
}));

export default function OptionsForConsultation({
  eDialog,
  pdf,
  disabled,
  deleteRemoteConsultation,
  id,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggedin = useSelector((state) => isLoggedIn(state));
  const user = useSelector(getUser);
  const [open, setOpen] = useState(false);
  const [viewResetPassword, setViewResetPassword] = useState(false);
  const [viewChangePassword, setViewChangePassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);
    
    APIService.post(
      'token',
      params,
      "text/plain",
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
      
    ).then(data => {
      setUsername('')
      setPassword('')
      Cookies.set('access_token', data['access_token'])
      Cookies.set('refresh_token', data['refresh_token'])
      APIService.get(
        'api/user',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      )
        .then(userData => {
          dispatch(writeUserData(userData));

          if (userData?.initialPassword) {
            setViewChangePassword(true)
          } else {
            handleClose();
          }
        });
    })
    .catch((error) => {
      toast.error("Ihre Anmeldedaten sind nicht korrekt.")
    });
  };

  const handleLogout = () => {
    dispatch(fetchUserLogout());
  };

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleViewResetPassword = (event) => {
    setViewResetPassword(true);
  };

  return (
    <>
      {isLoggedin && (
        <Box><Typography variant="body2">Hallo {user?.firstname}!</Typography></Box>
      )}
      {isLoggedin ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleLogout}
        >
          Abmelden
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleOpen}
        >
          Anmelden
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullScreen
        PaperProps={{ style: { backgroundColor: grey[100] } }}
      >
        <DialogContent>
          {!viewChangePassword && <Menu absolute closeEvent={handleClose} icon={{ image: "logo" }} />}
          <Box
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="380px">
              <Typography align="center" variant="h3">
                Playbook
              </Typography>
              <Typography
                align="center"
                variant="h4"
                className={classes.fontWeightBold}
                color="primary"
              >
                {viewResetPassword ? "Kennwort vergessen" : "Login"}
              </Typography>
              {viewResetPassword && (
                <ResetPassword setViewResetPassword={setViewResetPassword} />
              )}
              {viewChangePassword && (
                <ChangePassword handleClose={handleClose} setViewChangePassword={setViewChangePassword} />
              )}
              {(!viewResetPassword && !viewChangePassword) && (
                <Box>
                  <form onSubmit={(e) => handleLogin(e)}>
                    <Box marginY="20px" display="grid" gridRowGap="10px">
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={handleChangeUsername}
                        placeholder="Benutzername"
                        name="username"
                        id="username"
                        autoComplete="username"
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={handleChangePassword}
                        placeholder="Kennwort"
                        name="password"
                        id="password"
                        autoComplete="password"
                      />
                    </Box>
                    <Box
                      size="small"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button onClick={handleViewResetPassword}>
                        Kennwort vergessen?
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Anmelden
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
