export default function Calculator({ className }) {
    return (
        <svg className={className} id="uuid-5175a092-ddfc-4790-92b7-2888eed6e0c1" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
          <path d="m263.55,117.53l-189.04,109.86-.13,25.58,151.22,87.31,189.04-109.86.13-25.58-151.22-87.31Zm-111.53,76.65l20.72,11.96-9.57,5.57-11,6.4-20.73-11.96,20.58-11.97Zm54.74-31.81l20.71,11.96-20.57,11.96-20.71-11.96,20.57-11.96Zm29.69,48.59l20.73,11.96-20.59,11.96-20.72-11.96,20.58-11.96Zm3.78-10.46l-4.34,2.53-20.72-11.96,20.57-11.96,20.72,11.96-16.23,9.43Zm-10.92,6.34l-20.57,11.96-20.71-11.96,20.57-11.96,20.71,11.96Zm-48.3,4.08l20.71,11.95-4.95,2.88-15.62,9.08-12.97-7.48-7.75-4.47,20.58-11.96Zm27.83,16.08l20.72,11.96-10.88,6.32-9.69,5.64-20.72-11.96,20.57-11.96Zm27.61,16.21l20.71,11.96-20.57,11.89h-.01l-20.71-11.96,20.58-11.89Zm27.68,7.76l-20.72-11.95,20.58-11.89,20.72,11.96-16.45,9.49-4.13,2.39Zm-.4-31.87l-3.4-1.96-17.32-9.99,20.58-11.97,20.72,11.96-16.05,9.32-4.53,2.64Zm-83.97-17.04l-14.18-8.18-6.54-3.78,20.58-11.96,20.71,11.96-20.57,11.96Zm91.04,21.12l20.57-11.95,20.71,11.94-20.57,11.97-20.71-11.96Zm-146.15,10.74h-.03s-.05-.04-.05-.04l-6-3.46-14.49-8.37,20.57-11.95,20.71,11.95-4.76,2.77-15.81,9.19-.14-.09Zm23.87,13.79l-15.46-8.92,20.57-11.96,20.72,11.96-.41.23-20.16,11.72-5.26-3.03Zm33.1,19.11l-20.71-11.96,20.56-11.96,20.71,11.96-5.41,3.15-11.18,6.5-3.97,2.31Zm27.66-7.84l20.71,11.96-12.1,6.98-8.49,4.9-12.63-7.29-8.08-4.67,20.59-11.88Zm27.55,42.24l-22.69-13.1,77.28-44.91,22.69,13.1-77.28,44.91Zm82.11-47.96l-.22-.13-22.47-12.97,22.55-13.1,22.68,13.09-22.54,13.11Zm-76.75-90.9l-15.71-9.07,24.24-14.1,12.04,6.92,12.58,7.26,10.71,6.19,95.41,55.08-24.28,14.11-71.13-41.07-10.71-6.18-6.27-3.62-6.31-3.65-20.57-11.87Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="203.69 221.73 203.69 221.74 196.77 225.76 196.77 225.75 201.72 222.87 203.69 221.73" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="204.15 253.72 196.77 258.02 196.77 258.01 202.18 254.86 204.15 253.72" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="217.89 277.92 209.4 282.85 196.77 275.56 196.77 275.53 209.4 282.82 217.89 277.92" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="268.26 216.46 268.26 216.47 263.73 219.1 268.26 216.46" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="220.18" y="218.17" width="0" height="26.19" transform="translate(-87.09 144.39) rotate(-30.62)" style="fill: #e4032e; stroke-width: 0px;"/>
          <rect x="266.12" y="223.62" width="0" height="4.92" transform="translate(-62.72 343.54) rotate(-60.01)" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="236.58 267.06 231.92 269.77 209.28 256.7 196.77 263.92 213.9 253.96 215.87 255.1 236.58 267.06" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="268.26 248.58 268.26 248.65 259.07 253.99 236.45 240.94 215.27 253.17 241.51 237.92 243.41 239.02 264.13 250.97 268.26 248.58" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="218.67 245.28 230.62 238.34 229.55 238.96 218.67 245.28" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="224.71 215.49 220.75 217.8 224.71 215.49 224.71 215.49" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="222.45 150.97 222.45 150.97 222.45 150.97 222.45 150.97" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="229.45 173.19 206.76 160.09 229.45 173.19 229.45 173.19" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="258.43 189.92 235.74 176.83 258.43 189.92 258.43 189.92" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="124.57 233.88 124.57 233.88 124.64 233.92 124.61 233.91 124.57 233.88" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="121.1 231.88 118.57 230.42 124.57 233.88 124.57 233.88 121.1 231.88" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="280.15 184.28 357.17 228.75 286.04 187.68 280.15 184.28" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="251.48 167.73 269.07 177.88 262.76 174.23 251.48 167.73" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="286.68 237.95 271.83 229.38 286.68 237.95 286.68 237.95" style="fill: #e4032e; stroke-width: 0px;"/>
          <path d="m314.1,253.78l-22.68-13.1-79.48,46.19,24.87,14.37,77.3-44.93-77.28,44.91-22.69-13.1,77.28-44.91,22.69,13.1,2.18-1.26-2.19-1.27Z" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="147.33 220.91 145.91 221.74 145.36 222.05 124.65 210.1 104.08 222.05 102.11 220.91 124.65 207.81 140.11 216.74 147.33 220.91" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="229.45 173.19 227.47 174.33 206.76 162.37 186.19 174.33 184.22 173.19 206.76 160.09 229.45 173.19" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="202.3 188.96 200.33 190.1 179.62 178.14 159.04 190.1 157.08 188.97 171.86 180.38 179.62 175.86 202.3 188.96" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="258.43 189.93 258.34 189.98 256.46 191.07 235.74 179.11 215.17 191.07 213.62 190.17 213.2 189.93 235.74 176.83 235.75 176.83 258.43 189.93" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="174.71 205 172.74 206.14 152.02 194.18 131.44 206.15 129.48 205.01 152.02 191.91 160.61 196.87 174.71 205" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="286.28 205.99 286.28 206 286.27 206 284.31 207.14 263.59 195.18 243.01 207.15 241.04 206.01 241.05 206 247.49 202.26 247.9 202.02 248.58 201.63 263.59 192.9 286.28 205.99" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="259.14 221.78 257.18 222.92 236.45 210.96 215.87 222.92 213.9 221.78 220.75 217.8 224.72 215.49 236.45 208.68 248.26 215.49 257.84 221.03 259.14 221.78" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="203.69 221.73 201.72 222.87 181.01 210.92 160.43 222.88 158.46 221.74 171.63 214.08 181.01 208.63 203.69 221.73" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="176.31 237.65 174.35 238.79 153.63 226.83 133.06 238.79 131.09 237.65 131.09 237.64 153.63 224.55 176.31 237.65" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="231.53 237.81 230.63 238.34 229.56 238.96 208.84 227 188.27 238.96 187.1 238.28 186.3 237.81 208.84 224.71 208.85 224.72 231.53 237.81" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="204.15 253.72 202.18 254.86 181.47 242.9 160.91 254.86 158.94 253.72 181.47 240.62 204.15 253.72" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="286.68 237.95 284.71 239.09 263.99 227.13 243.41 239.02 241.51 237.92 241.44 237.88 263.99 224.85 264 224.85 268.26 227.31 274.39 230.85 286.68 237.95" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="259.14 254.03 257.16 255.17 236.45 243.21 215.87 255.1 213.9 253.96 215.54 253.01 236.45 240.94 259.07 253.99 259.14 254.03" style="fill: #e4032e; stroke-width: 0px;"/>
          <polygon points="231.97 269.8 229.99 270.94 209.28 258.98 188.69 270.86 186.75 269.74 186.71 269.72 196.66 263.98 196.77 263.92 209.28 256.7 231.92 269.77 231.97 269.8" style="fill: #e4032e; stroke-width: 0px;"/>
        </svg>
    );
  }
  