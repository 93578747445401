import { useEffect, useState } from "preact/hooks";
import { forwardRef } from "preact/compat";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  Slide,
  makeStyles,
  AppBar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Toolbar,
} from "@material-ui/core";

import { useModal } from "mui-modal-provider";

import CloseIcon from "@material-ui/icons/Close";

import { fetchUserLogout, isLoggedIn } from "../../redux/modules/userReducer";

import Beratungsdialog from "../Beratungsdialog";

import ConsultingIcon from "../SvgIcons/Consulting";
import MenuIcon from "../SvgIcons/Menu";
import KroschkeLogoRGB from "../SvgIcons/KroschkeLogoRGB";
import BackgroundElement from "../BackgroundElement";
import { getActiveChapter } from "../../redux/modules/backendState";
import { grey } from "@material-ui/core/colors";
import { route } from "preact-router";
import FilledArrowLeft from "../SvgIcons/FilledArrowLeft";
import ArrowIconLeft from "../SvgIcons/ArrowIconLeft";
import Account from "./Account";
import SmallKroschkeLogoRGB from "../SvgIcons/SmallKroschkeLogoRGB";
import { moveTo, sectionStart } from "playbook-pwa-ui";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  cardCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  responsiveLogo: {
    padding: "16px",
    transform: "translateX(-16px)",
    backgroundColor: grey["100"],
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  smallerFontSize: {
    fontSize: "16px",
  },
  chapterActive: {
    border: `3px solid ${theme.palette.primary.main} !important`,
  },
  pointer: {
    cursor: "pointer",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export default function Menu({ responsive, absolute, closeEvent, icon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [menuOpen, setMenuOpen] = useState(false);
  const isLoggedin = useSelector((state) => isLoggedIn(state));
  const activeChapter = useSelector((state) => getActiveChapter(state));

  const handleClose = () => {
    setMenuOpen(false);
  };

  const openConsultationDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(Beratungsdialog, {
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(fetchUserLogout());
    handleClose();
  };

  const toggleMenu = () => {
    setMenuOpen((v) => !v);
  };

  const routeToChapter = (path) => {
    route(`/${path}`, true);
  };

  const renderData = activeChapter.map((v, i) => (
    <Grid item xs={4} md={3}>
      <Card
        sx={{ maxWidth: 345, boxShadow: "0px 5px 8px 5px rgba(0, 0, 0, 0.05)" }}
        variant="outlined"
        square
        className={
          v.chapter.path === window.location.pathname.replace("/", "")
            ? classes.chapterActive
            : null
        }
      >
        <CardActionArea onClick={() => routeToChapter(v.chapter.path)}>
          <CardMedia
            component="img"
            alt={v.chapter.name}
            height="130"
            image={`../../assets/_Chapters/_Images/${v.chapter.chapterImage.img}`}
          />
          <CardContent className={classes.cardCenter}>
            <Typography
              variant="h6"
              align="center"
              color="primary"
              className={classes.smallerFontSize}
            >
              {v.chapter.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  ));

  const routeToIndex = () => {
    route("/", true);
  };

  const moveToIntroSlide = () => {
    moveTo(1, 1);
  };

  return (
    <div>
      <AppBar
        position={absolute ? "absolute" : "fixed"}
        color="transparent"
        style={{ zIndex: 1000 }}
      >
        <Toolbar>
          {icon?.image !== "back-arrow" || menuOpen ? (
            <Box
              className={clsx(
                responsive ? (menuOpen ? null : classes.responsiveLogo) : null,
                classes.pointer
              )}
            >
              <Box
                display="grid"
                gridColumnGap="10px"
                gridAutoFlow="column"
                alignItems="center"
              >
                {icon?.image === "logo" ? (
                  <KroschkeLogoRGB onClick={routeToIndex} />
                ) : (
                  icon?.image !== "none" && (
                    <>
                      <SmallKroschkeLogoRGB onClick={routeToIndex} />
                      <Box onClick={moveToIntroSlide}>
                        <Typography variant="body2">{icon?.text}</Typography>
                      </Box>
                    </>
                  )
                )}
              </Box>
            </Box>
          ) : icon?.image === "back-arrow" ? (
            <Box
              display="grid"
              gridColumnGap="10px"
              gridAutoFlow="column"
              alignItems="center"
              onClick={sectionStart}
            >
              <IconButton
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ backgroundColor: icon?.background || "white" }}
              >
                <ArrowIconLeft width="1.4em" height="1.4em" />
              </IconButton>
              <Typography variant="body2" className={classes.pointer}>
                {icon.text}
              </Typography>
            </Box>
          ) : (
            <Box />
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{ gap: "15px" }}
            marginLeft="auto"
            marginRight="10px"
          >
            {menuOpen ? <Account /> : <></>}
          </Box>
          <IconButton
            onClick={closeEvent || toggleMenu}
            size="medium"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            style={{ backgroundColor: icon?.background || "white" }}
          >
            {menuOpen || closeEvent ? (
              <CloseIcon width="1.4em" height="1.4em" />
            ) : (
              <MenuIcon width="1.4em" height="1.4em" />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      {!absolute && <Toolbar />}
      <Dialog
        fullScreen
        open={menuOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
        style={{ zIndex: 900 }}
      >
        <BackgroundElement line={{ color: "secondary" }} />
        <Toolbar />
        <Box zIndex={5000}>
          <Container>
            <Box
              sx={{
                overflowY: "scroll",
                maxHeight: "calc(100dvh - (90px + 20px * 2))",
                padding: "20px 20px 20px 0px",
              }}
            >
              <Grid container spacing={2}>
                {renderData}
              </Grid>
            </Box>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}
