
		import Async from "../../../../../node_modules/.pnpm/@preact+async-loader@3.0.2_preact@10.5.10/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../../../node_modules/.pnpm/babel-loader@8.2.2_@babel+core@7.22.10_webpack@4.46.0/node_modules/babel-loader/lib/index.js!../../../../../node_modules/.pnpm/babel-loader@8.2.2_@babel+core@7.22.10_webpack@4.46.0/node_modules/babel-loader/lib/index.js??ref--4!./index.js");
				typeof cb === 'function' && cb(result);
			}, "route-CustomerView");
		}

		export default Async(load);
	