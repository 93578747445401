const SET_CUSTOMERS = "playbook/customers/SET_CUSTOMERS";
const ADD_CUSTOMER = "playbook/customers/ADD_CUSTOMER";

const initialState = {
  customers: [],
  fetchDate: null,
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS: {
      return {
        ...state,
        customers: action.customers,
        fetchDate: new Date(),
      };
    }
    case ADD_CUSTOMER: {
      return {
        ...state,
        customers: [...state.customers, action.customer],
      };
    }
    default:
      return state;
  }
};

//actions
export const setCustomers = (customers) => ({
  type: SET_CUSTOMERS,
  customers,
});

export const addCustomer = (customer) => ({
  type: ADD_CUSTOMER,
  customer,
});

//selector
export const getCustomers = (state) => state.customers.customers;

export default customers;
