import { Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import WorkLifeWaage from "../../../../../../components/SvgIcons/KroschkeIcons/WorkLifeWaage";

const useStyles = makeStyles((theme) => ({
  textWrap: {
    hyphens: "auto",
    textWrap: "balance",
  },
}));
export default function WorkLifeBalance() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={7} className={classes.textWrap}>
          <QHeader title="Work & Life in Balance" />
          <QList
            items={[
              "Flexibles und familienfreundliches Arbeiten",
              "Familiäres Umfeld",
              "Mobiles Arbeiten",
              "Zuschüsse zu Kinderbetreuungskosten",
              "30 Tage Urlaub",
            ]}
          />
        </Grid>
        <Grid item xs={4}><WorkLifeWaage style={{ height: "85vh", width: "85%" }} /></Grid>
      </Grid>
    </Slide>
  );
}
