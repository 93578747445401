import { gsap } from "playbook-pwa-ui";
import { useEffect } from "preact/hooks";


export default function PfeileSVG (props) {
    return (
        <svg {...props} width="1793" height="3225" viewBox="0 0 1793 3225" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_45_81)">
                <path className="gsap-arrow-marathon" d="M1262.81 1928.68L1098.99 2212.42H1169.5V3223H1354.5V2212.42H1426.62L1262.81 1928.68Z" fill="#4A4A49"/>
                <path className="gsap-arrow-marathon" d="M1629.13 2246.77L1465.32 2530.51H1535V3224.5H1723V2530.51H1792.95L1629.13 2246.77Z" fill="#4A4A49"/>
                <path className="gsap-arrow-marathon" d="M530.15 1706.74L366.33 1990.47H436V3224.5H624V1990.47H693.96L530.15 1706.74Z" fill="#4A4A49"/>
                <path className="gsap-arrow-marathon" d="M163.82 2419.85L0 2703.59H70V3224.5H257.629V2703.59H327.64L163.82 2419.85Z" fill="#4A4A49"/>
                <path className="gsap-arrow-marathon" d="M896.48 0L732.66 283.74H802.676V3225H990.5V283.74H1060.29L896.48 0Z" fill="#E4032E"/>
            </g>
            <defs>
                <clipPath id="clip0_45_81">
                    <rect width="1793" height="3225" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}