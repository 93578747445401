import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Revolution(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
    <mask id="mask0_896_362" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="84" y="45" width="583" height="630">
    <path d="M666.461 45H84V674.571H666.461V45Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_362)">
    <mask id="mask1_896_362" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="88" y="49" width="579" height="622">
    <path d="M666.46 49.2773H88.2822V670.283H666.46V49.2773Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_362)">
    <mask id="mask2_896_362" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="88" y="49" width="579" height="622">
    <path d="M666.46 49.2734H88.2822V670.279H666.46V49.2734Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_362)">
    <mask id="mask3_896_362" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="88" y="49" width="579" height="622">
    <path d="M666.46 49.2715H88.2822V670.277H666.46V49.2715Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_896_362)">
    <path d="M99.7985 121.214C107.468 119.081 114.858 121.624 116.297 126.904L261.883 655.623C263.323 660.882 258.296 666.872 250.626 669.007C242.957 671.143 235.567 668.599 234.128 663.319L88.5417 134.576C87.1024 129.318 92.1293 123.327 99.7985 121.193V121.214ZM590.695 134.447L453.12 172.571L502.272 351.06C506.31 365.715 505.365 385.995 478.018 399.356C468.91 403.796 409.597 432.33 408.952 432.697C394.946 440.326 393.313 451.64 395.59 459.851C400.574 477.954 415.096 479.248 429.124 475.368L666.462 409.593L590.716 134.447H590.695ZM370.456 370.564L386.095 427.373C388.093 422.869 391.916 418.494 398.49 414.917C399.135 414.572 458.447 386.038 467.556 381.577C472.497 379.163 488.48 371.9 484.14 356.06C481.885 347.913 473.313 342.052 458.856 346.038L370.477 370.521L370.456 370.564ZM402.915 61.1292C400.896 53.8233 393.077 46.8407 377.631 51.1078L135.567 118.197L211.314 393.344L453.377 326.254C461.627 323.97 469.146 323.884 475.676 325.414L402.915 61.1292Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
