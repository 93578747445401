import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import { forwardRef } from "preact/compat";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PauseIcon from "@material-ui/icons/Pause";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import StopIcon from "@material-ui/icons/Stop";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Tab,
  Tabs,
  Badge,
  Fab,
  IconButton,
  Container,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/modules/userReducer";
import { getUnreadAmount } from "../../redux/modules/newsReducer";
import { useModal } from "mui-modal-provider";

import BeratungTable from "./BeratungTable";
import Beratungsform from "./BeratungsForm";
import Profil from "./Profil";
import ConsultingPage from "./ConsultingDialog";
import BeratungsAbschluss from "./BeratungsAbschluss";
import SearchIcon from "../SvgIcons/SearchIcon";

import {
  getChapters,
  getConsultingData,
  getConsultingRunning,
} from "../../redux/modules/consulting";
import {
  getConsultationStoreAmount,
  setOfflineConsultation,
} from "../../redux/modules/consultationStore";
import { getSetupData } from "../../redux/modules/setupReducer";
import { resetConsulting } from "../../redux/modules/reducers";
import { QDialog } from "playbook-pwa-ui";
import { getCustomerData } from "../../redux/modules/customer";

import SpeakBubbles from "../SvgIcons/SpeakBubbles";
import { getAllChapters } from "../../redux/modules/backendState";
import BeratungsForm from "./BeratungsForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dialog-tab-${index}`}
      aria-labelledby={`dialog-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `dialog-tab-${index}`,
    "aria-controls": `dialog-tab-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  fab: {
    "& > *": {
      margin: theme.spacing(1),
    },
    position: "fixed",
    bottom: "25px",
    right: "25px",
    zIndex: "20",
  },
  container: {
    overflow: "scroll",
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
  },
  alignLeft: {
    marginLeft: "auto",
  },
  tabButtons: {
    minWidth: "0",
    color: "black",
    fontFamily: "Averta-Bold",
  },
  dialogPaper: {},
  tabPadding: {
    padding: "1rem",
    height: "calc(100dvh - 90px - 2rem)",
  },
  appBar: {
    backgroundColor: "var(--grey-20)",
  },
  badge: {
    backgroundColor: "#007a33",
  },
  cardCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginBottom: {
    marginBottom: "6px",
  },
  hiddenLabel: {
    color: theme.palette.secondary.contrastText,
    "& .MuiFilledInput-multiline": {
      padding: "16px",
    },
  },
  smallSearch: {
    fontSize: "18px",
    height: "50px",
  },
  marginTop: {
    marginTop: "40px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  fontBold: {
    fontWeight: "700",
  },
  fontRegular: {
    fontWeight: "400",
  },
  widthButton: {
    minWidth: "150px",
  },
  iconBackground: {
    backgroundImage: "url('../../assets/_defaultAssets/iconwall-grey.svg')",
    height: "100dvh",
    backgroundSize: "cover",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BeratungPageDialog({ onConfirm, onCancel, ...props }) {
  const consulting = useSelector((state) => getConsultingData(state));
  const customer = useSelector((state) => getCustomerData(state));
  const [open, setOpen] = useState(false);
  const chapters = useSelector((state) => getAllChapters(state));
  const toggle = () => {
    setOpen((v) => !v);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const [tabIndex, setTabIndex] = useState("consultations");
  const [consultingTab, setConsultingTab] = useState(0);
  const [searchString, setSearchString] = useState("");

  const loggedIn = useSelector((state) => isLoggedIn(state));
  const unreadAmount = useSelector((state) => getUnreadAmount(state));
  const consultingRunning = useSelector((state) => getConsultingRunning(state));
  const storedConsultationsAmount = useSelector((state) =>
    getConsultationStoreAmount(state)
  );
  const customerData = useSelector((state) => getCustomerData(state));

  useEffect(() => {
    //Fallback zur Übersichtseite wenn keine Beratung läuft er aber im falschen Tab ist.
    if (!consultingRunning && consultingTab === 2) {
      setConsultingTab(0);
    }
  }, [consultingRunning, consultingTab, setConsultingTab]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const setTab = (value) => {
    setTabIndex(value);
  };

  const pauseConsulting = () => {
    dispatch(setOfflineConsultation());
    dispatch(resetConsulting());
    setTab("consultations");
    setConsultingTab(0);
  };

  const endConsulting = () => {
    setTab("consultingFinish");
  };

  const handleSetSearchString = (e) => {
    setSearchString(e.target.value);
  };

  const openSetupPage = (e, customer) => {
    if (customer) {
      const d = showModal(QDialog, {
        children: (
          <Box width="100vw" height="100vh">
            <Box p={2}>
              <BeratungsForm
                handleDialogClose={() => d.destroy()}
                updateCustomer
              />
            </Box>
          </Box>
        ),
        onConfirm: () => {
          d.destroy();
        },
        onCancel: () => {
          d.destroy();
        },
      });
    } else {
      const d = showModal(QDialog, {
        children: (
          <Box width="100vw" height="100vh">
            <Box p={2}>
              <SetupPage />
            </Box>
          </Box>
        ),
        onConfirm: () => {
          d.destroy();
        },
        onCancel: () => {
          d.destroy();
        },
      });
    }
  };
  
  useEffect(() => {
    setTabIndex("consultations");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!loggedIn) {
    return <></>;
  }

  return (
    <>
      <Fab
        onClick={toggle}
        color="primary"
        style={{
          zIndex: 1000,
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
      >
        <SpeakBubbles height="24px" width="24px" />
      </Fab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <>
          <AppBar position="fixed" color="primary.contrastText">
            <Toolbar>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{ style: { height: "4px" } }}
              >
                <Tab
                  value="consultations"
                  wrapped={consultingRunning}
                  label={
                    consultingRunning
                      ? `Gespräch mit ${customer?.firstname} ${customer?.lastname}`
                      : "Beratungen"
                  }
                  onClick={(e) => {
                    if (tabIndex === "consultations" && consultingRunning) {
                      openSetupPage(e, customer);
                    }
                  }}
                />
                <Tab value="profil" label="Profil" />
              </Tabs>
              <Box display="flex" alignItems="center" sx={{ gap: "15px" }}>
                {tabIndex === "consultations" && consultingTab === 0 && !consultingRunning && (
                  <TextField
                    variant="filled"
                    placeholder="Suche"
                    size="small"
                    hiddenLabel
                    className={classes.hiddenLabel}
                    value={searchString}
                    onChange={handleSetSearchString}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.smallSearch,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <IconButton
                  onClick={toggle}
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <CloseIcon width="1.4em" height="1.4em" />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Toolbar />
          <Box bgcolor={grey[100]} height="calc(100dvh - 90px)">
            <TabPanel
              value={tabIndex}
              index="consultations"
              dir={theme.direction}
              className={classes.tabPadding}
            >
              <Container>
                {consultingTab === 0 && !consultingRunning ? (
                  <BeratungTable
                    searchString={searchString}
                    setTab={setTab}
                    setConsultingTab={setConsultingTab}
                    handleDialogClose={handleClose}
                    openSetupPage={openSetupPage}
                  />
                ) : consultingTab === 1 && !consultingRunning ? (
                  <Beratungsform
                    setTab={setTab}
                    handleDialogClose={handleClose}
                    setConsultingTab={setConsultingTab}
                  />
                ) : (
                  consultingRunning && (
                    <ConsultingPage
                      closeConsultingDialog={handleClose}
                      pauseConsulting={pauseConsulting}
                      endConsulting={endConsulting}
                      setTab={setTab}
                    />
                  )
                )}
              </Container>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index="profil"
              dir={theme.direction}
              className={classes.tabPadding}
            >
              <Container>
                <Profil setTab={setTab} handleDialogClose={handleClose} />
              </Container>
            </TabPanel>
          </Box>
          <TabPanel
            value={tabIndex}
            index="consultingFinish"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <BeratungsAbschluss
              handleDialogClose={handleClose}
              setConsultingTab={setConsultingTab}
              setTab={setTab}
            />
          </TabPanel>
        </>
      </Dialog>
    </>
  );
}
