import {combineReducers} from 'redux'
import news from './newsReducer'
import consultingOld from './consultingReducer'
import consulting from './consulting'
import setup from './setupReducer'
import settings from './settingsReducer'
import user from './userReducer'
import consultationStore from './consultationStore'
import chapterSelections from './chapterSelections'
import backendState from './backendState'
import fieldValues from './fieldValuesReducer'
import customer from './customer'
import consultant from './consultant'
import companies from './companiesReducer'
import customers from './customersReducer'

const RESET_CONSULTING = "playbook/rootReducer/RESET_CONSULTING";
const RESET_APP = "playbook/rootReducer/RESET_APP";
const LOAD_CONSULTATION = "playbook/rootReducer/LOAD_CONSULTATION";

const reducers = {
  news,
  consultingOld,
  setup,
  settings,
  user,
  consultationStore,
  chapterSelections,
  // MYSQL-DB Update
  consulting,
  customer,
  consultant,
  backendState,
  fieldValues,
  companies,
  customers,
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_CONSULTING) {
    const { settings, categories, user, consultationStore, backendState, companies, customers } = state;
    state = { settings, categories, user, consultationStore, backendState, companies, customers };
  }

  if (action.type === RESET_APP) {
    state = undefined;
  }

  if (action.type === LOAD_CONSULTATION) {
    const { consultationStore, user, settings, categories, backendState } = state;
    let consultationStoreNew = {
      consultations: [],
    };
    consultationStoreNew.consultations = consultationStore.consultations.filter(
      (c) =>
        c.consulting.internalID !== action.consultation.consulting.internalID
    );

    state = {
      ...action.consultation,
      consultationStore: consultationStoreNew,
      user,
      settings,
      categories,
      backendState
    };
  }

  return appReducer(state, action);
};

export const resetConsulting = () => ({
  type: RESET_CONSULTING,
});

export const resetApp = () => ({
  type: RESET_APP,
});

export const loadConsultation = (consultation) => ({
  type: LOAD_CONSULTATION,
  consultation,
});

export default rootReducer;
