import Beratungsdialog from "../../../components/Beratungsdialog";
import ChapterWrapper from "../../../components/ChapterWrapper";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading"

import { Helmet } from "react-helmet";
import { getChapterByID, getChapterByKey } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";
import Allgemeines from "./sections/Allgemeines";
import Azubis from "./sections/Azubis";
import Berufserfahrene from "./sections/Berufserfahrene";


// Sections

export default function Karriere() {
  const chapterData = useSelector((state) => getChapterByKey(state, "karriere"));
  
  if (!chapterData) {
    return <ChapterLoading />
  }
  
  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;
  const chapterID = chapterData.chapter.id;

  return (
    <>
      <Helmet>
        <title>SSV-Kroschke Playbook | Karriere</title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <Allgemeines anchor="allgemeines" />
        <Azubis anchor="ausbildung-und-studium" />
        <Berufserfahrene anchor="berufserfahrene" />
      </ChapterWrapper>
    </>
  );
}
