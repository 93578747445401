import { makeStyles } from "@material-ui/core/styles";

import { Grid, Link, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import PlanungSVG from "../_Components/PlanungSVG";
import BeschaffungSVG from "../_Components/BeschaffungSVG";
import MontageSVG from "../_Components/MontageSVG";
import InspektionSVG from "../_Components/InspektionSVG";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function Betriebseinrichtung() {

  return (
    <Slide>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <QHeader
            title="Betriebseinrichtung in besten Händen"
            textAlign="center"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Link href="#beratung-planung-montage/1" color="inherit">
                <PlanungSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Planung
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#beratung-planung-montage/2" color="inherit">
                <BeschaffungSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Beschaffung
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#beratung-planung-montage/3" color="inherit">
                <MontageSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Montage
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#beratung-planung-montage/4" color="inherit">
                <InspektionSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Inspektion
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
