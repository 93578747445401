export default function MeisterSVG (props) {
    return (
      <svg {...props} viewBox="0 0 749 721" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M488.039 204.23V205.436H266.086V204.23C266.381 173.751 275.139 148.896 290.539 130.469C302.297 116.381 317.751 106.31 336.151 100.579L344.106 175.385H405.868L413.983 99.4004C434.124 104.864 450.971 115.39 463.586 130.469C478.987 148.896 487.745 173.751 488.039 204.23Z" fill="#E4032E"/>
        <path d="M467.951 352.797C458.818 382.098 442.801 403.204 423.812 416.113C408.947 426.184 392.368 431.219 375.79 431.219C359.211 431.219 342.659 426.184 327.794 416.113C308.804 403.204 292.788 382.098 283.628 352.797C273.772 341.763 237.963 297.035 265.576 255.681C265.442 254.288 265.308 252.869 265.228 251.503H486.378C486.298 252.869 486.164 254.288 486.03 255.681C513.643 297.035 477.834 341.763 467.951 352.797Z" fill="#E4032E"/>
        <path d="M548.114 475.249L453.435 422.995L446.9 419.406C427.08 437.806 403.511 450.984 375.522 450.85C347.989 450.743 323.67 437.271 304.091 418.844L297.663 422.379L201.886 475.223C148.239 503.345 150.007 589.561 150.007 589.802L150.167 599.096L158.845 602.363C182.173 611.094 207.511 617.522 232.392 622.263V595.989H361.595V635.173C369.469 635.307 374.237 635.253 375.013 635.253C375.79 635.253 380.531 635.307 388.405 635.173V595.989H517.608V622.263C542.489 617.522 567.826 611.094 591.155 602.363L599.833 599.096L599.993 589.802C599.993 589.561 601.761 503.345 548.114 475.249ZM361.595 572.393H239.034L260.756 452.885L302.913 428.325L361.595 514.648V572.393ZM388.405 572.393V514.648L447.087 428.325L489.244 452.885L510.965 572.393H388.405Z" fill="#E4032E"/>
        <path d="M492.782 205.436H259.687C253.326 205.436 248.17 210.592 248.17 216.952V221.05C248.17 227.411 253.326 232.567 259.687 232.567H492.782C499.142 232.567 504.299 227.411 504.299 221.05V216.952C504.299 210.592 499.142 205.436 492.782 205.436Z" fill="#E4032E"/>
        <path d="M391.755 176.51H358.195L349.544 95.4365C348.902 89.3299 353.669 84 359.829 84H390.094C396.228 84 401.022 89.3299 400.379 95.4365L391.728 176.51H391.755Z" fill="#E4032E"/>
      </svg>
    )
  }
  