import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MoreIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 11.995C16.5 10.753 17.508 9.745 18.75 9.745C19.992 9.745 21 10.753 21 11.995C21 13.237 19.992 14.245 18.75 14.245C17.508 14.245 16.5 13.237 16.5 11.995ZM9.75 11.995C9.75 10.753 10.758 9.745 12 9.745C13.242 9.745 14.25 10.753 14.25 11.995C14.25 13.237 13.242 14.245 12 14.245C10.758 14.245 9.75 13.237 9.75 11.995ZM3 11.995C3 10.753 4.008 9.745 5.25 9.745C6.492 9.745 7.5 10.753 7.5 11.995C7.5 13.237 6.492 14.245 5.25 14.245C4.008 14.245 3 13.237 3 11.995Z"
        fill="black"
      />
    </SvgIcon>
  );
}
