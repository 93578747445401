const SET_CUSTOMER_SALUTATION = "playbook/customer/SET_SALUTATION";
const SET_CUSTOMER_FIRSTNAME = "playbook/customer/SET_FIRSTNAME";
const SET_CUSTOMER_LASTNAME = "playbook/customer/SET_LASTNAME";
const SET_CUSTOMER_EMAIL = "playbook/customer/SET_EMAIL";
const SET_CUSTOMER_COMPANY = "playbook/customer/SET_CUSTOMER_COMPANY";

const initialState = {
  salutation: "Herr",
  firstname: "",
  lastname: "",
  email: "",
  companyID: "",
  company: {
    id: "",
    name: "",
    street: "",
    postcode: "",
    city: "",
    logo: "",
  },
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_SALUTATION: {
      return {
        ...state,
        salutation: action.salutation,
      };
    }
    case SET_CUSTOMER_FIRSTNAME: {
      return {
        ...state,
        firstname: action.firstname,
      };
    }
    case SET_CUSTOMER_LASTNAME: {
      return {
        ...state,
        lastname: action.lastname,
      };
    }
    case SET_CUSTOMER_EMAIL: {
      return {
        ...state,
        email: action.email.toLowerCase().trim(),
      };
    }
    case SET_CUSTOMER_COMPANY: {
      return {
        ...state,
        companyID: action.company.id,
        company: {
          ...state.company,
          ...action.company,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setCustomerSalutation = (salutation) => ({
  type: SET_CUSTOMER_SALUTATION,
  salutation,
});

export const setCustomerFirstname = (firstname) => ({
  type: SET_CUSTOMER_FIRSTNAME,
  firstname,
});

export const setCustomerLastname = (lastname) => ({
  type: SET_CUSTOMER_LASTNAME,
  lastname,
});

export const setCustomerEmail = (email) => ({
  type: SET_CUSTOMER_EMAIL,
  email,
});

export const setCustomerCompany = (company) => ({
  type: SET_CUSTOMER_COMPANY,
  company,
});

//selector
export const getCustomerSalutation = (state) => state.customer.salutation;
export const getCustomerFirstname = (state) => state.customer.firstname;
export const getCustomerLastname = (state) => state.customer.lastname;
export const getCustomerEmail = (state) => state.customer.email;
export const getCustomerCompany = (state) => state.customer.company;
export const getCustomerCompanyId = (state) => state.customer.company_id;
export const getCustomerData = (state) => state.customer;

export default customer;
