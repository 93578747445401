import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    marginTop: "1rem",
    marginBottom: ".5rem",
    textAlign: (props) => props.textAlign || "left",
    color: (props) => props.color || "black",
  },
  subTitle: {
    textAlign: (props) => props.textAlign || "left",
    color: (props) => props.color || "black",
  },
}));

export default function QHeader({ title, subTitle, textAlign, color }) {
  const classes = useStyles({ textAlign, color });

  return (
    <>
      <Typography
        variant="h3"
        className={classes.title}
        gutterBottom={!subTitle}
      >
        {title}
      </Typography>
      <Typography variant="h5" className={classes.subTitle} gutterBottom>
        {subTitle}
      </Typography>
    </>
  );
}
