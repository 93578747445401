import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/BeratungPlanungMontage/3.webp";
import { Grid } from "@material-ui/core";

export default function Montage() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Montage – auf uns ist Verlass" />
          <QList
            items={[
              "Effiziente Koordination von Lieferung & Montage",
              "Kurzfristige und flexible Terminvereinbarungen",
              "Fachkundiges SSV-Montageteam bei Ihnen vor Ort",
              "Montage ohne Unterbrechung Ihrer Arbeitsabläufe",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
