import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/3.webp";
import { Box, Grid } from "@material-ui/core";

export default function Korrektionsschutzbrillen() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <Box style={{ hyphens: "auto" }}>
            <QHeader title="Korrektionsschutzbrillen" />
          </Box>
          <QList
            items={[
              "Große Auswahl an hochwertigen Scheibenmaterialien und Fassungen",
              "Fokustypen: Einstärken-, Bifokal-, Gleitsicht- und Bildschirmbrillen",
              "Inklusive: Super Entspiegelung und Kratzfestbeschichtung",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box position="absolute" bottom="15px" right="15px" fontSize="12px">
        Foto: UVEX ARBEITSSCHUTZ GmbH
      </Box>
    </Slide>
  );
}
