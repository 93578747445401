import { h } from "preact";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  typography: {
    fontSize: (props) =>
      props.fontSize == "small"
        ? "clamp(.5rem, 1.4vw, 1.2rem)"
        : "clamp(1.06rem, 1.5vw, 1.5rem)",
    marginLeft: (props) => props.marginLeft,
    marginRight: (props) => props.marginRight,
    marginTop: (props) => props.marginTop,
    marginBottom: (props) => props.marginBottom,
    fontFamily: (props) =>
      props.fontWeight == "bold" ? "Averta-Bold" : "Averta-Regular",
    color: (props) => props.color,
    lineHeight: (props) => props.lineHeight,
    paddingTop: (props) => props.paddingTop,
    maxWidth: (props) => props.maxWidth,
  },
});

export function QBodyText({
  children,
  fontSize,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  fontWeight,
  color,
  lineHeight,
  maxWidth,
}) {
  const classes = useStyles({
    fontSize,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    fontWeight,
    color,
    lineHeight,
    maxWidth,
  });
  return (
    <Typography className={classes.typography} paragraph>
      {children}
    </Typography>
  );
}

QBodyText.defaultProps = {
  children: "",
  marginTop: "0px",
  marginBottom: "16px",
  color: "#000",
};
