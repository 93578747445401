export default function UmsatzSVG({className}) {
    return (
      <svg className={className} id="uuid-5c3dcbd9-0cfd-4992-9814-fa497e8243d1" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
        <path d="m252.15,253.56c-6.36,7.64-12.85,11.59-22.09,13.4-7.93,1.56-15.23-.05-20.54-4.52-3-2.52-5.23-5.81-6.6-9.62-4.62.75-9.23,1.43-13.85,2.03,1.25-2.49,2.5-4.98,3.75-7.48,2.88-.4,5.76-.84,8.64-1.29-.08-.89-.12-1.8-.12-2.72v-.17c0-.61.02-1.22.05-1.82-2.57.41-5.14.8-7.7,1.16,1.25-2.5,2.5-5.01,3.75-7.52,1.73-.26,3.45-.53,5.18-.81,1.32-4.62,3.65-8.99,6.87-12.83,5.38-6.42,12.86-10.83,21.08-12.45,8.88-1.75,15.3-.72,21.19,3.53-2.12,2.86-4.25,5.73-6.37,8.6-4.93-3.19-9.57-4.13-14.9-3.08-7.47,1.47-13.78,7.14-16.72,14.33,7.92-1.43,15.84-2.99,23.76-4.57-1.09,2.54-2.19,5.08-3.28,7.63-7.4,1.46-14.8,2.91-22.2,4.22-.04.55-.06,1.1-.06,1.66v.17c0,.99.06,1.95.18,2.87,6.92-1.23,13.83-2.57,20.75-3.94-1.09,2.54-2.19,5.08-3.28,7.62-5.12,1-10.25,1.99-15.37,2.92,3.09,5.55,9.06,8.39,16.24,6.98,6.54-1.29,10.86-4.56,15.4-9.4,2.09,1.7,4.18,3.4,6.27,5.1Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path d="m131.33,138.31v9.08c84.32,16.21,168.65-37.77,252.97-11.08v135.59c3.13.99,6.27,2.09,9.4,3.32v-144.67c-87.46-34.12-174.91,24.58-262.37,7.76Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path d="m113.88,151.89v9.08c84.32,24.12,168.65-29.99,252.97-11.47v135.59c3.13.69,6.27,1.48,9.4,2.37v-144.67c-87.46-25.02-174.91,34.12-262.37,9.1Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path d="m95.44,164.07v144.67c87.46,34.12,174.91-24.58,262.37-7.76v-144.67c-87.46-16.81-174.91,41.88-262.37,7.76Zm28.28,46.99c-4.15-.68-7.97-3.03-10.75-6.51-1.57-1.96-2.74-4.17-3.45-6.46-2.42-.62-4.83-1.31-7.25-2.06.65-1.01,1.31-2.03,1.96-3.06,1.51.45,3.01.87,4.52,1.26-.04-.48-.06-.97-.06-1.45v-.09c0-.32.01-.63.03-.94-1.34-.35-2.69-.73-4.03-1.12.65-1.02,1.31-2.05,1.96-3.09.9.25,1.81.49,2.71.73.69-2.13,1.91-3.9,3.59-5.22,2.81-2.21,6.73-2.96,11.03-2.27,4.65.75,8.01,2.34,11.09,5.47-1.11,1.17-2.22,2.34-3.33,3.49-2.58-2.48-5.01-3.79-7.8-4.24-3.91-.63-7.21,1.03-8.75,4.17,4.14.91,8.29,1.64,12.43,2.22-.57,1.14-1.15,2.27-1.72,3.4-3.87-.58-7.75-1.3-11.62-2.17-.02.28-.03.57-.03.85v.09c0,.52.03,1.03.09,1.54,3.62.81,7.24,1.49,10.86,2.05-.57,1.13-1.15,2.25-1.72,3.38-2.68-.44-5.36-.95-8.04-1.54,1.62,3.55,4.74,6.27,8.5,6.87,3.42.55,5.68-.42,8.06-2.21,1.09,1.23,2.19,2.45,3.28,3.66-3.33,3.03-6.73,4.02-11.56,3.24Zm102.91,83.53c-30.69,6.23-55.57-16.93-55.57-47.78,0-30.53,24.88-57.14,55.57-63.37,30.69-6.19,55.57,14.56,55.57,45.45,0,30.49-24.88,59.51-55.57,65.7Zm109.53-6.44c-4.15-.22-7.97-1.83-10.75-4.64-1.57-1.59-2.74-3.49-3.45-5.58-2.42.04-4.83.12-7.25.23.65-1.25,1.31-2.49,1.96-3.73,1.51-.06,3.01-.1,4.52-.13-.04-.47-.06-.95-.06-1.43v-.09c0-.32.01-.64.03-.95-1.34.03-2.69.07-4.03.12.65-1.24,1.31-2.48,1.96-3.71.9-.03,1.81-.05,2.71-.06.69-2.32,1.91-4.43,3.59-6.14,2.81-2.87,6.73-4.4,11.03-4.17,4.65.25,8.01,1.79,11.09,4.96-1.11,1.15-2.22,2.32-3.33,3.49-2.58-2.42-5.01-3.59-7.8-3.74-3.91-.21-7.21,2.1-8.75,5.56,4.14.04,8.29.2,12.43.5-.57,1.17-1.14,2.35-1.72,3.53-3.87-.25-7.75-.37-11.62-.39-.02.28-.03.57-.03.86v.09c0,.52.03,1.03.09,1.52,3.62.02,7.24.13,10.86.35-.57,1.18-1.15,2.37-1.72,3.55-2.68-.14-5.36-.22-8.04-.24,1.62,3.21,4.74,5.33,8.5,5.53,3.42.19,5.68-.88,8.06-2.72,1.09,1.21,2.19,2.43,3.28,3.66-3.33,2.97-6.73,4.03-11.56,3.77Z" style="fill: #e4032e; stroke-width: 0px;"/>
      </svg>
    );
  }
  