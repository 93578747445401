import ChapterWrapper from "../../../components/ChapterWrapper";
import ChapterIntro from "../../../components/ChapterIntro";
import ChapterLoading from "../../../components/Utils/ChapterLoading"
import { Helmet } from "react-helmet";
import { getChapterByID } from "playbook-pwa-ui/src/redux/modules/backendState";
import { useSelector } from "react-redux";
import Beratungsdialog from "../../../components/Beratungsdialog";

// Sections
import KlausKroschkeGruppeSection from "./sections/KlausKroschkeGruppeSection";
import Zusammenschluss from "./sections/Zusammenschluss";

export default function KlausKroschkeGruppe() {
  const chapterID = 2;
  const chapterData = useSelector((state) => getChapterByID(state, chapterID));

  if (!chapterData) {
    return <ChapterLoading />
  }

  const chapterBackground = chapterData.chapter.chapterImage.img;
  const chapterName = chapterData.chapter.name;

  return (
    <>
      <Helmet>
        <title>SSV-Kroschke Playbook | Klaus Kroschke Gruppe</title>
      </Helmet>
      <Beratungsdialog />
      <ChapterWrapper chapterData={chapterData} chapterID={chapterID}>
        <ChapterIntro
          anchor="chapter-intro"
          background={chapterBackground}
          id={chapterID}
          name={chapterName}
        />
        <KlausKroschkeGruppeSection anchor="keyfacts" />
        <Zusammenschluss anchor="zusammenschluss" />
      </ChapterWrapper>
    </>
  );
}
