import * as React from "react";
import { Box, Grid, makeStyles, Button } from "@material-ui/core";
import { useModal } from "mui-modal-provider";
import { QDialog }  from "playbook-pwa-ui";
import clsx from "clsx";
import imgElektrospicker from "../../assets/icons/elektrospicker.svg";
import imgPreisbeispiel from "../../assets/icons/preisbeispiel.svg";
import imgPreisliste from "../../assets/icons/preisliste.svg";
import imgLaptop from "../../assets/icons/laptop.svg";
import imgInfo from "../../assets/icons/infoIcon.svg";
import imgRegler from "../../assets/icons/regler.svg";
import imgMicrophone from "../../assets/icons/microphone.svg";
import imgYouTube from "../../assets/icons/youtube.svg";
import imgBestelldaten from "../../assets/icons/bestelldaten.svg";
import imgPDF from "../../assets/icons/pdf.svg";
import imgStromkompass from "../../assets/icons/stromkompass.svg";
import imgKonfiguratoren from "../../assets/icons/konfiguratoren.svg";
import imgKalender from "../../assets/icons/kalender.svg";
import imgSensorDistance from "../../assets/icons/sensor-distance.svg";
import imgGalery from "../../assets/icons/galery.png";
import imgVoice from "../../assets/icons/voice.svg";
import { QGalery } from "../QGalery";

const useStyles = makeStyles(() => ({
  slideWrap: {
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
  },
  slideContent: {
    height: "inherit",
  },
  slidePadding: {
    padding: "3.5rem 1.5rem 0 1.5rem",
  },
  slidePaddingBottom: {
    padding: "3.5rem 1.5rem 3.5rem 1.5rem",
  },
  slidePaddingNoTop: {
    padding: "1.5rem 1.5rem 0 1.5rem",
  },
  actions: {
    width: "fit-content",
    alignItems: "center",
    position: "absolute",
    bottom: "28px",
    "& img": {
      width: "auto",
      maxHeight: "40px",
      maxWidth: "45px",
    },
    "&:hover": {
      cursor: "pointer",
    },
    paddingRight: "2.5rem",
    paddingLeft: "2.5rem",
  },
  childWrap: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    position: "relative",
    height: "inherit",
    "& > div:nth-of-type(1)": {
      height: "90%",
    },
  },
  actionLink: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  logo: {
    position: "absolute",
    bottom: "12px",
    right: "75px",
    zIndex: 200,
  },
}));

const BackgroundBox = ({ settings }) => (
  <Box
    left={settings.alignment === "left" ? "0px" : ""}
    right={settings.alignment === "right" ? "0px" : ""}
    top={settings.top}
    bottom={settings.bottom}
    position="absolute"
    width={settings.width}
    height={settings.height}
    bgcolor={
      settings.backgroundColor !== undefined
        ? settings.backgroundColor
        : "var(--grey-20)"
    }
    style={{
      backgroundSize: settings.backgroundSize,
      backgroundPosition: settings.backgroundPosition,
      backgroundImage:
        settings.src !== "" || settings.src !== null
          ? `url(${settings.src})`
          : null,
      backgroundRepeat: "no-repeat",
      zIndex: settings.zIndex,
      filter:
        settings.brightness !== undefined
          ? "brightness(" + settings.brightness + ")"
          : "brightness(100%)",
    }}
  />
);

export function Slide({
  children,
  actions,
  background,
  noSlideClass,
  disablePadding,
  bottomPadding,
  defaultTopPadding,
  logo,
}) {
  const [newShowModal, setShowModal] = React.useState(false);
  const classes = useStyles();
  const { showModal } = useModal();

  const handleShowModal = (id) => {
    setShowModal(id);
  };
  const handleHideModal = () => {
    setShowModal(false);
  };

  const dialog = (children) => {
    const d = showModal(QDialog, {
      children,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const actionItems = actions.map((action, i) => {
    let id = action.id || "id";
    let item = null;
    let img = null;
    switch (action.img) {
      case "elektrospicker":
        img = imgElektrospicker;
        break;
      case "preisbeispiel":
        img = imgPreisbeispiel;
        break;
      case "preisliste":
        img = imgPreisliste;
        break;
      case "laptop":
        img = imgLaptop;
        break;
      case "info":
        img = imgInfo;
        break;
      case "regler":
        img = imgRegler;
        break;
      case "microphone":
        img = imgMicrophone;
        break;
      case "youtube":
        img = imgYouTube;
        break;
      case "bestelldaten":
        img = imgBestelldaten;
        break;
      case "pdf":
        img = imgPDF;
        break;
      case "stromkompass":
        img = imgStromkompass;
        break;
      case "konfiguratoren":
        img = imgKonfiguratoren;
        break;
      case "qvergleich":
        img = imgKalender;
        break;
      case "distance":
        img = imgSensorDistance;
        break;
      case "galery":
        img = imgGalery;
        break;
      case "audio":
        img = imgVoice;
        break;
      default:
        img = action.imgSrc;
        break;
    }

    switch (action.type) {
      case "button":
        item = action.onClick? (
            <Button
              variant="contained"
              onClick={action.onClick}
              style={
                action.buttonBackground === "white"
                  ? { backgroundColor: "#fff" }
                  : null
              }
            >
              {action.label}
            </Button>) : (
          <a href={action.link} target={action.target}>
            <Button
              variant="contained"
              style={
                action.buttonBackground === "white"
                  ? { backgroundColor: "#fff" }
                  : null
              }
            >
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </Button>
          </a>
        );
        break;
      case "dialogWithButton":
        item = (
          <Button
            variant="contained"
            style={
              action.buttonBackground === "white"
                ? { backgroundColor: "#fff" }
                : null
            }
            onClick={() => dialog(action.children)}
          >
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </Button>
        );
        break;
      case "dialog":
        const children = action.children;
        item = (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={img} onClick={() => dialog(children)} />
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </div>
        );
        break;
      case "galery":
        item = (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={action.img ? img : imgGalery}
                onClick={() => handleShowModal(id)}
              />
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </div>
            <QDialog
              onCancel={handleHideModal}
              open={id === newShowModal}
              children={
                <QGalery
                  images={action.images}
                  closeHandler={handleHideModal}
                />
              }
            />
          </>
        );

        break;
      case "modal":
        item = (
          <div
            onClick={() => {
              const m = showModal(action.children, {
                mode: action.mode,
                ...action.props,
                onConfirm: () => {
                  m.hide();
                },
                onCancel: () => {
                  m.hide();
                },
              });
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={img} style={{ marginRight: "1rem" }} />
            {action.label && (
              <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                {action.label}
              </span>
            )}
          </div>
        );
        break;
      case "icon":
        item =
          action.link == "#" ? (
            <></>
          ) : (
            <a
              href={action.link}
              target={action.target}
              className={classes.actionLink}
            >
              <img src={img} />
              {action.label && (
                <span style={{ paddingTop: "5px", fontSize: "12px" }}>
                  {action.label}
                </span>
              )}
            </a>
          );
        break;
      case "custom":
        item = action.custom;
        break;
    }

    return (
      <Grid item key={`${i}-action`}>
        {item}
      </Grid>
    );
  });

  const backgroundConfig = background
    ? {
        alignment: background.alignment ? background.alignment : "right",
        top: background.alignmentY === "top" ? "0px" : "",
        bottom: background.alignmentY === "bottom" ? "0px" : "",
        backgroundPosition: background.backgroundPosition
          ? background.backgroundPosition
          : "center",
        width: background.width ? background.width : 1 / 2,
        height: background.height ? background.height : 3.575 / 4,
        backgroundSize: background.backgroundSize
          ? background.backgroundSize
          : "cover",
        src: background.src ? background.src : "null",
        zIndex: background.zIndex,
        backgroundColor: background.backgroundColor
      }
    : null;

  return (
    <Box className={clsx(noSlideClass ? "" : "slide", classes.slideWrap)}>
      {background && <BackgroundBox settings={backgroundConfig} />}
      <Box
        className={clsx(
          classes.slideContent,
          disablePadding ? "" : bottomPadding ? classes.slidePaddingBottom : defaultTopPadding ? classes.slidePaddingNoTop : classes.slidePadding
        )}
      >
        <Box className={clsx(disablePadding ? "" : classes.childWrap)}>
          {children}
        </Box>
      </Box>
      {actionItems.length > 0 && (
        <Grid className={classes.actions} container spacing={2}>
          {actionItems}
        </Grid>
      )}
      {logo !== null && <Box className={classes.logo}>{logo}</Box>}
    </Box>
  );
}

Slide.defaultProps = {
  children: null,
  actions: [],
  noSlideClass: false,
  disablePadding: false,
  logo: null,
};
