import { theme } from "../../../../../../../../theme";

export default function ATeileBarSVG2(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
    {...props}
      viewBox="0 0 778 907"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="temp 1" clip-path="url(#clip0_2_10)">
        <text
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          fontFamily={fontFamily}
          fontSize="73"
        >
          <tspan x="100.327" y="70.28">
            80 %
          </tspan>
        </text>
        <rect id="rectangle" y="126" width="389" height="781" fill="#E20031" />
        <rect
          className="gsap-bar"
          x="389"
          y="753"
          width="389"
          height="154"
          fill="#4A4949"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_10">
          <rect width="778" height="907" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
