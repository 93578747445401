export default function FamilienunternehmenSVG({className}) {
  return (
    <svg className={className} id="uuid-8ae73e16-a75e-4175-b123-7bcf59dc5666" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
      <path d="m275.15,127.97l-.3,160.98c17.22,14.83,17.29,42.6,17.24,46.06l-.05,3.45c0,.05,0,.09-.01.14l81.41-11.44.02-8.77.38-204.3-98.69,13.87Zm43.42,184.77l-22.82,3.2.05-24.64,22.82-3.2-.05,24.64Zm.07-38.29l-22.82,3.21.04-24.65,22.83-3.2-.05,24.64Zm.07-38.29l-22.82,3.21.04-24.64,22.83-3.2-.05,24.63Zm33.82,71.8l-22.83,3.21.05-24.65,22.82-3.2-.04,24.64Zm.07-38.28l-22.83,3.2.05-24.65,22.82-3.2-.04,24.65Zm.06-38.29l-22.82,3.2.05-24.63,22.82-3.21-.05,24.64Zm.07-34.55l-56.79,7.98.02-10.32,56.79-7.98-.02,10.32Zm.03-17.9l-56.79,7.98.02-10.33,56.79-7.98-.02,10.33Zm.03-17.9l-56.78,7.98v-10.32s56.8-7.98,56.8-7.98l-.02,10.32Zm.03-17.9l-56.78,7.98.02-10.32,56.78-7.98-.02,10.32Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <polygon points="368.38 108.67 329.37 87.45 234.07 100.85 273.08 122.07 368.38 108.67" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m267.98,127.26l-.29,156.85c-3.82-2.11-24.7-13.62-33.44-18.44,3.12-4.36,5.66-9.36,7.57-14.89,9.49-11.34,15.92-27.81,7.93-42.76.07-1.33.1-2.65.08-3.94-.12-12.03-4.1-22.56-11.51-30.44-3.33-3.55-7.31-6.49-11.8-8.79l.02-60.13,41.44,22.55Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <g>
        <path d="m158.45,210.11c-.21-2.02-.3-4-.28-5.94.11-10.13,3.38-18.39,9.13-24.52,5.69-6.06,13.72-9.91,23.43-11.27,2.79-.39,5.75-.57,8.88-.54h.06s0-.02,0-.02c.12,0,.23,0,.35.02,2.97-.01,5.8.17,8.47.54,9.71,1.36,17.74,5.2,23.43,11.27,5.75,6.13,9.02,14.39,9.13,24.52.02,1.93-.07,3.91-.28,5.94,10.31,15.44-3.06,32.14-6.75,36.26-3.42,10.93-9.39,18.82-16.49,23.63-5.55,3.76-11.74,5.65-17.92,5.65s-12.38-1.88-17.92-5.65c-7.09-4.81-13.07-12.7-16.49-23.63-3.69-4.12-17.05-20.82-6.75-36.26Z" style="fill: #e4032e; stroke-width: 0px;"/>
        <path d="m199.3,351.83c-1.54.02-46.87.41-80.7-12.28l-3.25-1.22-.05-3.47c0-.09-.67-32.29,19.37-42.78h0s35.76-19.74,35.76-19.74l2.4-1.32c7.31,6.88,16.38,11.91,26.66,11.96,10.45.05,19.25-4.87,26.66-11.75l2.44,1.34,35.35,19.5h0c20.03,10.49,19.37,42.69,19.37,42.78l-.05,3.47-3.25,1.22c-33.83,12.69-79.17,12.3-80.7,12.28h0Zm28.06-51c-2.81,0-5.09,2.28-5.09,5.09s2.28,5.09,5.09,5.09h19.78c2.81,0,5.09-2.28,5.09-5.09s-2.28-5.09-5.09-5.09h-19.78Z" style="fill: #e4032e; stroke-width: 0px;"/>
      </g>
    </svg>
  );
}
