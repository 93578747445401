const SET_SELECTION = "playbook/chapterSelections/SET_SELECTION";

export const initialState = {
};

const chapterSelections = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTION: {
      return {
        ...state,
        [action.chapter]: {
          ...state[action.chapter],
          [action.selection]: action.selectionValue,
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setChapterSelection = (chapter, selection, selectionValue) => ({
  type: SET_SELECTION,
  chapter,
  selection,
  selectionValue,
});

//selector
export const getChapterSelection = (state, chapter, selection) =>
  state.chapterSelections[chapter][selection];

export const getChapterSelections = (state) => state.chapterSelections;

export default chapterSelections;
