import { gsap } from "playbook-pwa-ui";
import { useRef } from "preact/hooks";

export default function MitarbeiterSVG({className}) {
  return (
    <svg className={className} id="uuid-0a55d045-9a33-4d2a-a28b-3bb0839ddf2c" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
      <path d="m202.73,168.72c-.21-2.07-.31-4.1-.29-6.08.11-10.37,3.46-18.83,9.35-25.11,5.83-6.21,14.05-10.15,23.99-11.54,2.86-.4,5.89-.59,9.09-.55h.06s0-.02,0-.02c.12,0,.24,0,.36.02,3.05-.01,5.94.17,8.67.56,9.94,1.4,18.16,5.33,23.99,11.54,5.89,6.28,9.24,14.74,9.35,25.11.02,1.98-.07,4.01-.29,6.08,10.56,15.81-3.13,32.91-6.91,37.14-3.5,11.2-9.62,19.28-16.88,24.2-5.68,3.86-12.02,5.78-18.36,5.78s-12.67-1.93-18.36-5.78c-7.26-4.93-13.38-13.01-16.88-24.2-3.78-4.22-17.47-21.33-6.91-37.14Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m244.57,313.86c-1.57.02-48,.42-82.65-12.58l-3.32-1.25-.05-3.55c0-.1-.68-33.07,19.84-43.81h0s36.63-20.21,36.63-20.21l2.46-1.36c7.48,7.05,16.78,12.2,27.31,12.24,10.7.05,19.72-4.99,27.3-12.03l2.49,1.38,36.21,19.97h0c20.52,10.75,19.84,43.72,19.84,43.81l-.05,3.55-3.32,1.25c-34.65,12.99-81.08,12.59-82.65,12.58h0Zm28.74-52.23c-2.88,0-5.21,2.33-5.21,5.21s2.33,5.21,5.21,5.21h20.26c2.88,0,5.21-2.33,5.21-5.21s-2.33-5.21-5.21-5.21h-20.26Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m315.44,184.36c-.12-1.38-.18-2.74-.16-4.07.09-7.91,2.66-14.39,7.18-19.2,4.46-4.75,10.72-7.76,18.27-8.82,2.13-.3,4.4-.44,6.8-.41h.06v-.02c.12,0,.25,0,.37.02,2.24,0,4.37.14,6.37.42,7.55,1.06,13.81,4.07,18.27,8.82,4.52,4.82,7.09,11.29,7.18,19.2.02,1.33-.04,2.69-.16,4.07,7.54,11.9-2.1,24.48-5.22,28.05-2.66,8.31-7.27,14.33-12.73,18.04-4.37,2.97-9.25,4.45-14.13,4.45s-9.76-1.48-14.13-4.45c-5.47-3.71-10.07-9.73-12.73-18.04-3.12-3.57-12.76-16.14-5.22-28.05Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m340.88,291.44c9.23.27,18.85.02,28.3-.87,13.57-1.27,26.83-3.85,38.22-8.06,2.17-.59,3.78-2.56,3.83-4.92l-5.21-.11,5.19.1c.52-25.38-11.14-31.49-27.73-40.18-1.77-.93-3.61-1.89-6.17-3.3-1.94-1.23-4.53-1.07-6.3.55h0c-6.8,6.21-15.21,9.31-23.6,9.28-8.27-.02-16.56-3.09-23.29-9.2-1.59-1.66-4.16-2.12-6.27-.95h0c-1.57.88-3.46,1.85-5.54,2.92-1.47.76-3.03,1.56-4.6,2.39l8.28,4.57c.22.12.44.24.66.36l7.7,5.74c7.21,6.91,11.63,16.34,14.1,26.18,1.19,4.75,2.1,10.25,2.43,15.49Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m109.98,184.36c-.12-1.38-.18-2.74-.16-4.07.09-7.91,2.65-14.39,7.18-19.2,4.46-4.75,10.72-7.76,18.27-8.82,2.13-.3,4.4-.44,6.8-.41h.06v-.02c.12,0,.25,0,.37.02,2.24,0,4.37.14,6.38.42,7.55,1.06,13.81,4.07,18.27,8.82,4.52,4.82,7.09,11.29,7.18,19.2.01,1.33-.04,2.69-.16,4.07,7.54,11.9-2.1,24.48-5.22,28.05-2.66,8.31-7.27,14.33-12.73,18.04-4.37,2.97-9.25,4.45-14.13,4.45s-9.76-1.48-14.13-4.45c-5.47-3.71-10.07-9.73-12.73-18.04-3.12-3.57-12.76-16.14-5.22-28.05Z" style="fill: #e4032e; stroke-width: 0px;"/>
      <path d="m181.42,239.1c-1.51-.8-3.01-1.57-4.42-2.3-1.91-.99-3.67-1.89-5.15-2.7-1.94-1.23-4.53-1.06-6.3.55h0c-6.8,6.21-15.21,9.31-23.6,9.28-8.27-.02-16.56-3.09-23.29-9.2-1.59-1.66-4.16-2.11-6.27-.95h0c-2.73,1.52-4.7,2.55-6.59,3.53-16.52,8.64-28.23,14.77-27.88,39.77-.17,2.25,1.15,4.44,3.37,5.27l1.83-4.88-1.81,4.86c11.47,4.3,24.85,6.93,38.54,8.21,9.49.89,19.14,1.15,28.41.88.33-5.24,1.23-10.74,2.43-15.49,2.47-9.84,6.9-19.27,14.1-26.18l7.7-5.74c.22-.12.43-.24.66-.36l8.28-4.57Z" style="fill: #e4032e; stroke-width: 0px;"/>
    </svg>
  );
}
