import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Woooow(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
        <path d="M396.202 104C367.71 104 308.533 104.739 256.286 122.875C229.066 132.211 205.698 126.536 174.276 113.706C157.284 106.015 135.74 119.381 136.293 138.257C136.293 170.499 153.284 198.729 171.732 213.204C161.873 215.219 149.451 215.959 136.846 210.265C118.951 202.204 98.1256 217.789 99.0471 237.016C100.503 266.336 115.117 293.623 144.162 318.359C141.785 332.261 140.568 346.367 140.513 360.491C140.513 501.937 255.217 617 396.22 617C537.221 617 651.928 501.937 651.928 360.491C654.841 222.725 542.327 104 396.202 104ZM304.885 364.984L262.792 386.596L270.274 339.749L236.788 306.287L283.488 298.966L304.866 256.668L326.244 298.966L372.944 306.287L339.458 339.749L346.94 386.596L304.848 364.984H304.885ZM423.606 452.132C423.606 467.311 411.332 479.623 396.202 479.623C381.071 479.623 368.797 467.311 368.797 452.132V415.491C368.797 400.294 381.071 388 396.202 388C411.332 388 423.606 400.294 423.606 415.491V452.132ZM529.632 386.596L487.538 364.984L445.445 386.596L452.927 339.749L419.441 306.287L466.141 298.966L487.519 256.668L508.899 298.966L555.598 306.287L522.112 339.749L529.593 386.596H529.632Z" fill={primaryColor}/>
    </SvgIcon>
  );
}
