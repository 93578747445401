import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function GesundheitSVG(props) {
  const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} viewBox="0 0 340 352">
      <mask
        id="mask0_896_632"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="340"
        height="352"
      >
        <path d="M340 0H0V352H340V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_896_632)">
        <mask
          id="mask1_896_632"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="340"
          height="352"
        >
          <path d="M340 0H0V352H340V0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_896_632)">
          <mask
            id="mask2_896_632"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="339"
            height="350"
          >
            <path d="M340 1H1V351H340V1Z" fill="white" />
          </mask>
          <g mask="url(#mask2_896_632)">
            <mask
              id="mask3_896_632"
              style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="1"
              y="1"
              width="339"
              height="350"
            >
              <path d="M340 1H1V351H340V1Z" fill="white" />
            </mask>
            <g mask="url(#mask3_896_632)">
              <mask
                id="mask4_896_632"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="339"
                height="350"
              >
                <path d="M340 1H1V351H340V1Z" fill="white" />
              </mask>
              <g mask="url(#mask4_896_632)">
                <path
                  d="M235.959 181.35L218.507 142.417L231.87 123.201L251.355 123.403L270.948 103.258C272.981 101.176 272.933 97.8325 270.841 95.8097C268.76 93.7751 265.419 93.8227 263.398 95.9168L246.956 112.825L226.413 112.623L206.463 141.298L226.353 185.68C229.456 184.015 232.666 182.575 235.959 181.373V181.35Z"
                  fill={primaryColor}
                />
                <path
                  d="M334.683 340.457H307.267L297.875 319.503C294.772 321.169 291.563 322.609 288.269 323.811L295.724 340.457H47.1907L82.1196 290.031C78.6006 289.09 75.2718 287.663 72.2164 285.83L34.3866 340.457H6.31746C3.40474 340.457 1.05078 342.813 1.05078 345.728C1.05078 348.643 3.40474 350.999 6.31746 350.999H334.683C337.595 350.999 339.949 348.643 339.949 345.728C339.949 342.813 337.595 340.457 334.683 340.457Z"
                  fill={primaryColor}
                />
                <path
                  d="M54.3603 152.138C52.9099 149.437 51.7329 146.617 50.8294 143.714L48.1069 143.345C43.7913 142.774 39.9512 146.129 39.9512 150.496C39.9512 156.755 45.0158 161.823 51.2692 161.823H62.1593C58.9612 159.087 56.3576 155.862 54.3603 152.126V152.138Z"
                  fill={primaryColor}
                />
                <path
                  d="M90.7158 172.829C88.3975 172.507 86.1386 172.127 83.963 171.675C81.5971 171.175 79.374 170.592 77.2578 169.914L98.0867 209.346C100.476 206.038 102.759 202.826 104.922 199.732L90.7158 172.829Z"
                  fill={primaryColor}
                />
                <path
                  d="M165.484 220.864C177.409 207.026 195.741 191.296 217.747 181.135L210.031 163.93C191.247 175.019 176.006 190.428 165.972 202.231C160.396 208.799 155.89 214.843 152.561 219.603L165.484 220.876V220.864Z"
                  fill={primaryColor}
                />
                <path
                  d="M261.936 236.094L134.894 223.909C126.928 233.191 118.939 241.877 111.972 249.195C112.151 250.301 112.258 251.432 112.258 252.586C112.258 264.02 102.996 273.277 91.583 273.277C80.1699 273.277 70.9086 264.008 70.9086 252.586C70.9086 248.052 72.3709 243.852 74.8438 240.449C74.8438 240.449 77.2096 236.974 80.1224 233.262C83.6414 228.765 87.0534 224.291 90.3347 219.912C73.2864 220.804 59.4004 234.94 59.4004 252.586C59.4004 271.921 76.0683 287.044 95.2923 285.152L261.936 269.077C270.413 268.245 276.868 261.117 276.868 252.586C276.868 244.066 270.401 236.927 261.936 236.094ZM260.01 261.105C255.314 261.105 251.498 257.297 251.498 252.586C251.498 247.874 255.302 244.066 260.01 244.066C264.718 244.066 268.522 247.874 268.522 252.586C268.522 257.297 264.718 261.105 260.01 261.105Z"
                  fill={primaryColor}
                />
                <path
                  d="M170.392 101.795C167.159 90.3364 173.495 74.2138 180.985 63.9095C172.604 62.2437 165.506 57.639 160.917 50.7377C157.529 45.6451 155.817 39.7553 155.852 33.5918C137.496 35.0196 118.439 41.4806 96.29 59.8283C75.8653 76.7601 42.1015 116.668 59.6492 149.271C70.3965 169.237 99.393 168.142 131.873 168.546C129.614 174.246 109.475 205.515 84.8531 236.963C80.8942 242.009 82.0712 249.362 87.3973 252.932C91.808 255.882 97.7048 255.276 101.414 251.469C123.123 229.194 175.219 172.925 170.523 149.271C167.491 133.993 133.68 130.649 122.802 129.709C108.856 128.507 106.182 126.865 106.978 123.26C107.668 120.202 112.138 110.671 141.455 94.6676C142.073 99.5936 143.167 104.187 144.725 108.148C149.765 121.047 155.698 123.855 162.736 125.176C173.281 127.151 192.993 127.388 208.828 127.329L219.623 111.813C201.683 109.934 171.641 106.281 170.38 101.783L170.392 101.795Z"
                  fill={primaryColor}
                />
                <path
                  d="M218.747 12.182C227.117 24.7623 222.078 42.8413 207.492 52.5627C192.906 62.284 174.296 59.9664 165.925 47.3861C157.555 34.8058 162.594 16.7267 177.18 7.00539C191.767 -2.71597 210.377 -0.398276 218.747 12.182Z"
                  fill={primaryColor}
                />
                <path
                  className="gsap-rotate-fast"
                  d="M261.615 182.752C223.072 182.752 191.84 214.022 191.84 252.586C191.84 291.149 223.084 322.419 261.615 322.419C300.146 322.419 331.39 291.149 331.39 252.586C331.39 214.022 300.146 182.752 261.615 182.752ZM302.69 290.138L271.185 258.607L267.999 261.795L299.516 293.339C289.827 302.381 277.451 307.593 264.231 308.2V263.247H259.725V308.223C246.409 307.784 233.903 302.679 224.082 293.684L256.063 261.676L252.877 258.488L220.884 290.506C211.766 280.714 206.547 268.197 206.024 254.822H251.319V250.313H206.024C206.547 236.95 211.766 224.422 220.884 214.641L252.77 246.552L255.956 243.364L224.082 211.476C233.891 202.481 246.397 197.376 259.725 196.936V241.686H264.231V196.96C277.451 197.566 289.827 202.778 299.516 211.82L268.106 243.245L271.292 246.434L302.678 215.022C311.583 224.754 316.671 237.13 317.195 250.325H272.85V254.834H317.195C316.671 268.031 311.583 280.405 302.678 290.138H302.69Z"
                  fill={primaryColor}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M198.39 281.071L216.342 279.31L214.713 273.42L193.67 275.491L195.275 281.476L198.39 281.071Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M198.391 224.101L216.01 225.849V231.882L191.828 229.502L193.659 223.768L198.391 224.101Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
