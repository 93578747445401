import { theme } from "../../../../../../../../theme";

export default function CTeileBarSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg
      {...props}
      viewBox="0 0 389 907"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <text
          className="gsap-bar-label"
          fill="black"
          xmlSpace="preserve"
          style="white-space: pre"
          fontFamily={fontFamily}
          fontSize="73"
        >
          <tspan x="126.242" y="801.28">
            5 %
          </tspan>
        </text>
        <rect
          className="gsap-bar"
          y="857"
          width="389"
          height="50"
          fill="#E20031"
        />
      </g>
    </svg>
  );
}
