export default function LogistikSVG (props) {
    return (
      <svg {...props} viewBox="0 0 749 721" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M548.114 468.418L453.435 416.164L446.9 412.575C427.08 430.975 403.511 444.153 375.522 444.019C347.989 443.912 323.67 430.44 304.091 412.013L297.663 415.548L201.886 468.392C148.239 496.514 150.007 582.73 150.007 582.971L150.167 592.264L158.845 595.532C182.174 604.263 207.511 610.691 232.392 615.432V589.158H361.595V628.342C369.469 628.475 374.237 628.422 375.013 628.422C375.79 628.422 380.531 628.475 388.405 628.342V589.158H517.608V615.432C542.489 610.691 567.826 604.263 591.155 595.532L599.833 592.264L599.993 582.971C599.993 582.73 601.761 496.514 548.114 468.418ZM361.595 565.562H239.034L260.756 443.376L302.913 418.816L361.595 507.817V565.562ZM388.405 565.562V507.817L447.087 418.816L489.244 443.376L510.966 565.562H388.405Z" fill="#E4032E"/>
        <path d="M467.98 345.966C458.847 375.267 442.831 396.372 423.841 409.282C408.977 419.352 392.398 424.387 375.819 424.387C359.24 424.387 342.688 419.352 327.823 409.282C308.834 396.372 292.817 375.267 283.657 345.966C273.801 334.931 237.992 290.203 265.606 248.85C357.419 251.287 414.842 192.471 414.842 192.471C414.842 192.471 436.537 231.815 486.059 248.85C513.673 290.203 477.863 334.931 467.98 345.966Z" fill="#E4032E"/>
        <path d="M486.058 231.44C436.536 214.406 420.573 164.455 420.573 164.455C420.573 164.455 357.418 233.878 265.605 231.44C230.331 234.012 234.804 189.23 246.321 162.072C257.811 134.94 296.727 92.81 375.818 91.0423C454.909 89.2479 493.102 144.984 501.084 164.455C509.038 183.9 524.947 244.055 486.058 231.44Z" fill="#E4032E"/>
        <path d="M275.324 124.843C275.324 124.843 232.605 158.563 232.605 202.193C232.605 221.557 234.238 285.168 235.792 340.207C237.131 388.203 211.606 402.88 211.606 402.88C211.606 402.88 225.882 416.861 251.433 416.861C284.27 416.861 291.26 399.586 291.26 399.586C291.26 399.586 281.323 381.614 275.645 370.553C272.458 364.366 266.352 345.162 266.352 345.162C266.352 345.162 244.523 330.994 239.22 289.962C234.506 253.483 257.513 225.173 257.513 225.173L275.351 124.843H275.324Z" fill="#E4032E"/>
        <path d="M472.506 126.074C472.506 126.074 517.395 165.928 517.395 202.192C517.395 223.11 515.467 295.559 513.86 353.143C512.655 395.407 538.42 402.907 538.42 402.907C538.42 402.907 525.43 416.887 498.593 416.887C473.524 416.887 458.767 399.612 458.767 399.612C458.767 399.612 468.703 381.641 474.381 370.579C477.569 364.392 483.675 345.189 483.675 345.189C483.675 345.189 505.504 331.02 510.807 289.988C515.521 253.509 492.514 225.199 492.514 225.199L472.48 126.101L472.506 126.074Z" fill="#E4032E"/>
      </svg>
    )
  }
  