import {
  setADMEmail,
  setADMFirstname,
  setADMLastname,
  setADMSalutation,
} from './settingsReducer'
import Cookies from 'js-cookie'
import {
  setConsultantEmail,
  setConsultantFirstname,
  setConsultantLastname,
  setConsultantSalutation,
} from './consultant'
import APIService from '../../api/index'

export const FETCH_USER_REQUEST = 'playbook/user/REQUEST'
export const FETCH_USER_INVALIDATE = 'playbook/user/INVALIDATE'
export const FETCH_USER_FAILURE = 'playbook/user/FAILURE'
export const FETCH_USER_RECEIVE = 'playbook/user/RECEIVE'

//Reducer
export const initialState = {
  isInvalidated: true,
  isFetching: false,
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  salutation: '',
  telefon: '',
  fax: '',
  region: '',
  companyID: '',
  company: {
    id: "",
    name: "",
    street: "",
    postcode: "",
    city: "",
    logo: "",
  },
  initial_password: false,
  loggedInAt: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_INVALIDATE:
      return {
        ...initialState,
      }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
      }
    case FETCH_USER_RECEIVE:
      return {
        ...state,
        isFetching: false,
        isInvalidated: false,
        id: action.id,
        email: action.email,
        realname: action.realname,
        firstname: action.firstname,
        lastname: action.lastname,
        salutation: action.salutation,
        telefon: action.telefon,
        fax: action.fax,
        region: action.region,
        companyID: action.companyID,
        company: {
          id: action.company?.id || "",
          name: action.company?.name || "",
          street: action.company?.street || "",
          postcode: action.company?.postcode || "",
          city: action.company?.city || "",
          logo: action.company?.logo || "",
        },
        initial_password: action.initial_password,
        loggedInAt: Date.now ? Date.now() : new Date().getTime(),
      }
    default:
      return state
  }
}

//Action creators
export const requestUser = () => ({
  type: FETCH_USER_REQUEST,
})

export const invalidateUser = () => ({
  type: FETCH_USER_INVALIDATE,
})

export const fetchUserFailure = () => ({
  type: FETCH_USER_FAILURE,
})

export const writeUserData = user => {
  return {
    type: FETCH_USER_RECEIVE,
    id: user.id,
    email: user.email,
    realname: `${user.firstname} ${user.lastname}`,
    firstname: user.firstname,
    lastname: user.lastname,
    salutation: user.salutation,
    telefon: user.telefon,
    fax: user.fax,
    region: user.region,
    companyID: user.companyID,
    company: user.company,
    initial_password: user.initial_password,
  }
}

export const shouldFetchUserData = state =>
  (!state.username && !state.email && !state.isFetching) ||
  Cookies.get('access_token') === ''

export const fetchUserDataIfNeeded = () => (dispatch, getState) => {
  if (Cookies.get('access_token') === undefined) {
    dispatch(invalidateUser())
  } else if (shouldFetchUserData(getState().user)) {
    return dispatch(fetchUserData())
  }
}

//Async action creators
export const fetchUserData = () => (dispatch, getState) => {
  dispatch(requestUser())
  APIService.get(
    '/api/user',
    (status, data) => {
      if (status === 200) {
        return data;
      }
    }
  ).then(
    data => {
      dispatch(writeUserData(data))
      let user = getState().user
      dispatch(setConsultantEmail(user.email))
      dispatch(setConsultantFirstname(user.firstname))
      dispatch(setConsultantLastname(user.lastname))
      dispatch(
        setConsultantSalutation(user.gender_iso5218 === 1 ? 'Herr' : 'Frau'),
      )
    },
    error => {
      console.error(error)
      dispatch(fetchUserFailure())
    },
  )
}

//Async action creators
export const fetchUserLogout = () => {
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
  return {
    type: FETCH_USER_INVALIDATE,
  }
}

//Selectors
export const getUser = state => state.user

export const isLoggedIn = state =>
  state.user.username !== '' && Cookies.get('access_token') !== undefined

export const ssoRefresh = () => {
  const refreshToken = Cookies.get('refresh_token')

  if (!refreshToken) {
    return Promise.reject();
  }
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);
  return APIService.post(
    'token',
    params,
    "text/plain",
    (status, data) => {
      if (status === 200) {
        return data;
      }
    }
  ).then(data => {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    Cookies.set('access_token', data['access_token'])
    Cookies.set('refresh_token', data['refresh_token'])
    return data['access_token']
  }).catch(error => {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    return Promise.reject(error);
  })
}
