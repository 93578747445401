import { Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import PaketSVG from "../../../../../../components/SvgIcons/KroschkeIcons/PaketSVG";

const useStyles = makeStyles((theme) => ({
  textWrap: {
    hyphens: "auto",
    textWrap: "balance",
  },
}));
export default function Paket() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={7} className={classes.textWrap}>
          <QHeader
            title={
              <>
                Das perfekte Paket
                <br />
                für deine Karriere
              </>
            }
          />
          <QList
            items={[
              "Aus- und Weiterbildungsmöglichkeiten",
              "Moderne Arbeitsausstattung",
              "Urlaubs- und Weihnachtsgeld",
              "VWL",
              "Mitarbeiterprämien & -rabatte",
              "Mitarbeiterevents",
            ]}
          />
        </Grid>
        <Grid item xs={4}><PaketSVG style={{ height: "85vh", width: "80%" }} /></Grid>
      </Grid>
    </Slide>
  );
}
