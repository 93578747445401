import { theme } from "../../../../../../../theme";

export default function DiagrammSVG(props) {
  const fontFamily = theme.typography.fontFamily;
  return (
    <svg {...props} viewBox="0 0 734 933" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6_254)">
      <mask id="mask0_6_254" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="734" height="933">
      <path d="M734 0H0V933H734V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_6_254)">
      <path d="M11.9201 10.0005V38.0005H6.36006V17.7605H1.56006V13.6405C4.68006 13.4805 6.72006 12.3205 7.28006 10.0005H11.9201ZM41.5507 27.0805V32.2805H37.8707V38.0005H32.4707V32.2805H17.1507V27.2405L31.9907 10.0005H37.8707V27.0805H41.5507ZM32.4707 22.5205C32.4707 20.9205 32.5907 17.4405 32.5907 16.4005C32.0707 17.1205 31.0307 18.4805 30.1107 19.5605L26.1107 24.2405C25.2307 25.2805 24.0707 26.5605 23.4707 27.1605C24.4307 27.1605 27.4307 27.0805 29.0307 27.0805H32.4707V22.5205ZM56.9632 9.56055C64.6032 9.56055 68.7232 15.4005 68.7232 23.9205C68.7232 32.4405 64.5632 38.4405 56.9232 38.4405C49.2832 38.4405 45.2032 32.5205 45.2032 24.0005C45.2032 15.4805 49.3232 9.56055 56.9632 9.56055ZM56.9632 32.9605C61.2432 32.9605 63.0432 29.0005 63.0432 24.0005C63.0432 19.0005 61.2032 15.0405 56.9232 15.0405C52.6432 15.0405 50.8832 18.9205 50.8832 23.9205C50.8832 28.9205 52.6832 32.9605 56.9632 32.9605Z" fill="white"/>
      <path d="M13.1594 124.372V152.372H7.59932V132.132H2.79932V128.012C5.91932 127.852 7.95932 126.692 8.51932 124.372H13.1594ZM41.07 146.972V152.372H19.15C19.31 142.772 25.19 138.612 32.03 135.612C34.43 134.572 35.19 133.452 35.19 132.252C35.19 130.812 33.95 129.412 30.59 129.412C27.27 129.412 24.59 130.892 22.63 132.772L19.03 128.572C21.99 125.572 25.95 123.932 30.39 123.932C37.03 123.932 40.79 126.612 40.79 131.972C40.79 136.492 38.35 138.452 33.59 140.652C28.63 142.932 26.71 144.812 26.19 146.972H41.07ZM56.9525 123.932C64.5925 123.932 68.7125 129.772 68.7125 138.292C68.7125 146.812 64.5525 152.812 56.9125 152.812C49.2725 152.812 45.1925 146.892 45.1925 138.372C45.1925 129.852 49.3125 123.932 56.9525 123.932ZM56.9525 147.332C61.2325 147.332 63.0325 143.372 63.0325 138.372C63.0325 133.372 61.1925 129.412 56.9125 129.412C52.6325 129.412 50.8725 133.292 50.8725 138.292C50.8725 143.292 52.6725 147.332 56.9525 147.332Z" fill="white"/>
      <path d="M12.1994 238.743V266.743H6.63936V246.503H1.83936V242.383C4.95936 242.223 6.99936 241.063 7.55936 238.743H12.1994ZM29.43 238.303C37.07 238.303 41.19 244.143 41.19 252.663C41.19 261.183 37.03 267.183 29.39 267.183C21.75 267.183 17.67 261.263 17.67 252.743C17.67 244.223 21.79 238.303 29.43 238.303ZM29.43 261.703C33.71 261.703 35.51 257.743 35.51 252.743C35.51 247.743 33.67 243.783 29.39 243.783C25.11 243.783 23.35 247.663 23.35 252.663C23.35 257.663 25.15 261.703 29.43 261.703ZM56.9691 238.303C64.6091 238.303 68.729 244.143 68.729 252.663C68.729 261.183 64.5691 267.183 56.9291 267.183C49.2891 267.183 45.2091 261.263 45.2091 252.743C45.2091 244.223 49.3291 238.303 56.9691 238.303ZM56.9691 261.703C61.2491 261.703 63.0491 257.743 63.0491 252.743C63.0491 247.743 61.2091 243.783 56.9291 243.783C52.6491 243.783 50.8891 247.663 50.8891 252.663C50.8891 257.663 52.6891 261.703 56.9691 261.703Z" fill="white"/>
      <path d="M36.4294 365.902C38.5894 366.902 40.8694 368.862 40.8694 372.422C40.8694 378.062 36.3894 381.542 29.1494 381.542C21.9094 381.542 17.4294 378.222 17.4294 372.702C17.4294 368.822 19.7494 366.822 21.8694 365.902C19.8694 364.822 18.6294 363.062 18.6294 360.982C18.6294 355.462 23.2694 352.662 29.1494 352.662C34.6694 352.662 39.6694 355.262 39.6694 360.742C39.6694 362.742 38.5894 364.702 36.4294 365.902ZM29.1494 364.022C32.5894 364.022 34.0694 362.702 34.0694 360.862C34.0694 359.062 32.8294 357.502 29.1494 357.502C25.6694 357.502 24.2294 359.022 24.2294 360.822C24.2294 362.622 25.9094 364.022 29.1494 364.022ZM29.1494 376.622C32.9894 376.622 35.2694 375.182 35.2694 372.622C35.2694 370.062 33.3894 368.622 29.1494 368.622C24.9494 368.622 23.0294 370.182 23.0294 372.582C23.0294 375.142 25.5894 376.622 29.1494 376.622ZM56.9553 352.662C64.5953 352.662 68.7153 358.502 68.7153 367.022C68.7153 375.542 64.5553 381.542 56.9153 381.542C49.2753 381.542 45.1953 375.622 45.1953 367.102C45.1953 358.582 49.3153 352.662 56.9553 352.662ZM56.9553 376.062C61.2353 376.062 63.0353 372.102 63.0353 367.102C63.0353 362.102 61.1953 358.142 56.9153 358.142C52.6353 358.142 50.8753 362.022 50.8753 367.022C50.8753 372.022 52.6753 376.062 56.9553 376.062Z" fill="white"/>
      <path d="M31.5998 477.273C37.1598 477.273 41.4798 480.113 41.4798 486.313C41.4798 492.553 36.2798 495.913 29.6798 495.913C23.4398 495.913 18.5598 492.433 18.5598 484.593C18.5598 477.233 22.8798 467.033 37.3998 467.033V472.473C30.9198 472.473 26.7998 474.753 25.1998 479.033C26.9998 477.833 28.7598 477.273 31.5998 477.273ZM29.9198 490.713C33.6398 490.713 35.7998 489.113 35.7998 486.513C35.7998 483.673 33.7198 482.353 30.1998 482.353C26.3998 482.353 24.3598 483.633 24.3598 486.553C24.3598 489.153 26.2798 490.713 29.9198 490.713ZM56.9632 467.033C64.6032 467.033 68.7232 472.873 68.7232 481.393C68.7232 489.913 64.5632 495.913 56.9232 495.913C49.2832 495.913 45.2032 489.993 45.2032 481.473C45.2032 472.953 49.3232 467.033 56.9632 467.033ZM56.9632 490.433C61.2432 490.433 63.0432 486.473 63.0432 481.473C63.0432 476.473 61.2032 472.513 56.9232 472.513C52.6432 472.513 50.8832 476.393 50.8832 481.393C50.8832 486.393 52.6832 490.433 56.9632 490.433Z" fill="white"/>
      <path d="M41.5594 598.92V604.12H37.8794V609.84H32.4794V604.12H17.1594V599.08L31.9994 581.84H37.8794V598.92H41.5594ZM32.4794 594.36C32.4794 592.76 32.5994 589.28 32.5994 588.24C32.0794 588.96 31.0394 590.32 30.1194 591.4L26.1194 596.08C25.2394 597.12 24.0794 598.4 23.4794 599C24.4394 599 27.4394 598.92 29.0394 598.92H32.4794V594.36ZM56.9719 581.4C64.6119 581.4 68.7319 587.24 68.7319 595.76C68.7319 604.28 64.5719 610.28 56.9319 610.28C49.2919 610.28 45.2119 604.36 45.2119 595.84C45.2119 587.32 49.3319 581.4 56.9719 581.4ZM56.9719 604.8C61.2519 604.8 63.0519 600.84 63.0519 595.84C63.0519 590.84 61.2119 586.88 56.9319 586.88C52.6519 586.88 50.8919 590.76 50.8919 595.76C50.8919 600.76 52.6919 604.8 56.9719 604.8Z" fill="white"/>
      <path d="M41.0699 718.811V724.211H19.1499C19.3099 714.611 25.1899 710.451 32.0299 707.451C34.4299 706.411 35.1899 705.291 35.1899 704.091C35.1899 702.651 33.9499 701.251 30.5899 701.251C27.2699 701.251 24.5899 702.732 22.6299 704.612L19.0299 700.411C21.9899 697.411 25.9499 695.771 30.3899 695.771C37.0299 695.771 40.7899 698.451 40.7899 703.811C40.7899 708.331 38.3499 710.292 33.5899 712.492C28.6299 714.772 26.7099 716.651 26.1899 718.811H41.0699ZM56.9524 695.771C64.5924 695.771 68.7124 701.611 68.7124 710.131C68.7124 718.651 64.5524 724.651 56.9124 724.651C49.2724 724.651 45.1924 718.731 45.1924 710.211C45.1924 701.691 49.3124 695.771 56.9524 695.771ZM56.9524 719.172C61.2324 719.172 63.0324 715.211 63.0324 710.211C63.0324 705.211 61.1924 701.251 56.9124 701.251C52.6324 701.251 50.8724 705.131 50.8724 710.131C50.8724 715.131 52.6724 719.172 56.9524 719.172Z" fill="white"/>
      <path d="M56.9592 810.131C64.5992 810.131 68.7192 815.971 68.7192 824.491C68.7192 833.011 64.5592 839.011 56.9192 839.011C49.2792 839.011 45.1992 833.091 45.1992 824.571C45.1992 816.051 49.3192 810.131 56.9592 810.131ZM56.9592 833.531C61.2392 833.531 63.0392 829.571 63.0392 824.571C63.0392 819.571 61.1992 815.611 56.9192 815.611C52.6392 815.611 50.8792 819.491 50.8792 824.491C50.8792 829.491 52.6792 833.531 56.9592 833.531Z" fill="white"/>
      <path d="M492.229 889.871H449.189V932.911H492.229V889.871Z" fill="white"/>
      <rect x="160" y="890" width="43" height="43" fill="#4A4A49"/>
      <rect x="448" y="890" width="43" height="43" fill="#D9D9D9"/>
      </g>
      <line x1="93" y1="826.5" x2="734" y2="826.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="711.5" x2="734" y2="711.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="596.5" x2="734" y2="596.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="481.5" x2="734" y2="481.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="366.5" x2="734" y2="366.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="254.5" x2="734" y2="254.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="139.5" x2="734" y2="139.5" stroke="white" stroke-width="3"/>
      <line x1="93" y1="24.5" x2="734" y2="24.5" stroke="white" stroke-width="3"/>
      <text id="Vor der Analyse" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="214" y="917.92">Vor der Analyse</tspan></text>
      <text id="Nach der Analyse" fill="white" xmlSpace="preserve" style="white-space: pre" fontFamily={fontFamily} font-size="22" font-weight="bold" letter-spacing="0em"><tspan x="503" y="917.92">Nach der Analyse</tspan></text>
      <rect className="gsap-bar slow" x="198" y="141" width="174" height="684" fill="#4A4A49"/>
      <rect className="gsap-bar slow" x="449" y="368" width="173" height="457" fill="#D9D9D9"/>
      </g>
      <defs>
      <clipPath id="clip0_6_254">
      <rect width="734" height="933" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
