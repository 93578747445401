import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    color: "#fff",
    height: "auto",
    textTransform: "uppercase",
    "& .MuiChip-label": {
      marginBottom: -2,
      padding: "6px 10px",
      fontSize: "18px",
    },
  },
  pulsate: {
    animation: "$pulsate 1500ms ease-in-out infinite",
  },
  "@keyframes pulsate": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.3)",
    },
    "50%": {
      boxShadow: "0 0 0 8px rgba(0, 0, 0, 0)",
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.3)",
    },
  },
}));

export function QChip({ label, animation, date }) {
  const classes = useStyles();
  const today = new Date().toISOString().slice(0, 10);
  return (
    date > today && (
      <Chip
        className={
          animation ? clsx(classes.root, classes.pulsate) : classes.root
        }
        label={label}
      />
    )
  );
}
