import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";

// SVGs
import QHeader from "../../../../../../components/QHeader";
import Wrench from "./_Components/Wrench";
import CheckedPaper from "./_Components/CheckedPaper";
import Gear from "./_Components/Gear";
import Binoculas from "./_Components/Binoculas";

export default function Vorteile4() {
  return (
    <Slide id="vorteile-4">
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Vorteile im Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Wrench className="gsap-facts" />
              <Typography align="center" variant="body1">
                SSV-Beschaffungsplattform wird am Optimierungs&shy;prozess
                konfiguriert
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CheckedPaper className="gsap-facts" />
              <Typography align="center" variant="body1">
                Hilft bei der Einhaltung von Einkaufsrichtlinien
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Gear className="gsap-facts" />
              <Typography align="center" variant="body1">
                Automatisierte Bestellabwicklung
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Binoculas className="gsap-facts" />
              <Typography align="center" variant="body1">
                Vollständiger Überblick über alle Ausgaben
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
