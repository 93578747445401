import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PlayIcon(props) {
  return (
    <SvgIcon {...props} fill="#E4032E" viewBox="0 0 50 50" >
      <g clip-path="url(#clip0_929_1628)">
        <circle
          cx="25"
          cy="25"
          r="24"
          fill="white"
          stroke="#E4032E"
          stroke-width="2"
        />
        <path d="M18 35V15L36 25L18 35Z" fill="#E4032E" />
      </g>
      <defs>
        <clipPath id="clip0_929_1628">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
