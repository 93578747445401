import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { route } from "preact-router";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { DatePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import { getActiveModules } from "../../../redux/modules/backendState";
import { getUser } from "../../../redux/modules/userReducer";
import { getCustomerData } from "../../../redux/modules/customer";
import {
  getChapters,
  getConsultingData,
  setChapters,
  setConsultingRunning,
  setNextAppointment,
  setNextAppointmentPlace,
} from "../../../redux/modules/consulting";
import { Offline, Online } from "react-detect-offline";
import { toast } from "playbook-pwa-ui";
import QRCode from "qrcode.react";
import Link from "@material-ui/core/Link";
import Checkicon from "./checkicon";
import { resetConsulting } from "../../../redux/modules/reducers";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  getCompanies,
  addCompanies,
  addCompany,
  setCompanies
} from "../../../redux/modules/companiesReducer";
import {
  getCustomers,
  addCustomers,
  addCustomer,
  setCustomers
} from "../../../redux/modules/customersReducer";
import APIService from "../../../api/index";
import { theme } from "../../../theme";

const optionsDate = { weekday: "long", month: "long", day: "numeric" };
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
    // height: '550px'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  paper: {
    margin: "auto",
  },
  paperElements: {
    margin: "0 auto",
    width: "100%",
    height: "100%",
  },
  cardImg: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "25px",
    justifyContent: "center",
  },
  buttonNext: {
    textAlign: "right",
  },
  endConsultation: {
    textAlign: "center",
  },
  paperStepHeight: {
    // paddingTop: '25px',
    height: "265px",
  },
  sendData: {
    display: "flex !important",
    margin: "0 auto",
    width: "max-content",
  },
  buttonS: {
    "&::before": {
      content: "none !important",
    },
  },
  w80: {
    width: "80%"
  },
  max1200: {
    maxWidth: "1200px"
  },
  lastStep: {
    background: "none",
    boxShadow: "none",
  },
  instructions: {
    color: "#000000",
    fontWeight: "bold",
  },
  centerContent: {
    textAlign: "center",
  },
  marginBottom: {
    marginBottom: "6px",
  },
  marginTop: {
    marginTop: "40px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  fontBold: {
    fontWeight: "700",
  },
  fontMedium: {
    fontWeight: "500",
  },
  widthButton: {
    minWidth: "150px",
  },
  iconBackground: {
    backgroundImage: "url('../../assets/_defaultAssets/iconwall-grey.svg')",
    height: "calc(100dvh - (90px + 2rem))",
    backgroundSize: "cover",
  },
  paperShadow: {
    boxShadow: theme.customShadows.strong,
  },
  label: {
    fontSize: "16px",
  },
  truncate: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
}));

export default function ResultPage({
  handleDialogClose,
  setTab,
  setConsultingTab,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companies = useSelector(getCompanies);
  const customers = useSelector(getCustomers);
  const consulting = useSelector((state) => getConsultingData(state));
  const user = useSelector((state) => getUser(state));
  const customer = useSelector((state) => getCustomerData(state));
  const fieldValues = useSelector((state) => state.fieldValues.fieldValues);
  const activeModules = useSelector((state) => getActiveModules(state));
  const chapters = useSelector((state) => getChapters(state));

  const [checkedModules, setCheckedModules] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [uuid, setUUID] = useState("bje");
  const [emailLink, setEmailLink] = useState("bje");

  useEffect(() => {
    activeModules.forEach((module) => {
      let found = false;

      fieldValues.forEach((field) => {
        if (module.fields.filter((mF) => mF.id === field.id).length > 0) {
          found = true;
        }
      });

      if (!found) {
        if (
          typeof chapters === "object" &&
          chapters.find((chapter) => chapter === module.chapter.key)
        ) {
          found = true;
        }
      }
      setCheckedModules((prevState) => ({
        ...prevState,
        [module.chapter.key]: found,
      }));
    });
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await APIService.get(
        'api/companies/?size=10000',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      );
      
      dispatch(setCompanies(response.items));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await APIService.get(
        'api/customers/?size=10000',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      );
      
      dispatch(setCustomers(response.items));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (companies.length === 0) {
      fetchCompanies()
    }
    if (customers.length === 0) {
      fetchCustomers()
    }
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setTab("consultations");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = (e) => {
    route("/", true);
    setTab("consultations");
    setConsultingTab(0);
    handleDialogClose(e);
  };

  const handleCheckboxes = (e) => {
    const newChapters = Array.from(chapters);
    if (checkedModules[e.target.id]) {
      const index = newChapters.findIndex((el) => el === e.target.id);
      newChapters.splice(index, 1);
    } else {
      newChapters.push(e.target.id);
    }

    dispatch(setChapters(newChapters));
    setCheckedModules({
      ...checkedModules,
      [e.target.id]: !checkedModules[e.target.id],
    });
  };

  const handleDate = (v) => {
    dispatch(setNextAppointment(v));
  };

  const handleField = (e) => {
    dispatch(setNextAppointmentPlace(e.currentTarget.value));
  };

  const setEmail = (id) => {
    const breakLine = "\r\n";
    const mailTopic = `SSV-Kroschke-eReport | Unser Gespräch am ${new Date(
      consulting.appointment
    ).toLocaleDateString("de-DE", optionsDate)}`;

    const emailHeader = `${
      customer.salutation == "Frau" ? `Guten Tag Frau ` : `Guten Tag Herr `
    }${customer.lastname},${breakLine}${breakLine}`;
    const emailBody = `die Inhalte unseres Gesprächs können Sie sich noch einmal hier ansehen:${breakLine}${breakLine}`;
    const emailResultMessage = `SSV-Kroschke-eReport: ${process.env.PREACT_APP_PLAYBOOKMICROSITE}${id}${breakLine}${breakLine}`;
    const emailPrivacyUrl = `Unsere Hinweise zum Datenschutz finden Sie hier: https://www.ssv-kroschke.de/datenschutz${breakLine}${breakLine}`;
    const emailFooter = `Mit freundlichen Grüßen${breakLine}${breakLine}${user.firstname} ${user.lastname}${breakLine}${breakLine}`;

    const mailBody = `${emailHeader}${emailBody}${emailResultMessage}${emailPrivacyUrl}${emailFooter}`;

    setEmailLink(
      `mailto:${customer.email}?subject=${mailTopic}&body=${encodeURIComponent(
        mailBody
      )}`
    );
  };

  const shareDialog = () => {
    const shareData = {
      title: "SSV-Kroschke-eReport",
      text: "",
      url: `${process.env.PREACT_APP_PLAYBOOKMICROSITE}${uuid}`,
    };

    try {
      navigator.share(shareData);
    } catch (err) {}
  };

  const sendData = async () => {
    let consultation = {}
    
    // Consultation | Consultation
    let chapterRatings = [];

    const isNewCompany = !companies.find(
      (company) => company.id == customer.companyID
    );

    if (isNewCompany) {
      customer.company.id = null
      await APIService.post(
        `api/companies`,
        customer.company,
        "text/plain",
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      ).then((response)=> {
        customer.companyID = response?.id
        dispatch(addCompany(response))
      });
    }

    const isNewCustomer = !customers.find(
      (custom) => custom.email == customer.email
    );
    if (isNewCustomer) {
      await APIService.post(
        `api/customers`,
        {
          email: customer.email,
          firstname: customer.firstname,
          lastname: customer.lastname,
          salutation: customer.salutation,
          companyID: customer.companyID,
        },
        "text/plain",
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      ).then((response)=> {
        dispatch(addCustomer(response))
      });
    }

    consultation.consultant = {
      id: user.id,
      companyID: user.companyID,
      email: user.email,
      fax: user.fax,
      firstname: user.firstname,
      lastname: user.lastname,
      region: user.region,
      salutation: user.salutation,
      telefon: user.telefon,
    };
    consultation.customer = customer;
    consultation.consulting = {
      appointment: consulting.appointment,
      appointment_place: consulting.appointmentPlace,
      next_appointment: consulting.nextAppointment,
      next_appointment_place: consulting.nextAppointmentPlace,
      notes: consulting.notes,
      rating: 0,
      topic: consulting.topic,
      chapters: JSON.stringify(
        Object.keys(checkedModules).filter((mod) => checkedModules[mod])
      ),
    };
    consultation.fieldValues = fieldValues;
    consultation.miscdata = "{}";
    chapters.forEach((chapter) => {
      chapterRatings.push({
        chapter_name: chapter,
        rating: 0,
      });
    })
    consultation.chapterRatings = chapterRatings;


    APIService.post(
      '/api/consultation',
      consultation,
      "text/plain",
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    ).then(
      (data) => {
        // Delete prepared consultation in DB
        if (consulting.prepared && consulting.consultation_id) {
          APIService.delete(`/api/consultation/${consulting.consultation_id}`)
        }

        setUUID(data.id);
        setEmail(data.id);
        setActiveStep(3);
        dispatch(setConsultingRunning(false));
        dispatch(resetConsulting());
      },
      () => {
        setActiveStep(0);
        toast.error("Ein Fehler ist aufgetreten.");
      }
    );
  };

  if (activeStep === 2) {
    if (!navigator.onLine) {
      toast.error(
        "Keine Interverbindung.\nDie Beratung kann beendet werden, wenn Sie online sind."
      );
    }
  }

  return (
    <>
      {activeStep < 3 ? (
        <Container>
          <Box
            className={classes.paperShadow}
            sx={{
              height: "calc(100dvh - (90px + 40px + 100px))",
              overflowY: "scroll",
            }}
          >
            <Paper elevation={0} square style={{ height: "100%" }}>
              <Stepper activeStep={activeStep}>
                <Step>
                  <StepLabel>
                    Themen am {dayjs(consulting.apointment).format("D.MMMM")}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>Wann sehen wir uns wieder?</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Abschluss</StepLabel>
                </Step>
              </Stepper>
              <Box p={5}>
                <Grid container spacing={2}>
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h4" className={classes.fontBold}>
                          Über diese Themen haben wir gesprochen:
                        </Typography>
                      </Grid>
                      {checkedModules !== null &&
                        activeModules.map((module) => (
                          <Grid key={module.chapter.key} item xs={6}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={handleCheckboxes}
                                  checked={checkedModules[module.chapter.key]}
                                  value={checkedModules[module.chapter.key]}
                                  id={module.chapter.key}
                                />
                              }
                              label={module.chapter.name}
                            />
                          </Grid>
                        ))}
                    </>
                  )}
                  {activeStep !== 0 && (
                    <Box
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      gridGap="10px"
                      height="100%"
                      width="100%"
                      marginTop={10}
                    >
                      {activeStep === 1 && (
                        <>
                          <Typography
                            variant="h3"
                            className={classes.marginBottom}
                          >
                            Wann sehen wir uns wieder?
                          </Typography>
                          <DatePicker
                            label="Datum"
                            id="nextAppointment"
                            value={consulting.nextAppointment}
                            onChange={handleDate}
                            cancelLabel="Abbrechen"
                            format="dd.MM.yyyy"
                            fullWidth
                            InputProps={{
                              disableUnderline: true,
                              variant: "outlined",
                            }}
                            InputLabelProps={{ variant: "outlined" }}
                            inputVariant="outlined"
                          />
                          <TextField
                            id="nextAppointmentPlace"
                            label="Ort"
                            variant="outlined"
                            fullWidth
                            onChange={handleField}
                            value={consulting.nextAppointmentPlace}
                          />
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <Typography variant="h2" align="center">
                            Fast fertig!
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.colorPrimary}
                            align="center"
                          >
                            Alles erfasst?
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box
            height="100px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="primary" onClick={ activeStep === 0 ? handleDialogClose :handleBack}>
              {activeStep === 0 ? "Gespräch fortsetzen" : "Zurück"}
            </Button>
            {activeStep < 2 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.widthButton}
              >
                Weiter
              </Button>
            ) : (
              <>
                <Online
                  polling={{
                    url: `${process.env.PREACT_APP_MYSQLAPI}/ping`,
                    interval: 60000,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendData}
                    disabled={!navigator.onLine}
                  >
                    Gespräch beenden
                  </Button>
                </Online>
                <Offline
                  polling={{
                    url: `${process.env.PREACT_APP_MYSQLAPI}/ping`,
                    interval: 60000,
                  }}
                >
                  <Button variant="contained" color="primary" disabled>
                    Gespräch beenden
                  </Button>
                </Offline>
              </>
            )}
          </Box>
        </Container>
      ) : (
        <Box
          className={clsx(classes.paperShadow, classes.iconBackground)}
          sx={{
            height: "calc(100dvh - (90px + 40px + 100px))",
            overflowY: "scroll",
          }}
          md={{ p: 12 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paper elevation={0} square className={clsx(classes.paperShadow, classes.w80, classes.max1200)}>
            <Box py={4} px={8}>
              <Box>
                <Typography variant="h2" align="center">
                  Vielen Dank!
                </Typography>
                <Typography
                  variant="h4"
                  className={clsx(classes.colorPrimary, classes.marginBottom, classes.fontMedium)}
                  align="center"
                >
                  Beratung erfolgreich beendet!
                </Typography>
              </Box>
              <Box p={2} bgcolor="#f5f5f5" display="flex" alignItems="center">
                <Link
                  href={`${process.env.PREACT_APP_PLAYBOOKMICROSITE}${uuid}`}
                  target="_blank"
                >
                  <QRCode
                    size={180}
                    includeMargin={true}
                    value={`${process.env.PREACT_APP_PLAYBOOKMICROSITE}${uuid}`}
                  />
                </Link>
                <Box p={2} overflow="auto">
                  <Typography variant="h4" className={classes.fontBold}>
                    eReport
                  </Typography>
                  <Typography variant="body2" className={classes.fontRegular}>
                    Digitale Zusammenfassung des Gesprächs teilen.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={clsx(classes.fontBold, classes.marginBottom, classes.truncate)}
                  >
                    {`${process.env.PREACT_APP_PLAYBOOKMICROSITE}${uuid}`}
                  </Typography>
                  <Box display="grid" gridGap={10} gridAutoFlow="column">
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      href={emailLink}
                      target="_blank"
                    >
                      Per E-Mail versenden
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={shareDialog}
                      onTouchStart={shareDialog}
                    >
                      Teilen
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      href={`${process.env.PREACT_APP_PLAYBOOKMICROSITE}${uuid}`}
                      target="_blank"
                    >
                      Öffnen
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box display="block" margin="0px auto" width="40%" paddingTop="1rem">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  fullWidth
                >
                  Fenster schließen
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}
