import { Box, Grid, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

// SVG Grafiken
import MeisterSVG from "./_Components/MeisterSVG";
import EinkaufSVG from "./_Components/EinkaufSVG";
import LogistikSVG from "./_Components/LogistikSVG";
import BuchhaltungSVG from "./_Components/BuchhaltungSVG";

export default function Beispiel() {
  return (
    <Slide background>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <QHeader
            title="Schauen wir uns ein Beispiel an:"
            textAlign="center"
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="grid" justifyItems="center">
            <MeisterSVG height="35vh" className="gsap-facts" />
            <Box>
              <Typography variant="body1" color="primary" align="center">
                <strong>Meister</strong>
              </Typography>
              <Typography variant="body1" align="center">
                Benötigt täglich <strong>ca. 2,5 Stunden</strong> für das
                Beschaffen und Verteilen von C-Teilen.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="grid" justifyItems="center">
            <EinkaufSVG height="35vh" className="gsap-facts" />
            <Box>
              <Typography variant="body1" color="primary" align="center">
                <strong>Einkauf</strong>
              </Typography>
              <Typography variant="body1" align="center">
                <strong>1,5 Personen</strong> beschäftigen sich ausschließlich
                mit Bestellung, Bestellverfolgung und Disposition von C-Teilen.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="grid" justifyItems="center">
            <LogistikSVG height="35vh" className="gsap-facts" />
            <Box>
              <Typography variant="body1" color="primary" align="center">
                <strong>Logistik</strong>
              </Typography>
              <Typography variant="body1" align="center">
                Zahlreiche Wareneingänge verursachen einen erhöhten logistischen
                Aufwand.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="grid" justifyItems="center">
            <BuchhaltungSVG height="35vh" className="gsap-facts" />
            <Box>
              <Typography variant="body1" color="primary" align="center">
                <strong>Buchhaltung</strong>
              </Typography>
              <Typography variant="body1" align="center">
                Eine Vielzahl an Rechnungen muss pro Monat geprüft werden.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
