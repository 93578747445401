import WasSindEigentlichCTeileAntwort from "../../../ElektronischeAusgabesysteme/sections/Mission30/slides/WasSindEigentlichCTeileAntwort";
import Arbeitsschutz1 from "./slides/Arbeitsschutz2";
import Arbeitsschutz2 from "./slides/Arbeitsschutz1";
import Betriebseinrichtung from "./slides/Betriebseinrichtung";
import Handwerkszeug from "./slides/Handwerkzeuge";
import Messtechnik from "./slides/Messtechnik";
import WeiterePartner1 from "./slides/WeiterePartner1";
import WeiterePartner2 from "./slides/WeiterePartner2";
import Zerspanungsartikel1 from "./slides/Zerspanungsartikel1";
import Zerspanungsartikel2 from "./slides/Zerspanungsartikel2";

export default function Lieferanten() {
  return [
    <WasSindEigentlichCTeileAntwort key="was-sind-eigentlich-c-teile-antwort" />,
    <Arbeitsschutz2 key="arbeitsschutz-2" />,
    <Arbeitsschutz1 key="arbeitsschutz-1" />,
    <Zerspanungsartikel1 key="zerspanungsartikel-1" />,
    <Zerspanungsartikel2 key="zerspanungsartikel-2" />,
    <Betriebseinrichtung key="betriebseinrichtung" />,
    <Handwerkszeug key="handwerkzeuge" />,
    <Messtechnik key="messtechnik" />,
    <WeiterePartner1 key="weitere-partner-1" />,
    <WeiterePartner2 key="weitere-partner-2" />,
  ];
}
