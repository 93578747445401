import { Box, Grid, Typography } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import DiagrammSVG2 from "./_Components/DiagrammSVG2";
import { theme } from "../../../../../../theme";

export default function Grafik() {
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Ihr Verbrauchskosten-Einsparpotential" />
          <QList
            items={[
              "Bedarfsgerechte Bestellungen",
              "Reduzierung der Verbräuche",
              "Bessere Kostenübersicht und Planbarkeit",
              "Weniger Schwund",
            ]}
          />
          <Typography variant="body1" color="primary">
            = bis zu 30 % Einsparung
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box
        height="100dvh"
        width="50dvw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
        bgcolor={theme.palette.primary.main}
      >
        <DiagrammSVG2 width="70%" style={{ maxHeight: "80dvh" }}/>
      </Box>
    </Slide>
  );
}
