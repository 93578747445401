import { Box, Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import DerAufwandHinterCTeilenSVG from "./_Components/DerAufwandHinterCTeilenSVG";
import { BetterImage } from "playbook-pwa-ui";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.secondary.main,
  },
}));

export default function DerAufwandHinterCTeilen() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="Der Aufwand hinter C-Teilen" />
        </Grid>
        <Grid item xs={12} style={{ height: "60%" }}>
          <DerAufwandHinterCTeilenSVG height="100%" width="100%" />
        </Grid>
      </Grid>
    </Slide>
  );
}
