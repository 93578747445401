import React from "react";
import Markdown from "preact-markdown";
import { h } from "preact";
import { useState, useEffect, useMemo } from "preact/hooks";
import { route } from "preact-router";
import { Grid, TextField, Button, Typography, Box, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { passwordStrength } from 'check-password-strength'
import APIService from "../../../api/index";
import { toast } from "playbook-pwa-ui";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1303",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fontWeightBold: {
    fontWeight: 700,
  },
}));

export default function ChangePasswordModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen((v) => !v);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [pwStrength, setPasswordStrength] = useState(passwordStrength(password));
  const [passwordChanged, setPasswordChanged] = useState(false);
  
  useEffect(() => {
    setPasswordStrength(passwordStrength(password))
  }, [password])

  const handleChangeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePasswordAgain = (event) => {
    setPasswordAgain(event.target.value);
  };

  const handleChangePasswordClose = () => {
    handleClose()
    setPasswordChanged(false)
  }

  const handleSetPassword = () => {
    APIService.post(
      '/api/password/change',
      { password, currentPassword },
      "text/plain",
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
      
    ).then(
      (data) => {
        setPasswordChanged(true)
        return data
      },
      (error) => {
        if (error.response?.data?.error?.includes('wrong current password')) {
          toast.error("Das vorherige Kennwort stimmt nicht überein.")
        } else {
          toast.error("Das Kennwort konnte nicht gesetzt werden.")
        }
        
      }
    );
  };
  
  const Icon = useMemo(() => {
    let icon
    switch(pwStrength.value) {
      case 'Strong': 
        icon = CheckOutlinedIcon
        break;
      default: 
        icon = ErrorOutlineOutlinedIcon
    }

    return icon
  }, [pwStrength.value])

  const colors = useMemo(() => {
    let result = []
    const COLORS = {
      NEUTRAL: '#e0e0e0',
      WEAK: '#c20017',
      MEDIUM: '#dba029',
      STRONG: '#15842f',
    }

    switch(pwStrength.value) {
      case 'Weak': 
        result = [COLORS.WEAK, COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL]
        break;
      case 'Medium':
        result = [COLORS.MEDIUM, COLORS.MEDIUM, COLORS.NEUTRAL, COLORS.NEUTRAL]
        break;
      case 'Strong': 
        result = [COLORS.STRONG, COLORS.STRONG, COLORS.STRONG, COLORS.STRONG]
        break;
      default: 
        result = [COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL]
    }
    return result
  }, [pwStrength.value])

  const missingKeys = useMemo(() => {
    return ["lowercase","uppercase","number","symbol", "length"].filter(el => {
      if (el === "length") {
        return password.length < 10
      }
      return !pwStrength.contains.find(el2 => el2 === el)
    })
  }, [pwStrength.contains, password])




  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{marginRight: "1em"}}
        onClick={toggleOpen}
      >
        Kennwort ändern
      </Button>
      <Modal
        aria-labelledby="changelog-modal-title"
        aria-describedby="changelog-modal-description"
        className={classes.modal}
        open={open}
        onClose={toggleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth
        fullScreen
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {passwordChanged
              ? (
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Typography component="H2">
                      Ihr Kennwort wurde erfolgreich zurückgesetzt.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant="outlined" color="primary" size="small" onClick={handleChangePasswordClose}>Ok</Button>
                  </Grid>
                </Grid>
              )
              : (
                <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Sie möchten ihr Kennwort ändern?
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            value={currentPassword}
                            onChange={handleChangeCurrentPassword}
                            label="Aktuelles Kennwort"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={handleChangePassword}
                            label="Neues Kennwort"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            value={passwordAgain}
                            onChange={handleChangePasswordAgain}
                            label="Neues Kennwort wiederholen"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="center" gap="5px" margin="10px 0">
                          {colors.map((color, index) => {
                            return (
                              <Box key={index} flex={1} height="5px" borderRadius="5px" style={{backgroundColor: color}} />
                            )
                          })}
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" gap="5px" margin="10px 0">
                          <Icon htmlColor={colors[0]} />
                          <Typography style={{color: colors[0]}}>
                            {
                              pwStrength.value === 'Too Weak' 
                                ? 'zu schwach'
                                : pwStrength.value === 'Weak'
                                  ? 'schwach'
                                  : pwStrength.value === 'Medium'
                                    ? 'medium'
                                    : pwStrength.value === 'Strong'
                                      ? 'stark'
                                      : ''
                            }
                          </Typography>
                        </Box>
                        {pwStrength.value != "Strong" && (
                          <>
                            <Typography
                              variant="subtitle2"
                              color="text.headingLight"
                              style={{
                                fontSize: '1rem',
                                margin: '0 0 8px 0'
                              }}
                            >
                              Um Ihr Kennwort stärker zu machen müsen folgende Kriterien erfüllt sein.
                            </Typography>
                            
                            {missingKeys.length > 0 && (
                              <Typography
                                variant="subtitle2"
                                color="text.bodyLight"
                                style={{
                                  fontSize: '.8rem',
                                  margin: '0 0 24px 0'
                                }}
                              >
                                <ul>
                                  {missingKeys.map((key, index) => {
                                    switch(key) {
                                      case "lowercase":
                                        return <li key={`missingKey-${index}`}>einen Buchstaben</li>
                                      case "uppercase":
                                        return <li key={`missingKey-${index}`}>einen Großbuchstaben</li>
                                      case "number":
                                        return <li key={`missingKey-${index}`}>eine Nummer</li>
                                      case "symbol":
                                        return <li key={`missingKey-${index}`}>ein Sonderzeichen (z.B. @ ? ! $)</li>
                                      case "length":
                                        return <li key={`missingKey-${index}`}>mindestens 10 Zeichen</li>
                                      default:
                                        return null
                                    }
                                  })}
                                </ul>
                              </Typography>
                            )}
                          </>
                        )}

                        {password !== passwordAgain && (
                          <>
                            <Typography
                              variant="subtitle2"
                              color="text.bodyLight"
                              style={{
                                fontSize: '.8rem',
                                margin: '0 0 24px 0'
                              }}
                            >
                              Die angegebenen Passwörter sind nicht identisch.
                            </Typography>
                          </>
                        )}
                        
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={handleSetPassword}
                          disabled={pwStrength.value !== "Strong" || password !== passwordAgain || currentPassword === ""}
                        >
                          Kennwort ändern
                        </Button>
                      </Grid>
                    </Grid>
              )
            }
          </div>
        </Fade>
      </Modal>
    </>
  );
}
