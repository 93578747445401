// Components
import Slide from "../../../../../components/Slide";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";
import { Box, Grid } from "@material-ui/core";

import BackgroundImage from "../../../../../assets/_Chapters/LEANdustry/lean-management.webp";

export default function LeanManagement() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader
            title={
              <>
                LEANdustry<sup>®</sup>
              </>
            }
          />
          <QList
            items={[
              "Sinnvolle Zusammenführung von Lean Management und Industrie 4.0",
              "Unsere Dienstleistungen und Ausgabesysteme garantieren nachhaltige Einsparpotentiale",
              "Sorgen für eine optimale Versorgung in jedem Produktbereich",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
