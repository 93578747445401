export default function EinsparpotentialAnalyseSVG({className}) {
    return (
        <svg className={className} id="uuid-c71f81bd-b1f6-4aed-a887-2f553ca20d8e" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
            <polygon points="115.01 98.38 109.9 98.42 117.99 84.94 125.94 98.38 120.87 98.42 120.28 344.46 366.34 344.57 366.37 339.37 379.24 347.43 366.37 355.51 366.34 349.73 115.08 349.76 115.01 98.38" style="fill: #e4032e; stroke-width: 0px;"/>
            <path d="m261.6,121.97s-5,5.18-.6,10.23c4.39,5.04,26.99,27.82,26.99,27.82l-12.88,13.51,43.14,6.42-6.51-43.14-11.86,11.2-26.45-26.03s-5.78-6.16-11.84,0Z" style="fill: #e4032e; stroke-width: 0px;"/>
            <path d="m150.02,335.24l-16.92.09s-2.7-.56-2.98-4.18c-.28-3.63-.09-215.99-.09-215.99,0,0-.37-4.65,4.84-4.65h36.54s3.91-.28,4.18,3.25c.28,3.53.28,217.95.28,217.95,0,0,.46,3.35-3.44,3.53-3.91.19-22.41,0-22.41,0Z" style="fill: #e4032e; stroke-width: 0px;"/>
            <path d="m209.28,159.42l-19.62.09s-2.98-.44-3.02,2.37c-.04,2.37,0,169.92,0,169.92,0,0-.46,2.84,2.46,2.84s40.63.09,40.63.09c0,0,2.6-.14,2.6-2.51v-169.88s.3-2.81-2.46-2.88c-1.67-.04-20.6-.05-20.6-.05Z" style="fill: #e4032e; stroke-width: 0px;"/>
            <path d="m265.93,334.89l19.96-.12s2.39-.03,2.34-2.51c-.07-2.89.02-134.8.02-134.8,0,0,.2-2.46-1.63-2.5-.65-.01-41.88-.02-41.88-.02,0,0-2.24.08-2.21,2.61.03,2.84,0,134.29,0,134.29,0,0-.02,2.79,2.67,2.86s20.73.19,20.73.19Z" style="fill: #e4032e; stroke-width: 0px;"/>
            <path d="m321.08,245.83l-19.6.03s-2.79-.1-2.82,2.86-.03,83.33-.03,83.33c0,0-.15,2.61,2.55,2.62,3.03.01,40.02.07,40.02.07,0,0,3.16-.25,3.19-2.77.03-2.51-.07-83.28-.07-83.28,0,0-.21-2.81-3.52-2.81s-19.71-.05-19.71-.05Z" style="fill: #e4032e; stroke-width: 0px;"/>
        </svg>
    );
}
