import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/ServicesZerspanung/2.webp";

export default function Abwicklung() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Abwicklung über die Ausgabesysteme" />
          <QList
            noPadding
            items={[
              "Kooperation bereits bestehender Partnerschaften mit Schleifdiensten",
              "Möglichkeit der Abholung und Abgabe von VHM-Schrott",
              "Vollumfängliche Auswertung des Nachschliffs möglich (z. B.: Entnahmen, Verbräuche oder Einsparungen)",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
