import Slide from "../../../../../../components/Slide";
import { Grid, makeStyles } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import AnpassenVonOtoplastikenSVG from "../../Leistungen/slides/Beschaffung/_Components/AnpassenVonOtoplastikenSVG";
import KorrektionsschutzbrillenSVG from "../../Leistungen/slides/Beschaffung/_Components/KorrektionsschutzbrillenSVG";
import SchlappenSVG from "../_Components/SchlappenSVG";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));
export default function DetaillierterUberblick() {

  return (
    <Slide>
      <Grid container justify="center">
        <Grid item xs={12}>
          <QHeader title="Ein detaillierter Überblick" textAlign="center" />
        </Grid>
        <Grid item xs={3}>
          <KorrektionsschutzbrillenSVG width="100%" className="gsap-facts" />
          <QList
            items={[
              <>
                <b>650</b> Fielmann Niederlassungen
              </>,
              <>
                <b>110</b> pro optik Filialen
              </>,
              <>
                Ca. <b>500</b> weitere lokale Optiker
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <AnpassenVonOtoplastikenSVG width="100%" className="gsap-facts" />
          <QList
            items={[
              <>
                <b>400</b> Amplifon Filialen
              </>,
              <>
                <b>110</b> auric Filialen
              </>,
              <>
                Ca. <b>200</b> weitere lokale Akustiker
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SchlappenSVG width="100%" className="gsap-facts" />
          <QList
            items={[
              <>
                Ca. <b>300</b> lokale Systempartner
              </>,
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
