export default function Gear({ className }) {
    return (
        <svg className={className} id="uuid-cbb19f32-d938-43fc-8f37-a7bac3b7bb21" data-name="Schrift" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.14 439.28">
          <g id="uuid-eba6fea0-0b31-4eb4-9b58-814a783dc38d" data-name="Layer_1">
            <g id="uuid-9a27e390-71c9-4e37-b8a9-7a317fda96c0" data-name=" 401028000">
              <path id="uuid-140e4d04-4589-440c-81ef-25b50eda9382" data-name=" 401030184" d="m131.25,295.16c-1.87,0-3.61-.99-4.54-2.62-11.76-20.33-17.94-43.48-17.94-66.95s6.2-46.62,17.94-66.95c1.45-2.51,4.65-3.37,7.16-1.91,2.5,1.45,3.37,4.65,1.91,7.16-21.96,38.07-21.98,85.36,0,123.41,1.45,2.51.58,5.71-1.91,7.16-.8.45-1.7.69-2.62.69h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-93ae8157-89bc-413b-8975-249f8cd4ac81" data-name=" 401029656" d="m250.52,355.44c-23.47,0-46.62-6.21-66.95-17.94-2.51-1.45-3.37-4.65-1.91-7.16,1.45-2.51,4.65-3.37,7.16-1.91,38.08,21.96,85.34,21.93,123.43,0,2.5-1.44,5.71-.58,7.16,1.92,1.45,2.51.58,5.71-1.91,7.16-20.35,11.74-43.5,17.93-66.97,17.93h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-b157bc69-688e-40ff-8587-4dd2ed4f18c7" data-name=" 401030088" d="m357.89,283.26c-.92,0-1.81-.24-2.62-.7-2.5-1.45-3.37-4.65-1.91-7.16,21.96-38.07,21.96-85.36,0-123.41-1.44-2.51-.58-5.71,1.91-7.16,2.51-1.45,5.71-.59,7.16,1.91,11.74,20.33,17.94,43.48,17.94,66.95s-6.19,46.63-17.94,66.95c-.93,1.64-2.67,2.63-4.54,2.63h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-e38a5751-397d-4b6c-8b38-c3652ea37bac" data-name=" 401029080" d="m169.75,108.93c-1.45-2.51-.59-5.71,1.91-7.16,41.41-23.93,92.48-23.89,133.9,0,2.51,1.45,3.37,4.65,1.91,7.16-1.45,2.51-4.65,3.37-7.16,1.91-38.07-21.95-85.37-22.03-123.41,0-2.49,1.44-5.71.58-7.15-1.91h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-1040427c-ee94-4088-aab1-a7e182499e98" data-name=" 401029224" d="m333.94,205.91h-14.47c-1.44,0-2.67-1-2.99-2.39-1.86-8.3-5.1-16.15-9.65-23.31-.77-1.21-.59-2.79.42-3.81l10.23-10.23c2.54-2.54,2.54-6.69,0-9.22l-10.22-10.22c-1.22-1.22-2.87-1.9-4.61-1.9s-3.39.67-4.61,1.9l-10.23,10.23c-1.01,1.01-2.6,1.19-3.81.42-7.16-4.54-14.99-7.79-23.31-9.65-1.4-.31-2.39-1.55-2.39-2.99v-14.49c0-3.6-2.93-6.52-6.52-6.52h-14.45c-3.6,0-6.52,2.93-6.52,6.52v14.47c0,1.43-1,2.67-2.4,2.99-8.3,1.86-16.15,5.09-23.31,9.65-1.21.77-2.79.59-3.81-.42l-10.23-10.23c-1.22-1.22-2.86-1.9-4.61-1.9s-3.39.67-4.61,1.9l-10.21,10.21c-1.22,1.22-1.9,2.86-1.9,4.61s.67,3.39,1.9,4.61l10.23,10.23c1.01,1.01,1.19,2.6.42,3.81-4.54,7.16-7.79,14.99-9.65,23.31-.31,1.4-1.55,2.39-2.99,2.39h-14.47c-3.6,0-6.52,2.93-6.52,6.52v14.45c0,3.6,2.93,6.52,6.52,6.52h14.46c1.44,0,2.67,1,2.99,2.39,1.86,8.3,5.09,16.15,9.65,23.31.77,1.21.59,2.79-.42,3.81l-10.23,10.23c-1.22,1.22-1.9,2.86-1.9,4.61s.67,3.39,1.9,4.61l10.22,10.22c1.22,1.22,2.86,1.9,4.61,1.9s3.39-.67,4.61-1.9l10.23-10.23c.59-.59,1.37-.89,2.17-.89.56,0,1.12.15,1.64.47,7.16,4.54,15,7.79,23.31,9.65,1.4.31,2.39,1.55,2.39,2.99v14.47c0,3.6,2.93,6.52,6.52,6.52h14.45c3.6,0,6.52-2.93,6.52-6.52v-14.47c0-1.43,1-2.67,2.39-2.98,8.3-1.86,16.13-5.09,23.31-9.65,1.21-.77,2.79-.59,3.81.42l10.23,10.23c1.22,1.22,2.86,1.9,4.61,1.9s3.39-.67,4.61-1.9l10.23-10.22c2.54-2.54,2.54-6.69,0-9.22l-10.23-10.23c-1.01-1.01-1.19-2.6-.42-3.81,4.54-7.15,7.79-14.99,9.65-23.31.31-1.4,1.55-2.39,2.99-2.39h14.47c3.6,0,6.52-2.93,6.52-6.52v-14.44c0-3.6-2.93-6.52-6.52-6.52Zm-89.37,42.72c-16.01,0-28.99-12.98-28.99-28.99s12.98-28.99,28.99-28.99,28.99,12.98,28.99,28.99-12.98,28.99-28.99,28.99Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-40a225e2-d736-4139-8323-d1e68e61798e" data-name=" 401029320" d="m335.25,126.72l9.83,36.65,13.24-13.24c.11-.12.22-.23.35-.35l13.25-13.24-36.68-9.81h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-efc02e64-f7c9-4a78-b7ab-563c27189eb4" data-name=" 401029440" d="m161.46,92.29l-9.82,36.65,36.65-9.82-13.33-13.32c-.07-.05-.11-.11-.16-.16l-13.33-13.34h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-001d685d-3221-4015-a134-c6413633dc7e" data-name=" 401028888" d="m337.48,310.34l-36.65,9.82,13.22,13.22c.14.13.26.26.4.4l13.22,13.22,9.81-36.66h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
              <path id="uuid-c809ba50-7b35-488f-b96a-6a21e876709b" data-name=" 401028384" d="m144.04,275.9l-13.29,13.29c-.08.09-.16.16-.25.25l-13.29,13.3,36.67,9.82-9.84-36.66h0Z" style="fill: #e4032e; fill-rule: evenodd; stroke-width: 0px;"/>
            </g>
          </g>
        </svg>
    );
  }
  