import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo1 from "../../../../../../assets/_Chapters/CTeileSortiment/Messtechnik/kaefer.jpg";
import Logo2 from "../../../../../../assets/_Chapters/CTeileSortiment/Messtechnik/mahr.jpg";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/messtechnik.webp";

const useStyles = makeStyles({
  container: {
    "& .MuiGrid-grid-xs-6": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "130px",
        width: "auto",
        maxWidth: "75%",
      },
    },
  },
});
export default function Messtechnik() {
  const classes = useStyles();
  return (
    <Slide
      background={{
        image: BackgroundImage,
        size: "55dvw",
        align: "left",
        position: "top left",
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <QHeader title="Messtechnik" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <img src={Logo1} alt="Logo" loading="lazy" />
              </Grid>
              <Grid item xs={6}>
                <img src={Logo2} alt="Logo" loading="lazy" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
