import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import ATeileSVG from "./_Components/Teile/ATeileSVG";
import BTeileSVG from "./_Components/Teile/BTeileSVG";
import CTeileSVG from "./_Components/Teile/CTeileSVG";
import { theme } from "../../../../../../theme";
import ATeileBarSVG2 from "./_Components/Bar/ATeileBarSVG2";
import BTeileBarSVG2 from "./_Components/Bar/BTeileBarSVG2";
import CTeileBarSVG2 from "./_Components/Bar/CTeileBarSVG2";

const useStyles = makeStyles((theme) => ({
  secondary: {
    color: theme.palette.secondary.main,
  },
}));

export default function MengenanteileImVerhaeltnis() {
  const classes = useStyles();
  return (
    <Slide background>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader
            textAlign="center"
            title={
              <>
                <span className={classes.secondary}>Mengenanteil</span> im
                Verhältnis
              </>
            }
          />
        </Grid>
        <Grid container style={{ height: "70dvh" }} spacing={5}>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <ATeileBarSVG2 height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <ATeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                A-Teile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <BTeileBarSVG2 height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <BTeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                B-Teile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="grid" gridAutoRows="80% 20%" height="100%">
              <Box display="grid" gridAutoRows="50% 50%">
                <Box display="block" margin="15px auto">
                  <CTeileBarSVG2 height="100%" />
                </Box>
                <Box display="block" margin="15px auto">
                  <CTeileSVG height="100%" />
                </Box>
              </Box>
              <Typography variant="body1" align="center">
                C-Teile
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
