import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import { Grid } from "@material-ui/core";
import { BetterImage, ImageComponent } from "playbook-pwa-ui";

import Schrank from "../../../../../../assets/_Chapters/EPLSystem/e-pls-system.webp";

export default function EPLSSystem() {
  return (
    <Slide>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader title="Das E-PLS-System im Detail" textAlign="center" />
        </Grid>
        <Grid item xs={6} lg={8}>
          <ImageComponent
            col={12}
            wrapperWidth="100%"
            align="left"
            images={[
              {
                src: Schrank,
                alt: "E-PLS-System",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <QList
            headline="Fächergrößen (H x B x T)"
            items={[
              "S-Fach: 15,5 cm x 30 cm x 47,5 cm",
              "M-Fach: 24,5 cm x 30 cm x 47,5 cm",
              "L-Fach: 51,5 cm x 30 cm 47,5 cm",
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
