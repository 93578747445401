import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function FilledArrowLeft(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 44 44" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="arrowBackwards"
            transform="translate(22.000000, 22.000000) scale(-1, 1) translate(-22.000000, -22.000000) "
          >
            <circle id="Oval" fill="#000000" cx="22" cy="22" r="22" />
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="15 11.7396923 24.4343945 21.9230769 15 32.1064615 17.5381874 34.8461538 29.5107692 21.9230769 17.5381874 9"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
