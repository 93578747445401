import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function StopIcon(props) {
  return (
    <SvgIcon {...props}>
      <path id="Vector" d="M2.5 2H22.5V22H2.5V2Z" fill="white" />
    </SvgIcon>
  );
}
