import { Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import { useModal } from "mui-modal-provider";

import Image1 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/azubi-top-view.webp"
import Image2 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/feier.webp"
import Image3 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/gruppenfoto.webp"
import Image4 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/meeting-1.webp"
import Image5 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/meeting-2.webp"
import Image6 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/praesentation.webp"
import Image7 from "../../../../../../assets/_Chapters/Karriere/AusbildungUndStudium/thumbnail/sortierung.webp"
import { QDialog, QGalery } from "playbook-pwa-ui";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.secondary.main,
  },
}));

export default function Galerie() {
  const { showModal } = useModal();
  const dialog = (image, alt) => {
    const d = showModal(QDialog, {
      children: (
        <QGalery
          images={[
            {
              src: image,
              alt: alt,
            },
          ]}
          closeHandler={() => d.hide()}
        />
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };
  
  return (
    <Slide background>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} style={{ height: "100%" }}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: "100%", height: "100%"}} viewBox="0 0 768 379" fill="none">
          <g clip-path="url(#clip0_892_134)">
          <mask id="mask0_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="0" width="193" height="238">
          <rect x="200" width="193" height="238" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_892_134)">
          <rect x="83" width="358" height="238" fill="url(#pattern0_892_134)"/>
          </g>
          <mask id="mask1_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="400" y="65" width="167" height="173">
          <rect x="400" y="65" width="167" height="173" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask1_892_134)">
          <rect x="340" y="65" width="260" height="173" fill="url(#pattern1_892_134)"/>
          </g>
          <mask id="mask2_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="574" y="22" width="194" height="273">
          <rect x="574" y="22" width="194" height="273" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask2_892_134)">
          <rect x="567" y="2" width="232" height="308" fill="url(#pattern2_892_134)"/>
          </g>
          <mask id="mask3_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="22" width="193" height="94">
          <rect y="22" width="193" height="94" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask3_892_134)">
          <rect y="8" width="193" height="108" fill="url(#pattern3_892_134)"/>
          </g>
          <mask id="mask4_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="123" width="193" height="205">
          <rect y="123" width="193" height="205" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask4_892_134)">
          <rect x="-38" y="123" width="308" height="205" fill="url(#pattern4_892_134)"/>
          </g>
          <mask id="mask5_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="200" y="245" width="160" height="116">
          <rect x="200" y="245" width="160" height="116" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask5_892_134)">
          <rect x="200" y="239" width="162" height="122" fill="url(#pattern5_892_134)"/>
          </g>
          <mask id="mask6_892_134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="367" y="245" width="200" height="134">
          <rect x="367" y="245" width="200" height="134" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask6_892_134)">
          <rect x="327" y="245" width="240" height="134" fill="url(#pattern6_892_134)"/>
          </g>
          <rect y="22" width="193" height="94" fill="black" fill-opacity="0" 
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image4, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect y="123" width="193" height="205" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image2, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect x="200" width="193" height="238" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image6, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect x="400" y="65" width="167" height="173" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image7, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect x="574" y="22" width="194" height="273" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image1, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect x="200" y="245" width="160" height="116" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image3, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          <rect x="367" y="245" width="200" height="134" fill="black" fill-opacity="0"
                style={{ cursor: "pointer" }}
                onClick={() => dialog(Image5, "Ausbildung und Studium bei SSV-Kroschke")}
          />
          </g>
            <defs>
            <pattern id="pattern0_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_892_134" transform="matrix(0.000244141 0 0 0.000367237 0 -0.00146177)"/>
            </pattern>
            <pattern id="pattern1_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image1_892_134" transform="matrix(0.000244141 0 0 0.000366917 0 -0.00102454)"/>
            </pattern>
            <pattern id="pattern2_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image2_892_134" transform="matrix(0.000330688 0 0 0.00024909 0 -0.0021645)"/>
            </pattern>
            <pattern id="pattern3_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image3_892_134" transform="matrix(0.00078125 0 0 0.00139612 0 -0.00260417)"/>
            </pattern>
            <pattern id="pattern4_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image4_892_134" transform="matrix(0.000244141 0 0 0.000366806 0 -0.000874143)"/>
            </pattern>
            <pattern id="pattern5_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image5_892_134" transform="matrix(0.0002782 0 0 0.000369413 -0.00215035 0)"/>
            </pattern>
            <pattern id="pattern6_892_134" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image6_892_134" transform="matrix(0.00078125 0 0 0.00139925 0 -0.00373134)"/>
            </pattern>
            <clipPath id="clip0_892_134">
            <rect width="768" height="379" fill="white"/>
            </clipPath>
            <image id="image0_892_134" width="4096" height="2731" xlinkHref={Image6}/>
            <image id="image1_892_134" width="4096" height="2731" xlinkHref={Image7}/>
            <image id="image2_892_134" width="3024" height="4032" xlinkHref={Image1}/>
            <image id="image3_892_134" width="1280" height="720" xlinkHref={Image4}/>
            <image id="image4_892_134" width="4096" height="2731" xlinkHref={Image2}/>
            <image id="image5_892_134" width="3610" height="2707" xlinkHref={Image3}/>
            <image id="image6_892_134" width="1280" height="720" xlinkHref={Image5}/>
            </defs>
          </svg>
        </Grid>
      </Grid>
    </Slide>
  );
}
