import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/BeratungPlanungMontage/7.webp";
import { Grid, Typography } from "@material-ui/core";

export default function VR() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Ihre Planung mit VR" />
          <Typography variant="body1">
            Virtual Reality heißt „virtuelle Realität“ und ist eine
            computergenerierte Wirklichkeit, in die eingetaucht werden kann.
          </Typography>
          <QList
            items={[
              "Begutachtung der Betriebseinrichtung in Lebensgröße",
              "Bewegung im virtuellen Raum",
              "Realistischer Eindruck von Größen, Entfernungen und Farben vor dem Aufbau",
              "Platzbedarf kann genauer geplant werden",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
