import { h } from "preact";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Frame" clip-path="url(#clip0_140_4365)">
        <path
          id="Vector"
          d="M23.809 21.646L17.604 15.441C18.771 13.836 19.461 11.862 19.461 9.73C19.461 4.365 15.096 0 9.73 0C4.365 0 0 4.365 0 9.73C0 15.096 4.365 19.46 9.73 19.46C11.764 19.46 13.653 18.833 15.217 17.762L21.455 24L23.809 21.646ZM2.854 9.73C2.854 5.938 5.939 2.853 9.731 2.853C13.523 2.853 16.608 5.938 16.608 9.73C16.608 13.522 13.523 16.607 9.731 16.607C5.938 16.607 2.854 13.522 2.854 9.73Z"
          fill="#4A4949"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_4365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
