import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import { theme } from "../../../../../../theme";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "12px",
    color: "white !important",
    borderColor: "white",
  },
}));

export default function UnsereMission() {
  const classes = useStyles();
  return (
    <Slide background={{ width: "100dvw", color: theme.palette.primary.main }}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography
              variant="body1"
              align="center"
              style={{ color: "white" }}
            >
              UNSERE MISSION
            </Typography>
            <QHeader
              color="white"
              textAlign="center"
              title="Wir machen unsere Kunden erfolgreicher und schaffen für sie nachhaltige Wettbewerbsvorteile!"
            />
            <Button
              href="#mission/1"
              variant="outlined"
              size="medium"
              className={clsx(classes.button, "gsap-headline-button")}
              endIcon={<ChevronRightIcon />}
            >
              Wie wir das machen
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Slide>
  );
}
