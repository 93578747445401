import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../components/Slide";
import QHeader from "../../../../components/QHeader";

import BackgroundImage from "../../../../assets/_Chapters/_Images/background/E-Procurement/1.jpg";

const useStyles = makeStyles((theme) => ({
  fontBold: {
    fontWeight: 700,
  },
}));
export default function EProcurementSlide() {
  const classes = useStyles();

  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader
            title={
              <>
                E-Procurement —<br />
                Beschaffung leicht gemacht
              </>
            }
          />
          <Box marginBottom="14px">
            <Typography variant="body1">
              Elektronische Beschaffung, kurz E-Procurement, bezieht sich auf
              den digitalen Einkauf von Waren und Dienstleistungen.
            </Typography>
          </Box>
          <Typography variant="body1">
            Unsere{" "}
            <span className={classes.fontBold}>SSV-Beschaffungsplattform</span>{" "}
            vereinfacht und automatisiert Prozesse, und optimiert somit Ihre
            Beschaffungsstrategie.
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
