const SET_CONSULTANT_SALUTATION = "playbook/consultant/SET_SALUTATION";
const SET_CONSULTANT_FIRSTNAME = "playbook/consultant/SET_FIRSTNAME";
const SET_CONSULTANT_LASTNAME = "playbook/consultant/SET_LASTNAME";
const SET_CONSULTANT_EMAIL = "playbook/consultant/SET_EMAIL";
const SET_CONSULTANT_COMPANY = "playbook/consultant/SET_COMPANY";
export const RESET_CONSULTANT = "playbook/consultant/RESET_CONSULTANT";

const initialState = {
  salutation: "Herr",
  firstname: "",
  lastname: "",
  email: "",
  companyID: "",
  company: {
    id: "",
    name: "",
    street: "",
    postcode: "",
    city: "",
    logo: "",
  },
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULTANT_SALUTATION: {
      return {
        ...state,
        salutation: action.salutation,
      };
    }
    case SET_CONSULTANT_FIRSTNAME: {
      return {
        ...state,
        firstname: action.firstname,
      };
    }
    case SET_CONSULTANT_LASTNAME: {
      return {
        ...state,
        lastname: action.lastname,
      };
    }
    case SET_CONSULTANT_EMAIL: {
      return {
        ...state,
        email: action.email.toLowerCase().trim(),
      };
    }
    case SET_CONSULTANT_COMPANY: {
      return {
        ...state,
        companyID: action.company.id,
        company: {
          ...state.company,
          ...action.company,
        },
      };
    }
    case RESET_CONSULTANT: {
      return initialState;
    }
    default:
      return state;
  }
};

//actions
export const setConsultantSalutation = (salutation) => ({
  type: SET_CONSULTANT_SALUTATION,
  salutation,
});

export const setConsultantFirstname = (firstname) => ({
  type: SET_CONSULTANT_FIRSTNAME,
  firstname,
});

export const setConsultantLastname = (lastname) => ({
  type: SET_CONSULTANT_LASTNAME,
  lastname,
});

export const setConsultantEmail = (email) => ({
  type: SET_CONSULTANT_EMAIL,
  email,
});

export const setConsultantCompany = (company) => ({
  type: SET_CONSULTANT_COMPANY,
  company,
});

export const resetConsultant = () => ({
  type: RESET_CONSULTANT,
});

//selector
export const getConsultantSalutation = (state) => state.consultant.salutation;
export const getConsultantFirstname = (state) => state.consultant.firstname;
export const getConsultantLastname = (state) => state.consultant.lastname;
export const getConsultantEmail = (state) => state.consultant.email;
export const getConsultantCompany = (state) => state.consultant.company;
export const getConsultantData = (state) => state.consultant;
export default settings;
