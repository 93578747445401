import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Ueber-SSV-Kroschke/2.webp";
import { Grid } from "@material-ui/core";

export default function UnsereVisionAntwort() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Wie wir das machen:" />
          <QList
            items={[
              "Wir bauen für unsere Kunden unser Dienstleistungsprogramm immer weiter aus",
              "Wir bündeln Kompetenzen in allen Bereichen, um alle Herausforderungen lösen zu können",
              "Wir sind bereits deutschlandweit ansässig und setzen zukünftig unsere Projekte auch im europäischen Ausland um",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
