import { Box, Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import DasZielProzesseReduzierenSVG from "./_Components/DasZielProzesseReduzierenSVG";
import { BetterImage } from "playbook-pwa-ui";

export default function DasZielProzesseReduzieren() {
  return (
    <Slide
      background
      padding={{ top: true, bottom: true, left: false, right: true }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <QHeader textAlign="center" title="Das Ziel: Prozesse reduzieren" />
        </Grid>
        <Grid item xs={12} style={{ height: "90%" }}>
          <DasZielProzesseReduzierenSVG
            style={{ height: "100%", width: "100%" }}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
