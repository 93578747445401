import Slide from "../../../../../../components/Slide";
import { Grid, Typography } from "@material-ui/core";
import QHeader from "../../../../../../components/QHeader";

// SVGs
import PSAVollversorgungskonzeptSVG from "./_Components/PSAVollversorgungskonzeptSVG";
import ServicesBetriebseinrichtungSVG from "./_Components/ServicesBetriebseinrichtungSVG";
import ServicesZerspanungSVG from "./_Components/ServicesZerspanungSVG";
import VerwaltungMessmittelElektrowerkzeugeSVG from "./_Components/VerwaltungMessmittelElektrowerkzeugeSVG";

export default function Fakten2() {

  return (
    <Slide>
      <Grid container>
        <Grid item xs={12}>
          <QHeader title="Das steckt alles drin" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <PSAVollversorgungskonzeptSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                PSA-Vollversorgungskonzept
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ServicesBetriebseinrichtungSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Dienstleistungspakete für Betriebseinrichtung
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ServicesZerspanungSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Dienstleistungspakete für Zerspanung
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <VerwaltungMessmittelElektrowerkzeugeSVG className="gsap-facts" />
              <Typography align="center" variant="body1">
                Verwaltung Messmittel und Elektrowerkzeuge
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
