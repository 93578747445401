import { theme } from "../../../theme";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Waage(props) {
    const primaryColor = theme.palette.primary.main;
  return (
    <SvgIcon {...props} style={{width: "100%", height: "100%"}} viewBox="0 0 749 721">
    <mask id="mask0_896_512" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="178" width="657" height="364">
    <path d="M703 178H46V541.696H703V178Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_896_512)">
    <mask id="mask1_896_512" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="181" width="657" height="361">
    <path d="M703 181.912H46V541.698H703V181.912Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_896_512)">
    <mask id="mask2_896_512" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="181" width="657" height="361">
    <path d="M703 181.914H46V541.7H703V181.914Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_896_512)">
    <mask id="mask3_896_512" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="181" width="657" height="361">
    <path d="M703 181.916H46V541.702H703V181.916Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_896_512)">
    <path d="M700.388 381.875H506.487C506.487 415.777 544.155 443.691 592.601 447.341V463.72H406.601V452.066C406.601 445.121 401.027 439.487 394.156 439.487H352.232C345.361 439.487 339.787 445.121 339.787 452.066V463.72H153.789V447.341C202.219 443.675 239.901 415.76 239.901 381.875H46C46 415.777 83.6655 443.691 132.112 447.341V463.72H102.981C99.8529 463.72 97.3242 466.276 97.3242 469.437C97.3242 472.599 99.8529 475.155 102.981 475.155H339.787V507.459H326.994C315.598 507.459 306.364 516.792 306.364 528.311V541.663H440.024V528.311C440.024 516.792 430.79 507.459 419.394 507.459H406.601V475.155H643.389C646.518 475.155 649.048 472.599 649.048 469.437C649.048 466.276 646.518 463.72 643.389 463.72H614.258V447.341C662.688 443.675 700.372 415.76 700.372 381.875H700.388Z" fill={primaryColor}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M118.201 206.01C118.201 204.362 118.85 202.798 120.014 201.621C121.196 200.444 122.743 199.788 124.357 199.788H162.705C164.351 199.788 165.898 200.427 167.047 201.604C168.211 202.798 168.86 204.362 168.86 206.01V208.886C168.86 210.012 169.775 210.937 170.89 210.937H184.482C185.596 210.937 186.511 210.012 186.511 208.886V206.01C186.511 199.67 184.066 193.684 179.624 189.143C179.591 189.093 179.541 189.059 179.507 189.009C174.999 184.469 169.026 181.963 162.721 181.963H124.373C118.035 181.963 112.062 184.469 107.554 189.009C103.045 193.566 100.566 199.603 100.566 206.01V208.886C100.566 210.012 101.481 210.937 102.596 210.937H116.188C117.303 210.937 118.218 210.012 118.218 208.886V206.01H118.201Z" fill={primaryColor}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M220.386 218.543H66.1141C60.0251 218.543 55.1006 223.521 55.1006 229.675V255.892C55.1006 259.154 56.548 261.878 59.3762 263.98C60.9567 265.158 63.0197 266.15 65.4985 266.923L123.344 285.236C123.993 285.438 124.658 284.95 124.658 284.261V276.508C124.658 270.774 129.25 266.116 134.94 266.116H151.56C157.233 266.116 161.842 270.757 161.842 276.508V284.261C161.842 284.95 162.507 285.438 163.156 285.236L221.002 266.923C223.531 266.133 225.544 265.158 227.124 263.964C229.936 261.895 231.416 259.104 231.416 255.892V229.675C231.416 223.521 226.492 218.543 220.403 218.543H220.386Z" fill={primaryColor}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M161.827 299.476V303.815C161.827 309.549 157.235 314.207 151.545 314.207H134.925C129.252 314.207 124.644 309.566 124.644 303.815V299.476C124.644 298.585 124.061 297.795 123.229 297.525L63.67 278.674C62.8548 278.422 62.0729 278.136 61.3408 277.834C59.9933 277.296 58.5459 278.271 58.5459 279.717V341.987C58.5459 348.125 63.4703 353.103 69.5427 353.103H216.927C223 353.103 227.924 348.125 227.924 341.987V279.717C227.924 278.254 226.46 277.279 225.129 277.834C224.38 278.136 223.599 278.406 222.783 278.674L163.241 297.525C162.392 297.795 161.827 298.585 161.827 299.476Z" fill={primaryColor}/>
    <path d="M151.561 275.195C153.877 275.195 155.754 277.093 155.754 279.433V300.857C155.754 303.197 153.877 305.095 151.561 305.095H134.941C132.626 305.095 130.749 303.197 130.749 300.857V279.433C130.749 277.093 132.626 275.195 134.941 275.195H151.561Z" fill={primaryColor}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M559.678 190.289C527.535 190.188 499.085 223.198 512.229 262.397C527.336 301.159 569.192 334.875 605.977 362.353C642.761 334.875 684.617 301.142 699.724 262.397C712.849 223.198 684.418 190.188 652.275 190.289C636.023 190.34 619.234 199.185 605.977 219.684C592.735 199.185 575.946 190.34 559.678 190.289Z" fill={primaryColor}/>
    </g>
    </g>
    </g>
    </g>
    </SvgIcon>
  );
}
