import AufEinenBlick1 from "./slides/AufEinenBlick1";
import AufEinenBlick2 from "./slides/AufEinenBlick2";
import Galerie from "./slides/Galerie";
import NavSlide from "./slides/NavSlide";
import TeilDesTeams from "./slides/TeilDesTeams";

export default function Allgemeines() {
  return [
    <TeilDesTeams key="teil-des-teams" />,
    <Galerie key="galerie" />,
    <AufEinenBlick1 key="auf-einen-blick-1" />,
    <AufEinenBlick2 key="auf-einen-blick-2" />,
    <NavSlide key="nav-slide" />,
  ];
}
