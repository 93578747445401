import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/5.webp";
import { Grid } from "@material-ui/core";

export default function PersonalisierteBerufsbekleidung() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Personalisierte Berufsbekleidung" />
          <QList
            items={[
              "Druck oder Bestickung nach Ihren Wünschen",
              "Keine Herstellerbindung",
              "Individuelle Beschaffungslösungen",
              "Erstellung von Konzepten für Wäscheservice",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
