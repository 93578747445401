import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";

import BerufsbekleidungSVG from "./_Components/BerufsbekleidungSVG";
import BetrieblicheSicherheitSVG from "./_Components/BetrieblicheSicherheitSVG";
import FallschutzSVG from "./_Components/FallschutzSVG";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function Beschaffung2() {

  return (
    <Slide>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <QHeader
            title="Beschaffung bis an den Arbeitsplatz"
            textAlign="center"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={3}>
              <Link href="#leistungen/7" color="initial">
                <BerufsbekleidungSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Personalisierte Berufsbekleidung
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#leistungen/7" color="initial">
                <BetrieblicheSicherheitSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Betriebliche Sicherheit
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link href="#leistungen/8" color="initial">
                <FallschutzSVG className="gsap-facts" />
                <Typography align="center" variant="body1">
                  Fallschutz
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
