import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import { preTheme } from "../../../../../../theme";

import Image2000 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2000.webp";
import Image2001 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2001.webp";
import Image2002 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2002.webp";
import Image2005 from "../../../../../../assets/_Chapters/UeberSSVKroschke/timeline/2005.webp";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
  fitContentHeight: {
    height: "fit-content",
    "& .MuiTypography-body2": {
      height: "20dvh",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      fontSize: "13px",
      [preTheme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
      // 1200px
      [preTheme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
      // 1200px
      [preTheme.breakpoints.up("xxl")]: {
        fontSize: "18px",
      },
      // 1400px
      [preTheme.breakpoints.up("xxxl")]: {
        fontSize: "19px",
      },
    },
  },
  topPosition: {
    justifyContent: "flex-start",
  },
  bottomPosition: {
    justifyContent: "flex-end",
  },
}));

export default function Second() {
  const classes = useStyles();

  return (
    <Slide padding={{ top: true, bottom: true }}>
      <Box display="flex" height="100%" alignItems="center">
        <Grid
          container
          alignItems="center"
          className={classes.fitContentHeight}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.bottomPosition}>
              <span>
                <strong>
                  Einführung des Dienstleistungspaketes „Betriebseinrichtung“.
                </strong>{" "}
                Betriebseinrichtungsprojekte sind nun bei SSV-Technik in besten
                Händen. Beginnend beim Aufmaß, über die Entwicklung
                praxisgerechter Lösungen, der Planung bis zur Montage bietet die
                SSV-Technik kompetente Beratung, individuelle Betreuung und
                komplette Projektabwicklung an.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.bottomPosition}>
              <span>
                <strong>
                  Einführung des Dienstleistungspaketes „Zerspanung“.
                </strong>{" "}
                Um die Werkzeugkosten, Laufzeiten und Bearbeitungskosten der
                Kunden zu optimieren, werden Produkttests unter laufenden
                Bedingungen mit eigenen Anwendungstechnikern durchgeführt.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={Image2000} alt="2000" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2001} alt="2001" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2002} alt="2002" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <img src={Image2005} alt="2005" className={classes.image} />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.topPosition}>
              <span>
                <strong>Ein Süßigkeitenautomat</strong> am Eisenacher Bahnhof
                brachte die Idee der Versorgung von Industrieunternehmen mit
                MRO-Artikeln. Im gleichen Jahr wurde nach geeigneten Systemen
                gesucht und die Erarbeitung des Vollversorgungskonzeptes begann.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.topPosition}>
              <span>
                <strong>
                  Aufstellung des ersten elektronischen Ausgabesystems
                </strong>{" "}
                bei einem Automobilzulieferer in der Nähe von Eisenach. Die
                Versorgung mit allen C-Teilen begann. Seither werden die
                Systeme, die Software und das Konzept immer weiter ausgebaut, um
                den Kunden größtmöglichen Nutzen zu bieten.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </Slide>
  );
}
