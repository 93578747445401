import {createSelector} from 'reselect'

const SET_BACKEND_STATE = 'playbook/state/SET_BACKEND_STATE'

const initialState = {
  modules: [],
  version: '',
  fetchDate: null,
  initialState: null,
}

const backendState = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKEND_STATE: {
      return {
        ...state,
        modules: action.payload.modules,
        version: action.payload.version,
        initialState: {
          modules: action.payload.modules,
          version: action.payload.version,
        },
        fetchDate: new Date(),
      }
    }
    default:
      return state
  }
}

//actions
export const fetchBackendState = payload => ({
  type: SET_BACKEND_STATE,
  payload,
})

//selector
export const getStateVersion = state => state.backendState.version
export const getStateModules = state => state.backendState.modules

export const getModuleByID = (state, id) =>
  createSelector(getStateModules, modules =>
    modules.find(module => module.chapter.id === id),
  )(state)

export const getModuleByKey = (state, key) =>
  createSelector(getStateModules, modules =>
    modules.find(module => module.chapter.key === key),
  )(state)

export const getActiveModules = createSelector(getStateModules, modules => {
  return modules
    .filter(module => module.chapter.enabled === true)
    .sort((a, b) => a.chapter.sort - b.chapter.sort)
})

export const getActiveChapter = createSelector(getStateModules, modules => {
  return modules
    .filter(
      module =>
        module.chapter.enabled === true &&
        module.chapter.category.key === 'chapter',
    )
    .sort((a, b) => a.chapter.sort - b.chapter.sort)
})

export const getChapterByID = (state, id) =>
  createSelector(getStateModules, modules =>
    modules.find(module => module.chapter.id === id),
  )(state)

  export const getChapterByKey = (state, key) =>
    createSelector(getStateModules, modules =>
      modules.find(module => module.chapter.key === key),
    )(state)

export const getActiveApplications = createSelector(
  getStateModules,
  modules => {
    return modules
      .filter(
        module =>
          module.chapter.enabled === true &&
          module.chapter.category.key === 'applications',
      )
      .sort((a, b) => a.chapter.sort - b.chapter.sort)
  },
)

export const getModuleChapterCategories = createSelector(
  getStateModules,
  modules =>
    modules
      .map(module => module.chapter.category)
      .filter(
        (category, index, self) =>
          index === self.findIndex(c => c.id === category.id),
      )
      .sort((a, b) => a.sort - b.sort),
)

export const getModuleKeys = createSelector(getStateModules, modules =>
  modules.map(module => module.key),
)

export const getFields = createSelector(getStateModules, modules =>
  modules.map(module => module.fields),
)

export default backendState
