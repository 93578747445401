import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import { Grid } from "@material-ui/core";
import WirFindenDiePerfekteLoesungSVG from "../_Components/WirFindenDiePerfekteLoesungSVG";

export default function WirFindenDiePerfekteLoesung() {
  return (
    <Slide>
      <Grid container spacing={5} justify="center">
        <Grid item xs={12}>
          <QHeader title="Wir finden die perfekte Lösung" textAlign="center" />
        </Grid>
        <Grid item xs={12}>
          <WirFindenDiePerfekteLoesungSVG
            width="70%"
            style={{ margin: "0px auto", display: "block" }}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
