import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/PSA-Vollversorgung/2.webp";
import { Box, Grid } from "@material-ui/core";

export default function Otoplastiken() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Anpassen von Otoplastiken" />
          <QList
            items={[
              "Verschiedene Materialien, Bauformen und variable Dämmwirkung",
              "Perfekter Gehörschutz bei höchstmöglichem Tragekomfor",
              "Nachhaltig: Einsatzdauer liegt bei rund 5 Jahren",
              "Funktionsprüfung auf Passform und Wirksamkeit",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Box position="absolute" bottom="15px" right="15px" fontSize="12px">
        Foto: UVEX ARBEITSSCHUTZ GmbH
      </Box>
    </Slide>
  );
}
