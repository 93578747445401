import Slide from "../../../../../components/Slide";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";

import BackgroundImage from "../../../../../assets/_Chapters/_Images/background/VerwaltungMessmittelUndElektrowerkzeuge/1.webp";
import { Grid } from "@material-ui/core";

export default function SlideElektrowerkzeuge() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
        position: "center",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Verwaltung von Elektrowerkzeugen" />
          <QList
            items={[
              "Klare Regelung von Zugriffsrechten",
              "Genauer Überblick über die im Umlauf befindlichen Elektrowerkzeuge",
            ]}
          />
          <QList
            headline="SSV-Mietservice für Elektrowerkzeuge:"
            items={[
              "Zusätzliche Senkung von Investitionskosten",
              "Wir übernehmen Wartung, Reparatur und Ersatz",
              "Laufzeit richtet sich flexibel nach Ihren Anforderungen",
            ]}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Slide>
  );
}
