import { Grid, Box, makeStyles } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";

import Logo1 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/3m.png";
import Logo2 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/ansell.jpg";
import Logo3 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/atlas.png";
import Logo4 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/blaklader.png";
import Logo5 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/dupont.png";
import Logo6 from "../../../../../../assets/_Chapters/CTeileSortiment/Arbeitsschutz/elten.png";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/C-Teile-Sortiment/arbeitsschutz.webp";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridAutoRows: "auto 100%",
    "& .MuiGrid-grid-xs-4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: "75%",
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100px",
        width: "auto",
        maxWidth: "75%",
      },
    },
  },
});

export default function Arbeitsschutz1() {
  const classes = useStyles();

  return (
    <Slide
      background={{
        image: BackgroundImage,
        size: "55dvw",
        align: "left",
        position: "top left",
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <QHeader title="Arbeitsschutz" textAlign="center" />
        </Grid>
        <Grid container spacing={5} style={{ height: "auto" }}>
          <Grid item xs={4}>
            <img src={Logo1} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo2} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo3} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo4} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo5} alt="Logo" loading="lazy" />
          </Grid>
          <Grid item xs={4}>
            <img src={Logo6} alt="Logo" loading="lazy" />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
