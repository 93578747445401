import EProcurementSlide from "../sections/EProcurementSlide";
import SSVTradeCore from "../sections/SSVTradeCore";
import Vorteile1 from "../sections/Vorteile/slides/Vorteile1";
import Vorteile2 from "../sections/Vorteile/slides/Vorteile2";
import Vorteile3 from "../sections/Vorteile/slides/Vorteile3";
import Vorteile4 from "../sections/Vorteile/slides/Vorteile4";

export default function OneSection() {
    return [
        <EProcurementSlide key="a" />,
        <SSVTradeCore key="b" />,
        <Vorteile1 key="vorteile-1" />,
        <Vorteile2 key="vorteile-2" />,
        <Vorteile3 key="vorteile-3" />,
        <Vorteile4 key="vorteile-4" />,
    ];
  }
  