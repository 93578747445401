import Beschaffung from "./slides/Beschaffung";
import Betriebseinrichtung from "./slides/Betriebseinrichtung";
import Inspektion from "./slides/Inspektion";
import Montage from "./slides/Montage";
import Planung from "./slides/Planung";

export default function BeratungPlanungMontage() {
  return [
    <Betriebseinrichtung key="betriebseinrichtung" />,
    <Planung key="planung" />,
    <Beschaffung key="beschaffung" />,
    <Montage key="montage" />,
    <Inspektion key="inspektion" />,
  ];
}
