import Galerie from "./slides/Galerie";
import Gesundheit from "./slides/Gesundheit";
import NavSlide from "./slides/NavSlide";
import Paket from "./slides/Paket";
import Video from "./slides/Video";
import VideoOuttakes from "./slides/VideoOuttakes";
import WorkLifeBalance from "./slides/WorkLifeBalance";
import Zitat1 from "./slides/Zitat1";
import Zitat2 from "./slides/Zitat2";

export default function Berufserfahrene() {
  return [
    <Video key="video" />,
    <Zitat1 key="zitat1" />,
    <Galerie key="galerie" />,
    <WorkLifeBalance key="work-life-balance" />,
    <Gesundheit key="gesundheit" />,
    <Paket key="paket" />,
    <Zitat2 key="zitat2" />,
    <VideoOuttakes key="video-outtakes" />,
    <NavSlide key="nav-slide" />,
  ];
}
